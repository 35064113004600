import { NgModule } from '@angular/core';

import * as pipes from './pipes';
import { CommonModule } from '@angular/common';
import {CustomDate} from './pipes/custom-date.pipe';



@NgModule({
  imports: [CommonModule],
  declarations: [
    pipes.CourierNamePipe,
    pipes.CourierLogoPipe,
    pipes.DefaultTextPipe,
    pipes.VendorLogoPipe,
    pipes.ShipmentStatusLogoPipe,
    pipes.VendorNamePipe,
    pipes.IconSourcePipe,
    pipes.PaymentModePipe,
    pipes.PaymentStatusPipe,
    pipes.PaymentTypePipe,
    pipes.PhoneFormatPipe,
    pipes.PhoneDialFormatPipe,
    pipes.TrackingStatusColorPipe,
    pipes.TrackingStatusColorFFPipe,
    pipes.TrackingStatusPipe,
    pipes.TrackingStatusFFPipe,
    pipes.TrackStatusPipe,
    pipes.RefundStatusPipe,
    pipes.RefundStatusDescriptionPipe,
    pipes.StateIsoNamePipe,
    pipes.SuffixPipe,
    pipes.SuffixPackagePipe,
    pipes.OrderStatusPipe,
    pipes.OrderStatusFFPipe,
    pipes.AppImagePipe,
    pipes.AppIconPipe,
    pipes.CurrenciesPipe,
    pipes.CurrenciesNamePipe,
    pipes.CourierNamePipe,
    pipes.CustomDate,
    pipes.StoreImagePipe,
    pipes.FeeTypePipe,
  ],
  exports: [
    pipes.CourierNamePipe,
    pipes.CourierLogoPipe,
    pipes.DefaultTextPipe,
    pipes.VendorLogoPipe,
    pipes.ShipmentStatusLogoPipe,
    pipes.VendorNamePipe,
    pipes.IconSourcePipe,
    pipes.PaymentModePipe,
    pipes.PaymentStatusPipe,
    pipes.PaymentTypePipe,
    pipes.PhoneFormatPipe,
    pipes.PhoneDialFormatPipe,
    pipes.TrackingStatusColorPipe,
    pipes.TrackingStatusColorFFPipe,
    pipes.TrackingStatusPipe,
    pipes.TrackingStatusFFPipe,
    pipes.TrackStatusPipe,
    pipes.RefundStatusPipe,
    pipes.RefundStatusDescriptionPipe,
    pipes.StateIsoNamePipe,
    pipes.SuffixPipe,
    pipes.SuffixPackagePipe,
    pipes.OrderStatusPipe,
    pipes.OrderStatusFFPipe,
    pipes.AppImagePipe,
    pipes.AppIconPipe,
    pipes.CurrenciesPipe,
    pipes.CurrenciesNamePipe,
    pipes.CustomDate,
    pipes.StoreImagePipe,
    pipes.FeeTypePipe,
  ]
})
export class PipesModule { }
