import { Component, ViewChild, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { ModalComponent } from '../../shared/components/modal.component';
import { ShipmentTransaction } from 'src/app/core/models/models';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-confirmation-comment-modal',
    templateUrl: './confirmation-comment-modal.component.html'
})
export class ConfirmationCommentModalComponent {
    @Input() header?: string;
    @Input() message?: string;
    @Input() okText = 'Aceptar';
    @Input() cancelText = 'Cancelar';
    @Input() oTransaction? : ShipmentTransaction=null;
    @Output() close = new EventEmitter();
    @ViewChild(ModalComponent, { static : true } ) private modal: ModalComponent;

    countryCode = environment.countryCode;
    currencyDecimal = (environment.countryCode === "MX" || environment.countryCode === "ES") ? "1.2-2" : "1.0-0";
    defaultTextDecimal = (environment.countryCode === "MX" || environment.countryCode === "ES") ? "0.00" : "0";
    currencySymbol = environment.countryCode === "MX" ? "MXN" : environment.countryCode === "ES" ? "EUR" : "COP";

    show(selTransaction) {
        this.modal.show();
        this.commentForm = this.formBuilder.group({
            comment: [''
                , Validators.compose([
                    Validators.required
                    , Validators.minLength(3)
                    ,Validators.maxLength(500)])]
            });
        this.oTransaction=selTransaction;
    }

    hide() {
        this.modal.hide();
        this.close.emit(false);
    }

    commentForm: FormGroup;

    constructor(private formBuilder: FormBuilder) { }

    ngOnInit() {
        this.commentForm = this.formBuilder.group({
        comment: [''
            , Validators.compose([
                Validators.required
                , Validators.minLength(3)
                ,Validators.maxLength(500)])]
        });
    }

    ok(){
        this.modal.hide();
        this.close.emit({ confirm:true
            ,selTransaction:this.oTransaction
            ,comment:this.commentForm.get("comment").value
        });
    }

}
