import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {environment} from '../../../environments/environment';

@Directive({
  selector: '[appHideComponent]'
})
export class HideComponentDirective implements OnInit {

  @Input('appHideComponent') permission: string;

  environmentConfig = environment;

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    if (this.environmentConfig && this.permission && this.permission !== '') {
      const propertyValue = this.getValue(this.environmentConfig, this.permission);
      if (!propertyValue) {
        this.elementRef.nativeElement.remove();
      }
    }
  }

  private getValue(object, path): boolean {
    const properties = path.split('.');
    return (properties.length > 1) ?
      this.getValue(object[properties.shift()], properties.join('.')) : object[properties.shift()];
  }

}
