import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'phoneFormat'
})
export class PhoneFormatPipe implements PipeTransform {

    transform(value: string): string {

        let dict;
        let phone = '';

        //validar valor
        if (!value || value.length < 1) { return null; }

        if (isNaN(Number(value))) { return value; }

        let newData =  value.split(' ').join('');

        //Formatos (concatenar al iterar)

        if(newData.length > 3){
            dict = { 0: '(', 3: ') ' };
        }

        // if (value.length <= 2) {

        //     return '(' + value + ')';

        // } else if (value.length > 6) {

        //     dict = { 0: '(', 2: ') ', 6: '-' };
        // } else if (value.length > 2) {

        //     dict = { 0: '(', 2: ') ' };

        // }

        //Iterar y construir cadena
        for (var i = 0; i < newData.length; i++) {
            phone += (dict[i] || '') + newData[i];
        }

        return phone.trim();
    }
}
