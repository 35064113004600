import { Pipe, PipeTransform } from '@angular/core';
import { CustomValidators } from '../validators/custom-validators';

enum RouteImage {
  MGT = 'assets/img/brand/logo_magento.png' ,
  KOM = 'assets/img/brand/logo_kometia.png',
  SPF = 'assets/img/brand/logo_shopify.png',
  WOO = 'assets/img/brand/logo_woocommerce.png',
  PRS = 'assets/img/brand/logo_prestashop.png',
  AWZ = 'assets/img/brand/logo_amazon.png',
  MER = 'assets/img/brand/logo_mercadolibre.png',
  TNG = 'assets/img/brand/logo_withoutStore.png',
  JUM = 'assets/img/brand/logo_jumpseller.png',
  VTX = 'assets/img/brand/logo_vtex.png',
}

@Pipe({
  name: 'storeImage'
})
export class StoreImagePipe implements PipeTransform {

  transform(object: any, ...args: any[]): any {
    if(object['Config'] && object['Config']['image'] && CustomValidators.notEmpty(object)) return object.Config.image;
  
    return RouteImage[args[0].StoreId];
  }

}