import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  ViewEncapsulation
} from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  searchForm: FormGroup;
  ngOnInit() {
    this.msgPlaceHolder = "Buscar por folio...";
    this.searchForm = this.fb.group({
      searchOption: ["1", Validators.required],
      searchText: [
        this.searchInput.nativeElement.value,
        [
          Validators.required,
          //Validators.pattern("^[0-9]*$"),
          //Validators.minLength(1),
          //Validators.maxLength(20)
        ]
      ]
    });
  }

  msgPlaceHolder = "";
  radioChange(evnt) {
    switch (evnt.value) {
      case "1":
        this.msgPlaceHolder = "Buscar por folio...";
        this.searchForm = this.fb.group({
          searchOption: ["1", Validators.required],
          searchText: [
            this.searchInput.nativeElement.value,
            [
              Validators.required,
              //Validators.pattern("^[0-9]*$"),
              //Validators.minLength(1),
              //Validators.maxLength(20)
            ]
          ]
        });
        break;
      case "2":
        this.searchForm = this.fb.group({
          searchOption: ["2", Validators.required],
          searchText: [
            this.searchInput.nativeElement.value,
            [
              Validators.required,
              //Validators.minLength(5),
              //Validators.maxLength(20)
            ]
          ]
        });
        this.msgPlaceHolder = `Buscar por número de ${environment.countryCode === 'ES' ? 'envío' : 'guía'}...`;
        break;
      case "3":
        this.searchForm = this.fb.group({
          searchOption: ["3", Validators.required],
          searchText: [
            this.searchInput.nativeElement.value,
            [
              Validators.required,
              //Validators.minLength(1),
              //Validators.maxLength(20)
            ]
          ]
        });
        this.msgPlaceHolder = "Buscar en lotes...";
        break;
    }
    this.searchInput.nativeElement.focus();
  
  }
  searchIcon = "search";

  @ViewChild("searchInput", { read: ElementRef, static: true })
  private searchInput: ElementRef;
  @ViewChild("searchContainer", { read: ElementRef, static: true })
  private formSearch: ElementRef;
  interactedWithSearch = false;

  @Output()
  searchEvent = new EventEmitter<{
    query?: string;
    action: "SEARCH" | "CLEAR";
  }>();

  constructor(private fb: FormBuilder, private router: Router) {
    document.addEventListener("click", this.offClickHandler.bind(this));
  }
  offClickHandler(event: any) {
    const searchform_closeopenMatIcon = document.getElementById(
      "searchform_closeopenMatIcon"
    );
    const searchform_actionsearchButton = document.getElementById(
      "searchform_actionsearchButton"
    );
    const searchBtn = document.getElementById("searchBtn");
    const searchform_searchMatIcon = document.getElementById(
      "searchform_searchMatIcon"
    );
    const issearchform_searchMatIcon =
      event.target === searchform_searchMatIcon;
    const issearchform_closeopenMatIcon =
      event.target === searchform_closeopenMatIcon;
    const issearchform_actionsearchButton =
      event.target === searchform_actionsearchButton;
    const issearchBtn = event.target === searchBtn;
    const searchContainer = document.getElementById("search-container");
    if (this.hasClass(searchContainer, "open")) {
      if (
        issearchform_closeopenMatIcon ||
        issearchform_actionsearchButton ||
        issearchBtn ||
        issearchform_searchMatIcon
      ) {
      } else {
        if (!this.formSearch.nativeElement.contains(event.target)) {
          this.toggleSearch();
        }
      }
    }
  }
  focusRadios: boolean = true;
  toggleSearch() {
    const searchContainer = document.getElementById("search-container");
    const searchBtn = document.getElementById("searchBtn");
    const searchFields = document.getElementById("search-fields");
    const radioButtons = document.getElementById("radioButtons");
    this.toggleClass(searchContainer, "open");
    this.toggleClass(radioButtons, "open");
    this.toggleClass(searchBtn, "open");
    this.toggleClass(searchFields, "open");
    this.searchIcon = this.hasClass(searchContainer, "open")
      ? "clear"
      : "search";
    if (!this.hasClass(searchContainer, "open") && this.interactedWithSearch) {
      this.searchEvent.emit({ action: "CLEAR" });
      this.interactedWithSearch = false;
      this.searchInput.nativeElement.value = "";
    }
    if(this.searchIcon == "search"){
      const searchFormModel = this.searchForm.value;
      this.searchtxt = searchFormModel.searchText;
      this.searchForm.patchValue({
        searchText: ""
      });
    }
    if (this.hasClass(searchContainer, "open")) {
      this.searchInput.nativeElement.focus();
    } else {
      this.searchInput.nativeElement.blur();
    }
  }

  private toggleClass(elem, className) {
    this.hasClass(elem, className)
      ? elem.classList.remove(className)
      : elem.classList.add(className);
  }

  private hasClass(elem, className): boolean {
    return (elem && elem.classList && elem.classList.contains(className));
  }
  searchtxt = "";
  search() {
    this.toggleSearch();
    const searchFormModel = this.searchForm.value;
    if (this.searchtxt != "") {
      switch (searchFormModel.searchOption) {
        case "1":
          this.router.navigateByUrl("ordenes?folio=" + this.searchtxt);
          break;
        case "2":
          this.router.navigateByUrl("guias?trackingNumber=" + this.searchtxt);
          break;
        case "3":
          this.router.navigateByUrl("lotes?lote=" + this.searchtxt);
          break;
      }
    }
    this.searchtxt = "";
  }
}
