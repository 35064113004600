import { Component, Input, ViewChild, OnInit, OnDestroy, OnChanges, SimpleChanges } from "@angular/core";
import { ModalComponent } from "../modal.component";
import { ViewToRender } from "./enums/view-to-render.enum";
import { interval, Subject, Subscription } from "rxjs";
import { finalize, takeUntil, takeWhile } from "rxjs/operators";
import { Reseller , Countries, OnBoardingInfo, LoggedUserState} from "src/app/core/models/models";
import { ResellerService } from "src/app/core/services";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { environment } from "src/environments/environment";
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/core/services";
import { ResellerObsService } from "src/app/core/services/sharing/reseller-obs.service";
import { PakkeVerifyStore } from "./pakke-verify.store";
import { ProfileObsService } from "src/app/core/services/sharing/profile-obs.service";
@Component({
  selector: 'pakke-verify',
  templateUrl: './pakke-verify.component.html',
  styleUrls: ['./pakke-verify.component.scss']
})
export class PakkeVerifyComponent implements OnInit, OnDestroy, OnChanges {
  @Input() visualizationType: string;
  @ViewChild(ModalComponent , { static : true } ) private modal: ModalComponent;
  @ViewChild( 'phoneInt', { static : false } ) phoneInt: any;

  verificationStatus: string;
  isDuplicatedPhone: boolean;
  resellerInfo: Reseller;
  isPhoneVerified: boolean = false;
  isEmailVerified: boolean = false;
  viewToRender: ViewToRender;
  isCodeCompleted:boolean = false;
  isPhoneDifferent: boolean = false;
  isEmailDifferent: boolean = false;
  inputEmail: string;
  inputPhone: string;
  countdownTime: number;
  registerPhone: string;
  registerEmail: string;
  inputCode: string;
  errorMsg: string;
  labelMsg: string;
  phoneForm: FormGroup;
  emailForm: FormGroup;
  lada = environment.lada;
  allCountries: Countries[] = [];
  countryCode = environment.countryCode.toLowerCase();
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Mexico, CountryISO.Spain];
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  separateDialCode = true;
  isDuplicatedEmail: boolean = false;
  verificationData: OnBoardingInfo;
  startVerification: boolean;
  dataIsReady: boolean;
  isLoading: boolean;
  @Input() pendingVerification: boolean;
  profileDataReady: boolean = false;
  fullfillmentFlag: boolean = false;

  $onDestroy: Subject<void> = new Subject<void>();
  
  private secondsToResendCode: number = 35;
  private subscription: Subscription;

  constructor(
    private resellerService: ResellerService,
    private fb: FormBuilder,
    private profileObsService: ProfileObsService,
    private router: Router,
    private authServ: AuthenticationService,
    private resellerObsService: ResellerObsService,
    private pakkeVerifyStore: PakkeVerifyStore
  ) {
    this.resellerObsService.getUserStateObservable.pipe(takeUntil(this.$onDestroy)).subscribe((userState: LoggedUserState) => {
      if (userState) {
        this.fullfillmentFlag = userState.isFullfillment
      }
    });
  }

  // Modal functions

  /**
   * @description Takes the modal component and show it over the application
   */
  showModal() {
    this.modal.show();
    this.initForms();
    this.viewToRender = ViewToRender.METHODS;
    this.isCodeCompleted = false;
    this.getVerificationData();
  }

  /**
   * @description Takes the modal component and hide it
   */
  hideModal() {
    this.modal.hide();
    this.viewToRender = ViewToRender.METHODS;
    this.isCodeCompleted = false;
    this.inputEmail = '';
    this.inputPhone = '';
    this.isPhoneDifferent = false;
    this.isEmailDifferent = false;
    this.isCodeCompleted = false;
    this.countdownTime = this.secondsToResendCode;
    this.isEmailDifferent = false;
  }

  // Control views

  /**
   * @description 
   * @param view [string]: The name of the view to render. Take a value from the ViewToRender enum.
   */
  setViewToRender(view: string) {
    this.viewToRender = ViewToRender[view];
    this.isCodeCompleted = false;
    this.errorMsg = '';

    if(this.viewToRender === ViewToRender.EMAIL_CODE || this.viewToRender === ViewToRender.PHONE_CODE){
      this.countdownTime = this.secondsToResendCode;
      this.startCountDown();
    }
    
    if(this.viewToRender === ViewToRender.PHONE_INPUT || this.viewToRender === ViewToRender.EMAIL_INPUT){
      this.isEmailDifferent = false;
      this.initForms();
    }
  }

  // Code related functions

  /**
   * @description Invoked when the user complete the input and validate if the code it's correct
   * @param code [string]: Code entered by the user
   */
  onCodeCompleted(code: string){
    this.isCodeCompleted = true;
    this.inputCode = code;
  }

  sendEmailCode(){
    const { ResellerId: resellerId } = this.resellerInfo;
    this.errorMsg = '';

    const verificationBody = {
      email: this.inputEmail,
      code: this.inputCode,
      resellerId
    };

    this.isLoading = true;
    this.resellerService.verifyEmailCode(verificationBody).pipe(takeUntil(this.$onDestroy),
      finalize(() => this.isLoading = false)
    ).subscribe(
      (res) => {
        const { code, verificationStatus, message, isEmailVerified } = res;
        if(code && code !== 200){
          this.errorMsg = message;
          return false;
        }
        
        this.isEmailVerified = isEmailVerified;
        this.verificationStatus = verificationStatus;
        this.profileObsService.setOnboardingObservable = {...this.verificationData,isEmailVerified:isEmailVerified, status: verificationStatus};
        this.pendingVerification = this.verificationStatus !== 'VERIFIED' && (this.isPhoneVerified || this.isEmailVerified);
        this.pakkeVerifyStore.savePending(this.pendingVerification);

        if(this.isEmailDifferent){
          this.authServ.logout().subscribe();
          return true;
        }

        if(verificationStatus === 'VERIFIED'){
          this.hideModal()
          this.pakkeVerifyStore.saveVerifiedStatus(verificationStatus);
          const token = this.authServ.getAccessToken();
          const urlSegments = this.router.url.split('/');
          const route = urlSegments[urlSegments.length - 1]
          this.router.navigateByUrl(`/login/${token}?path=${route}`)
          return true;
        }


        this.setViewToRender(ViewToRender.METHODS);
      },
      err => this.errorMsg = 'Ocurrió un error. Intenta de nuevo'
    );
  }

  sendPhoneCode(){
    const { ResellerId: resellerId } = this.resellerInfo;
    this.errorMsg = '';

    const verificationBody = {
      phoneNumber: this.inputPhone,
      code: this.inputCode,
      resellerId
    };

    this.isLoading = true;
    this.resellerService.verifyPhoneCode(verificationBody).pipe(takeUntil(this.$onDestroy),
      finalize(() => this.isLoading = false)
    ).subscribe(
      (res) => {
        const { code, isPhoneVerified, message, verificationStatus } = res;

        if(code && code !== 200){
          this.errorMsg = message;
          return false;
        }

        this.isPhoneVerified = isPhoneVerified;
        this.verificationStatus = verificationStatus;
        this.profileObsService.setOnboardingObservable = {...this.verificationData,isPhoneVerified:isPhoneVerified, status: verificationStatus};
        this.pendingVerification = this.verificationStatus !== 'VERIFIED' && (this.isPhoneVerified || this.isEmailVerified);
        this.pakkeVerifyStore.savePending(this.pendingVerification);

        if(verificationStatus === 'VERIFIED'){
          this.hideModal()
          const token = this.authServ.getAccessToken();
          const urlSegments = this.router.url.split('/');
          const route = urlSegments[urlSegments.length - 1]
          this.router.navigateByUrl(`/login/${token}?path=${route}`)
          return true;
        }


        this.setViewToRender(ViewToRender.METHODS);
      },
      err => this.errorMsg = 'Ocurrió un error. Intenta de nuevo'
    );
  }

  sendMethodCode(){
    this.viewToRender === ViewToRender.EMAIL_CODE
    ? this.sendEmailCode()
    : this.sendPhoneCode();
  }

  // Countdown control functions

  /**
   * @description Starts the countdown and decrease the countdownTime property
   */
  startCountDown(){
    this.subscription = interval(1000).pipe(
      takeWhile(() => this.countdownTime > 0), takeUntil(this.$onDestroy)
    ).subscribe(() => {
      this.countdownTime--;
    })
  }

  /**
   * @description format the countdown to display minutes and seconds 
   * @returns a template literal like this 00:00
   */
  formatCountDown(){
    const minutes = Math.floor(this.countdownTime / 60);
    const seconds = this.countdownTime % 60;
    
    return `${this.completeWithZero(minutes)} : ${this.completeWithZero(seconds)}`;
  }

  /**
   * @description Takes a number and completed with a left zero to get  this format 00
   * @param digit [number]: the number to complete with a left zero
   * @returns a string with a cero and the digit param at the right
   */
  private completeWithZero(digit: number){
    return digit < 10 ? `0${digit}` : digit.toString();
  }

  setPhoneInternationalData() {
    const TEMP_COUNTRYS = this.phoneInt.allCountries;
    let temArrayCounntry = []
    this.allCountries.forEach((country) => {
      let COUNTRY_DATA = TEMP_COUNTRYS.find(x => x.iso2 == country.Code.toLocaleLowerCase());
      if (COUNTRY_DATA) {
        const OBJ_INT_PHONE = {
          areaCodes: COUNTRY_DATA.areaCodes,
          dialCode: COUNTRY_DATA.dialCode,
          flagClass: COUNTRY_DATA.flagClass,
          iso2: COUNTRY_DATA.iso2,
          name: country.Name,
          placeHolder: COUNTRY_DATA.placeHolder,
          priority: COUNTRY_DATA.priority,
        };

        temArrayCounntry.push(OBJ_INT_PHONE);
      }
    });

    if (Array.isArray(temArrayCounntry) && temArrayCounntry.length > 0) {
      temArrayCounntry.sort((a, b) => {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      });

      this.phoneInt.allCountries = temArrayCounntry;
      this.preferredCountries = [CountryISO.Mexico];
    }
  }

  initForms(){
    this.emailForm = this.fb.group({
      email: ['',
        Validators.compose([
          Validators.required,
          Validators.email
        ])
      ]
    });
    this.phoneForm = this.fb.group({
      phoneNumber: ['',
        Validators.compose([
          Validators.required,
        ])
      ]
    });
    this.emailForm.patchValue({ email: this.resellerInfo.Email });
    this.phoneForm.patchValue({ phoneNumber: this.resellerInfo.Phone });
  }

  get phoneNumber(): string {
    const telNumber = this.phoneForm.get('phoneNumber').value.internationalNumber;
    return telNumber;
  }

  submitPhone(){
    this.errorMsg = '';
    const { status, value } = this.phoneForm;
    const enteredPhoneNumber = value.phoneNumber.internationalNumber.replace(/\s+/g, '');
    this.registerPhone = this.resellerInfo.Phone;

    if(status === 'INVALID'){
      this.errorMsg = 'Teléfono inválido';
      return true;
    }
    
    this.inputPhone = enteredPhoneNumber;
    this.validateDuplicatedPhone(enteredPhoneNumber);
  }

  submitEmail(){
    this.errorMsg = '';
    const { status, value } = this.emailForm;
    const enteredEmail = value.email;
    this.registerEmail = this.resellerInfo.Email;

    this.isEmailDifferent = this.registerEmail !== enteredEmail;

    if(status === 'INVALID'){
      this.errorMsg = 'Correo inválido';
      return true;
    }

    this.inputEmail = enteredEmail;
    this.validateDuplicatedEmail(enteredEmail);
  }

  submitMethod(){
    const method = this.viewToRender === ViewToRender.PHONE_CODE || this.viewToRender === ViewToRender.PHONE_INPUT ? 'phone' : 'email';
    
    method === 'phone'
    ? this.submitPhone()
    : this.submitEmail();
  }

  validateDuplicatedPhone(phone: string){
    const { ResellerId: resellerId } = this.resellerInfo;
    this.isLoading = true;
    this.resellerService.validateDuplicatedPhone(phone, resellerId).pipe(takeUntil(this.$onDestroy),
      finalize(() => this.isLoading = false)
    ).subscribe(
      (res) => {
        const { code, isDuplicatedPhone, message } = res;

        if(code && code !== 200){
          this.errorMsg = message
          this.isDuplicatedPhone = isDuplicatedPhone ? isDuplicatedPhone : false;
          return false;
        }

        this.setViewToRender(ViewToRender.PHONE_CODE);
      },
      err => this.errorMsg = 'Ocurrió un error. Intente de nuevo'
    )
  }
  
  validateDuplicatedEmail(email: string){
    const { ResellerId: resellerId } = this.resellerInfo;
    this.isLoading = true;
    this.resellerService.validateDuplicatedEmail(email, resellerId).pipe(takeUntil(this.$onDestroy),
      finalize(() => this.isLoading = false)
    ).subscribe(
      (res) => {
        const { code, isDuplicatedEmail, message } = res;
        if(code && code !== 200){
          this.errorMsg = message
          this.isDuplicatedEmail = isDuplicatedEmail ? isDuplicatedEmail : false;
          return false;
        }

        this.setViewToRender(ViewToRender.EMAIL_CODE);
      },
      err => this.errorMsg = 'Ocurrió un error. Intente de nuevo'
    )
  }

  getResellerInfo(){
    this.resellerService.getAuthReseller(false).pipe(takeUntil(this.$onDestroy),
      finalize(() => {
        this.dataIsReady = true;
      })
    ).subscribe(
      (data) => {
        this.resellerInfo = data;
      },
      err => console.log(err.message)
    )
  }

  getVerificationData(){
    this.profileObsService.getOnboardingObservable.pipe(takeUntil(this.$onDestroy),
    finalize(() => {
      this.profileDataReady = true;
    })  
  ).subscribe(
    (data) => {
      this.verificationData = data;
      this.verificationStatus = data.status;
      this.isDuplicatedPhone = data.isDuplicatePhone;
      this.startVerification = data.startVerification;
      this.isPhoneVerified = data.isPhoneVerified;
      this.isEmailVerified = data.isEmailVerified;
      this.pendingVerification = data.status !== 'VERIFIED' && (data.isEmailVerified || data.isPhoneVerified);
      this.pakkeVerifyStore.savePending(this.pendingVerification);
    }
  )
  }

  validateChangeEmail(keyEvent: KeyboardEvent){
    this.isEmailDifferent = this.emailForm.value.email !== this.resellerInfo.Email;
  }

  checkIsPending() {
    this.pakkeVerifyStore.getPending().pipe(takeUntil(this.$onDestroy)).subscribe({
      next: (isPending) => {
        this.pendingVerification = isPending
      }
    })
  }

  ngOnInit(): void {
    this.checkIsPending();
    this.getResellerInfo();
    this.initForms();
    this.getVerificationData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pendingVerification) {
      this.getVerificationData();
    }
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }
}