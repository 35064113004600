import { Component, EventEmitter, OnInit, Output, ViewChild, Input, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { closestIndexTo } from 'date-fns/esm';
import { environment } from 'src/environments/environment';
import { ModalComponent } from '../../modal.component';
import { ConsigmentNote } from './models/consigment-note';
import { ItemConsigment } from './models/item-consigment';

@Component({
  selector: 'app-consigment-note',
  templateUrl: './consigment-note.component.html',
  styleUrls: ['./consigment-note.component.scss']
})
export class ConsigmentNoteComponent implements OnInit {
  @Output() consigmentNoteData = new EventEmitter<ConsigmentNote>();
  @Input() shipmentContent: string;
  @Input() CourierCode: string;

  @ViewChild(ModalComponent, { static: true }) private modal: ModalComponent;

  productType = [
    { title: "Sobre", value: "envelope" },
    { title: "Paquete", value: "package" }
  ];
  consigmnetNoteForm: FormGroup;
  countryCode = environment.countryCode;

  defaultForm: ConsigmentNote = {
    "RFC": "",
    "Items": [
      {
        "Description": "",
        "Quantity": 0,
        "Price": 0,
        "Packaging": this.productType[0].value,
      }
    ]
  };
  regexValidatorRFCNIF = this.countryCode === 'ES' ? 
    /((([X-Z])|([LM])){1}([-]?)((\d){7})([-]?)([A-Z]{1}))|((\d{8})([-]?)([A-Z]))/ : 
    /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;


  firstLoad: boolean = false;
  addProductSummit: boolean = false;

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.consigmnetNoteForm = this.initConsigmnetForm(this.defaultForm);
    this.firstLoad = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if((changes.CourierCode && changes.CourierCode.currentValue !== "UPS_INT") && (changes.CourierCode && changes.CourierCode.currentValue !== undefined)) {
      this.defaultForm.Items[0].Description = "";
      this.consigmnetNoteForm = this.initConsigmnetForm(this.defaultForm);
    } else if (changes.shipmentContent) {
      this.defaultForm.Items[0].Description = changes.shipmentContent.currentValue;
      this.consigmnetNoteForm = this.initConsigmnetForm(this.defaultForm);
    }
  }

  show() {
    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }


  private initConsigmnetForm(data) {
    const itemsCtrls = this.createItemsCtrls(data.Items);

    const itemForm = this.formBuilder.group({
      RFC: [data.RFC, Validators.compose([
        Validators.maxLength(13),
        Validators.pattern(this.regexValidatorRFCNIF)
      ])],
      Items: itemsCtrls
    });

    return itemForm;
  }

  private createItemsCtrls(items: ItemConsigment[]) {
    // Initilize an empty form array
    let formArrayCtrls = this.formBuilder.array([]);

    if (items && Array.isArray(items) && items.length > 0) {
      items.forEach(item => {
        // Create a new form control for each array value and push to form array
        formArrayCtrls.push(
          this.formBuilder.group({
            Description: [item.Description, Validators.compose([
              Validators.maxLength(100),
              Validators.minLength(5),
              Validators.required
            ])],
            Quantity: [item.Quantity, Validators.compose([
              Validators.required,
              Validators.min(1)
            ])],
            Price: [item.Price, Validators.compose([
              Validators.required,
              Validators.min(1)
            ])],
            Packaging: [item.Packaging]
          })
        );
      });
    }
    return formArrayCtrls;
  }

  addItemForm() {
    if (!this.consigmnetNoteForm.get('Items').valid && this.firstLoad) {
      this.addProductSummit = true
      return;
    }

    this.addProductSummit = false;
    let consigmentFormValue = this.consigmnetNoteForm.value;
    const defaultItem: ItemConsigment = {
      "Description": "",
      "Quantity": 0,
      "Price": 0,
      "Packaging": this.productType[0].value,
    };

    if (consigmentFormValue && consigmentFormValue.Items && Array.isArray(consigmentFormValue.Items)) {
      consigmentFormValue.Items.push(defaultItem);
      this.defaultForm.Items.push(defaultItem);
      this.consigmnetNoteForm = this.initConsigmnetForm(consigmentFormValue);
    }
  }

  removeItemForm(index) {
    let consigmentFormValue = this.consigmnetNoteForm.value;
    if (consigmentFormValue && consigmentFormValue.Items && Array.isArray(consigmentFormValue.Items)) {
      consigmentFormValue.Items.splice(index, 1);
      this.defaultForm.Items = [];
      this.defaultForm.Items = consigmentFormValue.Items;
      this.consigmnetNoteForm = this.initConsigmnetForm(consigmentFormValue);
    }
  }

  send() {
    if(this.consigmnetNoteForm.valid) {
      this.consigmentNoteData.emit(this.consigmnetNoteForm.value);
    }
  }
}
