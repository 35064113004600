import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ItemsFullfillment } from 'src/app/core/models/models';

@Injectable()
export class ItemFFService {

    itemFF: ItemsFullfillment = {
        FullfillmentItemId: '',
        FullfillmentCategoryId: '',
        FullfillmentTagId: '',
        NameCategory: '',
        Category: '',
        ResellerId: '',
        SKU: '',
        Name: '',
        IsActive: false,
        CeateAt: new Date(),
        Description: '',
        Disposition: 0,
        InStrore: 0,
        InPurchaseOrder: 0,
        UPC: '',
        PartNumber: 'string',
        Weigth: 0,
        Witdth: 0,
        Length: 0,
        Hight: 0,
        FullfillmentFileId: '',
        FileType: '',
        File: '',
        AsigOrder: 0,
        UnitOfMeasure: '',
        InList: false,
    }

    private _itemObservable: BehaviorSubject<ItemsFullfillment> = new BehaviorSubject<ItemsFullfillment>(this.itemFF);

    get getItemObservable() {
        return this._itemObservable.asObservable();
    }

    set setItemObservable(data: ItemsFullfillment) {
        this._itemObservable.next(data);
    }

}

