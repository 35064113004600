import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddressFormComponent } from './address-form/address-form.component';
import { FrequentAddressComponent } from './frequent-address/frequent-address.component';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TextMaskModule } from 'angular2-text-mask';
import { PipesModule } from 'src/app/shared/pipes.module';
import { PhoneVerificationModule } from 'src/app/shared/phoneVerification.module';
import {AutoCompleteModule} from 'primeng/autocomplete';
import { SharedModule } from 'src/app/shared/shared.module';
import { TooltipModule } from 'primeng/tooltip';
import { MatAutocompleteModule } from '@angular/material';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

@NgModule({
  declarations: [AddressFormComponent, FrequentAddressComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollPanelModule,
    NgSelectModule,
    PhoneVerificationModule,
    TextMaskModule,
    PipesModule,
    AutoCompleteModule,
    MatAutocompleteModule,
    SharedModule,
    TooltipModule,
    NgxIntlTelInputModule
  ],
  exports: [
    AddressFormComponent,
    FrequentAddressComponent,
  ]
})
export class AddressFormModule { }
