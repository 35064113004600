import { Component, OnInit, Inject, Input, Output, EventEmitter, ContentChild, TemplateRef, ElementRef } from '@angular/core';

@Component({
    templateUrl: 'dropdown-menu.component.html',
    styleUrls: ['dropdown-menu.component.scss'],
    selector: 'app-dropdown-menu'
})

export class DropdownMenuComponent implements OnInit {

    @Input() actions: any[];
    @Input() fulfillment: any = false;
    @Input() folioNext: any = '0';
    @Input() OrderStatus: any = '';
    @Input() ShipmentStatus: any;
    @Input() OrderStatusFullfillment: any = '';
    @Input() Store: any = '';
    @Input() OrderItems: Array<any> = [];

    @Output() actioned = new EventEmitter<any>();

    constructor() { }

    ngOnInit() {
        if (this.fulfillment && (this.folioNext == '0' || this.folioNext == '-1') && this.OrderStatus != '6') {
            this.actions = this.actions.filter(x => x.actionId != 'addCustomShipment' && x.actionId != 'cancelOrder' && x.actionId != 'editOrder' && x.actionId != 'deleteOrder' && x.actionId != 'addReturn');
        }
        else if (this.fulfillment && this.folioNext != '0' && (this.OrderStatus != '6' && this.OrderStatus != '3')) {
            this.actions = this.actions.filter(x => x.actionId != 'addCustomShipment' && x.actionId != 'cancelOrder' && x.actionId != 'editOrder' && x.actionId != 'deleteOrder' && x.actionId != 'addReturn');

        } else if (this.fulfillment && (this.OrderStatus == '6' || this.OrderStatus == '3') && (this.ShipmentStatus && this.ShipmentStatus.filter(x => x.TrackingStatus == 'DELIVERED').length > 0)) {
            this.actions = this.actions.filter(x => x.actionId == 'addReturn');
        }
        else if (!this.fulfillment && this.OrderStatus != '6') {
            this.actions = this.actions.filter(x => x.actionId != 'editCustomerDetails' && x.actionId != 'addReturn' && x.actionId != 'cancelOrderFF');
        }
        if (this.OrderStatusFullfillment == 'Surtido') {
            this.actions = this.actions.filter(x => x.actionId != 'changeEstatus' && x.actionId != 'cancelOrderFF');
        }
        if(this.OrderStatusFullfillment == 'Preparación'){
            this.actions = this.actions.filter(x => x.actionId == 'editCustomerDetails');
        }
        if(this.Store == 'TNG'){
            this.actions = this.actions.filter(x => x.actionId != 'addCustomShipment');
        }
        if (this.orderAssortmentConditional()) {
          this.removeAction('orderAssortment');
        }
    }
    actionate(action) {
        this.actioned.emit(action.actionId);
    }

    removeAction(actionId: string) {
      this.actions = this.actions.filter(x => x.actionId !== actionId);
    }

  orderAssortmentConditional(): boolean {
      return !(this.fulfillment && (this.OrderStatusFullfillment === 'Sin Stock' || this.OrderStatusFullfillment === 'Falta informacion') && this.OrderItems.some(item => item.QuantityAsig > 0) && !this.OrderItems.every(item => item.Quantity === item.QuantityAsig));
  }
}
