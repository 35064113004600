import { Injectable } from "@angular/core";
import { Orden } from "../../models/models";
import { FormatHelperService } from "../util/format-helper.service";

@Injectable ()
export class StoreUtilsService {

  private readonly PROTOCOL_SPF = 'https://';
  private readonly DOMAIN_SPF = '.myshopify.com/';

  static setStoreConfigObjectFromOrders(orders: Orden[]) {
    if (orders && Array.isArray(orders) && orders.length > 0) {
      let formatHelp: FormatHelperService = new FormatHelperService();
      for (let order of orders) {
        if (order && order.resellerStore) {
          order.resellerStore = formatHelp.getJSONFromString(order.resellerStore);
          if (order.resellerStore.Config) {
            order.resellerStore.Config = formatHelp.getJSONFromString(order.resellerStore.Config);
          }
        }
      }
    }
  }

  getShopNameInSPFromURL(urlStore: string): string {
    urlStore = urlStore.replace(this.DOMAIN_SPF, '');
    urlStore = urlStore.replace(this.PROTOCOL_SPF, '');
    return urlStore;
  }
  parseShopNameInSPFToURL(storeNameInSPF: string): string {
    return `${this.PROTOCOL_SPF}${storeNameInSPF}${this.DOMAIN_SPF}`;
  }

  getSPFDomain(): string {
    return this.DOMAIN_SPF.replace('/', '');
  }
}
