import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map, mergeMap } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api.service';
import { ResellerStore,LocationStore,LocationSKU } from 'src/app/core/models/models';
import { EcommerceShipmentMethod, ResellerMarketplace, Store } from '../model/store';
import { CommonService } from 'src/app/core/services';


@Injectable()
export class StoreService {

    public marketplaceIcons = [];
    protected basePath = 'Stores';
    constructor(private apiService: ApiService, private commonService: CommonService) { }

    /* create ResellerStore, it returns the ResellerStoreId */
    createStore(batch: ResellerStore): Observable<string> {
        return this.apiService.post<string>(`${this.basePath}`, batch);
    }

    updateStore(resellerStore: ResellerStore, isFulfillment: boolean = false): Observable<ResellerStore> {
      resellerStore = Object.assign(resellerStore, { isFulfillment });
      return this.apiService.patch<ResellerStore>(`${this.basePath}/${resellerStore.ResellerStoreId}`, resellerStore);
    }

    connectStore(resellerStore: ResellerStore): Observable<ResellerStore> {
        return this.apiService.post<ResellerStore>(`${this.basePath}/connect/${resellerStore.ResellerStoreId}`, resellerStore);
    }

    getLocation(_resellerStore:any):Observable<LocationStore[]>{
        const params = {
            resellerStore:_resellerStore
          }
        return this.apiService.get<LocationStore[]>('Stores/getLocation', params);
      }
      getSKUByLocation(_resellerStore:any):Observable<any>{
        const params = {
            resellerStoreId:_resellerStore
          }
        return this.apiService.get<any>('Stores/getInventoryItem', params);
      }

      updateSKU(arraySKU: LocationSKU[],_resellerStore: ResellerStore): Observable<any> {
        const params = {
            dataSKU:arraySKU,
            resellerStoreId:_resellerStore.ResellerStoreId
          }
        return this.apiService.post<any>(`${this.basePath}/updateSKUStore`, params);
    }

    insertItem(items:LocationSKU[],resellerStoreId:string): Observable<any> {
      const params = {
          dataItem:items,
          ResellerStoreId:resellerStoreId
        }
      return this.apiService.post<any>(`${this.basePath}/insertItem`, params);
  }

  getEcommerceGlobalConfig(storeCode: string): Observable<any> {
    return this.apiService.get<any>(`${this.basePath}/ecommerceGlobalConfig/${storeCode}`);
  }

  getResellerMarketplace(): Observable<ResellerMarketplace> {
    return this.apiService.get<ResellerMarketplace>(`${this.basePath}/reseller/marketplace`);
  }

  createTemplateCustomEmail() {
    return this.apiService.get<ResellerMarketplace>(`${this.basePath}/reseller/marketplace`);
  }

  getShippingMethods(resellerStoreId) {
    return this.apiService.get(`${this.basePath}/shippingMethods/${resellerStoreId}`);
  }

  getCouriersAndServiceMethods() {
    return this.apiService.get(`${this.basePath}/shippingMethods/couriersAndServiceMethods`);
  }

  sendShippingMethods(resellerStoreId: string, serviceStoreMethods: EcommerceShipmentMethod[]) {
    return this.apiService.post(`${this.basePath}/shippingMethods/saveConfig/${resellerStoreId}`, serviceStoreMethods);
  }

  async fillMarketplaceStores() {
    const STORES = await this.apiService.get<Store[]>('Stores/').toPromise();
    if(STORES && Array.isArray(STORES) && STORES.length > 0) {
      STORES.map(store => {
        if (this.commonService.isJson(store.Config) && store.MarketPlace) {
          const STORE_CONFIG = JSON.parse(store.Config);
          this.marketplaceIcons.push({
            StoreId: store.StoreId,
            image: STORE_CONFIG.image
          });
        }
      });
    }

  }
  disconnectStoreConfigMELI(resellerStore:ResellerStore): Observable<any> {
    let result =  this.apiService.get<any>(`Stores/refreshTokenManual?resellerStoreId=${resellerStore}`);
    return result;
  }

  /**
   * PMTI-240 Service to get URL to authenticate ecommerce
   * @param storeId
   */
  getURLAuthenticationFromStore(storeId): Observable<any> {
    return this.apiService.get(`${this.basePath}/marketplace/${storeId}/authentication`);
  }

  /**
   * PMTI-240 Service to get needed fields by ecommerce
   * @param storeId
   */
  getFieldsFromEcommerce(storeId): Observable<any> {
    return this.apiService.get(`${this.basePath}/marketplace/${storeId}/fields`);
  }

  /**
   * PMTI-240 Service to create a MKT by auth fields
   * @param storeId
   * @param requestBody
   */
  createMarketplaceByFields(storeId: string, requestBody): Observable<any> {
    return this.apiService.post(`${this.basePath}/marketplace/${storeId}`, requestBody);
  }

  /**
   * SD-852 New service, Update InactivityCounterStrore
   * @param resellerStoreId
   */
  postUpdateActivityCounterStore(resellerStoreId: string): Observable<any> {
    return this.apiService.post(`${this.basePath}/UpdateActivityCounterStore/${resellerStoreId}`);
  }
}

