import { Component, ViewChild, EventEmitter, Output, Input, AfterViewInit, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { ModalComponent } from '../../shared/components/modal.component';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CustomValidators } from '../../shared/validators/custom-validators';
import { ResellerService, GrowlSvc, CommonService } from '../../core/services';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { ɵINTERNAL_BROWSER_DYNAMIC_PLATFORM_PROVIDERS } from '@angular/platform-browser-dynamic';
import * as $ from 'jquery';
import { Countries } from 'src/app/core/models/models';
import { ResellerObsService } from 'src/app/core/services/sharing/reseller-obs.service';

@Component({
  selector: 'app-phone-verification',
  templateUrl: './phone-verification.component.html',
  styleUrls: ['./phone-verification.component.scss']
})

export class PhoneVerificationComponent implements OnInit,AfterViewInit {
  @ViewChild( 'phoneInt', { static : false } ) phoneInt: any;

  @Output() phoneNumberVerified = new EventEmitter();
  @Output() emailVerified = new EventEmitter();
  @Output() verificationCodeSent = new EventEmitter();
  @Output() verificationEmailCodeSent = new EventEmitter();
  @Output() onHide = new EventEmitter();
  @Input() useModal = true;
  @Input() phoneImg = 'phoneVerification.png';
  @Input() emailImg = 'emailVerification.png';
  @Input() params:{
    showInit:boolean,
    showBotton:boolean,
    isPhone:boolean,
  };
  @ViewChild(ModalComponent, { static : true } ) public modal: ModalComponent;
  phoneNumberForm: FormGroup;
  loadingReseller:boolean = false;
  emailForm: FormGroup;
  verifyCodeForm: FormGroup;
  step:string =  'init_phone_number';
  lada = environment.lada;
  countryCode = environment.countryCode.toLowerCase();

  verifyingPhoneNumber = false;
  verifyingEmail = false;
  sendingSms = false;
  sendingEmail = false;
  isPhone = false;
  errorMessage = '';
  reseller;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Mexico, CountryISO.Colombia, CountryISO.Spain];
  sideToggle = true;
  verificationCounter = 10;
  allCountries: Countries[] = [];
  constructor(
    private fb: FormBuilder,
    private resellerSvc: ResellerService,
    private growlSvc: GrowlSvc,
    private commonSvc: CommonService,
    public renderer : Renderer2,
    private resellerObsService: ResellerObsService
  ) {
    this.initForms();
  }
  ngOnInit(): void {

    if(this.params && this.params.showInit){
      this.show(false,"","",false);
      this.loadingReseller = true;
      this.resellerObsService.getResellerObservable.subscribe((reseller) => {
        if (reseller) {
          this.reseller = reseller;
          if(!this.params.isPhone && reseller.isEmailVerified){
              this.step = "email_verified";
              this.emailVerified.emit();
          }else if(this.params.isPhone && reseller.IsPhoneVerified){
            this.step = "phone_verified";
            this.phoneNumberVerified.emit();
          }
          if(this.step == "email_verified" || this.step == "phone_verified"){
            this.show(this.params.isPhone,reseller.Phone,reseller.Email,false,false);
          }else{
            this.show(this.params.isPhone,reseller.Phone,reseller.Email,false);
          }
          this.loadingReseller = false;
        }
      });
     
    }else{
      this.loadingReseller = false;
      this.params = {
        showBotton:true,
        showInit:false,
        isPhone:true
      }
    }

    if(this.countryCode.toUpperCase() == 'ES') {
      this.commonSvc.getCountries().subscribe(response => {
        if (response) {
          this.allCountries = response;
          this.setPhoneInternationalData();
        }
      });
    }
  }

  ngAfterViewChecked(): void {
    const RANDOM_NAME = Math.random().toString(36).slice(-8);
    const GET_PHONE_SEARCH: ElementRef | any = document.querySelector(`ngx-intl-tel-input .search-container input`);
    if (GET_PHONE_SEARCH && !GET_PHONE_SEARCH.getAttribute('autocomplete')) {
      $('input').attr('autocomplete','chrome-off');
      $('input').attr('name',RANDOM_NAME);
      this.renderer.setAttribute(GET_PHONE_SEARCH, 'autocomplete', 'chrome-off');
      this.renderer.setAttribute(GET_PHONE_SEARCH, 'name', `phone-search-${RANDOM_NAME}`);
      this.renderer.setAttribute(GET_PHONE_SEARCH, 'type', 'search');
    }
  }

  setPhoneInternationalData() {
    const TEMP_COUNTRYS = this.phoneInt.allCountries;
    let temArrayCounntry = []
    this.allCountries.forEach((country) => {
      let COUNTRY_DATA = TEMP_COUNTRYS.find(x => x.iso2 == country.Code.toLocaleLowerCase());
      if (COUNTRY_DATA) {
        const OBJ_INT_PHONE = {
          areaCodes: COUNTRY_DATA.areaCodes,
          dialCode: COUNTRY_DATA.dialCode,
          flagClass: COUNTRY_DATA.flagClass,
          iso2: COUNTRY_DATA.iso2,
          name: country.Name,
          placeHolder: COUNTRY_DATA.placeHolder,
          priority: COUNTRY_DATA.priority,
        };

        temArrayCounntry.push(OBJ_INT_PHONE);
      }
    });

    if (Array.isArray(temArrayCounntry) && temArrayCounntry.length > 0) {
      temArrayCounntry.sort((a, b) => {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      });

      this.phoneInt.allCountries = temArrayCounntry;
      this.preferredCountries = [CountryISO.Spain];
    }
  }



  initForms(){
    this.emailForm = this.fb.group({
      email: ['',
        Validators.compose([
          Validators.required,
        ])
      ]
    });
    this.phoneNumberForm = this.fb.group({
      phoneNumber: ['',
        Validators.compose([
          Validators.required,
        ])
      ]
    });

    this.verifyCodeForm = this.fb.group({
      verificationCode: ['',
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5)
        ])
      ]
    });

  }
  viewInited = false;

  ngAfterViewInit() {
    setTimeout(() => {
      this.viewInited = true;
    }, 300);
  }

  tmpphone = "";
  get phoneNumber(): string {
    const telNumber = this.phoneNumberForm.get('phoneNumber').value.internationalNumber;
    return telNumber;
  }

  show(isphone:boolean,phoneNumber?: string,email?:string, toggle=true,initStep=true) {
    this.sideToggle = toggle;
    phoneNumber = phoneNumber? phoneNumber:"";
    this.initForms();
    this.modal.show(toggle);
    this.isPhone = isphone;
    this.tmpphone = phoneNumber;
    if(initStep){
      this.step = isphone ? 'init_phone_number' : 'init_email';
    } 
    this.phoneNumberForm.reset({ phoneNumber: phoneNumber });
    this.emailForm.reset({ email: email });
    this.emailForm.controls['email'].disable();
    this.verifyCodeForm.reset();
    this.errorMessage = '';
  }

  hide() {
    this.modal.hide(this.sideToggle);
    setTimeout(() => this.onHide.emit(true), 100);
  }

  

  onPhoneNumberSubmit() {
    this.errorMessage = '';
    this.verifyCodeForm.reset();
    if (this.phoneNumberForm.valid) {
      this.sendingSms = true;
      let telNumber = '' + this.phoneNumber + '';
      telNumber = telNumber.replace(/\s/g, '');
      telNumber = JSON.stringify( { phone : telNumber })
      this.sendingSms = true;
      this.resellerSvc.sendPhoneNumberVerificationCode(telNumber)
        .pipe(finalize(async () => { 
          await this.startCountdown(10);
          this.sendingSms = false; 
          this.verificationCounter = 10;  }))
        .subscribe(
          () => {
            this.verificationCodeSent.emit(this.phoneNumber);
            this.step = 'verification_code';
          },
          err =>
          this.errorMessage = 'Ocurrió un error al enviar el teléfono. ' + this.getErrorText(err)
        );
    }
  }
  onEmailSubmit() {
    this.errorMessage = '';
      this.sendingEmail = true;
      this.verifyingEmail = true;
      this.resellerSvc.sendEmailVerificationCode()
        .pipe(finalize(async () => {
          await this.startCountdown(10);
          this.verificationCounter = 10;
          this.sendingEmail = false;
          this.verifyingEmail = false;
        }))
        .subscribe(
          () => {
            this.verificationEmailCodeSent.emit();
            this.step = 'verification_email_code';
          },
          err =>
          this.errorMessage = 'Ocurrió un error al enviar el teléfono. ' + this.getErrorText(err)
        );
    
  }

  onVerificationCodeSubmit() {
    this.errorMessage = '';

    if (this.verifyCodeForm.valid) {
      const verificationCode = this.verifyCodeForm.value.verificationCode;

      this.verifyingPhoneNumber = true;
      this.resellerSvc.verifyPhoneNumber(verificationCode)
        .pipe(finalize(() => this.verifyingPhoneNumber = false))
        .subscribe(
          () => {
            this.step = 'phone_verified';
            this.phoneNumberVerified.emit(this.phoneNumber);
          },
          err => 
          {
            this.errorMessage = this.getErrorText(err).replace('Lo sentimos, algo salió mal. ', '')
          }
        );
    }
  }
  onEmailVerificationCodeSubmit() {
    this.errorMessage = '';
    if (this.verifyCodeForm.valid) {
      const verificationCode = this.verifyCodeForm.value.verificationCode;
      this.verifyingEmail = true;
      this.resellerSvc.verifyEmail(verificationCode)
        .pipe(finalize(() => this.verifyingEmail = false))
        .subscribe(
          () => {
            this.step = 'email_verified';
            this.emailVerified.emit();
          },
          err => this.errorMessage = this.getErrorText(err).replace('Lo sentimos, algo salió mal. ', '')
        );
    }
  }

  modifyPhoneNumber() {
    this.initForms();
    this.phoneNumberForm.reset({ phoneNumber: this.tmpphone });
    this.errorMessage = '';
    this.step = 'init_phone_number';
  }

  resendVerificationCode() {
    this.errorMessage = '';

    this.sendingSms = true;
    let telNumber = '' + this.phoneNumber + '';
    telNumber = telNumber.replace(/\s/g, '');
    telNumber = JSON.stringify( { phone : telNumber })
    this.resellerSvc.sendPhoneNumberVerificationCode(telNumber)
      .pipe(finalize(async () => {
        await this.startCountdown(10);
        this.sendingSms = false;
        this.verificationCounter = 10;
      }))
      .subscribe(
        () => this.verificationCodeSent.emit(),
        err => this.errorMessage = 'Ocurrió un error al enviar el código. ' + this.getErrorText(err)
      );
  }



  resendEmailCode() {
    this.errorMessage = '';
      this.sendingEmail = true;
      this.resellerSvc.sendEmailVerificationCode()
        .pipe(finalize(async () => {
          await this.startCountdown(10);
          this.verificationCounter = 10;
          this.sendingEmail = false;
        }))
        .subscribe(
          () => {
            this.verificationEmailCodeSent.emit();
          },
          err =>
          this.errorMessage = 'Ocurrió un error al enviar el teléfono. ' + this.getErrorText(err)
        );
  }

  private getErrorText(err: any) {
    return (typeof err === 'string') ? err
      : err.detail ? err.detail
        : err.message;
  }

  async startCountdown(seconds){
    var counter = seconds;
    let interval  =  setInterval(() => {
      //console.log(counter);
      this.verificationCounter = counter;
      counter--;
      
  
      if(counter < 0 ){
        
        // The code here will run when
        // the timer has reached zero.
        
        clearInterval(interval);
        
        //console.log('Ding!');
       
      }
    }, 1000);

    return await new Promise(resolve => setTimeout(resolve, (seconds+2) * 1000));
  }
}

