import { Pipe, PipeTransform } from '@angular/core';

import { ISOState } from '../../core/models/models';
import { CommonService } from '../../core/services/common.service';

@Pipe({
    name: 'stateIsoName'
})
export class StateIsoNamePipe implements PipeTransform {
    constructor(
        private _commonSvc: CommonService) { }

    transform(code: any): string {

        let state = this._commonSvc.getStates().find(f => f.code == code);
        return state ? state.name : '';
    };
}
