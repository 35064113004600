import {Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild, ElementRef, AfterViewChecked, Renderer2} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import { GrowlSvc, CommonService, ResellerService, AuthenticationService } from 'src/app/core/services';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';
import { AddressType } from 'src/app/core/constants';
import { AddressTypes } from './model/address-type';
import { switchMap, filter, debounceTime, distinctUntilChanged, map, takeUntil, finalize } from 'rxjs/operators';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { AddressFormValidators, Countries, Municipio, ResellerAddress, ZipCodeDetail } from 'src/app/core/models/models';
import { environment } from 'src/environments/environment';
import { LoadingSpinnerService } from 'src/app/shared/loadingspinner/loading-spinner.service';
import {CountriesModel} from './model/countries.model';
import {COUNTRIES_INTERNATIONAL_SEND} from './constants/countries';
import {element} from 'protractor';
import { AddressConfig } from './address-form-config';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import * as $ from 'jquery';
import { ResellerObsService } from 'src/app/core/services/sharing/reseller-obs.service';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit, OnDestroy, AfterViewChecked {

  @ViewChild( 'phoneInt', { static : false } ) phoneInt: any;

  @Input() type: AddressType = 0;
  @Input() forceSave = false;
  @Input() fromOrders = false;
  @Input() internationalCheck = false;
  @Input() perfil = false;
  @Input() bussiness = false;
  @Input() isStore = false;
  @Input() noSave = false;
  @Input() noNotifications = false;
  @Input() useNeighborhoodCombo = false;
  @Input() tight = false;
  @Input() filterType = '';
  @Input() notUseFrequentAddr = false;
  @Input() neighborhoodAutoComplete = false;
  @Input() newOrderAddress = false;
  @Input() frequentAddressToForm = false;
  @Input() findAddresSave = false;
  @Input() isEditStore = false;
  @Input() isInternational = false;
  @Input() isEditAddress = false;
  @Input()  set address(addr: ResellerAddress) {

    this.theAddress = addr;
    setTimeout(() => {
      this.setAddress();
    }, 50);
  }

  @Input() deleteCount: number | undefined;
  @Input() maxAddresses: boolean | undefined;

  @Input() fromOnBoarding: boolean = false;
  @Input() isReturn: boolean = false;
  get address(): ResellerAddress {
    return this.theAddress;
  }

  @Output() selectionChange = new EventEmitter<ResellerAddress>();
  @Output() formChange = new EventEmitter<any>();

  private subscriptions: Subscription[] = [];

  theAddress: ResellerAddress;
  addressForm: FormGroup;
  addressType: any;
  addressFormSubmitted = false;
  manualMunicipios = false;
  allmunicipios: Municipio[];
  municipiosLoading = false;
  useFrequentAddress = false;
  public saveAddress = false;
  addressAlias = '';
  frequentAddresses: ResellerAddress[] = [];
  addressZipCodeDetail: ZipCodeDetail;
  state: any;
  municipio: any;
  resellerInfo: any;
  CountryCode = environment.countryCode;
  ZipCodeLength: number;
  ZipcodesDetails: ZipCodeDetail[] = [];
  ZipcodesDetailsSuggestions: string[] = [];
  zipCodeaAutoComplete = false;
  neighborhoodSuggestions: string[] = [];
  mbindLabel = 'Municipio';
  mbindValue = 'stateCode';
  mgroupBy = 'StateName';
  stateAndCityLabel = 'Ciudad y Estado';
  neighborhoodbLabel = 'Colonia';
  allMunReady = false;
  addressReady = false;
  selectMun = false;
  fulfillment = false;
  specialCharRegEx = '^[a-zá-źA-ZÁ-Ź0-9 \/\,\#\._-]+$';
  subscriptionUser: Subscription;
  international: boolean = false;
  // country: CountriesModel;
  country: Countries;
  lengthPhone = environment.lengthPhone;
  defaultCountry: Countries = {Name: environment.nameCountry, Code: environment.countryCode, CallingCode: environment.lada};
 // defaultCountry: Countries = environment.countryCode === 'MX' ? { Name: 'México', Code: 'MX', CallingCode: '52' } : { Name: 'Colombia', Code: 'CO', CallingCode: '57' };
  countries: CountriesModel[] = COUNTRIES_INTERNATIONAL_SEND;
  disabledCityAndState = false;
  isManualCityState: boolean = false;

  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [];
  countryCodeTel = environment.countryCode.toLowerCase();
  intlPhoneMode = environment.countryCode === 'ES';
  intlPhoneModeSubmit = false;
  intlPhoneStoredValue = {number: null, dialCode: null};
  intlPhoneFormValue = null;
  phoneIntControl = null;
  intlPhoneDialCode = environment.lada;

  allCountries: Countries[] = [];
  countryControl = new FormControl();
  filteredOptions: Countries[];
  countrySelected: Countries;

  validators: AddressFormValidators;
  frecuentAddressSelected: boolean = false;

  $onDestroy: Subject<void> = new Subject<void>();


  constructor(
    private fb: FormBuilder,
    private growlSvc: GrowlSvc,
    public commonSvc: CommonService,
    private resellerSvc: ResellerService,
    private loading: LoadingSpinnerService,
    private _authSvc: AuthenticationService,
    public renderer: Renderer2,
    private resellerObsService: ResellerObsService
  ) { }

   ngOnInit() {
    this.subscriptionUser = this.resellerObsService.getUserStateObservable.subscribe((userState) => {
      if (userState) {
        this.fulfillment = userState.isFullfillment;
        this.intlPhoneMode = this.fulfillment ? true : environment.countryCode === 'ES';

        this.addressType = AddressTypes[this.type];
        this.country = this.defaultCountry;
        this.countrySelected = this.defaultCountry;
        this.initAddressForm();
        this.setZipcodeSub();
        this.getResellerConf(this.filterType);

        if (this.CountryCode === 'CO') {
          this.mbindLabel = 'ciudad';
          this.mbindValue = 'dane';
          this.mgroupBy = 'departamento';
          this.stateAndCityLabel = 'Ciudad y Departamento';
          this.neighborhoodbLabel = 'Barrio';
          this.neighborhoodAutoComplete = true;
          this.zipCodeaAutoComplete = true;
          this.getAllMunicipios();
        }
        this.ZipCodeLength = (this.CountryCode === 'MX' || this.CountryCode === 'ES') ? 5 : 6;
        this.manualMunicipios = this.CountryCode === 'CO';
        this.selectCountry();
      }

    });

    // TODO: Se dejara comentado temporalmente hasta que se valide un buen funcionamiento en PROD
    //  this.subscriptionUser = this._authSvc.user$.subscribe(userState => {
    //     this.fulfillment = userState.isFullfillment;
    //     this.intlPhoneMode = this.fulfillment ? true : false;

    //     this.addressType = AddressTypes[this.type];
    //     this.country = this.defaultCountry;
    //     this.countrySelected = this.defaultCountry;
    //     this.initAddressForm();
    //     this.setZipcodeSub();
    //     this.getResellerConf(this.filterType);

    //     if (this.CountryCode === 'CO') {
    //       this.mbindLabel = 'ciudad';
    //       this.mbindValue = 'dane';
    //       this.mgroupBy = 'departamento';
    //       this.stateAndCityLabel = 'Ciudad y Departamento';
    //       this.neighborhoodbLabel = 'Barrio';
    //       this.neighborhoodAutoComplete = true;
    //       this.zipCodeaAutoComplete = true;
    //       this.getAllMunicipios();
    //     }
    //     this.ZipCodeLength = (this.CountryCode === 'MX' || this.CountryCode === 'ES') ? 5 : 6;
    //     this.manualMunicipios = this.CountryCode === 'CO';
    //     this.selectCountry();
    // });

    if (this.internationalCheck) {
      this.commonSvc.getCountries().subscribe(response => {
        if (response) {
          this.allCountries = response;
          this.filteredOptions = this.allCountries;
          if (this.CountryCode === 'ES') { this.internationalCheckActive(true); }

          this.countryControl.valueChanges.subscribe(value => {
            if (value) {
              this.filterCountries(value);
              if (this.allCountries.find((country) => country.Name === value)) {
                this.countrySelected = this.allCountries.find((country) => country.Name === value);
                this.setLengthControl(this.countrySelected.Code);
                this.country = this.countrySelected;
              } else {
                this.countrySelected = null;
                this.country = this.defaultCountry;
              }
              this.selectCountry();
            }
          });

            if (this.address && this.notUseFrequentAddr) {
              if (this.address.Country) {
                this.international = this.isInternational;
                this.countryControl.setValue(this.address.Country);
              }
              else {
                this.countryControl.setValue(this.defaultCountry.Name);
              }
              if (this.address.ZipCode) {
                setTimeout(() => {
                  this.addressForm.patchValue({ZipCode: this.address.ZipCode});
                }, 400);
              }
            }

          this.setPhoneInternationalData();
      }});
    }

     if (this.CountryCode === 'ES') {
       this.countryControl.setValue(this.defaultCountry.Name);
     }
  }

  ngAfterViewChecked(): void {
    const RANDOM_NAME = Math.random().toString(36).slice(-8);
    const GET_PHONE_SEARCH: ElementRef | any = document.querySelector(`ngx-intl-tel-input .search-container input`);
    if (GET_PHONE_SEARCH && !GET_PHONE_SEARCH.getAttribute('autocomplete')) {
      this.renderer.setAttribute(GET_PHONE_SEARCH, 'autocomplete', 'chrome-off');
      this.renderer.setAttribute(GET_PHONE_SEARCH, 'name', `phone-search-${RANDOM_NAME}`);
      this.renderer.setAttribute(GET_PHONE_SEARCH, 'type', 'search');
    }
    const GET_PHONE: ElementRef | any = document.querySelector(`ngx-intl-tel-input .intl-tel-input input`);
    if (GET_PHONE) {
      this.renderer.setAttribute(GET_PHONE, 'name', `phone-${RANDOM_NAME}`);
    }
    $('input').attr('autocomplete','chrome-off');
  }

  setPhoneInternationalData() {
    const TEMP_COUNTRYS = this.phoneInt.allCountries;
    let temArrayCounntry = [];
    this.allCountries.forEach((country) => {
      let COUNTRY_DATA = TEMP_COUNTRYS.find(x => x.iso2 == country.Code.toLocaleLowerCase());
      if (COUNTRY_DATA) {
        const OBJ_INT_PHONE = {
          areaCodes: COUNTRY_DATA.areaCodes,
          dialCode: COUNTRY_DATA.dialCode,
          flagClass: COUNTRY_DATA.flagClass,
          iso2: COUNTRY_DATA.iso2,
          name: country.Name,
          placeHolder: COUNTRY_DATA.placeHolder,
          priority: COUNTRY_DATA.priority,
        };

        temArrayCounntry.push(OBJ_INT_PHONE);
      }
    });

    if (Array.isArray(temArrayCounntry) && temArrayCounntry.length > 0) {
      temArrayCounntry.sort((a, b) => {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      });

      this.phoneInt.allCountries = temArrayCounntry;
      this.preferredCountries = [CountryISO.Spain];
    }
  }

  private filterCountries(value: string) {
    const filterValue = value.toLowerCase();
    this.filteredOptions = this.allCountries.filter(option => option.Name.toLowerCase().includes(filterValue));
  }

  private setZipcodeSub() {
    this.subscriptions.push(
      this.pipeZipCodeValueChanges(this.addressForm.get('ZipCode').valueChanges)
        .pipe(takeUntil(this.$onDestroy),finalize(()=> this.setZipcodeSub()), switchMap((value: string) =>
          this.refreshAddressZipCodeDetails(value))
        )
        .subscribe(undefined, err => {
          this.growlSvc.growlError(err);
        })
    );
  }

  private resetZipSub() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.setZipcodeSub();
  }

  private initAddressForm() {
    const keyForm = this.fulfillment ? 'FF' : this.CountryCode;
    const genericForm = AddressConfig.GENERIC_ADDRESS_FORM[keyForm];
    if (!this.addressFormSubmitted)
      this.addressForm = this.fb.group(genericForm);

    // this.phoneIntControl = new FormControl(this.intlPhoneFormValue);
      this.addressForm.get('Phone1INT').valueChanges.subscribe(value => {
        // console.log('Phone value has changed:', value)
        this.intlPhoneFormValue = value;
    });
    // this.addressForm.addControl(this.intlPhoneFormValue, this.phoneIntControl);

    // TODO: Deprecado?
    // this.addressForm.get('citySelection').valueChanges.subscribe(city => {
    //   this.changeMun(city);
    // });

    if (this.forceSave || this.bussiness) { this.saveAddress = true; }
  }

  private pipeZipCodeValueChanges(stream: Observable<any>) {
    return stream.pipe(
      filter(
        (value: string) => {
          const val = (value != null ? value.length : 0);
          if(value === ''){
            return true;
          }
          if (val === 0 && this.CountryCode === 'CO') {
            this.getAllMunicipios();
          } else if (this.country.Code !== 'MX') {
            return val >= 3;
          }
          return val >= 5;
        }
      ),
      debounceTime(300),
      distinctUntilChanged()
      , map(res => {
        // this.CostReady = false; // TODO: Deprecado?
        return res;
      })
    );
  }

  manualCityAndState(value: string) {
    this.addressForm.patchValue({
      City: value.trim(),
      State: value.trim(),
      CityAndState: value.trim()
    });
    this.isManualCityState = true;
  }

  internationalCheckActive(event) {
    this.countrySelected = this.defaultCountry;
    this.country = this.defaultCountry;
    this.addressForm.controls['ZipCode'].clearValidators();
    if (this.internationalCheck && event) {
      this.international = true;
      if (!this.countrySelected) {
        this.countryControl.setValue(this.defaultCountry.Name);
      }
      else {
        this.countryControl.setValue(this.countrySelected.Name);
      }
    } else {
      this.international = false;
      this.countryControl.setValue("");
    }
    //Limpia los codigos postales
    this.addressForm.patchValue({
      City: '',
      State: '',
      StateName: '',
      CityAndState: '',
      ZipCode: '',
    });
  }

  setLengthControl(code) {
    let lengthCountry = 9;
    switch(code) {
      case 'MX':
      case 'CO':
        lengthCountry = 10;
        break;
      case 'ES':
        lengthCountry = 9;
        break;
      default:
        lengthCountry = this.lengthPhone;
        break;
    }
    this.lengthPhone = lengthCountry;
    if (!this.intlPhoneMode) {
      this.addressForm.controls['Phone1'].setValidators([Validators.minLength(lengthCountry), Validators.maxLength(lengthCountry)]);
    }
  }

  refreshAddressZipCodeDetails(zipCode: string): Observable<void> {
    if(zipCode === '' || typeof zipCode === 'undefined') { return of(void 0); } // Esto es para evitar ejecutar el metodo cada vez que se limpie el input
    this.manualMunicipios = this.CountryCode === 'CO';
    if(this.international && this.countrySelected === null){
      this.growlSvc.growlWarn("No has seleccionado un pais valido.");
      return;
    }

    //TODO Refactorizacion para solo consultar en api de google, importante, no subirlo a México @Cesar Rivera
    return this.commonSvc.getZipCodeDetail(zipCode,
      (this.international && this.countrySelected && (this.countrySelected.Code !== this.CountryCode || this.CountryCode === 'ES')),
      this.countrySelected ? this.countrySelected.Code : undefined).pipe(
      map(zipCodeDetail => {
        if (zipCodeDetail.ZipCode === 'NoZipCode') {
          this.getAllMunicipios();

          if (this.CountryCode === 'CO') {
            this.addressZipCodeDetail = null;
          } else {
            this.disabledCityAndState = !(zipCodeDetail.StateName === '' || zipCodeDetail.City === '');
            this.manualMunicipios = !this.disabledCityAndState && !this.international;
            if(!this.isManualCityState) {
              this.addressForm.patchValue({
              City: '',
              State: '',
              CityAndState: '',
              StateName: '',
            });
            }

            if (this.useNeighborhoodCombo) {
              this.addressForm.patchValue(
                {Neighborhood: ''}
              );
            }
          }

          this.addressZipCodeDetail = null;
        } else {
          this.isManualCityState = false;
          this.addressZipCodeDetail = zipCodeDetail;
          this.disabledCityAndState = this.international
            ? zipCodeDetail.StateName !== ''
            : zipCodeDetail.City !== '';
          const city = this.disabledCityAndState ? zipCodeDetail.StateName : zipCodeDetail.City;
          const actualNeighborhood = this.addressForm.get('Neighborhood').value;
          const State = '[{ "code": "' + zipCodeDetail.StateCode + '" , "name": "' + zipCodeDetail.StateName + '"}]';
          this.state = JSON.parse(State);
          const Mun = '[{ "Municipio": "' + city + '" }]';
          this.municipio = JSON.parse(Mun);

          if (this.CountryCode === 'MX' || this.CountryCode === 'ES') {
            const cityAndState = this.international
              ? zipCodeDetail.StateName === ''
                ? ''
                : zipCodeDetail.StateName + ', ' + zipCodeDetail.StateCode
              : zipCodeDetail.City !== ''
                ? zipCodeDetail.City + ', ' + zipCodeDetail.StateCode
                : '';
            this.addressForm.patchValue({
              City: city,
              State: zipCodeDetail.StateCode,
              CityAndState: cityAndState
            });

            if (this.useNeighborhoodCombo) {
              this.addressForm.patchValue({
                Neighborhood: zipCodeDetail.Neighborhoods.includes(actualNeighborhood) ? actualNeighborhood : null
              });
            }

            this.manualMunicipios = false;
          } else if (this.CountryCode === 'CO') {
            this.getAllMunicipios();
            const currentDane = this.addressForm.get('DaneShort').value;

            if (!currentDane || !currentDane.length || this.addressZipCodeDetail.Dane !== currentDane) {
              this.addressForm.patchValue({
                City: '',
                State: '',
                CityAndState: '',
                StateName: '',
                Dane: '',
                DaneShort: '',
              });
              this.manualMunicipios = false;
              setTimeout(() => {
                this.manualMunicipios = true;
              }, 100);
            }
            this.filterCities(this.addressZipCodeDetail.Dane);

            if ( zipCodeDetail.Neighborhoods &&  zipCodeDetail.Neighborhoods.length) {
              this.addressForm.patchValue({
                Neighborhood: actualNeighborhood
              });
            }
          }
        }
      })
    );
  }

  private getAllMunicipios() {
    return new Promise((resolve) => {
      this.allmunicipios = [];
      this.municipiosLoading = true;
      const auxCountryCode = this.countrySelected ?  this.countrySelected.Code : this.CountryCode;
      this.commonSvc.getAllMunicipios(auxCountryCode).subscribe(data => {
        this.municipiosLoading = false;
        this.allmunicipios = data;
        this.allMunReady = true;

        if (this.fromOrders && this.CountryCode === 'CO' && this.theAddress) {
          const municipio = this.allmunicipios.find(x => x.ciudad === this.theAddress.City);
          this.changeMun(municipio);
        }

        if (this.selectMun) {
          this.selectMun = false;
          setTimeout(() => { this.selectOneMun(); }, 100);
        }

        resolve(true);
      }, err => { this.municipiosLoading = false; resolve(true); });
    });
  }

  private selectOneMun() {
    if (!this.addressForm) { return ; }

    if (!this.addressForm.get('State').value && !this.addressForm.get('Dane').value) {
      this.addressForm.patchValue({ citySelection: null});
      return ;
    }

    if (this.addressForm.get('Dane').value && this.allmunicipios && !this.addressForm.get('StateName').value) {
      const mun = this.allmunicipios.find(item => item.dane === this.addressForm.get('Dane').value);

      if (mun) {
        this.addressForm.patchValue({
          StateName: mun.departamento,
          State: mun.StateCode,
          City: mun.ciudad,
        });
      }
    }

    this.addressForm.patchValue({ citySelection: {
      StateCode: this.addressForm.get('State').value,
      dane: this.addressForm.get('Dane').value,
      daneShort: this.addressForm.get('DaneShort').value,
      ciudad: this.addressForm.get('City').value,
      Municipio: this.addressForm.get('City').value,
      StateName: this.addressForm.get('StateName').value,
      departamento: this.addressForm.get('StateName').value,
    }});
  }

  private getResellerConf(filterType) {

    this.resellerObsService.getResellerObservable.subscribe(
      async res => {
        if (res) {
          const addressesRes = res.addresses;
          this.maxAddresses = addressesRes.filter((item) => item.IsOriginAddress === true).length >= res.maxAddresses? true : false;

          this.resellerInfo = {
            SendRecipientNotifications: res.SendRecipientNotifications,
            IsPhoneVerified: res.IsPhoneVerified,
            Phone: res.Phone
          };

          //Valida si es una orden nueva para no llenar los campos
          if (!this.newOrderAddress) {
            // Don't override the value if the user already selected an option before we get the default configuration
            if (!this.addressForm.get('SendRecipientNotifications') && this.addressForm.get('SendRecipientNotifications').pristine) {
              this.addressForm.patchValue({ SendRecipientNotifications: this.resellerInfo.SendRecipientNotifications });
            }
          }

          let addresses = [];

          if (filterType === 'origin' && res.addresses) {
            addresses = res.addresses.filter(x => x.IsOriginAddress === true);
          } else if (filterType === 'destination' && res.addresses) {
            addresses = res.addresses.filter(x => x.IsDestinationAddress === true);
          } else if (filterType === 'all' && res.addresses) {
            addresses = res.addresses;
          }

          this.frequentAddresses = addresses;
          this.useFrequentAddress = !!this.frequentAddresses.length;
          await this.setAddress();


          //Esta seccion solo se utilizaba en ordenes nuevas
          // if (this.selectFirstAddress && this.frequentAddresses.length > 0 && !this.theAddress) {
          //   this.theAddress =  this.frequentAddresses[0];

          //   if (this.frequentAddressToForm) {
          //     this.theAddress = JSON.parse(JSON.stringify(this.theAddress ));
          //     this.theAddress.AddressId = null;
          //     this.theAddress.AddressAlias = null;
          //   }

          //   this.setAddress();
          // }
        }

      },
      err => {
        this.growlSvc.growlError(err);
      }
    );

  }

  public async setAddress() {
    if (this.theAddress && (this.international || (this.country && this.country.Code != environment.countryCode))) {
      this.setInternationalCountry(this.theAddress.Country);
    } else {
      this.cleanInternationalCountry();
    }

    if (this.theAddress && this.theAddress.AddressId && !this.perfil) {
      this.useFrequentAddress = true;

      if (!this.forceSave && !this.bussiness) { this.saveAddress = false; }

      if (this.frequentAddresses && this.frequentAddresses.length) {
        const address = this.frequentAddresses.find(item => item.AddressId === this.theAddress.AddressId);

        if (address) {
          const indexOf = this.frequentAddresses.indexOf(address);
          this.frequentAddresses.splice(indexOf, 1);
          this.frequentAddresses.unshift(address);
          this.theAddress = address;
        }
      }
    } else if (this.theAddress) {
      // this.getAllMunicipios(); // TODO: Deprecado?
      this.addressType = AddressTypes[this.type];

      if (!this.manualMunicipios) {
        this.initAddressForm();
        this.setZipcodeSub();
      }

      this.useFrequentAddress = false;
      this.addressForm.patchValue(this.theAddress);
      this.addressForm.patchValue({
        isOriginAddress : this.theAddress.IsOriginAddress,
        isDestinationAddress: this.theAddress.IsDestinationAddress
      });

      if (this.isReturn) {
        this.addressForm.controls['isOriginAddress'].setValue(false);
      }
      
      if ((environment.countryCode === 'ES') && (this.international || this.country.Code !== environment.countryCode) || this.fulfillment) {
        this.addressForm.patchValue({
          Phone1INT : this.theAddress.Phone1,
        });
      }

      if (this.fromOrders && this.CountryCode === 'CO') {
        await this.getAllMunicipios();
      }

      this.addressReady = true;

      if (this.perfil && this.theAddress.AddressAlias) {
        this.addressAlias = this.theAddress.AddressAlias;
      }

      if (this.CountryCode === 'CO') {
        this.selectMun = true;
        this.selectOneMun();
      }

    } else {
      this.useFrequentAddress = !!(this.frequentAddresses && this.frequentAddresses.length && !this.notUseFrequentAddr);

      if (!this.forceSave && !this.bussiness) { this.saveAddress = false; }
    }
  }

  selectedAddressChange() {
    this.selectionChange.emit(this.theAddress);
    if ( this.theAddress.Country && this.internationalCheck ) {
      this.country = this.allCountries.find(x => x.Code === this.theAddress.Country);
    } else {
      this.country = this.defaultCountry;
    }
    this.countrySelected = this.country;

    if (this.frequentAddressToForm) {
      this.setAddress();
    }
  }

  onSubmit() {
    this.addressFormSubmitted = true;
    const twoSelected = (this.addressForm.controls['isOriginAddress'].value && this.addressForm.controls['isDestinationAddress'].value)
    if (this.isValid() && (this.addressForm.controls['isOriginAddress'].value || this.addressForm.controls['isDestinationAddress'].value) && !twoSelected) {
      if (!this.useFrequentAddress) {
        this.fillAnAddress();
      }

      this.selectedAddressChange();
    } else {
      if (this.isValid() && (!this.addressForm.controls['isOriginAddress'].value || !this.addressForm.controls['isDestinationAddress'].value) && twoSelected) {
        this.growlSvc.growlWarn('Selecciona si es una dirección de origen o destino');
      } else {
        this.growlSvc.growlError('Datos de dirección incompletos o inválidos.');
      }
    }
  }

  // este metodo lo llama geneate modal
  getAddres(): ResellerAddress {
    if (this.useFrequentAddress && this.isValid()) {
      return this.theAddress;
    } else if (this.isValid()) {
      this.fillAnAddress();
      return this.theAddress;
    }
  }

  reset() {
    if (this.forceSave) { this.saveAddress = true; } else { this.saveAddress = false; }

    this.addressAlias = '';

    // TODO: Deprecado?
    // this.addressForm.reset();
    // this.addressForm.get('ZipCode').setValue(null);
    this.theAddress = null;
    this.addressFormSubmitted = false;
    this.addressForm = null;
    this.initAddressForm();
    this.addressForm.markAsUntouched();

    if (this.type) {
      this.addressForm.reset({
        SendRecipientNotifications: this.resellerInfo.SendRecipientNotifications
      });
    }

    this.resetZipSub();
    this.resetPhoneInternational();
  }

  private resetPhoneInternational() {
    this.intlPhoneModeSubmit = false;
    this.intlPhoneStoredValue = { number: null, dialCode: null };
    this.intlPhoneFormValue = null;
    this.phoneIntControl = null;
  }

  private getPhoneNumberFromInternational(data: any): string {
    let val = data || '';
    if (typeof val == "string") return val;
    let telNumber = val.number || '';
    if (this.intlPhoneDialCode != val.dialCode && val.number) {
      // telNumber = ((val.dialCode == this.intlPhoneDialCode ? '' : val.dialCode ) + val.number) || ''; //val.e164Number
      telNumber = val.number.includes(val.dialCode) ? val.number : val.dialCode + val.number;
    }
    return telNumber;
  }

  private fillAnAddress() {
    const formModel = this.addressForm.value;
    let id = null;

    if (this.theAddress) { id = this.theAddress.AddressId; }

    let phoneVal = '';
    if (!this.intlPhoneMode) {
      let phone = formModel.Phone1;
      if(this.CountryCode === 'ES'){
        if(!phone){
          phone = formModel.Phone1INT.number;
        }
      }
      phoneVal = this.commonSvc.getOnlyNumbers(phone).substr(0, 10);
    } else {
// if (this.intlPhoneStoredValue.number === null) {
//   // this.intlPhoneStoredValue = this.addressForm.get('Phone1INT').value;
//   this.intlPhoneStoredValue = this.intlPhoneFormValue;
// } else {
        // if (this.addressForm.get('Phone1INT').value && (this.intlPhoneStoredValue.number != this.addressForm.get('Phone1INT').value.number)) {
        if (this.intlPhoneFormValue && (this.intlPhoneStoredValue.number != this.intlPhoneFormValue.number)) {
          // this.intlPhoneStoredValue = this.addressForm.get('Phone1INT').value;
          this.intlPhoneStoredValue = this.intlPhoneFormValue;
          this.intlPhoneStoredValue.number = this.intlPhoneStoredValue.number.includes(this.intlPhoneStoredValue.dialCode) ?
            this.intlPhoneStoredValue.number : this.intlPhoneStoredValue.dialCode + this.intlPhoneStoredValue.number;
        }
        // }
      this.intlPhoneModeSubmit = true;
      phoneVal = this.getPhoneNumberFromInternational(this.intlPhoneStoredValue);
    }


    this.theAddress = {
      OrderId: '',
      AddressId: id,
      ZipCode: formModel.ZipCode,
      State: formModel.State,
      City: formModel.City,
      Neighborhood: formModel.Neighborhood,
      Address1: formModel.Address1,
      Address2: formModel.Address2,
      AddressAlias: this.addressAlias || '',
      Country: this.countrySelected && this.international ? this.countrySelected.Code : this.CountryCode,
      CompanyName: formModel.CompanyName,
      ContactName: formModel.ContactName,
      Email: formModel.Email,
      Phone1: phoneVal,
      Phone2: '',
      ResellerId: '',
      IsDestinationAddress: formModel.isDestinationAddress == null ? false : formModel.isDestinationAddress ,
      IsOriginAddress: formModel.isOriginAddress == null ? false : formModel.isOriginAddress ,
      Dane: formModel.Dane,
      DaneShort: formModel.DaneShort,
      International: this.international,
    };
  }

  isAliasInvalid(): boolean {
    if (!this.useFrequentAddress && this.type === 0) {
      this.saveAddress = true;
    }
    if (this.saveAddress && !this.noSave) {
      if (!this.addressAlias || !this.addressAlias.length) {
        return true;
      }
    }

    return false;
  }

  isPhoneValid(): boolean {
    if (!this.intlPhoneMode) {
      let aphone = this.addressForm.get('Phone1').value;

      if (!aphone) {
        if(this.CountryCode === 'ES'){
          aphone = this.addressForm.get('Phone1INT').value.number;
          if(!aphone){return false;}
        }
        else{
          return false;
        }
      }

      const thephone = this.commonSvc.getOnlyNumbers(aphone);
      return !!thephone.length;
    } else {
      const valid = this.isPhoneIntlValid();
      return valid;
    }
  }

  isPhoneIntlValid(): boolean {
    // if (!this.addressFormSubmitted && !this.addressForm.get('Phone1INT').touched) return true;
    if (!this.addressForm.get('Phone1INT').touched ||
      (this.intlPhoneModeSubmit && (this.intlPhoneFormValue && (this.intlPhoneFormValue.number == this.intlPhoneStoredValue.number)))) {
      return true;
    }
    // this.intlPhoneFormValue = null;
    const valid = (this.addressForm.get('Phone1INT').valid);
    return valid;
  }

  prepareLoad(): void {
    if (this.intlPhoneMode) {
      if (this.intlPhoneStoredValue.number != null) {
        const phoneNumber = this.intlPhoneStoredValue.number.includes(this.intlPhoneStoredValue.dialCode) ? this.intlPhoneStoredValue.number : this.intlPhoneStoredValue.dialCode + this.intlPhoneStoredValue.number;
        this.addressForm.get('Phone1INT').setValue(phoneNumber);
        // this.intlPhoneStoredValue = {number: null, dialCode: null};
      } else {
        //In generate modal never enter into fillAddress
        // this.addressForm.get('Phone1INT').setValue('');
      }
    }
  }

  isValid(submit = false): boolean {
    if (this.addressForm.controls['Email'].value && !this.addressForm.controls['Email'].valid) {
      return false;
    }

    if (this.useFrequentAddress) {
      if (this.isFrecuentAddresDataValid()) {
        return true;
      }
      else {
        return false;
      }
    } else {
      if (submit) { this.addressFormSubmitted = true; } // this.addressFormSubmitted lo modifica generate modal

      if (!this.findAddresSave) {
        if (!this.intlPhoneMode) {
          return this.addressForm.valid && !this.isAliasInvalid() && this.isPhoneValid();
        } else {
          if (this.intlPhoneModeSubmit && (this.intlPhoneFormValue && (this.intlPhoneStoredValue.number == this.intlPhoneFormValue.number))
          || (this.intlPhoneStoredValue.number != null)) { return true; }
          return this.addressForm.valid && !this.isAliasInvalid() && this.isPhoneValid();
        }
      } else {
        return true;
      }
    }
  }

  // TODO: Deprecado?, ya no se utiliza
  isAddressAliasDuplicated(): boolean {
    if (!this.isAliasInvalid()) {
      const repeted = this.frequentAddresses.find(adr => adr.AddressAlias === this.addressAlias);

      if (repeted) {
        this.growlSvc.growlError('Ya existe una dirección con el mismo alias.');
        return true;
      }
    }

    return false;
  }

  changeMun(data = null) {
    if (!data) {
      this.addressForm.patchValue({
        City: '',
        State: '',
        StateName: '',
        CityAndState: '',
        Dane: '',
        DaneShort: ''
      });
      this.getZipsByDane('');
      this.addressForm.patchValue({ZipCode: ''});
      return ;
    }

    if (this.CountryCode === 'CO') {
      this.addressForm.patchValue({
        City: data.ciudad,
        State: data.StateCode,
        StateName: data.departamento,
        CityAndState: data.ciudad + ', ' + data.StateCode,
        Dane: data.dane,
        DaneShort: data.daneShort
      });

      if (this.addressZipCodeDetail && this.addressZipCodeDetail.Dane && this.addressZipCodeDetail.Dane !==  data.daneShort) {
        this.addressForm.patchValue({ ZipCode: '' });
      }

      if (this.addressForm.get('Address1').value !== '') {
        this.getZipsByDane(data.daneShort, this.addressForm.get('Address1').value, data.Municipio, data.departamento, true);
      } else {
        this.getZipsByDane(data.daneShort, data.ciudad, data.Municipio, data.departamento, true);
      }
    } else {
      this.addressForm.patchValue({
        City: data.Municipio,
        State: data.StateCode,
        StateName: data.StateName,
        CityAndState: data.Municipio + ', ' + data.StateCode
      });
    }
  }

  private getZipsByDane(dane: string, address = '', town = '', department = '', autoComplete = false) {
    if (!dane || !dane.length) {
      this.ZipcodesDetails = [];
      return ;
    }

    this.commonSvc.getZipCodeDetailsByDane(dane, address, town, department).subscribe(data => {
      this.ZipcodesDetails = data;
      if (!this.notUseFrequentAddr || autoComplete) {
        if (this.ZipcodesDetails.length > 0) {
          this.addressForm.patchValue({ZipCode: this.ZipcodesDetails[0].ZipCode});
        } else {
          this.addressForm.patchValue({ZipCode: '0000000'});
        }
      }
    });
  }

  municipiosSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    const state = (environment.countryCode === 'MX' || environment.countryCode === 'ES') ? 'StateName' : 'departamento';
    const city = (environment.countryCode === 'MX' || environment.countryCode === 'ES') ? 'Municipio' : 'ciudad';
    return item[city]
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLocaleLowerCase()
      .indexOf(term) > -1 || item[state]
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLocaleLowerCase()
      .indexOf(term) > -1;
  }

  filterZipCode(event) {
    const query = event.query;
    const filtered = this.ZipcodesDetails.filter( zip => zip.ZipCode.indexOf(query) >= 0 );
    this.ZipcodesDetailsSuggestions = filtered.map(item => item.ZipCode);
  }

  filterNeighborhoods(event) {
    const query = event.query;

    if (!this.addressZipCodeDetail || !this.addressZipCodeDetail.Neighborhoods || !this.addressZipCodeDetail.Neighborhoods.length) {
      this.neighborhoodSuggestions = [];
      return;
    }

    this.neighborhoodSuggestions = this.addressZipCodeDetail.Neighborhoods.filter( item => {
      const neight = item.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLocaleLowerCase();
      const normquery = query.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLocaleLowerCase();
      return neight.indexOf(normquery) >= 0;
    });
  }

  private filterCities(dane) {
    const filtered = this.allmunicipios.filter( mun => mun.daneShort === dane );

    if (filtered && filtered.length) {
      this.allmunicipios = filtered;
    }
  }

  cityCompareFn(a, b): boolean {
    if (environment.countryCode === 'CO') {
      return (!!a && !!b && a.dane === b.dane);
    }

    return (!!a && !!b && a.stateCode === b.stateCode);
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
    this.subscriptionUser.unsubscribe();
  }

  selectCountry() {
    if(!this.isManualCityState){
      this.addressForm.patchValue({
        City: '',
        State: '',
        StateName: '',
        CityAndState: '',
        ZipCode: '',
      });
    }
    this.addressForm.patchValue({
      Country: this.countrySelected && this.international ? this.countrySelected.Code : this.CountryCode,
      International: this.international,
    });
  }

  getCountryIcon (coutry: Countries): string {
    return coutry ? `http://purecatamphetamine.github.io/country-flag-icons/3x2/${coutry.Code}.svg` : '';
  }

  setInternationalCountry(countryCode: string) {
    this.international = true;
    this.countrySelected = this.allCountries.find(element => element.Code === countryCode);
    const value = this.countrySelected ? this.countrySelected.Name : '';
    this.countryControl.setValue(value);
  }

  cleanInternationalCountry() {
    this.international = this.CountryCode === 'ES' ? true : false;
    this.countrySelected = this.CountryCode === 'ES' ? this.defaultCountry :  undefined;
    this.countryControl.setValue(this.countrySelected ? this.countrySelected.Name : '');
  }

  isFrecuentAddresDataValid() {
    let addressValidators: AddressFormValidators = new AddressFormValidators();

    try {
      if (this.theAddress && this.theAddress.AddressId) {
        this.frecuentAddressSelected = true;
        this.setAddressFormValidators(addressValidators);
      }
      else {
          this.frecuentAddressSelected = false;
          return false;
      }

      this.validators = Object.assign(addressValidators);

      for (let key in addressValidators) {
        if(!addressValidators[key]) {
          return false;
        }
      }
      return true;
    }
    catch(error) {
      this.growlSvc.growlError('No se pudo obtener si la dirección fue válida o no.');
      return false;
    }

  }

  setAddressFormValidators(addressValidators: AddressFormValidators) {
    try {
      if (this.theAddress && addressValidators) {
        for (let key in addressValidators) {
          if (!this.theAddress[key] || this.theAddress[key] === '') {
            addressValidators[key] = false;
          }
        }
      }
    }
    catch(error) {
      this.growlSvc.growlError('No se pudieron obtener las validaciones para las direcciones.');
    }
  }

  changeForm() {
    this.formChange.emit(this.addressForm.valid);
  }

  /**
   * @description Select the other option
   * @see PT-1061
   */
  onOriginAddressChange() {
    if (this.addressForm.get('isOriginAddress').value) {
      this.addressForm.get('isDestinationAddress').setValue(false);
    }
  }

    /**
   * @description Select the other option
   * @see PT-1061
   */
  onDestinationAddressChange() {
    if (this.addressForm.get('isDestinationAddress').value) {
      this.addressForm.get('isOriginAddress').setValue(false);
    } 
  }
}
