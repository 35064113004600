import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InboxDirective } from './inbox.directive';

@NgModule({
  declarations: [InboxDirective],
  imports: [
    CommonModule,
  ],
  exports: [
    InboxDirective,
  ],
})
export class InboxDirectiveModule { }
