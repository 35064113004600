import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { EditFrequentAddressComponent } from './edit-frequent-address.component';
import { EditDetailAddressComponent } from './edit-detail-address.component';
import { EditFrequentParcelComponent } from './edit-frequent-parcel.component';
import { PhoneVerificationModule } from 'src/app/shared/phoneVerification.module';
import { ConfigNotificationModule } from 'src/app/shared/configNotification.module';
import { AddressFormModule } from 'src/app/address-form/address-form.module';
import { AddReturnComponent } from './add-return.component';
import { SendReturnComponent } from './send-return.component';
import { TooltipModule } from 'primeng/tooltip';
@NgModule({
  imports: [SharedModule,AddressFormModule, PhoneVerificationModule, ConfigNotificationModule, TooltipModule],
  declarations: [
    EditFrequentAddressComponent
    ,EditFrequentParcelComponent
    ,EditDetailAddressComponent
    ,AddReturnComponent
    ,SendReturnComponent
  ],
  exports: [
    SharedModule,
    EditFrequentAddressComponent,
    EditFrequentParcelComponent,
    PhoneVerificationModule,
    ConfigNotificationModule
    ,EditDetailAddressComponent
    ,AddReturnComponent
    ,SendReturnComponent
  ]
})
export class ResellerCommonModule { }
