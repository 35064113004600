import { Injectable } from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { mergeMap, map, delay } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ISOState, ZipCodeDetail, ZipCodeMunicipios, Municipio, OrdenFolioCheck, resellerGlobalConfig, Countries, RegimesCatalogResponse } from '../models/models';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import {
  Orden,
} from "../../core/models/models";
const ALPHANUMERIC_REGEXP = /^([a-zA-ZñÑ0-9 _-]+)$/;
const VALID_CHARACTERS_REGEXP = /^([a-zA-ZÀ-ÿ0-9 _-]+)$/;

@Injectable()
export class CommonService {

  constructor(
    private http: HttpClient,
  ) { }

  apiUrl = environment.apiUrl;
  municipiosList: Municipio[];

  private _IsoStates: Array<ISOState> = [
    { 'code': 'MX-AGU', 'name': 'Aguascalientes' },
    { 'code': 'MX-BCN', 'name': 'Baja California' },
    { 'code': 'MX-BCS', 'name': 'Baja California Sur' },
    { 'code': 'MX-CAM', 'name': 'Campeche' },
    { 'code': 'MX-CHH', 'name': 'Chihuahua' },
    { 'code': 'MX-CHP', 'name': 'Chiapas' },
    { 'code': 'MX-CMX', 'name': 'Ciudad de México' },
    { 'code': 'MX-COA', 'name': 'Coahuila de Zaragoza' },
    { 'code': 'MX-COL', 'name': 'Colima' },
    { 'code': 'MX-DUR', 'name': 'Durango' },
    { 'code': 'MX-GRO', 'name': 'Guerrero' },
    { 'code': 'MX-GUA', 'name': 'Guanajuato' },
    { 'code': 'MX-HID', 'name': 'Hidalgo' },
    { 'code': 'MX-JAL', 'name': 'Jalisco' },
    { 'code': 'MX-MEX', 'name': 'México' },
    { 'code': 'MX-MIC', 'name': 'Michoacán' },
    { 'code': 'MX-MOR', 'name': 'Morelos' },
    { 'code': 'MX-NAY', 'name': 'Nayarit' },
    { 'code': 'MX-NLE', 'name': 'Nuevo León' },
    { 'code': 'MX-OAX', 'name': 'Oaxaca' },
    { 'code': 'MX-PUE', 'name': 'Puebla' },
    { 'code': 'MX-QUE', 'name': 'Querétaro' },
    { 'code': 'MX-ROO', 'name': 'Quintana Roo' },
    { 'code': 'MX-SIN', 'name': 'Sinaloa' },
    { 'code': 'MX-SLP', 'name': 'San Luis Potosí' },
    { 'code': 'MX-SON', 'name': 'Sonora' },
    { 'code': 'MX-TAB', 'name': 'Tabasco' },
    { 'code': 'MX-TAM', 'name': 'Tamaulipas' },
    { 'code': 'MX-TLA', 'name': 'Tlaxcala' },
    { 'code': 'MX-VER', 'name': 'Veracruz' },
    { 'code': 'MX-YUC', 'name': 'Yucatán' },
    { 'code': 'MX-ZAC', 'name': 'Zacatecas' }

  ];

  getStates(): Array<ISOState> {

    return this._IsoStates;
  }

  getStateName(code: string) {
    const state = this._IsoStates.find(f => f.code === code);
    return state ? state.name : '';
  }

  getZipCodeDetail(zipCode: string, international: boolean = false, country = ''): Observable<ZipCodeDetail> {
    const params = new HttpParams({
      fromObject : {country}
    });

    return this. http.get(`${this.apiUrl}ZipCodes/${zipCode}?international=${international}`, { params })
      .pipe(
        mergeMap(results => {
            if (results === null) {
              return this.castToZipCodeDetail(JSON.parse('{ "ZipCode":"NoZipCode","StateCode":"","StateName":"","City":"","Details":[] }'));
            }

            return this.castToZipCodeDetail(results);
          })
      );
  }

  getZipCodeMassiveDetail(zipCodesInformation) {
    return this. http.post(`${this.apiUrl}ZipCodes/massiveZipCodeInformation`, zipCodesInformation);
  }

  getZipCodeDetailsByDane(dane: string, address: string, town: string, department: string): Observable<ZipCodeDetail[]> {
    const params = new HttpParams({
      fromObject: {address, town, department}
    });
    const result =  this.http.get<any[]>(this.apiUrl + 'ZipCodes/byDane/' + dane, { params })
      .pipe(

        map(results => {
            if (!results || !results.length) {
             return this.castToZipCodeDetail(JSON.parse('[]'), false);
            }
            const resultarr = [];
            results.forEach(item => {
              const anitem: ZipCodeDetail = this.castToZipCodeDetail(item, false);
              resultarr.push(anitem);
            });
            return resultarr;
          })
      ) as Observable<ZipCodeDetail[]>;
    return result;
  }

  getMunicipios(stateCode: string): Observable<ZipCodeMunicipios[]> {

    return this.http.get<ZipCodeMunicipios[]>(this.apiUrl + 'ZipCodes/Municipios/' + stateCode);

  }

  getCountries(): Observable<Countries[]> {
    return this.http.get<Countries[]>(this.apiUrl + 'ZipCodes/Countries');
  }

  getMyConfig(): Observable<resellerGlobalConfig> {
    return this.http.get<resellerGlobalConfig>(this.apiUrl + 'ResellerGlobalConfigs/myconfig');
  }

  getAllMunicipios(countryCode = null): Observable<Municipio[]> {
    if (!this.municipiosList || !this.municipiosList.length) {
      return this.http.get<Municipio[]>(this.apiUrl + 'ZipCodes/allmunicipios?countryCode=' + countryCode).pipe(map(items => {
        this.municipiosList = items;
        return items;
      }));
    } else {
      return of(this.municipiosList);
    }
  }

  checkOrderFolio(req: OrdenFolioCheck): Observable<boolean> {
    return this.http.post<boolean>(this.apiUrl + '/Orders/existFolio', req);
  }
  private castToZipCodeDetail(data: any, obsv?: true): Observable<ZipCodeDetail>;
  private castToZipCodeDetail(data: any, obsv?: false): ZipCodeDetail;
  private castToZipCodeDetail(data: any, obsv = true) {
    // cast to type
    const detail = new ZipCodeDetail();

    detail.ZipCode = data.ZipCode;
    detail.StateCode = data.StateCode;
    detail.StateName = data.StateName;
    detail.City = !data.Details ? '' : data.Details[0] ? data.Details[0].City : '';
    if (data.Dane) {
      detail.Dane = data.Dane;
    }

    // Group detail cities
    if (data.Details) {
      detail.Neighborhoods = data.Details.reduce(function (acc, val) {
        if (!acc.includes(val.Neighborhood)) {
          acc.push(val.Neighborhood);
        }

        return acc;
      }, []).sort();
    }

    if (!obsv) { return  detail; }

    // return as observable
    return Observable.create(observer => {
      observer.next(detail);
      observer.complete();
    });
  }

  getCardExpiryYears(): number[] {
    const currYear = new Date(Date.now()).getFullYear();
    const result = [];

    for (let i = 0; i < 25; i++) { result.push(currYear + i); }

    return result;
  }

  /* Patterns*/
  passwordPattern = /^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W])|(?=.*\W)(?=.*\d))|(?=.*\W)(?=.*[A-Z])(?=.*\d)).{8,}$/gm;

  /* Mascaras */
  phoneMask = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  numberMask = createNumberMask({ prefix: '', includeThousandsSeparator: false, thousandsSeparatorSymbol: '', allowLeadingZeroes: true });
  decimalMask = createNumberMask({ prefix: '', includeThousandsSeparator: false, thousandsSeparatorSymbol: '', allowLeadingZeroes: true, allowDecimal: true });

  cvvMask3 = createNumberMask({ prefix: '', includeThousandsSeparator: false, thousandsSeparatorSymbol: '', allowLeadingZeroes: true, allowDecimal: false, integerLimit: 3 });
  cvvMask4 = createNumberMask({ prefix: '', includeThousandsSeparator: false, thousandsSeparatorSymbol: '', allowLeadingZeroes: true, allowDecimal: false, integerLimit: 4 });

  zipCodeMask = createNumberMask({ prefix: '', includeThousandsSeparator: false, thousandsSeparatorSymbol: '', allowLeadingZeroes: true, allowDecimal: false, integerLimit: 5 });

  cardMask = function (rawValue) {

    if (rawValue === 'americanexpress') {
      return [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/];
    }

    return [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  };

  getOnlyNumbers(text: string) {
    return text ? text.replace(/\D/g, '') : null;
  }

  sanitizeInternationalPhoneNumber(text:string){
    return text ? text.replace(/\s/g, '') : null;
  }

  getCountryIcon (coutryCode : Countries) : string {
    return coutryCode ? `http://purecatamphetamine.github.io/country-flag-icons/3x2/${coutryCode}.svg` : '';
  }

  getPaymentTermsCatalog(): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + 'BillingPaymentTermsCodes/Catalog');
  }

  getPaymentMethodsCatalog(): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + 'BillingPaymentMethodsCode/Catalog');
  }

  getCFDIUseCatalog(personType: string): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + `BillingCFDIUseCode/Catalog/${personType}`);
  }

  getCFDiCatalogByTaxRegimen(billingTaxRegimeId: number) {
    const url = `${this.apiUrl}/BillingCFDIUseCode/regimen/${billingTaxRegimeId}`;
    return this.http.get<any[]>(url);
  }

  getTaxRegimesCatalog() {
    return this.http.get<RegimesCatalogResponse>(this.apiUrl + '/BillingTaxRegimen/catalog');
  }

  getTaxRegimenByPersonType(personType?: string): Observable<any[]> {
    const url = `${this.apiUrl}/BillingTaxRegimen/list${personType ? `?personType=${personType}` : ''}`;
    return this.http.get<any[]>(url);
  }

  isJson(stringData): boolean {
    try {
      JSON.parse(stringData);
    } catch (e) {
      return false;
    }
    return true;
  }

  onlyAlphanumericCharacters(stringToValidate: string): boolean {
    return ALPHANUMERIC_REGEXP.test(stringToValidate);
  }

  onlyValidCharacters(stringToValidate: string): boolean {
    return VALID_CHARACTERS_REGEXP.test(stringToValidate);
  }

  /**
   * @param {Orden} Order Orden para calcular propiedades
   * @description Toma una orden y calcula sus propiedades para seguimiento de guías pendientes
   * @see PAK-4290
   */
  computePendingPropertiesFromOrder(Order:Orden):any{
    if(Order && Array.isArray(Order.orderShipment) && Order.orderShipment.length > 0){
      let pendingGuides = 0;
      let slowestGuide = "DELIVERED"
      const priority = {
        'DELIVERED': 0,
        'ON_DELIVERY': 1,
        'IN_TRANSIT': 2,
        'WAITING': 3,
        'EXCEPTION': 4,
        'RETURNED': 5,
        'CANCELLED': 6,
      };
      const pendingStatuses = [
        'WAITING',
        'IN_TRANSIT',
        'EXCEPTION',
      ];
      for (let i in Order.orderShipment) {
        if (pendingStatuses.includes(Order.orderShipment[i].TrackingStatus)) {
          pendingGuides += 1;
        }
        if (priority[slowestGuide] < priority[Order.orderShipment[i].TrackingStatus]) {
          slowestGuide = Order.orderShipment[i].TrackingStatus;
        }
      }
      return {
        pendingGuides,
        slowestGuide,
      };
    } else {
      return {
        pendingGuides: 0,
        slowestGuide: '',
      };
    }
  }

}
