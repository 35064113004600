import { Component, Input } from '@angular/core';
import { GrowlSvc } from '../../core/services/growl.service';
import { LayoutService } from 'src/app/containers/default-layout/service/layout.service';

@Component({
  selector: 'app-modal-xl',
  template: `
  <ng-template #content><ng-content></ng-content></ng-template>
    <div *ngIf="useModal" class="modal fade" tabindex="-1"
         [ngClass]="{'show': visibleAnimate }"
         [ngStyle]="{'display': visible ? 'block' : 'none', 'overflow-x': visible ? 'hidden' : 'none', 'overflow-y': visible ? 'auto' : 'none', 'opacity': visibleAnimate ? 1 : 0, 'z-index': 1000}">
      <div class="modal-xl-dialog modal-dialog-centered" [ngClass]="dilogClass">
        <div class="modal-xl-content">
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
      </div>
    </div>
    <div *ngIf="!useModal" [hidden]="!visible">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
    `,
  styles: [`
    .modal {
      backdrop-filter: blur(8px);
      background: rgba(170,170,170,0.2);
    }
    
    .modal-xl-dialog {
      max-width: 800px;
      margin: 1.75rem auto;
    }

    .modal-xl-content {
        width: 900px !important;
        height: auto;
        position: relative;
        display: flex;
        flex-direction: column;
        pointer-events: auto;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 0.3rem;
        outline: 0;
    }

  `]
})
export class ModalXLComponent {
  constructor(
    public growlSvc: GrowlSvc,
    public layoutService: LayoutService,
  ) { }
  public visible = false;
  public visibleAnimate = false;

  @Input() dilogClass = '';
  @Input() useModal = true;

  public show(toggle = true): void {
    if (this.useModal && toggle) {
      this.layoutService.toggleSide(false);
    }
    setTimeout(() => { this.visibleAnimate = true; this.visible = true; }, 100);

  }

  public hide(toggle = true): void {
    if (window.innerWidth > 960 && toggle) { this.layoutService.toggleSide(true); }
    this.visibleAnimate = false;
    setTimeout(() => this.visible = false, 300);
  }
}
