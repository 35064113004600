import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
    name: 'refundStatusDescription'
})
export class RefundStatusDescriptionPipe implements PipeTransform {
    constructor() { }

    transform(refundStatus: string, shortFormat: boolean = true): string {
        switch (refundStatus) {
            case 'SUCCESS': return `El reembolso puede aplicarse siempre y cuando la ${environment.countryCode === 'ES' ? 'envío' : 'guía'} no esté en tránsito.`;
            case 'REFUNDPENDING': return 'El reembolso ya fue solicitado por el reseller y requiere de aprobación, o bien, ya fue aprobado y está en proceso de ser aplicado.';
            case 'REFUNDED': return `El reembolso de esta ${environment.countryCode === 'ES' ? 'envío' : 'guía'} ya fue aplicado.`;
            case 'REFUNDFAILED': return `El reembolso no pudo aplicarse, posiblemente porque la ${environment.countryCode === 'ES' ? 'envío' : 'guía'} ya está en tránsito.`;
            default: return '';
          }
    };
}
