import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormHelperService {

  constructor() { }

    public validateFormGroup(form: FormGroup): void {
        Object.keys(form.controls).forEach(key => {
            const control = form.get(key);
            if (control instanceof FormGroup) {
                this.validateFormGroup(control);
            } else {
                if (control) {
                    control.markAsTouched();
                    control.updateValueAndValidity();
                }
            }
        });
    }
}
