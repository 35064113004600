import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'defaultText'
})
export class DefaultTextPipe implements PipeTransform {

    transform(value: string, defaultValue: any): string {
 
        if (value == undefined || value == null || value.trim().length == 0) { 
            return defaultValue ? defaultValue : "\xa0"; //non breaking space
        }

        return value;

    }
}
