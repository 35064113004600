import { Pipe, PipeTransform } from '@angular/core';
import { StoreService } from 'src/app/views-reseller/store/service/store.service';

enum RouteImage {
    MGT = 'assets/img/brand/logo_magento.png' ,
    KOM = 'assets/img/brand/logo_kometia.png',
    SPF = 'assets/img/brand/logo_shopify.png',
    WOO = 'assets/img/brand/logo_woocommerce.png',
    PRS = 'assets/img/brand/logo_prestashop.png',
    AWZ = 'assets/img/brand/logo_amazon.png',
    MER = 'assets/img/brand/logo_mercadolibre.png',
    TNG = 'assets/img/brand/logo_withoutStore.png',
    JUM = 'assets/img/brand/logo_jumpseller.png',
    VTX = 'assets/img/brand/logo_vtex.png',
}

@Pipe({
    name: 'VendorLogo'
})
export class VendorLogoPipe implements PipeTransform {
    constructor(private storeService: StoreService) {
    }

    /**
     * Devuelve la ruta donde se obtendrá la imagen de la tienda.
     * @param vendorId Identificador de la tienda virtual
     */
    transform(vendorId: any): string {
        let result = RouteImage[vendorId];
        if (!result) {
            const STORE = this.storeService.marketplaceIcons.find(store => store.StoreId == vendorId);
            if (STORE && STORE.image) {
                return STORE.image;
            }
            result = '';
        }
        return result;
    }
}
