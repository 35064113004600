import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AdminService,
  AuthenticationService,
  CommonService,
  CourierSvc,
  GrowlSvc,
  OpenPayService,
  EtominService,
  ResellerService,
  FullfillmentService ,
  ShipmentService,
  SvgChartService,
  UserService,
  ApiService,
  RouteParamsUtilService,
  DateUtilService,
  FileUtilService,
  ExcelService,
  PaycometBizumService,
  StripeService,
  MercadoPagoService
} from './services';
import { httpInterceptorProviders } from './http-interceptors';
import { AuthAdminGuard, AuthResellerGuard, AuthHelpdeskGuard, AuthFullfillmentGuard, EditionGuard, LoggedGuard, DeprecatedModuleGuard, PreAuthGuard} from './guards';
import { PaymentStatusPipe, PaymentTypePipe, TrackingStatusPipe, RefundStatusPipe, RefundStatusDescriptionPipe, CourierNamePipe, FeeTypePipe } from '../shared/pipes';
import { MessageService } from 'primeng/components/common/messageservice';
import { LogUtilService } from './services/util/log.service';
import { AppMenuService } from './services/app-menu.service';
import { CookieService } from 'ngx-cookie-service';
import { FormatHelperService } from './services/util/format-helper.service';
import { ItemFFService } from './services/sharing/item-ff.service';
import { ShipmentObsService } from './services/sharing/shipment-obs.service';
import { NotificationService } from './services/notification/notification.service';
import { NotificationObsService } from './services/sharing/notification-obs.service';
import { ProfileObsService } from './services/sharing/profile-obs.service';
import { AsynchronousGuardProcessor } from './guards/asynchronous-guard-processor.service';
import { ResellerStoreActionService } from './services/domain/resellerStoreAction.service';
import { ResellerObsService } from './services/sharing/reseller-obs.service';
import {StoreUtilsService} from './services';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  exports: [],
  providers: [
    AdminService,
    AuthenticationService,
    CommonService,
    CourierSvc,
    GrowlSvc,
    OpenPayService,
    EtominService,
    ResellerService,
    FullfillmentService,
    ShipmentService,
    SvgChartService,
    UserService,
    ApiService,
    AppMenuService,
    httpInterceptorProviders,
    AuthAdminGuard,
    AuthResellerGuard,
    AuthHelpdeskGuard,
    LoggedGuard,
    AuthFullfillmentGuard,
    EditionGuard,
    PreAuthGuard,
    AsynchronousGuardProcessor,
    PaymentStatusPipe,
    PaymentTypePipe,
    TrackingStatusPipe,
    RefundStatusPipe,
    RefundStatusDescriptionPipe,
    CourierNamePipe,
    MessageService,
    RouteParamsUtilService,
    DateUtilService,
    FileUtilService,
    ExcelService,
    LogUtilService,
    CookieService,
    DeprecatedModuleGuard,
    FormatHelperService,
    PaycometBizumService,
    StripeService,
    MercadoPagoService,
    ItemFFService,
    ShipmentObsService,
    NotificationObsService,
    NotificationService,
    ProfileObsService,
    ResellerStoreActionService,
    ResellerObsService,
    FeeTypePipe,
    StoreUtilsService,
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule
    };
  }
}
