import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import { catchError, map, flatMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { CommonService } from '../services';
import { LogUtilService } from 'src/app/core/services/util/log.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private auth: AuthenticationService, private cSvc: CommonService, private logHelper: LogUtilService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req)
      .pipe(
        catchError(response => {
          // this.logHelper.info("Interceptor",null,response,"Respuesta interceptor");
          const userErrorMessage = { detail: 'Lo sentimos, algo salió mal. Inténtalo de nuevo más tarde.' };

          if (response.status === 0) {
            userErrorMessage.detail = 'Lo sentimos, algo salió mal. El servicio no está disponible, inténtalo de nuevo más tarde.';
          }

          if( response.status == 413){
            userErrorMessage.detail += ' La transferencia de datos sobrepasa el límite permitido';
          }

          if (response.error) {
            // response.error is an object created by angular.
            // response.error.error is an object turned by the API.
            if(response.error.error && response.error.error.message && response.error.error.message.indexOf('Error inesperado.')!=-1){
              userErrorMessage.detail = response.error.error.message;
            }else{
              if (response.error.error && response.error.error.message) {
                userErrorMessage.detail = 'Lo sentimos, algo salió mal.';
                if (response.status !== 500) {
                  if( response.status == 413){
                      userErrorMessage.detail += ' La transferencia de datos sobrepasa el límite permitido';
                  } if ((response.status == 404 || response.status == 401) && (response.error.error.code && (response.error.error.code === 'UNAUTHORIZED' || response.error.error.code === 'LOGIN_FAILED'))) {
                    // "Usuario y/o contraseña incorrectos."
                    userErrorMessage.detail = response.error.error.message;
                  } else {
                      userErrorMessage.detail += ' ' + response.error.error.message;
                  }
                }
                //userErrorMessage.detail = response.error.error.message;
              }

              if (response.error.error && response.error.error.details && Array.isArray(response.error.error.details)) {
                userErrorMessage.detail = 'Lo sentimos, algo salió mal. ' + response.error.error.details.join('; ');
              }

              if (response.status === 401 && (response.error.error.code === 'AUTHORIZATION_REQUIRED' || response.error.error.code === 'INVALID_TOKEN')) {
                if (response.url.indexOf('/ResellerGlobalConfigs/myconfig') > -1) {
                  userErrorMessage.detail = '';
                  this.auth.localLogout();
                } else {
                  return this.cSvc.getMyConfig().pipe(
                    flatMap(data =>{
                      userErrorMessage.detail = 'Lo sentimos, no tiene permisos para esta acción.';
                      return throwError(userErrorMessage);
                    })
                  );
                }
              }
            }
          }
          return throwError(userErrorMessage);
        })
      );
  }
}
