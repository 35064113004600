import {Component, Inject, OnInit} from '@angular/core';
import {ShipmentService} from '../../core/services';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-rate-dialog',
  templateUrl: './rate-dialog.component.html',
  styleUrls: ['./rate-dialog.component.scss']
})

export class RateDialogComponent implements OnInit {
  loading = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private svcShipment: ShipmentService,
              public dialogRef: MatDialogRef<RateDialogComponent>) {}

  ngOnInit(): void {
    this.svcShipment.postRateShipping({token: this.data.token, score: this.data.score}).subscribe((res) => {
      if (res.result) {
        this.loading = false;
      } else {
        this.dialogRef.close();
      }
    }, () => {
      this.dialogRef.close('Ha ocurrido un problema interno. Serás redireccionado automáticamente');
    });
  }
}
