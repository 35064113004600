import { Component, Input, OnInit } from '@angular/core';
import { OnBoardingService } from 'src/app/on-boarding/service/on-boarding.service';
import { environment } from 'src/environments/environment';
import {AuthenticationService, GrowlSvc} from '../../../core/services';
import {Observable} from 'rxjs';
import {LoggedUserState} from '../../../core/models/models';
import { ResellerObsService } from 'src/app/core/services/sharing/reseller-obs.service';
import { ProfileObsService } from 'src/app/core/services/sharing/profile-obs.service';
import { DateTime } from 'luxon';
import { finalize } from 'rxjs/operators';


@Component({
  selector: 'app-metamap-banner',
  templateUrl: './metamap-banner.component.html',
  styleUrls: ['./metamap-banner.component.scss']
})
export class MetamapBannerComponent implements OnInit {
  @Input() typeButton: string = 'true';
  @Input() bannerType: string;
  user$: Observable<LoggedUserState>;

  clientId = environment.metamap.clientId;
  flowId = environment.metamap.flowId;
  color = environment.metamap.color;
  resellerId = null;
  onBoardingInfo = {
    completePercentage: 0,
    profile: false,
    balance: false,
    business: false,
    status: 'VERIFIED',
    isDuplicatePhone : false,
    verificationLimit: DateTime.local(),
    isVIPVerification: false,
    startVerification: false,
  };
  isFullfillment = '0';
  showButton = false;
  showBanner = false;
  showUnverifiedBanner = false;
  showPaymentBanner = false;
  showManualBanner = false;
  daysRemaining = null;
  showPhoneBanner = false;
  showPendingBanner = false;
  showAgainButton = false;
  isDataReadyBanner = false;
  clientEmail: string | undefined;

  constructor(private onBoardingService: OnBoardingService,
              private _authSvc: AuthenticationService,
              private _growlSvc: GrowlSvc,
              private resellerObsService: ResellerObsService,
              private profileObsService: ProfileObsService) {
    this.user$ = _authSvc.user$;
  }

  ngOnInit() {
    const dateCurrent = DateTime.local()
    const accessUser = JSON.parse(localStorage.getItem('accessToken'));
    if (!!localStorage.getItem('fullfillment')) {
      this.isFullfillment = localStorage.getItem('fullfillment');
    } else {
      console.debug('No FF data');
    }
    if (accessUser && accessUser.ResellerGlobalConfig && accessUser.ResellerGlobalConfig.ResellerId) {
      this.resellerId = accessUser.ResellerGlobalConfig.ResellerId;
      this.clientEmail = accessUser.user;
    } else {
      console.error('No reseller data');
    }
    if (this.bannerType && this.bannerType === 'payment') {
      this.showPaymentBanner = true;
    } else {
      this.showPaymentBanner = false;
      const subs = this.resellerObsService.getUserStateObservable.subscribe(async (userState) => {
        if (userState && !userState.isHelpDesk && userState.isReseller) {
          this.profileObsService.getOnboardingObservable.pipe(
            finalize(()=>{
              this.isDataReadyBanner = true;
            })
          ).subscribe((data) => {
            if (data) {
              this.updateOnboardingData(data)
            }
          });
          // TODO: Se dejara comentado temporalmente hasta que se valide un buen funcionamiento en PROD
          // this.onBoardingService.getOnBoardingInfo().subscribe(data => this.updateOnboardingData(data));
        }
      }, err => {
        this._growlSvc.growlError(err);
      });
      subs.unsubscribe();
    }
  }

  updateOnboardingData(data) {
    const dateCurrent = DateTime.local();

    if (data.verificationLimit && !data.isVIPVerification) {
      const verificationLimit: DateTime = DateTime.fromISO(data.verificationLimit);
      this.daysRemaining = Math.ceil(verificationLimit.diff(dateCurrent, 'days').toObject().days);
    }
    this.onBoardingInfo = data;
    console.debug('VM:' + (this.onBoardingInfo && this.onBoardingInfo.status ? this.onBoardingInfo.status : '-') + '|D:' + (data && data.status ? data.status : '-'));
    if (this && this.isFullfillment) { console.debug('F:' + this.isFullfillment + '|' + (typeof this.isFullfillment)); }
    if (this && this.typeButton) { console.debug('B:' + this.typeButton + '|' + (typeof this.typeButton)); }
    this.showButton = (this.isFullfillment === '0' && this.typeButton === 'true' && (!this.onBoardingInfo.status || this.onBoardingInfo.status === 'UNVERIFIED' || this.onBoardingInfo.status === 'REJECTED' || this.daysRemaining));
    this.showUnverifiedBanner = (this.isFullfillment === '0' && this.typeButton === 'false' && (this.onBoardingInfo.status && (this.onBoardingInfo.status !== 'UNVERIFIED' && this.onBoardingInfo.status !== 'VERIFIED' && !this.daysRemaining )));
    this.showBanner = (this.isFullfillment === '0' && this.typeButton === 'false' && (!this.onBoardingInfo.status || this.onBoardingInfo.status === 'UNVERIFIED') && !this.daysRemaining);
    this.showPhoneBanner = (this.isFullfillment === '0' && this.typeButton === 'false' && this.onBoardingInfo.isDuplicatePhone && !this.daysRemaining);
    this.showPendingBanner = (this.isFullfillment === '0' && this.typeButton === 'false' && this.onBoardingInfo.status === 'UNVERIFIED' && this.onBoardingInfo.startVerification && !this.daysRemaining);
    this.showAgainButton = this.onBoardingInfo.startVerification;
    // this.showBanner = !(this.isFullfillment == '0' && this.typeButton === 'false' && (!this.onBoardingInfo.status || this.onBoardingInfo.status !== 'VERIFIED'))
     console.debug('VM:' + (this.onBoardingInfo && this.onBoardingInfo.status ? this.onBoardingInfo.status : '-') + '|bt:' + this.showButton + '|bn:' + this.showBanner + '|ubn:' + this.showUnverifiedBanner);
  }
}
