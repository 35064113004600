import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'courierName'
})
export class CourierNamePipe implements PipeTransform {

    transform(courierCode: any): string {

        switch (courierCode) {
            case 'STF': case 'STFRT':
                return 'Estafeta';
            case 'FDX':
                return 'FedEx';
            case 'MBE_FDX':
                return 'FedEx';
            case 'DHL':
            case 'DHL_PAKKE':
                return 'DHL';
            case 'MBE_DHL':
                return 'DHL';
            case '99M':
            case '99M_PAKKE':
                return '99 Minutos';
            case '472':
                return '472';
            case 'AEF':
                return 'Aeroflash';
            case 'SDX_MEX':
                return 'Sendex';
            default: return '';
        }
    }
}
