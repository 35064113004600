import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ShipmentService } from 'src/app/core/services';
import { ShipmentObsService } from 'src/app/core/services/sharing/shipment-obs.service';
import { FormatHelperService } from 'src/app/core/services/util/format-helper.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-result-massive-guides',
  templateUrl: './result-massive-guides.component.html',
  styleUrls: ['./result-massive-guides.component.scss'],
})
export class ResultMassiveGuidesComponent implements OnInit {
  @Output() modalConfirm = new EventEmitter<any>();

  shipmentsResponse = [];
  ordersWithShipment: boolean = true;
  listWithShipment = [];
  listWithoutShipment = [];
  currency = environment.currency;
  currencySymbol = environment.currency;
  languageCode = environment.languageCode;
  loadingMassivePackList = false;

  constructor(
    private formatHelp: FormatHelperService,
    public shipmentObsService: ShipmentObsService,
    private shipmentService: ShipmentService,
  ) {}

  ngOnInit() {
    this.shipmentObsService.getShipmentsObservable.subscribe((data) => {
      this.shipmentsResponse = data;
      if (Array.isArray(this.shipmentsResponse) && this.shipmentsResponse.length > 0) {
        this.listWithShipment = this.formatResponseData(this.shipmentsResponse.filter(x => x.created == true));
        this.listWithoutShipment = this.formatResponseData(this.shipmentsResponse.filter(x => x.created == false));

        if (Array.isArray(this.listWithShipment) && this.listWithShipment.length === 0) {
          this.ordersWithShipment = false;
        }
      }
    });
  }

  selectWithGuide() {
    if (this.listWithShipment.length > 0) {
      this.ordersWithShipment = true;
    }
  }

  selectWithoutGuide() {
    if (this.listWithoutShipment.length > 0) {
      this.ordersWithShipment = false;
    }
  }

  onCloseModal(event) {
    this.modalConfirm.emit(true);
  }

  formatCurrency(value){
    return this.formatHelp.formatCurrency(this.languageCode, this.currencySymbol, value);
  }

  formatResponseData(shipments) {
    if (!Array.isArray(shipments) || shipments.length <= 0) {
      return [];
    }


    return shipments.map(item => {
      let shipment: any = {};
      if (item.shipment) {
        shipment = item.shipment;
      }
      let newFormat = {
        created: false,
        OrderId: item.OrderId ? item.OrderId : '',
        folio: item.Folio ? item.Folio : '',
        storeName: item.StoreName ? item.StoreName : '',
        courierCode: item.Courier.CourierCode ? item.Courier.CourierCode : '',
        courierName: item.Courier.CourierName ? item.Courier.CourierName : '',
        typeService: item.Courier.typeService ? item.Courier.typeService : '',
        courierServiceName: item.Courier.CourierServiceName ? item.Courier.CourierServiceName : '',
        estimatedDeliveryDate: item.Courier.EstimatedDeliveryDate ? item.Courier.EstimatedDeliveryDate : '',
        totalPrice: item.Courier.TotalPrice ? item.Courier.TotalPrice : 0,
        shipmentId: shipment.ShipmentId ? shipment.ShipmentId : '',
        trackingNumber: shipment.TrackingNumber ? shipment.TrackingNumber : '',
        coveredWeight: shipment.CoveredWeight ? shipment.CoveredWeight : 0,
        overWeightPrice: shipment.OverWeightPrice ? shipment.OverWeightPrice : 'No disponible',
        Shipment: shipment,
        error: item.error ? item.error.message : '',
      };
      return newFormat;
    });
  }

  downloadMassivePackList() {
    this.loadingMassivePackList = true;
    this.shipmentService.downloadMassivePackList(this.listWithShipment).subscribe((resp) => {
      this.loadingMassivePackList = false;
    }, (error) => {
      this.loadingMassivePackList = false;
    });
  }

}
