import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentType'
})
export class PaymentTypePipe implements PipeTransform {
  constructor() { }

  transform(type: any): string {
    switch (type) {
      case 'bank_account': return 'Banco';
      case 'store': return 'Tienda';
      case 'card': return 'Tarjeta';
      default: return type;
    }
  }
}
