import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'phoneDialFormat'
})
export class PhoneDialFormatPipe implements PipeTransform {

  dialCode = '';

  constructor(private translate: TranslateService) {
    this.translate.get(['contact.dialCode'
    ])
      .subscribe(translations => {
        this.dialCode = translations['contact.dialCode'];
      });
  }

  transform(value, courierCode = ''): string {

    let dict;
    let phone = '';

    // validar valor
    if (!value || value.length < 1) {
      return null;
    }

    let newData = value.split(' ').join('');

    // if (isNaN(Number(newData))) { return newData; }

    // //Formatos (concatenar al iterar)
    // if(courierCode === "UPS_INT") {
    //     this.dialCode = "+1";
    // }
    // newData = this.dialCode + newData;

    if (!newData.startsWith('+')) {
      if (isNaN(Number(newData))) {
        return newData;
      }
      // Formatos (concatenar al iterar)
      if (courierCode === 'UPS_INT') {
        this.dialCode = '+1';
      }

      newData = this.dialCode + newData;
    } else {
      // nothign to do, number has dialcode already
    }

    // TODO: change this frangment to a better approach
    // see https://github.com/ruimarinho/google-libphonenumber
    if (newData.length > 3 && courierCode !== 'UPS_INT') {
      dict = {0: '(', 3: ') '};
    } else {
      dict = {0: '(', 2: ') '};
    }

    // Iterar y construir cadena
    for (let i = 0; i < newData.length; i++) {
      phone += (dict[i] || '') + newData[i];
    }

    return phone.trim();
  }
}
