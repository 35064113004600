import { Pipe, PipeTransform } from '@angular/core';
 
@Pipe({
    name: 'paymentStatus'
})
export class PaymentStatusPipe implements PipeTransform {
    constructor() { }

    transform(status: any): string {
        switch (status) {
            case 'completed': return 'Pagado';
            case 'in_progress': return 'Pendiente'; 
            case 'in_progres': return 'Pendiente'; 
            case 'canceled': return 'Cancelado'; 
            case 'failed': return 'Fallo'; 
            case 'refunded': return 'Cancelado'; 
            default: return status;
          }
    };
} 