import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import {HttpClient, HttpParams} from '@angular/common/http';

declare var etomin;

@Injectable()
export class EtominService {

  private _deviceSessionId: string;
  private _banks : any[] = [
    // { "code": "0", "description": "A continuación seleccione su banco" },
    { "code": "1040", "description": "BANCO AGRARIO" },
    { "code": "1052", "description": "BANCO AV VILLAS" },
    { "code": "1013", "description": "BANCO BBVA COLOMBIA S.A." },
    { "code": "1032", "description": "BANCO CAJA SOCIAL" },
    { "code": "1066", "description": "BANCO COOPERATIVO COOPCENTRAL" },
    { "code": "1051", "description": "BANCO DAVIVIENDA" },
    { "code": "1001", "description": "BANCO DE BOGOTA" },
    { "code": "1023", "description": "BANCO DE OCCIDENTE" },
    { "code": "1062", "description": "BANCO FALABELLA " },
    { "code": "1012", "description": "BANCO GNB SUDAMERIS" },
    { "code": "1006", "description": "BANCO ITAU" },
    { "code": "1060", "description": "BANCO PICHINCHA S.A." },
    { "code": "1002", "description": "BANCO POPULAR" },
    { "code": "1058", "description": "BANCO PROCREDIT" },
    { "code": "1065", "description": "BANCO SANTANDER COLOMBIA" },
    { "code": "1069", "description": "BANCO SERFINANZA" },
    { "code": "1007", "description": "BANCOLOMBIA" },
    { "code": "1061", "description": "BANCOOMEVA S.A." },
    { "code": "1283", "description": "CFA COOPERATIVA FINANCIERA" },
    { "code": "1009", "description": "CITIBANK " },
    { "code": "1292", "description": "CONFIAR COOPERATIVA FINANCIERA" },
    { "code": "1551", "description": "DAVIPLATA"},
    { "code": "1507", "description": "NEQUI" },
    { "code": "1019", "description": "SCOTIABANK COLPATRIA" }
  ];

  private _documents : any[] = [
    { "code": "CC", "description": "Cédula de ciudadanía." },
    { "code": "CE", "description": "Cédula de extranjería." },
    { "code": "NIT", "description": "Número de Identificación Tributario." },
    { "code": "TI", "description": "Tarjeta de Identidad." },
    { "code": "PP", "description": "Pasaporte." },
    { "code": "IDC", "description": "Identificador único de cliente, para el caso de ID’s únicos de clientes / usuarios de servicios públicos." },
    { "code": "CEL", "description": "En caso de identificarse a través de la línea del móvil." },
    { "code": "RC", "description": "Registro civil de nacimiento." },
    { "code": "DE", "description": "Documento de identificación extranjero." }
  ];

  private _typeClients : any[] = [
    { "code": "N", "description": "Persona natural" },
    { "code": "J", "description": "Persona jurídica" }
  ];

  get deviceSessionId(): string {
    return this._deviceSessionId;
  }

  constructor(
    private apiService: ApiService,
    private http: HttpClient,
  ) {
  }

  getSession(): Promise<any> {
    
    return new Promise<any>((resolve, reject) => {
      return etomin.autentication(function (err, session) {
        if (err) {
          console.log('err', err);
          reject();
        };
        resolve(session);
      });
    });

  }


  getEtominBanks(): any[] {
    return this._banks;
  }

  getEtominDocument(): any[] {
    return this._documents;
  }

  getEtominTypeClient(): any[] {
    return this._typeClients;
  }
  
}
