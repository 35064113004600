import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSerializer } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent, GradientLayoutComponent, PublicLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import {
  AuthAdminGuard,
  AuthHelpdeskGuard,
  AuthResellerGuard,
  AuthFullfillmentGuard,
  LoggedGuard,
  ResellerGlobalConfigGuard
} from './core/guards';
import { LowerCaseUrlSerializer } from './core/services/lower-case-url-serializer.service';
import { HomeComponent } from './shared/components/home.component';
import {RateComponent} from './views/general/rate.component';
import { GradientRegisterLayoutComponent } from './containers/gradient-register-layout/gradient-register-layout.component';
import {SpainGuard} from './core/guards/spain.guard';
import {EnableModuleGuard} from './core/guards/enable-module.guard';
import { PreAuthGuard } from './core/guards/pre-auth-guard.service';
import { AsynchronousGuardProcessor } from './core/guards/asynchronous-guard-processor.service';


export const routes: Routes = [
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login/:token',
    component: HomeComponent,
    canActivate: [AsynchronousGuardProcessor],
    data: {syncGuards: [
        PreAuthGuard,
        AuthResellerGuard
      ]},
  },
  {
    path: 'login/:token/:uid',
    component: HomeComponent,
    canActivate: [AsynchronousGuardProcessor],
    data: {syncGuards: [
        PreAuthGuard,
        AuthResellerGuard
      ]},
  },
  {
    path: 'shipment/:token/rate/:score',
    component: RateComponent
  },
  {
    path: '',
    component: GradientLayoutComponent,
    children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path: '',
        loadChildren: './views/login/login.module#LoginModule'
      },
      {
        path: '',
        loadChildren: './views/email-confirm/email-confirm.module#EmailConfirmModule'
      },
      {
        path: 'reset-password',
        loadChildren: './views/password-reset/password-reset.module#PasswordResetModule'
      },
      {
        path: 'fulfillment/reset-password',
        loadChildren: './views/password-reset/password-reset.module#PasswordResetModule'
      }
    ]
  },
  {
    path: '',
    component: GradientRegisterLayoutComponent,
    children: [
      {
        path: 'registro',
        loadChildren: './views/register/register.module#RegisterModule',
        canActivate: [LoggedGuard]
      },{
        path: 'fulfillment/registro',
        loadChildren: './views/register/register.module#RegisterModule',
        canActivate: [LoggedGuard]
      }
    ]
  },
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      // {
      //   path: 'aviso-de-privacidad',
      //   loadChildren: './views/privacy-policy/privacy-policy.module#PrivacyPolicyModule'
      // },
      // {
      //   path: 'terminos-y-condiciones',
      //   loadChildren: './views/terms-of-service/terms-of-service.module#TermsOfServiceModule'
      // },
      {
        path: 'rastreo',
        loadChildren: './views/tracking/tracking.module#TrackingModule'
      }
    ]
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: 'admin/configuracion',
        loadChildren: './views-admin/configuration/configuration.module#ConfigurationModule',
        canActivate: [AuthAdminGuard]
      },
      {
        path: 'helpdesk/resellers',
        loadChildren: './views-helpdesk/resellers/resellers.module#ResellersModule',
        canActivate: [AuthHelpdeskGuard]
      },
      {
        path: 'helpdesk/guias2',
        loadChildren: './views-helpdesk/shipments2/shipments2-helpdesk.module#Shipment2HelpdeskModule',
        canActivate: [AuthHelpdeskGuard]
      },
      {
        path: 'helpdesk/guias',
        loadChildren: './views-helpdesk/shipments/shipments-helpdesk.module#ShipmentsHelpdeskModule',
        canActivate: [AuthHelpdeskGuard]
      },
      {
        path: 'helpdesk/reporte',
        loadChildren: './views-helpdesk/reporte/reporte-helpdesk.module#ReporteHelpdeskModule',
        canActivate: [AuthHelpdeskGuard]
      },
      {
        path: 'helpdesk',
        //loadChildren: './views-helpdesk/statistics/statistics-helpdesk.module#StatisticsHelpdeskModule',
        loadChildren: './views-helpdesk/shipments2/shipments2-helpdesk.module#Shipment2HelpdeskModule',
        canActivate: [AuthHelpdeskGuard]
      },
      {
        path: 'helpdesk/reporte/transaccion',
        loadChildren: './views-helpdesk/reporte/reporte-helpdesk.module#ReporteHelpdeskModule',
        canActivate: [AuthHelpdeskGuard]
      },
      {
        path: 'helpdesk/domiciliacion',
        loadChildren:
          './views-helpdesk/domiciliacion/domiciliacion.module#DomiciliacionModule',
        canActivate: [AuthHelpdeskGuard],
      },
      {
        path: 'helpdesk/reporte/bonus',
        loadChildren: './views-helpdesk/reporte/reporte-helpdesk.module#ReporteHelpdeskModule',
        canActivate: [AuthHelpdeskGuard]
      },
      {
        path: 'dashboard',
        loadChildren: './views-reseller/dashboard-advance/dashboard-advance.module#DashboardAdvanceModule',
        canActivate: [AuthResellerGuard, ResellerGlobalConfigGuard]
      },
      {
        path: 'perfil',
        loadChildren: './views-reseller/profile/profile.module#ProfileModule',
        canActivate: [AuthResellerGuard, ResellerGlobalConfigGuard]
      },
      {
        path: 'configuracion',
        loadChildren: './views-reseller/setting/setting.module#SettingModule',
        canActivate: [AuthResellerGuard, ResellerGlobalConfigGuard]
      },
      {
        path: 'notificacion',
        loadChildren: './views-reseller/notification-setting/notification-setting.module#NotificationSettingModule',
        canActivate: [AuthResellerGuard, ResellerGlobalConfigGuard]
      },
      {
        path: 'courier/preferences',
        loadChildren: './views-reseller/courier-preferences/courier-preferences.module#CourierPreferencesModule',
        canActivate: [AuthResellerGuard, ResellerGlobalConfigGuard]
      },
      {
        path: 'guias',
        loadChildren: './views-reseller/shipments/shipments.module#ShipmentsModule',
        canActivate: [AuthResellerGuard, ResellerGlobalConfigGuard]
      },
      {
        path: 'devoluciones',
        loadChildren: './views-reseller/shipment-return/shipment-return.module#ShipmentReturnModule',
        canActivate: [AuthResellerGuard, ResellerGlobalConfigGuard]
      },
      {
        path: 'pickup',
        loadChildren: './views-reseller/pickup/pickup.module#PickupModule',
        canActivate: [AuthResellerGuard, ResellerGlobalConfigGuard]
      },
      {
        path: 'direcciones',
        loadChildren: './views-reseller/address/address.module#AddressModule',
        canActivate: [AuthResellerGuard, ResellerGlobalConfigGuard]
      },
      {
        path: 'estado-de-cuenta',
        loadChildren: './views-reseller/account-statement/account-statement.module#AccountStatementModule',
        canActivate: [AuthResellerGuard, ResellerGlobalConfigGuard]
      },
      {
        path: 'saldo-y-pagos',
        loadChildren: './views-reseller/account-deposits/account-deposits.module#AccountDepositsModule',
        canActivate: [AuthResellerGuard, ResellerGlobalConfigGuard]
      },
      {
        path: 'saldo-y-pagos/failure',
        loadChildren: './views-reseller/account-deposits/account-deposits.module#AccountDepositsModule',
        canActivate: [AuthResellerGuard, ResellerGlobalConfigGuard]
      },
      {
        path: 'saldo-y-pagos/success',
        loadChildren: './views-reseller/account-deposits/account-deposits.module#AccountDepositsModule',
        canActivate: [AuthResellerGuard, ResellerGlobalConfigGuard]
      },
      {
        path: 'ordenes',
        loadChildren: './views-reseller/orders/orders.module#OrdersModule',
        canActivate: [AuthResellerGuard, ResellerGlobalConfigGuard]
      },
      {
        path: 'lotes',
        loadChildren: './views-reseller/batch/batch.module#BatchModule',
        canActivate: [AuthResellerGuard, ResellerGlobalConfigGuard]
      },
      {
        path: 'tiendas',
        loadChildren: './views-reseller/store/store.module#StoreModule',
        canActivate: [AuthResellerGuard, ResellerGlobalConfigGuard],
      },
      {
        path: 'paquetes',
        loadChildren: './views-reseller/parcel/parcel.module#ParcelModule',
        canActivate: [AuthResellerGuard, ResellerGlobalConfigGuard]
      },
      {
        path: 'equipo',
        loadChildren: './views-reseller/team/team.module#TeamModule',

        canActivate: [AuthResellerGuard, ResellerGlobalConfigGuard]
      },
      {
        path: 'facturacion',
        loadChildren: './views-reseller/bill/bill.module#BillModule',
        canActivate: [AuthResellerGuard, ResellerGlobalConfigGuard]
      },
      {
        path: 'fulfillment/dashboard',
        loadChildren: './views-reseller/dashboard-advance/dashboard-advance.module#DashboardAdvanceModule',
        canActivate: [AuthFullfillmentGuard, ResellerGlobalConfigGuard]
      },
      {
        path: 'fulfillment/ordenes',
        loadChildren: './views-fullfillment/orders/orders.module#OrdersFullfillmentModule',
        canActivate: [AuthFullfillmentGuard, ResellerGlobalConfigGuard]
      },
      {
        path: 'fulfillment/tiendas',
        loadChildren: './views-reseller/store/store.module#StoreModule',
        canActivate: [AuthFullfillmentGuard, ResellerGlobalConfigGuard]
      },
      {
        path: 'fulfillment/solicitud-de-producto',
        loadChildren: './views-fullfillment/purchaseOrder-detail/purchaseOrder-detail.module#PurchaseOrderFullfillmentModule',
        canActivate: [AuthFullfillmentGuard, ResellerGlobalConfigGuard]
      },

      {
        path: 'fulfillment/solicitudes',
        loadChildren: './views-fullfillment/purchaseOrder-detail/bulk-purchases/bulk-purchases.module#BulkPurchasesModule',
        canActivate: [AuthFullfillmentGuard, ResellerGlobalConfigGuard]
      },
      {
        path: 'fulfillment/inventario',
        loadChildren: './views-fullfillment/stocktaking/stocktaking.module#StocktakingModule',
        canActivate: [AuthFullfillmentGuard, ResellerGlobalConfigGuard]
      },
      {
        path: 'fulfillment/notificacion',
        loadChildren: './views-reseller/notification-setting/notification-setting.module#NotificationSettingModule',
        canActivate: [AuthFullfillmentGuard, ResellerGlobalConfigGuard]
      },
      {
        path: 'fulfillment/perfil',
        loadChildren: './views-reseller/profile/profile.module#ProfileModule',
        canActivate: [AuthFullfillmentGuard, ResellerGlobalConfigGuard]
      },
      {
        path: 'fulfillment/proveedores',
        loadChildren: './views-fullfillment/provider/provider.module#ProviderModule',
        canActivate: [AuthFullfillmentGuard, ResellerGlobalConfigGuard]
      },
      {
        path: 'fulfillment/categorias',
        loadChildren: './views-fullfillment/category/category.module#CategoryModule',
        canActivate: [AuthFullfillmentGuard, ResellerGlobalConfigGuard]
      },
      {
        path: 'fulfillment/etiquetas',
        loadChildren: './views-fullfillment/tag/tag.module#TagModule',
        canActivate: [AuthFullfillmentGuard, ResellerGlobalConfigGuard]
      },
      {
        path: 'fulfillment/saldo-y-pagos',
        loadChildren: './views-reseller/account-deposits/account-deposits.module#AccountDepositsModule',
        data: {module: 'modules.balancesAndPayments'},
        canActivate: [AuthFullfillmentGuard, ResellerGlobalConfigGuard, EnableModuleGuard]
      },
      {
        path: 'fulfillment/estado-de-cuenta',
        loadChildren: './views-fullfillment/account-statement/account-statement-ff.module#AccountStatementFFModule',
        data: {module: 'modules.movements'},
        canActivate: [AuthFullfillmentGuard, ResellerGlobalConfigGuard, EnableModuleGuard]
      },
      {
        path: 'fulfillment/guias',
        loadChildren: './views-reseller/shipments/shipments.module#ShipmentsModule',
        canActivate: [AuthFullfillmentGuard, ResellerGlobalConfigGuard]
      },
      {
        path: 'fulfillment/facturacion',
        loadChildren: './views-reseller/bill/bill.module#BillModule',
        data: {module: 'modules.bill'},
        canActivate: [AuthFullfillmentGuard, ResellerGlobalConfigGuard, EnableModuleGuard]
      },
      {
        path: 'fulfillment/devoluciones',
        loadChildren: './views-fullfillment/orders-return/orders-return.module#OrdersRetunFullfillmentModule',
        canActivate: [AuthFullfillmentGuard, ResellerGlobalConfigGuard]
     },
      {
        path: 'fulfillment/tarifario',
        loadChildren: './views-fullfillment/rate-information/rate-information.module#RateInformationModule',
        data: {module: 'modules.tariff'},
        canActivate: [AuthFullfillmentGuard, ResellerGlobalConfigGuard, EnableModuleGuard]
      },
      {
        path: 'articulos',
        loadChildren: './views-reseller/item/item.module#ItemModule',
        canActivate: [AuthResellerGuard, ResellerGlobalConfigGuard]
      },
    ]
  },
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
    }
  ]
})
export class AppRoutingModule { }
