import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Router, NavigationEnd} from '@angular/router';
import { AuthenticationService } from './core/services/authentication.service';
import { Subscription } from 'rxjs';
import { SesionModalComponent } from './shared/components/modals/sesion/sesion-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { LayoutService } from './containers/default-layout/service/layout.service';
import {Title, Meta} from '@angular/platform-browser';
import {NgxFaviconService} from 'ngx-favicon';
import {CustomFavicon} from './favicon.config';
import {CourierSvc} from './core/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild(SesionModalComponent, { static : true } ) sesionModalComponent: SesionModalComponent;

  opts = environment;

  private subscription: Subscription;
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private translate: TranslateService,
    private title: Title,
    private meta: Meta,
    private layoutService: LayoutService,
    private ngxFaviconService: NgxFaviconService<CustomFavicon>
    ) {

      // let defaultLang = environment.countryCode === 'MX' ? 'mx' :
      //                   environment.countryCode === 'ES' ? 'es' : 'co';
      // this.translate.setDefaultLang(`es-${ defaultLang }`);
      // this.listenIfIsFullfillment();
   }

  ngOnInit() {
    // let defaultLang = environment.countryCode === 'MX' ? 'mx' :
    //                   environment.countryCode === 'ES' ? 'es' : 'co';
    // this.translate.setDefaultLang(`es-${ defaultLang }`);
    this.translate.setDefaultLang(this.opts.languageCode.toLowerCase());
    document.getElementById('wabutton').setAttribute('href', this.opts.branding.whatsappUrl);
    document.getElementById('tbutton').setAttribute('href', this.opts.branding.telegramUrl);

    //Se ocultan para ES y MX
    document.getElementById('tbutton').style.visibility = 'hidden';


    this.ngxFaviconService.setFaviconByUrl(`/${this.opts.branding.favicon}`);
    this.title.setTitle(this.opts.branding.pageTitle);
    this.meta.updateTag({ name: 'description', content: this.opts.branding.pageDescription});

    this.initGoogleTagManager();

    //Se oculta para ES y MX
    //this.initPakkeBot();


    // let defaultLang = environment.countryCode === 'MX' ? 'mx' :
    //                   environment.countryCode === 'ES' ? 'es' : 'co';
    // this.translate.setDefaultLang(`es-${ defaultLang }`);

    // Estar a la escucha de un cambio en la caducidad de la sesión
    this.subscription =  this.authService.main$.subscribe(isSesionActive => {
      if (!isSesionActive) {
        this.sesionModalComponent.show();
      }
    });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  private initGoogleTagManager() {
    const trackingID = environment.googleTagManagerId ? environment.googleTagManagerId : 'G-S67C4XNCZK';
    const gtmScriptElement = document.getElementById('GtmScript');
    gtmScriptElement.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${ trackingID }');`;

    const gtmNoScriptElement = document.getElementById('GtmNoScript');
    gtmNoScriptElement.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${ trackingID }" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

    if (environment.countryCode === 'MX') {
      const trackingUser = document.getElementById('trackingUser');
      trackingUser.innerText = `adroll_adv_id = "SCSVKNBEMBEEXF46AYXAHI"; adroll_pix_id = "3QBQJHCG3NHYXGOI4AFCTU"; adroll_version = "2.0"; (function(w, d, e, o, a) { w.__adroll_loaded = true; w.adroll = w.adroll || []; w.adroll.f = [ 'setProperties', 'identify', 'track' ]; var roundtripUrl = "https://s.adroll.com/j/" + adroll_adv_id + "/roundtrip.js"; for (a = 0; a < w.adroll.f.length; a++) { w.adroll[w.adroll.f[a]] = w.adroll[w.adroll.f[a]] || (function(n) { return function() { w.adroll.push([ n, arguments ]) } })(w.adroll.f[a]) } e = d.createElement('script'); o = d.getElementsByTagName('script')[0]; e.async = 1; e.src = roundtripUrl; o.parentNode.insertBefore(e, o); })(window, document);  adroll.track("pageView");`;

      const noScriptTrackingUser = document.getElementById('noScriptTrackingUser');
      noScriptTrackingUser.innerHTML = `<script type="text/javascript"></script>`;
    } else if (environment.countryCode === 'CO') {
      const gaScript = document.createElement('script');
      gaScript.setAttribute('async', 'true');
      gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${ trackingID }`);

      const gaScript2 = document.createElement('script');
      gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${ trackingID }\');`;

      document.head.appendChild(gaScript);
      document.head.appendChild(gaScript2);
    } else if (environment.countryCode === 'ES') {
      const trackingID2 = 'UA-216061186-1';
      const gtmScriptElement2 = document.getElementById('GtmScript2');
      gtmScriptElement2.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${ trackingID2 }');`;

      const gtmNoScriptElement2 = document.getElementById('GtmNoScript2');
      gtmNoScriptElement2.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${ trackingID2 }" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    }
  }

  // private listenIfIsFullfillment() {
  //   this.layoutService.isFullfillmentObservable.subscribe((isFullfillmentResponse: boolean) => {
  //     if (isFullfillmentResponse) {
  //       if (environment.fulfillmentProduct) { this.setPageConfigurationFromTranslateCode(`site.${environment.fulfillmentProduct}`); }
  //     } else {
  //       this.title.setTitle(environment.branding.pageTitle);
  //       this.meta.updateTag({ name:'description', content: environment.branding.pageDescription});
  //       this.ngxFaviconService.setDefaultFavicon();
  //     }
  //   });
  // }

  // private setPageConfigurationFromTranslateCode(code: string) {
  //   this.translate.get(code).subscribe((siteResponse: {name: string, icon: string}) => {
  //     if (siteResponse && typeof siteResponse === 'object') {
  //       const {name, icon} = siteResponse;
  //       if (name) { this.title.setTitle(siteResponse.name); }
  //       if (icon) { this.ngxFaviconService.setFaviconByUrl(`/${siteResponse.icon}`); }
  //     }
  //   });
  // }

  private initPakkeBot() {
    const botElement = document.getElementById('pakkeBotNo');
    const botScript = document.getElementById('pakkeBot');
    const wa = document.getElementById('wabutton');
    if (environment.countryCode === 'ES') {
      wa.setAttribute('href', 'https://api.whatsapp.com/send?phone=34900838759');
      botElement.innerHTML = '<script type="application/javascript" charset="UTF-8" src="https://cdn.agentbot.net/core/d54bb9a37348c544d3e519d213ecb4c0.js"></script>';
      botScript.innerText = '!function(b){var g="$aivo",h=!!window.$aivo&&window.$aivo;b[g]=b[g]||{next:null,init:function(j){var m,p=document.getElementsByTagName("head")[0],q=document.createElement("script"),u="_agentCoreScript",w=window;q.onload=q.onerror=q.onreadystatechange=function(){if(!(q.readyState&&!/^c|loade/.test(q.readyState)||m)){if(q.onload=q.onreadystatechange=null,m=1,h=w[g],h)w[g]=h;else try{delete w[g]}catch(x){w[g]=void 0}b[g]=h,setTimeout(function(){$aivo.run(j,$aivo.__next)},1)}},document.getElementById(u)&&document.getElementById(u).parentNode.removeChild(document.getElementById(u)),q.async=1,q.id=u,q.src="https://cdn.agentbot.net/core/latest/core.js?djMuNi42",q.charset="UTF-8",p.parentNode.insertBefore(q,p)},ready:function(j){$aivo.__next=j}}}(this);$aivo.init("d54bb9a37348c544d3e519d213ecb4c0");';
    } else {
      botElement.innerHTML = '<script id="pakkeBot" type="application/javascript" charset="UTF-8" src="https://cdn.agentbot.net/core/c2c5bd744f4e9410c3033cad9f711e59.js"></script>';
      botScript.innerText = '!function(b){var g="$aivo",h=!!window.$aivo&&window.$aivo;b[g]=b[g]||{next:null,init:function(j){var m,p=document.getElementsByTagName("head")[0],q=document.createElement("script"),u="_agentCoreScript",w=window;q.onload=q.onerror=q.onreadystatechange=function(){if(!(q.readyState&&!/^c|loade/.test(q.readyState)||m)){if(q.onload=q.onreadystatechange=null,m=1,h=w[g],h)w[g]=h;else try{delete w[g]}catch(x){w[g]=void 0}b[g]=h,setTimeout(function(){$aivo.run(j,$aivo.__next)},1)}},document.getElementById(u)&&document.getElementById(u).parentNode.removeChild(document.getElementById(u)),q.async=1,q.id=u,q.src="https://cdn.agentbot.net/core/latest/core.js?djMuNi42",q.charset="UTF-8",p.parentNode.insertBefore(q,p)},ready:function(j){$aivo.__next=j}}}(this);$aivo.init("c2c5bd744f4e9410c3033cad9f711e59");';
    }
  }
  // private setPageConfigurationFromTranslateCode(code: string) {
  //   this.translate.get(code).subscribe((siteResponse: {name: string, icon: string}) => {
  //     if (siteResponse && typeof siteResponse === 'object') {
  //       const {name, icon} = siteResponse;
  //       if (name) { this.title.setTitle(siteResponse.name); }
  //       if (icon) { this.ngxFaviconService.setFaviconByUrl(`/${siteResponse.icon}`); }
  //     }
  //   });
  // }
}
