import { Validators } from "@angular/forms";
import { CustomValidators } from "src/app/shared/validators/custom-validators";
import { environment } from "src/environments/environment";

export const CONFIG_FORM = {
    specialCharRegEx: '^[a-zá-źA-ZÁ-Ź0-9 \/\,\#\._-]+$',
    lengthPhone: environment.lengthPhone,
};


export class AddressConfig {


    static GENERIC_ADDRESS_FORM = {
        'MX': {
            ContactName: ['', Validators.compose([Validators.required, Validators.maxLength(30), Validators.pattern( CONFIG_FORM.specialCharRegEx)])],
            CompanyName: ['', Validators.compose([Validators.pattern(CONFIG_FORM.specialCharRegEx)])],
            Email: ['', Validators.compose([CustomValidators.isValidEmail])],
            Phone1INT: [''],
            Phone1: ['', Validators.required],
            Phone2: [''],
            SendRecipientNotifications: '',
            State: ['', Validators.required],
            StateName: [''],
            City: ['', Validators.required],
            Neighborhood: ['', Validators.required],
            Address1: [
                '',
                Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(30),
                Validators.pattern(CONFIG_FORM.specialCharRegEx)
                ])
            ],
            Address2: ['', Validators.compose([Validators.maxLength(30), Validators.pattern(CONFIG_FORM.specialCharRegEx)])],
            CityAndState: [''],
            Dane: [null],
            DaneShort: [null],
            ZipCode: [null, Validators.compose([Validators.required])],
            citySelection: null,
            isOriginAddress: false,
            isDestinationAddress: false,
            UserState: [''],
            Country: [''],
            International: false,
        },
        'CO': {
            ContactName: ['', Validators.compose([Validators.required, Validators.maxLength(30), Validators.pattern( CONFIG_FORM.specialCharRegEx)])],
            CompanyName: ['', Validators.compose([Validators.pattern(CONFIG_FORM.specialCharRegEx)])],
            Email: ['', Validators.email],
            Phone1INT: [''],
            Phone1: ['', Validators.compose([Validators.required, Validators.minLength(CONFIG_FORM.lengthPhone)])],
            Phone2: [''],
            SendRecipientNotifications: '',
            State: ['', Validators.required],
            StateName: [''],
            City: ['', Validators.required],
            Neighborhood: ['', Validators.required],
            Address1: [
                '',
                Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(30),
                Validators.pattern(CONFIG_FORM.specialCharRegEx)
                ])
            ],
            Address2: ['', Validators.compose([Validators.maxLength(30), Validators.pattern(CONFIG_FORM.specialCharRegEx)])],
            CityAndState: [''],
            Dane: [null],
            DaneShort: [null],
            ZipCode: [null, Validators.compose([Validators.required, CustomValidators.zipCodeCo])],
            citySelection: [null, Validators.compose([Validators.required])],
            isOriginAddress: false,
            isDestinationAddress: false,
            UserState: [''],
            Country: [''],
            International: false,
        },
        'ES': {
            ContactName: ['', Validators.compose([Validators.required, Validators.maxLength(45), Validators.pattern( CONFIG_FORM.specialCharRegEx)])],
            CompanyName: ['', Validators.compose([Validators.pattern(CONFIG_FORM.specialCharRegEx)])],
            Email: ['', Validators.email],
            Phone1INT: ['', Validators.required],
            Phone1: [''],
            Phone2: [''],
            SendRecipientNotifications: '',
            State: ['', Validators.required],
            StateName: [''],
            City: ['', Validators.required],
            Neighborhood: ['', Validators.required],
            Address1: [
                '',
                Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(45),
                Validators.pattern(CONFIG_FORM.specialCharRegEx)
                ])
            ],
            Address2: ['', Validators.compose([Validators.maxLength(35), Validators.pattern(CONFIG_FORM.specialCharRegEx)])],
            CityAndState: [''],
            Dane: [null],
            DaneShort: [null],
            ZipCode: [null, Validators.compose([Validators.required])],
            citySelection: null,
            isOriginAddress: false,
            isDestinationAddress: false,
            UserState: [''],
            Country: [''],
            International: true,
        },
        'FF': {
            ContactName: ['', Validators.compose([Validators.required, Validators.maxLength(30), Validators.pattern( CONFIG_FORM.specialCharRegEx)])],
            CompanyName: ['', Validators.compose([Validators.pattern(CONFIG_FORM.specialCharRegEx)])],
            Email: ['', Validators.compose([CustomValidators.isValidEmail])],
            Phone1INT: ['', Validators.required],
            Phone1: [''],
            Phone2: [''],
            SendRecipientNotifications: '',
            State: ['', Validators.required],
            StateName: [''],
            City: ['', Validators.required],
            Neighborhood: ['', Validators.required],
            Address1: [
                '',
                Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(100),
                Validators.pattern(CONFIG_FORM.specialCharRegEx)
                ])
            ],
            Address2: ['', Validators.compose([Validators.maxLength(30), Validators.pattern(CONFIG_FORM.specialCharRegEx)])],
            CityAndState: [''],
            Dane: [null],
            DaneShort: [null],
            ZipCode: [null, Validators.compose([Validators.required])],
            citySelection: null,
            isOriginAddress: false,
            isDestinationAddress: false,
            UserState: [''],
            Country: [''],
            International: false,
        },
    };
}
