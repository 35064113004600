import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'refundStatus'
})
export class RefundStatusPipe implements PipeTransform {
    constructor() { }

    transform(refundStatus: string, shortFormat: boolean = true): string {
        switch (refundStatus) {
            case 'SUCCESS': return shortFormat? 'No solicitado' : 'Reembolso no solicitado';
            case 'REFUNDPENDING': return shortFormat? 'Pendiente' : 'Reembolso pendiente';
            case 'REFUNDED': return shortFormat? 'Reembolsado': 'Reembolsado';
            case 'REFUNDFAILED': return shortFormat? 'Fallido': 'Reembolso fallido';
            default: return '';
          }
    };
}
