import {Component, OnInit} from '@angular/core';
import {RateDialogComponent} from './rate-dialog.component';
import {MatDialog} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {GrowlSvc} from '../../core/services';

@Component({
  selector: 'app-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.scss']
})
export class RateComponent implements OnInit {

  constructor(private dialog: MatDialog, private route: ActivatedRoute, private growlSvc: GrowlSvc, private router: Router) {}

  ngOnInit() {
    const token = this.route.snapshot.paramMap.get('token');
    const score = +this.route.snapshot.paramMap.get('score');
    if (!token || !score) {
      return this.redirectToLogin('Parámetros inválidos. Serás redireccionado automáticamente');
    }
    const dialogRef = this.dialog.open(RateDialogComponent, {
      disableClose: true,
      autoFocus: true,
      data: {token, score}
    });
    dialogRef.afterClosed().subscribe(this.redirectToLogin.bind(this));
  }

  redirectToLogin(error = null) {
    this.growlSvc.growlError(error || 'El token que utilizas ha expirado o no es válido. Serás redireccionado automaticamente.', false);
    setTimeout(() => {
      this.router.navigate(['/login']);
    }, 2500);
  }

}
