import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpParams } from '@angular/common/http';

@Injectable()
export class TrimFiltersInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.method !== 'GET' || req.params.keys().length === 0) {
      return next.handle(req);
    }

    const filters = req.params.keys();
    const trimmedFilters: { [param: string]: string | string } = {};

    filters.forEach(key => {
      const value = req.params.getAll(key);
      // Just trim parameters whose type is 'string' and are not multi-value parameters (value.length > 1).
      if (value && value.length === 1 && typeof (value[0]) === 'string') {
        trimmedFilters[key] = value[0].trim();
      }
    });

    const modifiedReq = req.clone({ setParams: trimmedFilters });
    return next.handle(modifiedReq);
  }
}
