import { Component, HostListener, OnInit } from '@angular/core';
import { throwMatDuplicatedDrawerError } from '@angular/material';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { ResellerInboxPagination } from './reseller-inbox.pagination';
import * as moment from 'moment';
import { NotificationObsService } from 'src/app/core/services/sharing/notification-obs.service';
import { ResellerObsService } from 'src/app/core/services/sharing/reseller-obs.service';
import { environment } from '../../../../environments/environment';
const Moment = require("moment-timezone");
const MexicoCentralTimeZone = "America/Mexico_City";

@Component({
  selector: 'app-reseller-inbox',
  templateUrl: './reseller-inbox.component.html',
  styleUrls: ['./reseller-inbox.component.scss'],
})
export class ResellerInboxComponent implements OnInit {

  news: boolean = true;
  _paginator: ResellerInboxPagination;

  notifications: any[] = [];
  newsNotifications: any[] = [];
  openNotifications: any[] = [];
  count = 0;
  retrievedInformation = false;
  wasInside = false;
  isFullfillment = false;

  clickedOutside = 0;
  opts = environment;
  
  constructor(
    private notificationService: NotificationService,
    public notificationObsService: NotificationObsService,
    private resellerObsService: ResellerObsService) {
  }
  
  ngOnInit() {
    this.newsNotifications = []; 
    this.openNotifications = []; 
    this._paginator = new ResellerInboxPagination();
    this.notificationObsService.setInboxBannerObservable = true;
    this.resellerObsService.getUserStateObservable.subscribe((userState) =>  {
        this.isFullfillment = userState.isFullfillment;
    });

    this.notificationObsService.getNotificaionObservable.subscribe(history => {
      this.historyProcess(history);
    });

    this.notificationService.getInboxHistory().subscribe((history) => {
      if(this.count === 0) {
        this.historyProcess(history);
        this.notificationObsService.setcountNewNotifiObservable = this.newsNotifications.length;
        this.retrievedInformation = true;
      }
    });
  }

  changeTypeNotification(value) {
    if(this.retrievedInformation){
      this.news = value;
      if (this.news) {
        this._paginator.chageData(this.newsNotifications);
      } else {
        this.openNotifications = this.openNotifications.map(noti => {
          noti.remove = false;
          return noti;
        });
        this._paginator.chageData(this.openNotifications);
      }
    }
  }

  view(item) {
    this.openNotifications.unshift(item);

    setTimeout(() => {
      this.newsNotifications = this.newsNotifications.filter(x => x.InboxHistoryId !== item.InboxHistoryId);
      this._paginator.chageData(this.newsNotifications);
      this.notificationObsService.setClearIconInboxObservable = true;
    }, 300);

    this.newsNotifications = this.newsNotifications.map((noti) => {
      if (noti.InboxHistoryId === item.InboxHistoryId) {
        noti.Seen = true;
        noti.remove = true;
      }
      return noti;
    });

    this._paginator.chageData(this.newsNotifications);

    this.notificationService.updateInboxHistory(item).subscribe();
  }

  viewAll() {
    this.count = 100;
    // this.notificationService.updateAllInboxHistory(this._paginator.scopedData); //? Borrar solo la página actual ?

    setTimeout(() => {
      this.newsNotifications = [];
    }, 1000);

    this.notificationService.updateAllInboxHistory().subscribe();
    for (const notifi of this.newsNotifications) {

      this.openNotifications.push(notifi);

      setTimeout(() => {
        notifi.Seen = true;
        notifi.remove = true;
        this.notificationObsService.setClearIconInboxObservable = true;
      }, this.count);

      this.count = this.count+100;
    }
    
  }

  ngOnDestroy() {
    this.notificationObsService.setInboxBannerObservable = false;
  }

  getNotificationHistoryObs() {
    this.notificationObsService.getNotificaionObservable.subscribe(history => {
      this.newsNotifications = [];
      this._paginator.chageData([]);
      history.map((data) => {

        let toDay = moment(new Date());
        let endDate = data.CreatedAt;
        let difDate = moment(toDay).diff(endDate);

        let totalMinute = difDate / (1000 * 60);
        let hours = Math.trunc(Math.floor(totalMinute / 60));
        let days = Math.trunc(hours/24);
        let minutes = Math.trunc(totalMinute % 60);
        let seconds = Math.trunc(totalMinute * 60);

        if (days > 0) {
          data.time = `${days} ${days > 1 ? 'ds.' : 'd.'}`;
        } else if (hours > 0) {
          data.time = `${Math.trunc(hours)} ${hours > 1 ? 'hrs.' : 'hr.'}`;
        } else if (minutes > 0) {
          data.time = `${Math.trunc(minutes)} min.`;
        } else {
          data.time = `${Math.trunc(seconds)} seg.`;
        }

        data.CreatedAt = moment(data.CreatedAt).format('DD/mm/YYYYY');
        data.remove = false;

        if (data.Seen) {
          this.openNotifications.push(data);
        } else {
          this.newsNotifications.push(data);
        }

      });

      this._paginator.chageData(this.newsNotifications);
    });
  }

  historyProcess(history) {
    this.newsNotifications = [];
    this.openNotifications = [];
    this._paginator.chageData([]);
      history.map((data) => {
        let totalMinute = moment(new Date()).diff(data.CreatedAt) / (1000 * 60);
        let hours = Math.trunc(Math.floor(totalMinute / 60));
        let days = Math.trunc(hours/24);
        let minutes = Math.trunc(totalMinute % 60);
        let seconds = Math.trunc(totalMinute * 60);

        if (days > 0) {
          data.time = `${days} ${days > 1 ? 'ds.' : 'd.'}`;
        } else if (hours > 0) {
          data.time = `${Math.trunc(hours)} ${hours > 1 ? 'hrs.' : 'hr.'}`;
        } else if (minutes > 0) {
          data.time = `${Math.trunc(minutes)} min.`;
        } else {
          data.time = `${Math.trunc(seconds)} seg.`;
        }

        data.Created = moment(data.CreatedAt).format('DD/MM/YYYY');
        data.remove = false;

        if (data.Event === 'Custom' && data.Message.length > 55) {
            data.showMessage = false;
        }

        if (data.Seen) {
          this.openNotifications.push(data);
        } else {
          this.newsNotifications.push(data);
        }

      });

      const customList = this.newsNotifications.filter(x => x.Event == 'Custom');
      this.newsNotifications = this.newsNotifications.filter(x => x.Event != 'Custom');

      customList.sort((a, b) => a.CreatedAt - b.CreatedAt);
      this.openNotifications.sort((a, b) => a.CreatedAt - b.CreatedAt);
      this.newsNotifications.sort((a, b) => a.CreatedAt - b.CreatedAt);
      this.newsNotifications = customList.concat(this.newsNotifications);
      this._paginator.chageData(this.newsNotifications);
  }

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }
  
  @HostListener('document:click')
  clickout() {
    if (!this.wasInside && this.clickedOutside !== 0) {
      this.notificationObsService.setInboxBannerObservable = false;
    }
    this.clickedOutside++;
    this.wasInside = false;
  }

}
