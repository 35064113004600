import { Component, ViewChild, EventEmitter, Output, OnDestroy, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { GrowlSvc } from '../../core/services/growl.service';
import { ResellerService } from '../../core/services/domain/reseller.service';
import { CommonService } from '../../core/services/common.service';

import { ResellerAddress, ZipCodeDetail, ItemReturn } from '../../core/models/models';

import { ModalComponent } from '../../shared/components/modal.component';
import { finalize, tap, filter, debounceTime, switchMap, map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { AddressType } from '../../core/constants';
import { FullfillmentService } from 'src/app/core/services';
import { Pagination } from 'src/app/shared/paginator/pagination';



@Component({
  selector: 'add-return-component',
  templateUrl: './add-return.component.html',
  styleUrls: ['./add-return.component.scss']
})

export class AddReturnComponent implements OnInit, OnDestroy {

  @Input() addressTypeMode?: AddressType;

  @Output() saved = new EventEmitter<any>();
  @Output() closed = new EventEmitter();
  @Input() title = 'Devolución de orden';
  @Input() orderId = '';

  @ViewChild(ModalComponent , { static : true } ) private modal: ModalComponent;

  paginator: Pagination;

  saving = false;
  submitted = false;
  savedOk=false;
  folioReturn= '000001';
  get isAddressTypeModeSet(): boolean {
    return !!this.addressTypeMode;
  }
  itemOrder:ItemReturn[];
  itemReturn:ItemReturn[];

  private editingModel: ResellerAddress;

  orderShipment: any;
  ShipmentSelected: any = null;
  nextStep: boolean = false;
  mleConfirm: boolean = false;

  constructor(
    private fb: FormBuilder,
    private growlSvc: GrowlSvc,
    private _fulfillmentService: FullfillmentService,
    public commonSvc: CommonService
  ) {
    this.paginator = new Pagination();
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
  }

  show(orderId: string, order: any, storeId?: string) {
    this.itemOrder = [];
    this.submitted = false;
    this.orderId = orderId;
    this.mleConfirm = storeId && storeId === 'MER';

    if (order.Items) {
      this.itemOrder = order.Items;
      this.itemOrder.forEach(res => { res.QuantityReturn = 0; });
    }

    if (order.Shipments && Array.isArray(order.Shipments) && order.Shipments.length > 0) {
      this.orderShipment = order.Shipments;
      this.paginator.chageData(this.orderShipment);
    }

    this.modal.show();
  }

  hide() {
    this.modal.hide();
    this.ShipmentSelected = null;
    this.orderShipment = null;
    this.nextStep = false;
    this.mleConfirm = false;
    this.closed.emit(true);
  }

  finalice() {
    this.modal.hide();
    this.savedOk = false;
    this.ShipmentSelected = null;
    this.nextStep = false;
    this.closed.emit(true);
    this.saved.emit(this.orderId);

  }
  save() {
   this.saving=true;
   let item=this.itemOrder.filter(x=> x.QuantityReturn!=0);
   if(item.length==0)
   {
    this.saving=false;
    this.growlSvc.growlError('No se cuenta con datos para generar devolución');
    return 0;
   }

   if(this.ShipmentSelected && this.ShipmentSelected.TrackingStatus && this.ShipmentSelected.TrackingStatus != 'DELIVERED') {
    this.saving=false;
    this.growlSvc.growlWarn('Solo se permite generar devoluciones de guias con estatus Entregada');
    this.nextStep = false;
    return;
   }

    this._fulfillmentService.saveReturn(item,this.orderId, this.ShipmentSelected.ShipmentId)
      .pipe(
        tap((updatedModel) => {

          if(updatedModel.statusCode===200){
            this.folioReturn=updatedModel.Folio;
            this.itemReturn=this.itemOrder.filter(x=> x.QuantityReturn>0);
            this.savedOk=true;
            this.saving = false

          }
          else{
            this.savedOk=false;
            this.growlSvc.growlError(updatedModel.message);
            this.saved.emit(true);
          }

        }),
        finalize(() => this.saving = false)
      )
      .subscribe(undefined, err => {this.growlSvc.growlError(err);
        this.savedOk=false;});
  }

  subtractQuantity(index,id,QuantityD)
  {
    if((this.itemOrder[index].QuantityReturn - 1) >= 0)
    {
       this.itemOrder[index].QuantityReturn = this.itemOrder[index].QuantityReturn - 1;
    }
    if ((this.itemOrder[index].QuantityReturn) < 0) {
      this.growlSvc.growlError('No se permiten datos negativos');
    }
}
  addQuantity(index,id,QuantityD) {
    if((this.itemOrder[index].QuantityReturn + 1) <=QuantityD)
    {
       this.itemOrder[index].QuantityReturn = this.itemOrder[index].QuantityReturn + 1;
    }
    if ((this.itemOrder[index].QuantityReturn) >QuantityD) {
      this.growlSvc.growlError('No puede exceder la cantidad disponible');
    }

  }

  selectShipment(shipment: any) {
    this.ShipmentSelected = shipment;
    this.nextStep = true;
  }

  isSelected(shipment: any): boolean {
    return this.ShipmentSelected == shipment;
  }

}
