import { Pipe, PipeTransform } from '@angular/core';

enum Currencies {
    MXN = 'Peso Mexicano' ,
    COP = 'Peso Colombiano',
    USD = 'Dólar estadounidense',
    EUR = 'Euro',
    GBP = 'Libra Británica'
}
@Pipe({
    name: 'CurrenciesName'
})
export class CurrenciesNamePipe implements PipeTransform {

    /**
     * Devuelve el nombre del vendedor solicitado
     * @param currenciesCode Código de ISO de la moneda
     */
    transform(currenciesCode: any): string {

        let result = Currencies[currenciesCode];
        if(!result){
            result = '';
        }
        return result;
    }
}
@Pipe({
    name: 'Currencies'
})
export class CurrenciesPipe implements PipeTransform {
    transform() : any {
        let items: any[] = [];
        for (let key in Currencies){
            items.push({Iso: key, Name:Currencies[key]});
        }
        return items;
    }
  }