import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {OnBoardingFileType, OnBoardingInfo, Reseller, ResellerFileToUpload, ResellerLoadedFile} from 'src/app/core/models/models';
import {OnBoardingFileService} from '../service/on-boarding-file.service';
import {GrowlSvc, ResellerService} from 'src/app/core/services';
import { TranslateService } from '@ngx-translate/core';
import { OnBoardingService } from '../service/on-boarding.service';

declare var $;

@Component({
  selector: 'app-on-boarding-profile',
  templateUrl: './on-boarding-profile.component.html',
  styleUrls: ['./on-boarding-profile.component.scss']
})
export class OnBoardingProfileComponent implements OnInit {

  private reseller: Reseller;
  profileTypes: SelectItem[];
  profileType: string;
  filesToUpload: OnBoardingFileType[] = [];
  loadedFiles: any = {};
  uploading: any = {};
  downloading: any = {};
  currentFileToUpload: OnBoardingFileType;
  loading = false;
  previusFileLoaded = '';
  profile: string;
  taxData: string;
  companyData: any = { name: '' };
  @Output() updateEvent = new EventEmitter<void>();
  @ViewChild('adjuntoUpload', {static: false}) fileInput: ElementRef;
  onBoardingInfo : OnBoardingInfo;

  constructor(
    private onBoardingFileService: OnBoardingFileService,
    private growlSvc: GrowlSvc,
    private resellerService: ResellerService,
    private translateServ: TranslateService,
    private onBoardingService: OnBoardingService
    ) {
      this.translateServ.get(['fiscalData', 'companyData', 'onboarding.stages']).subscribe(result => {
        this.taxData = result.fiscalData.name;
        this.profile = result['onboarding.stages'].profile;
        this.companyData = result.companyData;
      });
  }

  ngOnInit() {
    this.onBoardingInfo = this.onBoardingService.getOnboardingData();

    this.getReseller();
    this.profileTypes = [
      {label: 'Fisica', value: 'fisica'},
      {label: 'Moral', value: 'moral'},
    ];
  }

  getFileTypes() {
    this.loading = true;
    this.onBoardingFileService.getFileTypes(this.profileType).subscribe(data => {
      this.filesToUpload = data;
      this.loading = false;
    }, err => {
      this.loading = false;
      this.growlSvc.growlError(err);
    });
  }

  getReseller() {
    this.loading = true;
    this.resellerService.getAuthReseller().subscribe(data => {
      this.loading = false;
      this.reseller = data;
      this.loadedFiles = {};
      if (this.reseller && this.reseller.files && this.reseller.files.length) {
        this.reseller.files.forEach(afile => {
          this.loadedFiles[afile.ResellerFileType] = afile;
        });
      }
      this.profileType = this.reseller.TipoPersona;
      this.getFileTypes();
    }, err => {
      this.loading = false;
      this.growlSvc.growlError(err);
    });
  }

  downloadFile(adjunto: ResellerLoadedFile) {
    this.downloading[adjunto.ResellerFileType] = true;
    this.onBoardingFileService.downLoadFile(adjunto.ResellerFileId).subscribe(data => {
      const blobData = this.convertBase64ToBlobData(data.File, data.Mime);
      const blob = new Blob([blobData], {type: data.Mime});
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = adjunto.Name || 'file';
      link.click();
      this.downloading[adjunto.ResellerFileType] = false;
    }, err => {
      console.log('Error al descargar archivo: ', err);
      this.growlSvc.growlError(err);
      this.downloading[adjunto.ResellerFileType] = false;
    });
  }


  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if( file && file.size > 20005671 ){
               this.growlSvc.growlWarn('El archivo supera los 20MB permitidos. Favor de ingresar un archivo de menor tamaño.');
                return;
      }
      reader.readAsDataURL(file);
      reader.onload = () => {
        const adjunto: ResellerFileToUpload = {
          fileType: this.currentFileToUpload.ResellerFileTypeId,
          name: file.name,
          file: typeof reader.result === 'string' ? reader.result.split(',')[1] : null
        };
        this.uploadFile(adjunto);
      };
    }
  }

  private uploadFile(adjunto: ResellerFileToUpload) {

    if (!adjunto.file) {
      this.growlSvc.growlError('error inesperado.');
      return;
    }

    this.uploading[adjunto.fileType] = true;
    this.onBoardingFileService.upLoadFile(adjunto).subscribe(data => {
      this.uploading[adjunto.fileType] = false;
      this.loadedFiles[adjunto.fileType] = {
        ResellerFileId: data.ResellerFileId,
        ResellerFileType: adjunto.fileType,
        Mime: 'unknow',
        Name: adjunto.name,
        Validated: false,
      };
      this.updateEvent.emit();
    }, err => {
      this.growlSvc.growlError(err);
      this.uploading[adjunto.fileType] = false;
    });
  }

  private convertBase64ToBlobData(base64Data: string, contentType: string, sliceSize = 512) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  promtUploadFile(fileToUpload: OnBoardingFileType) {
    console.log('promtUploadFile#item');
    console.log(fileToUpload);
    if (!(this.previusFileLoaded === fileToUpload.ResellerFileTypeId)) {
      $('#uploadFileInput').val('');
    }
    this.currentFileToUpload = fileToUpload;
    this.previusFileLoaded = fileToUpload.ResellerFileTypeId;
    this.fileInput.nativeElement.click();
  }


  changeProfileType(data) {
    let theconfirm = true;
    if (this.reseller.files && this.reseller.files.length) {
      const found = this.reseller.files.find(afile => afile.Validated);
      if (found) {
        theconfirm = confirm(`Ya tiene archivos validados, si cambia a persona ${data.value} tendrán que ser validados nuevamente ¿desea continuar?`);
      }
    }
    if (theconfirm) {
      this.reseller.TipoPersona = data.value;
      this.updateResellerTipoPersona();
    } else {
      this.profileType = null;
      setTimeout(() => {
        this.profileType = this.reseller.TipoPersona;
      }, 100);
    }
  }

  updateData() {
    this.updateEvent.emit();
  }

  private updateResellerTipoPersona() {
    this.loading = true;
    this.resellerService.saveReseller({TipoPersona: this.reseller.TipoPersona}).subscribe(data => {
      this.loading = false;
      this.getReseller();
      this.updateEvent.emit();
    }, err => {
      this.growlSvc.growlError(err);
      this.loading = false;
    });
  }

}
