import {
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
  FormGroup
} from "@angular/forms";
const DECIMAL_REGEXP = /^\d+(\.{1}\d{1,2})?$/;
const NUMBER_REGEXP = /^\d+$/;
const ZIPCODE_REGEXP = /^[a-zA-Z0-9_-]+$/;
const ZIPCODE_REGEXP_CO = /^\d{1,6}$/;
const ALPHANUMERIC_REGEXP = /^([a-zA-ZñÑ0-9 _-]+)$/;
const ALPHANUMERIC_REGEXP_NO_SPACE = /^([a-zA-Z0-9_-]+)$/;
const VALID_CHARACTERS_REGEXP = /^([a-zA-ZÀ-ÿ0-9 _-]+)$/;
const EMAIL_REGEXP = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z]+$/;

export class CustomValidators {

  /**
   * Validator that requires a FormGroup to have at least a defined number of controls
   * that have passed the supplied validator function.
   *
   * @param atLeastCount The minumum number of controls that must pass the supplied validator function.
   *
   * @param validator The `ValidatorFn` that will be applied to the FormGroup controls.
   *
   * @param controlNames An optional array of control names or paths whose validity will be check.
   * If not supplied or empty, the validator will be applied to all the controls in the FormGroup.
   *
   * @usageNotes
   *
   * ### Require at least two controls to be valid among all the controls in the FormGroup
   *
   * ```ts
   * const searchCriteriaform = new FormGroup({
   *   name: '',
   *   email: '',
   *   phone: ''
   * }, CustomValidators.atLeast(2, Validators.Required));
   *```
   *
   * ### Require at least one control to be valid among a subset of the controls in the FormGroup
   *
   * ```ts
   * const searchCriteriaform = new FormGroup({
   *   name: '',
   *   email: '',
   *   phone: ''
   * }, CustomValidators.atLeast(1, Validators.Required, ['name', 'email']));
   *```
   */
  static atLeast(
    atLeastCount: number,
    validator: ValidatorFn,
    controlNames: string[] = []
  ) {
    return (group: FormGroup): ValidationErrors | null => {
      if (!group || !group.controls) {
        return null;
      }

      const controlsToVerify =
        controlNames && controlNames.length
          ? controlNames
          : Object.keys(group.controls);

      let firstValidationErrorFound: any = null;

      const hasAtLeastCount =
        controlsToVerify.filter(k => {
          const control = group.get(k);

          /** Store the validation of the first error found, just to know the error structure.
           *  This will be needed later when returning the result.
           * */
          if (control && firstValidationErrorFound === null) {
            firstValidationErrorFound = validator(control);
          }

          return !control ? false : !validator(control);
        }).length >= atLeastCount;

      return hasAtLeastCount
        ? null
        : {
            atLeast: {
              atLeastCount: atLeastCount,
              validator: firstValidationErrorFound
                ? Object.keys(firstValidationErrorFound)[0]
                : null
            }
          };
    };
  }


  static nonZero(control:AbstractControl):{ [key: string]: any; } {
    if (Number(control.value) <= 0) {
      return {nonZero: true};
    } else {
      return null;
    }
  }

  /**
   * Validator that requires controls to have a positive integer value.
   */
  static integer(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null; // don't validate empty values to allow optional controls
    }
    return NUMBER_REGEXP.test(control.value) ? null : { integer: true };
  }

  /**
   * Validator that requires controls to have a numeric positive value, either decimal or integer.
   */
  static decimal(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null; // don't validate empty values to allow optional controls
    }
    return DECIMAL_REGEXP.test(control.value) ? null : { decimal: true };
  }

  /**
   * Validator that requires controls to match a zip code format to its value.
   */
  static zipCode(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null; // don't validate empty values to allow optional controls
    }
    return ZIPCODE_REGEXP.test(control.value) && control.value.length === 5 ? null : { zipCode: true };
  }

  /**
   * Validator that requires controls to match a zip code format generic.
   */
  static zipCodeGeneric(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null; // don't validate empty values to allow optional controls
    }
    return  ALPHANUMERIC_REGEXP.test(control.value) ? null : { zipCode: true };
  }

  /**
   * Validator that requires controls to match a zip code format to its value for Colombia.
   */
  static zipCodeCo(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null; // don't validate empty values to allow optional controls
    }
    return ZIPCODE_REGEXP_CO.test(control.value) ? null : { zipCode: true };
  }

  /**
  * Validator that requires controls to match a valid email.
  */
  static isValidEmail(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null; // don't validate empty values to allow optional controls
    }
    return EMAIL_REGEXP.test(control.value) ? null : { email: true };
  }

  // Validates US phone numbers
  static phoneValidator(number): any {
    const aphone = number.value;
    if (!aphone) { return false; }
    const thephone = getOnlyNumbers(aphone);
    return thephone.length != 10 ?  {
      phoneValidator: true
    } : null;
  }

  static minimunBalance(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null; // don't validate empty values to allow optional controls
    }
    return control.value >= 500 ? null : { minimunBalance: true };
  }

  static balanceToRecharge(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null; // don't validate empty values to allow optional controls
    }
    return (control.value >= 500 && control.value <= 30000) ? null : { minimunBalance: true };
  }

  static onlyAlphanumericCharacters(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null; // don't validate empty values to allow optional controls
    }
    return ALPHANUMERIC_REGEXP.test(control.value) ? null : { onlyAlphanumericCharacters: true };
  }

  static onlyAlphanumericCharactersNoSpace(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null; // don't validate empty values to allow optional controls
    }
    return ALPHANUMERIC_REGEXP_NO_SPACE.test(control.value) ? null : { onlyAlphanumericCharactersNoSpace: true };
  }

  static onlyValidCharacters(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null; // don't validate empty values to allow optional controls
    }
    return VALID_CHARACTERS_REGEXP.test(control.value) ? null : { onlyValidCharacters: true };
  }
  static isEmpty(obj) {
    return (!obj || 0 === obj.length || (Object.keys(obj).length === 0 && obj.constructor === Object) || obj === '');
  };

  static notEmpty(obj) {
    return this.not(this.isEmpty(obj));
  }

  static not(x) {return !(x)};

  static getValidJSON(jsonData) {
    let result = { body: undefined, isValidJson: true };

    try {
      if(typeof jsonData === 'object') result.body = jsonData;
      else result.body = JSON.parse(jsonData);
    } catch( err ) {
      result.isValidJson = false
    }

    return result;

  }

  static dateIsValid(dateObject) {
    return dateObject.toString() !== 'Invalid Date';
  }

}
function getOnlyNumbers(text: string) {
  return text ? text.replace(/\D/g, '') : null;
}
function isEmptyInputValue(value: any): boolean {
  // we don't check for string here so it also works with arrays
  return value == null || value.length === 0;
}

