
export class ResellerAlertBanner{
  type: TypeAlertBanner;
  value: string;
  resellerStoreId?: string;
}


export enum TypeAlertBanner{
  StoreDisconnected = 'storeDisconnected',
  OrderWarning = 'orderWarning',
}
