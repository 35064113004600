import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-go-back-button',
  templateUrl: './go-back-button.component.html'
})
export class GoBackButttonComponent {
  constructor(
    private location: Location
  ) { }

  goBack() {
    this.location.back();
  }
}
