import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  ResellerAlertBanner,
  TypeAlertBanner,
} from "src/app/core/models/store.model";
import { FullfillmentService, GrowlSvc } from "src/app/core/services";
import "rxjs/add/observable/interval";
import { animate, style, transition, trigger } from "@angular/animations";
import { Observable } from "rxjs-compat/Observable";
import { delay } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";
import { LoadingSpinnerService } from "../../loadingspinner/loading-spinner.service";
import { StoreService } from 'src/app/views-reseller/store/service/store.service';
@Component({
  selector: "app-alert-banner",
  templateUrl: "./alert-banner.component.html",
  styleUrls: ["./alert-banner.component.scss"],
  animations: [
    trigger("smallUpDownAnimation", [
      transition(":enter", [
        style({ opacity: 0, transform: 'translateY(-2em)' }),
		    animate('500ms', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition(":leave", [
        animate('500ms', style({ opacity: 0, transform: 'translateY(2em)' }))
      ]),
    ]),
  ],
})
export class AlertBannerComponent implements OnInit, OnDestroy {
  sideOpened = true;
  activeAlert: string = "";
  activeValueAlert: string = "";
  nextAlert = 0;
  private secondsNextAlert = 4;
  private intervaleTimer;
  listAlertBanner: ResellerAlertBanner[];

  constructor(
    public _fullfillmentService: FullfillmentService,
    private router: Router,
    private loading: LoadingSpinnerService,
    private _growlSvc: GrowlSvc,
    private stare_service: StoreService
  ) {}

  ngOnDestroy(): void {
    this._fullfillmentService.clearDataAlertsBanner();
  }

  ngOnInit() {
    this._fullfillmentService.alertsBanner$.subscribe(
      (listAlertBanner: ResellerAlertBanner[]) => {
        if (listAlertBanner && listAlertBanner.length > 0) {
          this.listAlertBanner = [, ...listAlertBanner];
          this.startNextAlert();
        }
      }
    );
  }

  currentRouteIs(routeMatch: string): boolean {
    return this.router.url === routeMatch;
  }

  navigate(route: string) {
    if (window.innerWidth < 960) {
      this.sideOpened = false;
    }
    this.router.navigate([`/${route}`]);
  }

  private startNextAlert() {
    this.getNextAlertBanner();
    this.intervaleTimer = Observable.interval(
      this.secondsNextAlert * 1000
    ).subscribe(async () => {
      this.nextAlert++;
      this.getNextAlertBanner();
    });
  }

  private getNextAlertBanner() {
    if (this.nextAlert === this.listAlertBanner.length) {
      this.nextAlert = 0;
    }

    // TODO: Revision PAK-4142
    // const nextAlertData: ResellerAlertBanner = this.listAlertBanner[this.nextAlert];
    //
    // if (this.router.url === '/fulfillment/ordenes' && nextAlertData.type === TypeAlertBanner.OrderWarning) {
    //   this.getNextAlertBanner();
    //   return;
    // }
  }
/**
 * @description Remove store banners
 * @param resellerStoreId 
 * @see SD-852 
 */
  updateInactiviCounterStore(resellerStoreId){
    this.listAlertBanner = this.listAlertBanner.filter(item => {
      if (!item.hasOwnProperty('resellerStoreId') || item.resellerStoreId !== resellerStoreId) {
        return true;
      }
      return false;
    });
    this.stare_service.postUpdateActivityCounterStore(resellerStoreId)
      .subscribe(
        res => {
        },
        err => {
          this._growlSvc.growlError(err);
        }
      );
  }
  public get typeAlert(): typeof TypeAlertBanner {
    return TypeAlertBanner;
  }

}
