import { Component,Input,ViewChild } from '@angular/core';import { ModalComponent } from "./modal.component";
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { Pagination } from '../paginator/pagination';
import { Orden } from 'src/app/core/models/models';
import { FormatHelperService } from 'src/app/core/services/util/format-helper.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: "app-history-orders-modal",
  templateUrl: "./history-orders-modal.component.html",
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', visibility: 'hidden' })
      ),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ],
  styleUrls: ["./history-orders-modal.component.scss"]
})
export class HistoryOrdersModalComponent {
  @Input() header?: string;
  @Input() message?: string;
  @Input() okText = "Aceptar";
  listHistory?: any[] = [];
  @ViewChild(ModalComponent, { static: true }) private modal: ModalComponent;


  constructor( private formatHelp: FormatHelperService) {
    //this.paginator.scopedData = []; 
    this.paginator = new Pagination();
    //this.dataSource = new MatTableDataSource<any>([]);
    //this.dataSource.paginator = new Pagination();
    //this.dataSource.paginator = this.paginator;
  }

  languageCode = environment.languageCode;
  currency = environment.currency;

  totalOrders = 0;
  totalSuccess = 0;
  totalWarning = 0;
  totalError = 0;
  displayedColumns: string[] = ['Logo', 'Tienda', 'Folio', 'Estatus', 'Resultado'];  
  dataSource: MatTableDataSource<any>;
  isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');
  expandedElement: any;
  orderDetails: any = null;
  //@ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
  paginator: Pagination;
  
  @ViewChild(MatSort,{static:true}) sort: MatSort;
  Folio:string;
  fullfillment=false;
  show(listHistory,folio,full:boolean) {
    this.fullfillment=full;
    this.Folio = folio;
    this.listHistory = listHistory;
    this.totalOrders = 0; 
    this.listHistory.forEach(historyRes => {
      historyRes.OrderJson = historyRes.OrderJson ? JSON.parse(historyRes.OrderJson) : [];
      this.totalOrders ++;
    });
    this.paginator.chageData(this.listHistory);
    this.modal.show();
  }

  dataDetails: any = null;
  expandedIndex: string = null;
  isRowExpanded( data: any ) {
    return this.expandedIndex === data.OrderHistoryId;
  }
  collapseRow(data: any) {
    if ( data.OrderHistoryId === this.expandedIndex) {
      this.expandedIndex = null;
      this.dataDetails = null;
      return;
    }
    this.parseOrderDetail( data.OrderJson );
    this.expandedIndex = data.OrderHistoryId;
  }
  private parseDataDetail(data: any) {
    this.dataDetails = {
      listErrorDetail: data.ErrorDetail,
      errorPosition: data.MsgPosition

    };
  }

  formatCurrency(value, currency) {
    if(currency === undefined) return value;
    return this.formatHelp.formatCurrency(this.languageCode, currency, value);
  }

  isNotBlank(data: any): boolean {
    if (!data) {
      return false;
    }
    data = String(data).trim();
    if (!data.length) {
      return false;
    }
    return true;
  }

  isNotBlankPhone(data: any): boolean {
    if (!data) {
      return false;
    }
    data = String(data).trim();
    if (!data.length) {
      return false;
    }
    if (data=='0000000000') {
      return false;
    }
    return true;
  }

  private parseOrderDetail(order: Orden) {
    let StoreName = order.StoreId;
    // const resellerStore = this.storesConfigfiltered.find(
    //   item => item.ResellerStoreId === order.ResellerStoreId
    // );
    // if (
    //   resellerStore &&
    //   resellerStore.Config &&
    //   resellerStore.Config.StoreName
    // ) {
    //   StoreName = resellerStore.Config.StoreName;
    // }
    let  CreatedAtStore = null;
    if(order.CreatedAtStore){
      CreatedAtStore = order.CreatedAtStore.toString().replace('{"when":"', '').replace('"}','');
    }

  
    this.orderDetails = {
      StoreName: StoreName,
      Items: order.Items,
      AddressTo: JSON.parse(order.AddressTo),
      Recipient: JSON.parse(order.Recipient),
      AddressFrom: JSON.parse(order.AdressFrom),
      Seneder: JSON.parse(order.Sender),
      StatusStore: order.StatusStore,
      CreatedAtStore: CreatedAtStore,
      Updated_AtStore: order.Updated_AtStore.toString().replace('{"when":"', '').replace('"}',''),
      Folio: order.Folio,
      Paid: this.isOrderPaid(order),
      Currency: order.Currency,
      Subtotal: order.Subtotal,
      Taxes: order.Taxes,
      Extras: order.Extras,
      Discounts: order.Discounts,
      Total: order.Total
    };
  }
  private isOrderPaid(order: Orden): boolean {
    const InternalStatus = {
      VALORATION: '1', // "En valoración",
      PENDING: '2', // "Pendiente",
      PROCESSED: '3', // "Procesada"
      CANCELLED: '4', // "Cancelada"
      MANUALPROCESSED: '5' // "Guia Manual"
    };
    if (
      order.Status === InternalStatus.PENDING ||
      order.Status === InternalStatus.PROCESSED
    ) {
      return true;
    }
    return false;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  hide() {
    this.expandedIndex = null;
    this.paginator.chageData([]);
    this.Folio = "";
    this.modal.hide();
  }
}
