import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';

@Injectable()
export class ResellerStoreActionService {
  constructor(
    private apiService: ApiService,
  ) { }

  saveRulesConfig(data:any): Observable<any> {
    return this.apiService.post('Reseller/resellerStoreAction/create',data);
  }

  updateRulesConfig(data:any): Observable<any> {
    return this.apiService.put('Reseller/resellerStoreAction/update',data);
  }

  getStates(): Observable<any> {
    return this.apiService.get('Reseller/getStates');
  }

  getRules(ResellerStoreId): Observable<any> {
    return this.apiService.get(`Reseller/resellerStoreAction/getByFilter?resellerStoreId=${ResellerStoreId}`);
  }

}
