import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'trackingStatus'
})
export class TrackingStatusPipe implements PipeTransform {
  shipmentTranslate: any;

  constructor(private translateServ: TranslateService) {

    this.translateServ.get(['shipments']).subscribe(result => {
      this.shipmentTranslate = result.shipments;
    });

   }

  transform(trackingStatus: any): string {
    switch (trackingStatus) {
      case 'WAITING': return this.shipmentTranslate.status.waiting;
      case 'IN_TRANSIT': return this.shipmentTranslate.status.inItransit;
      case 'ON_DELIVERY': return this.shipmentTranslate.status.onDelivery;
      case 'DELIVERED': return this.shipmentTranslate.status.delivered;
      case 'EXCEPTION': return this.shipmentTranslate.status.exception;
      case 'RETURNED': return this.shipmentTranslate.status.returned;
      case 'CANCELLED': return this.shipmentTranslate.status.cancelled;
      default: return trackingStatus;
    }
  }
}

