import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigNotificationComponent } from '../views-reseller/reseller-common/config-notification.component';
import { ModalModule } from './modal.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LaddaModule } from 'angular2-ladda';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  imports: [CommonModule, ModalModule, FormsModule, ReactiveFormsModule, LaddaModule, NgxIntlTelInputModule,TranslateModule],
  declarations: [
    ConfigNotificationComponent
  ],
  exports: [
    ConfigNotificationComponent, TranslateModule
  ]
})
export class ConfigNotificationModule { }
