import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

declare var OpenPay;

@Injectable()
export class OpenPayService {

  private _deviceSessionId: string;

  get deviceSessionId(): string {
    return this._deviceSessionId;
  }

  MerchantId = environment.openPayMerchantId;
  ApiKey = environment.openPayApiKey;

  useOpenPaySandbox = environment.production === false;
  openPayUrl = environment.openPayUrl;

  constructor(
) {
    OpenPay.setId(this.MerchantId);
    OpenPay.setApiKey(this.ApiKey);
    OpenPay.setSandboxMode(this.useOpenPaySandbox);

    this._deviceSessionId = OpenPay.deviceData.setup();
  }

  createCardToken(cardInfo: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      OpenPay.token.create(cardInfo,
        response =>  resolve(response),
        error => {
          console.log(error);

          // obten error y traduce via codigo
          const message = this.extractErrorDetail(error.data);

          reject({ status: error.status, message: message });
        }
      );
    });
  }

  validateCardAndCvv(cardNumber: string, cardCvv: string) {
    return OpenPay.card.validateCVC(cardCvv, cardNumber);
  }

  private extractErrorDetail(data) {
    const errorCode = data ? data.error_code : null;

    // Lista de errores de Openpay Feb-2018
    // Lista de errores de Openpay Marzo -2021
    switch (errorCode) {
      case 1000: return 'Ocurrió un error interno en el servidor de Openpay';
      case 1001: return 'El formato de la petición no es JSON, los campos no tienen el formato correcto, o la petición no tiene campos que son requeridos.';
      case 1002: return 'La llamada no esta autenticada o la autenticación es incorrecta';
      case 1003: return 'La operación no se pudo completar por que el valor de uno o más de los parametros no es correcto';
      case 1004: return 'Un servicio necesario para el procesamiento de la transacción no se encuentra disponible';
      case 1005: return 'Uno de los recursos requeridos no existe.';
      case 1006: return 'Ya existe una transacción con el mismo ID de orden.';
      case 1007: return 'La transferencia de fondos entre una cuenta de banco o tarjeta y la cuenta de Openpay no fue aceptada.';
      case 1008: return 'Una de las cuentas requeridas en la petición se encuentra desactivada';
      case 1009: return 'El cuerpo de la petición es demasiado grande.';
      case 1010: return 'Se esta utilizando la llave pública para hacer una llamada que requiere la llave privada, o bien, se esta usando la llave privada desde JavaScript.';
      case 1011: return 'Se solicita un recurso que esta marcado como eliminado.';
      case 1012: return 'El monto transacción esta fuera de los limites permitidos.';
      case 1013: return 'La operación no esta permitida para el recurso.';
      case 1014: return 'La cuenta esta inactiva.';
      case 1015: return 'No se ha obtenido respuesta de la solicitud realizada al servicio.';
      case 1016: return 'El mail del comercio ya ha sido procesada.';
      case 1017: return 'El gateway no se encuentra disponible en ese momento.';
      case 1018: return 'El número de intentos de cargo es mayor al permitido.';
      case 1020: return 'El número de dígitos decimales es inválido para esta moneda.';
      case 1023: return 'Se han terminado las transacciones incluidas en tu paquete. Para contratar otro paquete contacta a soporte@openpay.mx.';
      case 1024: return 'El monto de la transacción excede su límite de transacciones permitido por TPV';
      case 1025: return 'Se han bloqueado las transacciones CoDi contratadas en tu plan';
      case 2001: return 'La cuenta de banco con esta CLABE ya se encuentra registrada en el cliente.';
      case 2002: return 'La tarjeta con este número ya se encuentra registrada en el cliente.';
      case 2003: return 'El cliente con este identificador externo (External ID) ya existe.';
      case 2004: return 'El dígito verificador del número de tarjeta es inválido de acuerdo al algoritmo Luhn.';
      case 2005: return 'La fecha de expiración de la tarjeta es anterior a la fecha actual.';
      case 2006: return 'El código de seguridad de la tarjeta (CVV2) no fue proporcionado.';
      case 2007: return 'El número de tarjeta es de prueba, solamente puede usarse en Sandbox.';
      case 2008: return 'La tarjeta no es valida para puntos Santander.';
      case 2009: return 'El código de seguridad de la tarjeta (CVV2) es inválido.';
      case 2010: return 'Autenticación 3D Secure fallida.';
      case 2011: return 'Tipo de tarjeta no soportada.';
      case 3001: return 'La tarjeta fue declinada.';
      case 3002: return 'La tarjeta ha expirado.';
      case 3003: return 'La tarjeta no tiene fondos suficientes.';
      case 3004: return 'La tarjeta ha sido identificada como una tarjeta robada.';
      case 3005: return 'La tarjeta ha sido rechazada por el sistema antifraudes.';
      case 3006: return 'La operación no esta permitida para este cliente o esta transacción.';
      case 3007: return 'Deprecado. La tarjeta fue declinada.';
      case 3008: return 'La tarjeta no es soportada en transacciones en linea.';
      case 3009: return 'La tarjeta fue reportada como perdida.';
      case 3010: return 'El banco ha restringido la tarjeta.';
      case 3011: return 'El banco ha solicitado que la tarjeta sea retenida. Contacte al banco.';
      case 3012: return 'Se requiere solicitar al banco autorización para realizar este pago.';
      case 3201: return 'Comercio no autorizado para procesar pago a meses sin intereses.';
      case 3203: return 'Promoción no valida para este tipo de tarjetas.';
      case 3204: return 'El monto de la transacción es menor al mínimo permitido para la promoción.';
      case 3205: return 'Promoción no permitida.';
      case 4001: return 'La cuenta de Openpay no tiene fondos suficientes.';
      case 4002: return 'La operación no puede ser completada hasta que sean pagadas las comisiones pendientes.';
      case 5001: return 'La orden con este identificador externo ya existe.';
      case 6001: return 'El webhook ya ha sido procesado.';
      case 6002: return 'No se ha podido conectar con el servicio de webhook.';
      case 6003: return 'El servicio respondio con errores.';
    }

    return 'Error desconocido';
  }

  /* Descarga de Etiquetas */
  getOpenPayReferenceLabel(reference: string, type: string): string {
    let labelUrl = '';

    switch (type) {
      case 'bank_account':
        labelUrl = this.openPayUrl + 'spei-pdf/' + this.MerchantId + '/' + reference;
        break;

      case 'store':
        labelUrl = this.openPayUrl + 'paynet-pdf/' + this.MerchantId + '/' + reference;
        break;

      case 'OXXO':
        labelUrl = reference;
        break;

      default: break;
    }

    return labelUrl;
  }
}
