import {Inject, Injectable, Optional} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {map} from 'rxjs/operators';
import {ApiService} from 'src/app/core/services/api.service';
import {Shipment, OnBoardingInfo} from 'src/app/core/models/models';
import {of} from 'rxjs';
import {Bussiness} from '../bussiness/bussiness.model';
import {Subject} from 'rxjs/Subject';


@Injectable()
export class OnBoardingService {

  public stages = [
    {
      name: 'onboarding.stages.completeProfile',
      completeImage: 'onboarding-profile-complete',
      incompleteImage: 'onboarding-profile-incomplete',
      key: 'profile',
      action: 'stage',
      value: '',
      blockClose: false
    },
    {
      name: 'onboarding.stages.payBalance',
      completeImage: 'onboarding-balance-complete',
      incompleteImage: 'onboarding-balance-incomplete',
      key: 'balance',
      action: 'link',
      value: 'saldo-y-pagos',
      blockClose: false
    },
    {
      name: 'onboarding.stages.enterpriseData',
      completeImage: 'onboarding-store-complete',
      incompleteImage: 'onboarding-store-incomplete',
      key: 'business',
      action: 'stage',
      value: '',
      blockClose: false
    },
  ];

  protected basePath = 'Reseller/profileStatus';

  private dummy: OnBoardingInfo = {
    completePercentage: 75,
    email: true,
    phone: true,
    profile: true,
    balance: true,
    business: true,
    status: '',
    isDuplicatePhone: false
    /* businessAddress: false,
     order: false, */
  };

  private onboardingData = null;
  private resellerStatus: string;
  private verificationAllow = false;

  resellerVerificationStatus: Subject<any> = new Subject();

  constructor(private apiService: ApiService) {
    this.onboardingData = {};
  }

  /* OnBoardingInfo */
  getOnBoardingInfo(): Observable<OnBoardingInfo> {
    if (this.basePath === '**TO-DO**') {
      return of(this.dummy);
    }

    return this.apiService.get<OnBoardingInfo>(this.basePath);
  }

  setOnboardingData(data: OnBoardingInfo) {
    this.onboardingData = data;
  }

  getOnboardingData() {
    return this.onboardingData;
  }

  setResellerBussiness(data) {
    return this.apiService.post<Bussiness>('Reseller/setResellerBussiness/', data);
  }

  getResellerBussiness() {
    return this.apiService.get('Reseller/getResellerBussiness/');
  }

  setResellerStatus(status: string) {
    this.resellerStatus = status;
    this.resellerVerificationStatus.next();
  }

  getResellerStatus(): string {
    return this.resellerStatus;
  }

  setVerificationAllowed(value: boolean) {
    this.verificationAllow = value;
    this.resellerVerificationStatus.next();
  }

  isVerificationAllowed(): boolean {
    return this.verificationAllow ;
  }
}
