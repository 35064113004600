import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trackingStatusFF'
})
export class TrackingStatusFFPipe implements PipeTransform {
  constructor() { }

  transform(trackingStatus: any): string {
    switch (trackingStatus) {
      case 'WAITING': return 'Por recolectar';
      case 'IN_TRANSIT': return 'En tránsito';
      case 'ON_DELIVERY': return 'En tránsito';
      case 'DELIVERED': return 'Entregada';
      case 'EXCEPTION': return 'Devuelta';
      case 'RETURNED': return 'Devuelta';
      case 'CANCELLED': return 'Devuelta';
      case 'UNKNOWN': return 'Devuelta';
      case 'Surtido': return 'Procesando';
      default: return status;
    }
  }
}
