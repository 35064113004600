import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { DefaultHeadersInterceptor } from './default-headers-interceptor';
import { ErrorInterceptor } from './error-interceptor';
import { TrimFiltersInterceptor } from './trim-filters-interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: DefaultHeadersInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: TrimFiltersInterceptor, multi: true },
];
