import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import * as moment from 'moment';

@Injectable()
export class RouteParamsUtilService {
  normalizeQueryParams(params?: { [param: string]: any }): { [key: string]: string } {
    const normalizedParms = {};

    if (params) {
      Object.keys(params).forEach((key) => {
        // Ignore unset parameters and blank strings
        if (params[key] === null || params[key] === undefined || params[key] === '') {
          return;
        }

        normalizedParms[key] = String(params[key]).trim();
      });
    }

    return normalizedParms;
  }

  getStringFromParam(params: ParamMap, key: string): string | null {
    const paramValue = params.get(key);

    if (isParamEmpty(paramValue)) {
      return null;
    }

    return paramValue.trim();
  }

  getBooleanFromParam(params: ParamMap, key: string): boolean | null {
    const paramValue = params.get(key);

    if (isParamEmpty(paramValue)) {
      return null;
    }
    return ['true', 'false'].includes(paramValue.trim().toLowerCase())
      ? JSON.parse(paramValue)
      : null;
  }

  getMyDpBindingValueFromParam(params: ParamMap, key: string, dateFormat = 'YYYY-MM-DD')
    : { date: { year: Number, month: Number, day: Number } } {
    const paramValue = params.get(key);

    if (isParamEmpty(paramValue)) {
      return null;
    }

    // Try to parse just the beginning of the string that matches the dateFormat lenght and ignore the rest.
    const matchedStringValue = paramValue.substring(0, dateFormat.length);
    const momentDate = moment(matchedStringValue.substring(0, 10), dateFormat, true);
    return momentDate.isValid() ?
      {
        date: {
          year: momentDate.year(),
          month: momentDate.month() + 1,
          day: momentDate.date()
        }
      }
      : null;
  }

  getNumberFromParam(params: ParamMap, key: string): number | null {
    const paramValue = params.get(key);

    if (isParamEmpty(paramValue)) {
      return null;
    }

    const numberValue = Number(paramValue);
    return !Number.isNaN(numberValue) ? numberValue : null;
  }

  // Converts a value string which contains comma-separeted keys, to a hash set object.
  // Example. The string 'a, b, c' generates an object {a: true, b: true; c: true}.
  getHashSetFromCommaSeparatedStringKeysParam(params: ParamMap, key: string): object {
    const paramValue = params.get(key);

    if (isParamEmpty(paramValue)) {
      return {};
    }

    return paramValue.split(',')
      .reduce((result, propName) => {
        propName = propName.trim();
        if (propName.length > 1) {
          result[propName.trim()] = true;
        }
        return result;
      }, {});
  }
}

function isParamEmpty(paramValue?: string) {
  return paramValue === 'undefined'
    || paramValue === 'null'
    || paramValue === undefined
    || paramValue === null
    || paramValue.trim().length === 0;
}
