export class Pagination {
    private data: any[];
    private _scopedData: any[];
    private currentPage: number;
    private _rowsPerPage: number;
    totalRows: number;
    totalpages: number;
    pages: number[];
    noOfPages = [10, 20, 30, 50];
    protected maxPages = 5;

    get scopedData(): any[]{
        return this._scopedData;
    }

    set scopedData(data: any[]) {
        this.scopeDataChange(data, this._scopedData);
        this._scopedData = data;
    }

    get rowsPerPage(): number {
        return this._rowsPerPage;
    }

    set rowsPerPage(rpa: number) {
        this.changePerPage(rpa);
    }

    constructor(data: any[] = [], currentPage = 1, rowsPerPage = 10) {
        this.data = data;
        this.totalRows = this.data.length;
        this.currentPage = currentPage;
        this.rowsPerPage = rowsPerPage;
    }

    scopeDataChange(newData: any[], oldData: any[]) {
        // metodo llamado cada vez que cambian los datos
    }

    next(): any[] {
        if (!this.hasNextPage()) { return this.scopedData; }
        return this.goToPage(this.currentPage + 1);

    }

    previous(): any[] {
        if (!this.hasPreviousPage()) { return this.scopedData; }
        return this.goToPage(this.currentPage - 1);
    }

    goToPage(page: number): any[] {
        if (!this.totalRows) {
            this.currentPage = 1;
            this.scopedData = [];
            return this.scopedData;
        } else if ( this.totalpages === 1 ) {
            this.currentPage = 1;
            this.scopedData = this.data.map(item => item);
            return this.scopedData;
        }
        if (page < 1) {page = 1; }
        if (this.totalRows && page > this.totalpages) { page = this.totalpages; }
        this.currentPage = page;
        const end: number = this.currentPage * this.rowsPerPage;
        const start: number = end - this.rowsPerPage;
        this.scopedData = this.data.slice(start, end);
        return this.scopedData;
    }

    getCurrentPage(): number {
        return this.currentPage;
    }

    getTotalPages(): number {
        return this.totalpages;
    }

    hasNextPage(): boolean {
        return this.currentPage < this.totalpages;
    }

    changePerPage(perPage: number) {
        this._rowsPerPage = perPage;
        this.calculateTotalPages();
        this.goToPage(this.currentPage);
    }

    chageData(data: any[] = []) {
        this.data = data;
        this.totalRows = this.data.length;
        this.calculateTotalPages();
        this.goToPage(this.currentPage);
    }

    hasPreviousPage(): boolean {
        return this.currentPage > 1;
    }

    private calculateTotalPages() {
        if (this.rowsPerPage >= this.totalRows) {
            this.totalpages = 1;
        } else {
            this.totalpages = Math.ceil(this.totalRows / this.rowsPerPage);
        }
        this.pages = [];
        for (let i = 1; i <= this.totalpages; i++) {
            this.pages.push(i);
        }
    }

    getPages(): number[] {
        if (this.pages.length > this.maxPages) {
            const pages: number[] = [];
            const currentPage = this.getCurrentPage();
            if (currentPage === 1) {
                for (let i = 1; i <= this.maxPages; i++) {
                    pages.push(i);
                }
            } else if (currentPage === this.pages.length) {
                for (let i = (this.pages.length - this.maxPages + 1 ); i <= this.pages.length; i++) {
                    pages.push(i);
                }
            } else {
                let sidepages = this.maxPages / 2;
                let lefpage = currentPage - sidepages + 1;
                let rightpage = currentPage + sidepages;
                if (this.maxPages % 2) {
                    sidepages = (this.maxPages - 1) / 2;
                    lefpage = currentPage - sidepages;
                    rightpage = currentPage + sidepages;
                }
                if (lefpage < 1) {
                    lefpage = 1;
                    rightpage = this.maxPages;
                } else if (rightpage > this.pages.length ) {
                    rightpage = this.pages.length ;
                    lefpage = rightpage - this.maxPages + 1;
                }
                for (let i = lefpage; i <= rightpage; i++) {
                    pages.push(i);
                }
            }
            return pages;
        }
        return this.pages;
    }

    public getAllData() {
      return this.data;
    }
}
