import { Injectable } from '@angular/core';
//import * as Excel from 'exceljs';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as ExcelProper from "exceljs";
import { saveAs } from 'file-saver';

@Injectable()
export class ExcelService {

   generateExcel(jsonData, headerData, fileName) {
     
    //Create workbook and worksheet
    let workbook: ExcelProper.Workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('Reporte');

  worksheet.columns = headerData;

    // Add Data and Conditional Formatting
    jsonData.forEach(d => {
        worksheet.addRow(d);
      }
    );

    //Generate Excel File with given name
     workbook.xlsx.writeBuffer().then( ( jsonData : ArrayBuffer ) => {
                  let blob = new Blob( [ jsonData as BlobPart ], { 
                                                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
                                                   });
      saveAs(blob, fileName);
    }) 

  }

}
