import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'suffixpkgInf'
})
export class SuffixPackagePipe implements PipeTransform {

    transform(value: string, suffix: string): string {
 
        if (value === undefined || value === null) { 
            return 'N/D';
        }

        return value + ' ' + suffix;

    }
}
