import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'trackingStatusColor'
})
export class TrackingStatusColorPipe implements PipeTransform {
    constructor() { }

    transform(status: any): string {
        switch (status) {
            case 'WAITING': return 'black'; //Creada      
            case 'IN_TRANSIT': return 'blue'; //Tránsito            
            case 'ON_DELIVERY': return 'darkblue';//En Entrega
            case 'DELIVERED': return 'green';//Entregada            
            case 'EXCEPTION': return 'red'; //Excepción
            case 'RETURNED': return 'darkred';//Devuelta
            case 'CANCELLED': return 'dimgrey';//Cancelada
            default: return 'grey';
        }
    };
} 