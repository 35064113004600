import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api.service';
import { Shipment, OnBoardingInfo, OnBoardingFileType, ResellerFile, ResellerFileToUpload } from 'src/app/core/models/models';
import { of } from 'rxjs';


@Injectable()
export class OnBoardingFileService {

    protected basePath = 'Reseller/file';

    constructor(private apiService: ApiService) { }

    /* OnBoardingInfo */
    getFileTypes(type = 'fisico'): Observable<OnBoardingFileType[]> {
        return this.apiService.get<OnBoardingFileType[]>(`${this.basePath}/type/${type}`);
    }

    downLoadFile(fileId: string): Observable<ResellerFile> {
        return this.apiService.get<ResellerFile>(`${this.basePath}/donwload/${fileId}`);
    }

    upLoadFile(file: ResellerFileToUpload): Observable<any> {
        return this.apiService.post<any>(this.basePath, file);
    }

    getAclarationFileTypes(type:any): Observable<OnBoardingFileType[]> {
        return this.apiService.get<OnBoardingFileType[]>(`${this.basePath}/getFileTypesByNumClass/${type}`);
    }

    upLoadAclarationFile(files: ResellerFileToUpload[]): Observable<any> {
        console.log('ResellerFileToUpload ->', files);
        return this.apiService.post<any>(`${this.basePath}/uploadAclarationFiles`, files);
    }
}
