import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthResellerOrHelpdeskGuard implements CanActivate {
  constructor(
    private _authSvc: AuthenticationService,
    private _router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._authSvc.user$.pipe(
      map(userState => {
        if (userState.authenticated && (userState.isReseller || userState.isHelpDesk) && this._authSvc.isAPIKeyValid('reseller-guard')) {
          return true;
        }

        if (userState.authenticated && isLoginUrl(this._router.url)) {
          // The user logged in with an unauthorized returlUrl, so navigate them to 'home'
          this._router.navigate(['/'], { queryParams: { returnUrl: state.url } });
        } else {
          this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        }

        return false;
      })
    );
  }
}

function isLoginUrl(url: string): boolean {
  return url.toLowerCase().startsWith('/login') || url.toLowerCase().startsWith('login');
}
