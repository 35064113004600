import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  templateUrl: './gradient-register-layout.component.html',
  styleUrls: ['./gradient-register-layout-component.scss']
})
export class GradientRegisterLayoutComponent {
  fulfillmentLogin = !!environment.fulfillmentLogin;
  fullfillment: boolean = false;
  country: string = environment.countryCode;
  environmentCode: string = environment.environmentCode;

  constructor(private router: Router) {
    if (this.router.url.includes('/fulfillment') || this.fulfillmentLogin) {
      this.fullfillment = true;
    } else {
      this.fullfillment = false;
    }
   }
}
