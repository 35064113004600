import { NgModule } from '@angular/core';
import { OnBoardingComponent } from './on-boarding.component';
import { OnBoardingService } from './service/on-boarding.service';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../shared/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { MatAutocompleteModule, MatTabsModule } from '@angular/material';
import { BussinessComponent } from './bussiness/bussiness/bussiness.component';
import { AddressFormModule } from '../address-form/address-form.module';

import { OnBoardingProfileComponent } from './on-boarding-profile/on-boarding-profile.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { OnBoardingFileService } from './service/on-boarding-file.service';
import { LaddaModule } from 'angular2-ladda';
import { PhoneVerificationModule } from '../shared/phoneVerification.module';
import { BillInfoComponent } from './bill-info/bill-info.component';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { NgSelectModule } from '@ng-select/ng-select';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ResellerCommonModule } from '../views-reseller/reseller-common/reseller-common.module';
import { TooltipModule } from 'primeng/tooltip';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    SharedModule,
    AddressFormModule,
    PhoneVerificationModule,
    MatAutocompleteModule,
    PhoneVerificationModule,
    SelectButtonModule,
    LaddaModule,
    ScrollPanelModule,
    NgSelectModule,
    TextMaskModule,
    NgxIntlTelInputModule,
    ResellerCommonModule,
    AutoCompleteModule,
    ReactiveFormsModule,
    MatTabsModule,
    TooltipModule
  ],
  declarations: [
    OnBoardingComponent,
    OnBoardingProfileComponent,
    BussinessComponent,
    BillInfoComponent
  ],
  providers: [
    OnBoardingService,
    OnBoardingFileService,
  ],
  exports: [
    OnBoardingComponent,
  ]
})
export class OnBoardingModule { }
