import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable()
export class DeprecatedModuleGuard implements CanActivate {
  CountryCode = environment.countryCode;

  constructor(
    private router: Router
  ) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.CountryCode == 'MX' || this.CountryCode == 'ES') {
      this.router.navigate(['/helpdesk']);
    }
    return true;
  }
}
