import { ConsigmentNote } from "src/app/shared/components/modals/consigment-note/models/consigment-note";
import { environment } from "src/environments/environment";

export class AuthUser {
  token: string;
  userId: string;
  resellerId: string;
  email: string;
  name: string;
  phone: string;
  password: string;
  confirm: string;
  ReferralCode: string;
  ReferralMedia: string;
  roles: Array<string>;
  ttl: number;
  fullfillment: boolean;
  acceptedTerms?: boolean;
  acceptedPersonalTreat?: boolean;
  numberShipment?: string;
  captchaToken?: string;
}

export class AccessTokenInfo {
  token: string;
  created: Date;
  roles: string[];
  ttl: number;
  user: string;
  ResellerGlobalConfig: resellerGlobalConfig;
}

export class User {
  id: string;
  username: string;
  email: string;
}

// TODO: Es una clase y no debería llevar nombre de función
export class resellerGlobalConfig {
  Orden_Compra: number; // TODO: No debería de llevar guíon bajo en un nombre de una variable
  ResellerId: string;
  ResellerGlobalConfigId: string;
  Batches: number;
  ShipmentGeneration: number;
  DontShowAgain?: boolean;
  DontShowAgainOnboarding?: boolean;
}

export class LoggedUserState {
  user: string;
  balance: number;
  paymentMode: string;
  balanceIsOutdated = false;
  authenticated = false;
  role: string;
  resellerGlobalConfig: resellerGlobalConfig = null;

  get isAdmin(): boolean {
    return this.hasRole('PLATFORM');
  }

  get isReseller(): boolean {
    return (this.hasRole('END_USER') || this.hasRole('END_USER_W') || this.hasRole('END_USER_R'));
  }

  get catEdit(): boolean {
    return (this.hasRole('END_USER') || this.hasRole('END_USER_W')) || this.hasRole('FULLFILLMENT');
  }

  get isHelpDesk(): boolean {
    return this.hasRole('HELP_DESK');
  }

  get isResellerOwner(): boolean {
    return this.hasRole('END_USER');
  }

  get isFullfillment(): boolean {
    return this.hasRole('FULLFILLMENT');
  }

  get isWriteUser(): boolean {
    return (this.hasRole('END_USER') || this.hasRole('END_USER_W'));
  }

  private hasRole(role: string): boolean {
    return this.role === role;
  }
}

export class ConfirmCredentials {
  uid: string;
  token: string;
}

export class Password {
  oldPassword: string;
  newPassword: string;
}

/* Common */
export class ISOState {
  code: string;
  name: string;
}

export class ZipCodeMunicipios {
  Municipio: string;
}

export class Countries {
  Code: string;
  Name: string;
  CallingCode: string;
}

export class Municipio {
  StateCode?: string;
  StateName?: string;
  Municipio?: string;

  dane?: string;
  daneShort?: string;
  departamento?: string;
  ciudad?: string;
}

export class AddressPickup {
  _id: string;
  zipcode: string;
  state: string;
  area: string;
  city: string;
  street: string;
  number: number;
  comment: string;
  dane?: string;
  dane_short?: string;
  country: string;
  constructor() {
    this._id = '';
    this.zipcode = '';
    this.state = '';
    this.area = '';
    this.city = '';
    this.street = '';
    this.number = 0;
    this.comment = '';
    this.dane = '';
    this.dane_short = '';
    this.country = '';
  }
}

// TODO: Es una clase y no debería llevar nombre de función
export class guidePickup {
  number_boxes: number;
  address: string;
  zipcode_origin: string;
  zipcode_destiny: string;
  constructor() {
    this.number_boxes = 0;
    this.address = '';
  }
}

export class Pickup {
  address: AddressPickup;
  guide: guidePickup;
  client: string;
  courrier: string;
  user: string;
  pickup_date: Date;
  pickup_hour: string;
  pickup_hour_end: string;
  contact_name: string;
  contact_phone: string;
  guide_comments: string;

  constructor() {
    this.address = new AddressPickup();
    this.guide = new guidePickup();
    this.client = '';
    this.courrier = '';
    this.user = '';
    this.pickup_date = new Date(Date.now());
    this.pickup_hour = '';
    this.pickup_hour_end = '';
    this.guide_comments = '';
  }
}

export class ZipCodeDetail {
  ZipCode: string;
  StateCode: string;
  StateName: string;
  City: string;
  Neighborhoods: Array<string>;
  Dane?: string;

  constructor() {
    this.Neighborhoods = new Array<string>();
  }
}

export class GuiaBase64 {
  data: string;
  TrackingNumber: string;
  LabelType: string;
}

export class CartaPorte64 {
  data: string;
  TrackingNumber: string;
}

export class ReportHistory {
  data: string;
}

export class Courier {
  CourierCode: string;
  CourierName: string;
  Name: string;
  Status: number;
  HasPickupActive: number;
  isDynamic: boolean;
}

export class CourierCustomAccount {
  CourierCode: string;
  Status: number;
}

export class CourierService {
  CourierServiceId: string;
  CourierCode: string;
  CourierServiceCode: string;
  Description: string;
  serviceMap: ServiceMap;
}

export class ServiceMap {
  PakkeServiceCode: string;
  CourierServiceId: string;
}

export class Pager {
  counter: number;
  page: number;
  pageSize: number;
}

/* Reseller */
export class Reseller {
  ResellerId: string;
  Name: string;
  Phone: string;
  Email: string;
  IsPhoneVerified: boolean;
  isEmailVerified: boolean;
  CreateCouriersAccounts: boolean;
  Type: number;
  PaymentMode: number;
  OwnerId: string;
  ReferralPercentageOwner: number;
  ReferralFixedOwner: number;
  ReferralPercentage: number;
  ReferralFixed: number;
  Code: string;
  SendRecipientNotifications: boolean;
  addresses: ResellerAddress[];
  maxAddresses?: number;
  parcels: ResellerParcel[];
  applications: ResellerApiKey[];
  billingInfo: ResellerBillingInfo = new ResellerBillingInfo();
  serviceConfigs: ResellerServiceConfig[];
  priceGroups: PriceGroup[];
  files?: ResellerLoadedFile[];
  TipoPersona?: string;
  resellerPriceGroup: any;
  recurringCharge: ResellerRecurringCharge;
  HasRecurringPayments: number;
  IdClientNext?: number;
}

export class ResellerGeneralInfo {
  ResellerId?: string;
  Name?: string;
  SendRecipientNotifications?: boolean;
  TipoPersona?: string;
}

export class NotificationConfig {
  PhoneNumber: string;
  Email: string;
  Webhook: string;
  IsSmsState: boolean;
  IsEmailState: boolean;
  IsWebhookState: boolean;
  IsWebhookShipGenerate: boolean;
  IsSmsShipGenerate: boolean;
  IsEmailShipGenerate: boolean;

  constructor() {
    this.PhoneNumber = '';
    this.Email = '';
    this.Webhook = '';
    this.IsSmsState = false;
    this.IsEmailState = false;
    this.IsWebhookState = false;
    this.IsWebhookShipGenerate = false;
    this.IsSmsShipGenerate = false;
    this.IsEmailShipGenerate = false;
  }
}

export class ResellerDetail {
  OwnerId: string;
  ResellerId: string;
  Name: string;
  Phone: string;
  Email: string;
  Code: string;
  ReferralCode: string;
  ReferralPercentageOwner: number;
  ReferralPercentage: number;
  ReferralFixedOwner: number;
  ReferralFixed: number;
  balance: ResellerBalance;
  balancebreakdown: ResellerBalanceBreakdown;
  billingInfo: ResellerBillingInfo;
  IsActive: boolean;
  IsGenerate : boolean;

  resellerPriceGroup: ResellerPriceGroup;

  constructor() {
    this.balance = new ResellerBalance();
    this.balancebreakdown = new ResellerBalanceBreakdown();
    this.billingInfo = new ResellerBillingInfo();
  }
}

export interface PriceGroup {
  PriceGroupResellerId: string;
  PriceGroupId: string;
  ResellerId: string;
  ModifiedBy: string;
  ModifiedDate: Date;
}

export interface ResellerPriceGroup {
  PriceGroupBaseId: string;
  ResellerId: string;
  UpdatedBy: string;
  UpdatedAt: string;
  priceGroupBase: PriceGroupBase;
}

export interface PriceGroupBase {
  PriceGroupId: string;
  Name: string;
}

export class ResellerBalance {
  Balance: number;
  PaymentMode: string;
}

export class ResellerBalanceBreakdown {
  PreviousBalance: number;
  DateBalancePrevious: Date;
  Charges: number;
  Payments: number;
  CurrentBalance: number;
  PaymentMode: number;
}

export class ResellerPaymentConfig {
  ResellerPaymentConfigId: string;
  ResellerId: string;
  WalletId: string;
  CustomerId: string;
  Active: boolean;
}

export class ResellerAddressResponse {
  deleteCount: number;
  maxAddresses: number;
  maxDelete: number;
  addresses: ResellerAddress[];
}
export class ResellerAddress {
  AddressId: string;
  ResellerId?: string;
  AddressAlias: string;
  ZipCode: string;
  State: string;
  City: string;
  Neighborhood: string;
  IsOriginAddress = true;
  IsDestinationAddress = true;
  Address1: string;
  Address2: string;
  CompanyName: string;
  ContactName: string;
  Phone1: string;
  Phone2: string;
  Email: string;
  Dane?: string;
  DaneShort?: string;
  Country = environment.countryCode;
  OrderId?: string;
  International? = environment.countryCode === 'ES';
  editCount?: number;
}

export class ResellerParcel {
  ParcelId: string;
  Name: string;
  Width: number;
  Length: number;
  Height: number;
  Default = false;
  CreatedAt: Date;
  UpdatedAt: Date;
  Active = true;
}

export class ResellerApiKey {
  restApiKey: string;
  status: string;
}

export class ResellerBillingInfo {
  BillingInfoId: number;
  ResellerId: string;
  TaxName: string;
  TaxId: string;
  ZipCode: string;
  State: string;
  City: string;
  Neighborhood: string;
  Address1: string;
  Email: string;
  Dane?: string;
  DaneShort?: string;

  PersonType = 'F';
  PaymentTermsCode: string;
  PaymentMethodCode: string;
  Regimen?: string;
  ClaveRegimen?: string;
  CFDIUseCode: string;

  UpdateAt?: Date;

}

export class ResellerNotificationConfig {
  ResellerNotificationId: string;
  PakkeEventTypeId: string;
  Channel: string;
  Value: string;
}

export class ResellerNotificationView {
  email: string;
  sms: string;
  webhook: string;
  swSmsException: boolean;
  swEmailException: boolean;
  swWebhookException: boolean;
  swWebhookGenerar: boolean;
  swSmsGenerar: boolean;
  swEmailGenerar: boolean;

  constructor() {
    this.swSmsException = false;
    this.swEmailException = false;
    this.swSmsGenerar = false;
    this.swEmailGenerar = false;
    this.swWebhookException = false;
    this.swWebhookGenerar = false;
  }
}

export class ResellerRechargeConfig {
  ResellerRechargeConfigId: string;
  WarningAmount: number;
  AutoRechargeOnLimit: boolean;
  LimitAmount: number;
  RechargeAmount: number;

  constructor() {
    this.AutoRechargeOnLimit = false;
    this.LimitAmount = 0;
    this.RechargeAmount = 0;
    this.WarningAmount = 0;
  }
}

/* Service Configs */

export class PakkeServiceConfig {
  PakkeServiceConfigId: string;
  CourierCode: string;
  PakkeServiceCode: string;
  CourierServiceCode: string;
  CourierName: string;
  CourierServiceName: string;
  FriendlyName: string;
  MinWeight: number;
  MaxWeight: number;
  Cost: number;
  OverWeightCost: number;
  Credentials: any;
}

export class ResellerServiceConfig {
  ResellerServiceConfigId: string;
  ResellerId: string;
  ServiceMapId: string;
  FriendlyName: string;
  Credentials: any;
  MinWeight: number;
  MaxWeight: number;
  Cost: number;
  OverWeightCost: number;
  CourierCode: string;
  CourierServiceCode: string;
  PakkeServiceCode: string;
}

export class FedexCredential {
  account_number: string;
  key: string;
  meter_number: string;
  password: string;
}

export class EstafetaCredential {
  customerNumber: string;
  login: string;
  password: string;
  officeNumber: string;
  serviceType: string;
  suscriberId: string;
}

/* Shipment */
export class ShipmentItem {
  ShipmentId: string;
  ResellerId: string;
  ShipmentType: number;
  CreatedAt: Date;
  ExpiresAt: Date;
  Clarification: any;
  DeliveredAt: Date;
  TransitAt: Date;
  CourierName: string;
  CourierCode: string;
  CourierServiceId: string;
  CourierService: string;
  ResellerReference: string;
  Content: string;
  Status: string;
  HasExceptions: boolean;
  HasLost: number;
  TrackingNumber: string;
  TrackingStatus: string;
  DaysInTransit: string;
  EnableRefund: number;
  InsuredAmount: number;
  Parcel: Parcel = new Parcel();
  QuotedWeight: number;
  RealWeight: number;
  RealOverWeight: number;
  CoveredWeight: number;
  OverWeight: number;
  OverWeightPrice: number;
  OriginalVolumetricWeight: number;
  OriginalWeight: number;
  OriginalHeight: number;
  OriginalLength: number;
  OriginalWidth: number;
  CostingPercentageAdjustment: number;
  CourierVolumetricWeight: number;
  CourierWeight: number;
  CourierHeight: number;
  CourierLength: number;
  CourierWidth: number;
  CoveredAmount: number;
  ExtrasAmount: number;
  CouponCode: string;
  DiscountAmount: number;
  Credentials: any;
  Dependencies: [ShipmentDependency];
  QuotedAmount: number;
  OverWeightCounterAmount: number;
  InsuranceAmount: number;
  TotalAmount: number;
  AddressFrom: Address = new Address();
  AddressTo: Address = new Address();
  Sender: AddressContact = new AddressContact();
  Recipient: AddressContact = new AddressContact();
  ReceivedAt: string;
  ReceivedBy: string;
  TrackingNumberReplaced: string;
  TrackingNumberReplaces: string;
  Owner: string;
  DispatchIndications: string;
  isCustomCredentials: boolean;
  Credential: {
    Description: string;
    CourierAccountId: string;
    Credentials: { [key: string]: any };
  };
  History: ShipmentHistory[];
  Incidents: ShipmentIncidents[];
  latitude: number;
  longitude: number;
  IsMultiPackage?: number;
  Packages?: MultiPackages[];

  // REFACTOR NEEDED: The tickets funcitonality is not well designed.
  // This property is a hook to the first ShipmentTicket record found
  ShipmentTicketId?: string;
  HasChangeZipCode: boolean;
  ChangeZipCode: any;
  transactions: ShipmentTransaction [];
  transactionsPending: ShipmentTransactionPending [];

  get HasExpired(): boolean {
    return this.ExpiresAt < new Date(Date.now()) && this.TrackingStatus !== 'RETURNED';
  }

  get ProofOfDeliveryUrl(): string {
    if (this.TrackingStatus !== 'DELIVERED') {
      return '';
    }

    switch (this.CourierCode) {
      case 'FDX': {
        return `https://www.fedex.com/trackingCal/retrievePDF.jsp?trackingNumber=${this.TrackingNumber}`
          + `&trackingCarrier=FDXE&locale=es_MX&type=SPOD`;
      }
      default: return '';
    }
  }
}

export class ShipmentDependency {
  ShipmentChild: string;
  Reason: string;
  ShipmentType: string;
  CreatedAt: Date;
}

export class Shipment {
  ShipmentId: string;
  CourierCode: string;
  CourierServiceId: string; // PakkeServiceCode
  CourierService: string;
  ResellerServiceConfigId: string; // For use with Reseller credential
  PaternGuide: string;
  ShipmentType: number;
  ReasonGuide: string;
  ResellerReference: string;
  InsuredAmount: number;
  SendRecipientNotifications: boolean;
  Content: string;
  CouponCode: string;
  AddressTo: Address;
  Recipient: AddressContact;
  OriginalHeight: number;
  OriginalWidth: number;
  OriginalLength: number;
  OriginalWeight: number;
  AddressFrom: Address;
  Sender: AddressContact;
  Parcel: any;
  detail: ShipmentDetail;
  CreatedAt: Date;
  Rate: ShipmentRate;
  TrackingNumber: string;
  TrackingStatus: string;
  HasExceptions: boolean;
  Label: string;
  Status: string;
  History: ShipmentHistory[];
  OrderId: string;
  isCustomCredentials: boolean;
  ResellerCourierAccountId: string;
  ResellerCourierServiceId: string;
  IsElectronic: boolean;
  International: boolean;
  ConsigmentNote?: ConsigmentNote;
  TypeShipping: string;
  Items?: any;
  IsFront: boolean;

  constructor() {
    this.AddressTo = new Address();
    this.Recipient = new AddressContact();
    this.AddressFrom = new Address();
    this.Sender = new AddressContact();
    this.Parcel = new Parcel();
    this.detail = new ShipmentDetail();
  }
}

export class ShipmentResume {
  ShipmentId: string;
  Selected = false;
  ResellerReference: string;
  TrackingNumber: string;
  CreatedAt: Date;
  ExpiresAt: Date;
  CourierCode: string;
  TrackingStatus: string;
  HasExceptions: boolean;
  Status: string;
  InsuredAmount: number;
  Weight: number;
  HasLost: number;
  DaysInTransit: number;
  Content: string;
  Parent?: any;
  Overweight?: number;
  OverweightFee?: number;
  LabelType?: string;
  Packages?: MultiPackages[];
  get HasExpired(): boolean {
    return this.ExpiresAt < new Date(Date.now());
  }
  IsMultiPackage?: number;
}

export interface MultiPackages {
  PackageId:       string;
  ShipmentId:      string;
  PackageParcel:   PackageParcel;
  PackageTracking: string;
}

export interface PackageParcel {
  Height:           string;
  Width:            string;
  Length:           string;
  Weight:           number;
  VolumetricWeight: number;
}

export class BatchResume {
  BatchId: string;
  ResellerId: string;
  Description: string;
  IsOpen = false;
  CreatedAt: Date;
  UpdatedAt: Date;
  NoBatch: string;
  TotalOrder: number;
  TotalOrderShipment: number;
}

export class BatchDetail {
  BatchId: string;
  ResellerId: string;
  Description: string;
  IsOpen = false;
  CreatedAt: Date;
  UpdatedAt: Date;
  NoBatch: string;
  TotalOrder: number;
  TotalOrderShipment: number;
  Orders: [Orden];
}

export class PickupResume {
  _id: string;
  shorter_id: string;
  courrier: string;
  Selected = false;
  pickup_date: Date;
  pickup_hour: string;
  created_date: Date;
}

export class ShipmentRateOptions {
  Pakke: ShipmentRate[];
  Reseller: ShipmentRate[];

  constructor() {
    this.Pakke = new Array<ShipmentRate>();
    this.Reseller = new Array<ShipmentRate>();
  }
}

export class ShipmentRate {
  BestOption: boolean;
  CourierCode: string;
  CourierName?: string;
  CourierServiceId: string;
  CourierServiceName: string;
  ClarificationPercent: number;
  DeliveryPercent: number;
  StorePercent: number;
  DeliveryDays: string;
  CouponCode: string;
  DiscountAmount: number;
  TotalPrice = 0;
  ShipmentAmount?: number;
  ShipmentSubtotalAmount?: number;
  ShipmentVatAmount?: number;
  InsuranceAmount?: number;
  InsuranceSubtotalAmount?: number;
  InsuranceVatAmount?: number;
  VatAmount?: number;
  EstimatedDeliveryDays: number;
  FriendlyName: string;
  ResellerServiceConfigId: string;
  OverWeightFrom: number;
  OverWeightPrice: number;
  isCustomCredentials: boolean;
  ResellerCourierAccountId: string;
  ResellerCourierServiceId: string;
  CityId?: number;
  CityName?: string;
  OnboardingCosting?: number;
  CourierScore?: number;
  Rank?: number;
  VolumetricWeight?: number;
  Kg?: number;
}

export interface GeneratedRate {
  selectedRate: ShipmentRate;
  rates: ShipmentRateOptions;
  quoteData: ShipmentQuoteRq;
  originAddress ?: ResellerAddress;
  destinationAddress ?: ResellerAddress;
  frequentParcel ?: ResellerParcel;
}

export class Address {
  ZipCode: string;
  State: string;
  City: string;
  Neighborhood: string;
  Address1: string;
  Address2: string;
  SaveAddress: boolean;
  AliasAddress: string;
  Dane?: string;
  DaneShort?: string;
  CityId?: number;
  CityName?: string;
  Country: string;
  International: boolean;
}

export class AddressContact {
  Name: string;
  CompanyName: string;
  Phone1: string;
  Phone2: string;
  Email: string;
}

export class Parcel {
  Length: number;
  Width: number;
  Height: number;
  Weight: number;
  VolumetricWeight?: number;
}

export class MultiPiece {
  Length: number;
  Width: number;
  Height: number;
  Weight: number;
  Reference: string;
}

export class ShipmentDetail {
  QuotedWeight: number;
  CoveredWeight: number;
  OverWeight: number;
  RealWeight: number;
  CoveredAmount: number;
  ExtrasAmount: number;
  TotalAmount: number;
  ReceivedAt: string;
  ReceivedBy: string;
  CourierWeight: string;
  CourierWidth: string;
  CourierLength: string;
  CourierHeight: string;
  CourierVolumetricWeight: string;
}

export class ShipmentQuoteRq {
  ZipCodeTo: string;
  ZipCodeFrom: string;
  DaneTo?: string;
  DaneShortTo?: string;
  DaneFrom?: string;
  DaneShortFrom?: string;
  InsuredAmount: number;
  CouponCode: string;
  Parcel: any;
  IsMultiPackage?: boolean;
  TypeService: string;
  TypeShipping: string;
  CityFrom?: string;
  CityTo?: string;
  StateFrom?: string;
  StateTo?: string;
  StreetFrom?: string;
  StreetTo?: string;
  International?: boolean;
  CountryFrom?: string;
  CountryTo?: string;
  resellerStoreId?: string;
  resellerReference?: string;
  SendRecipientNotifications?: boolean;
  ShipmentContent?: string;
  OrderId?: string;
}

export class ShipmentFilterUrlQuery {
  resellerId: string;
  courierCode: string;
  startDate: string;
  endDate: string;
  status: string;
  trackingStatus: string;
  hasExceptions: boolean;
  trackingNumber: string;
  executiveId: string;
  reference: string;
  pageNumber: number;
  pageSize: number;

  constructor() { } // TODO: Si no se usará, no debería de colocarse; internamente por default se levanta un constructor por ser una clase.
}

/* History */
export class ShipmentHistoryLocation {
  ZipCode: string;
  State: string;
  City: string;
}

export class ShipmentHistory {
  ShipmentHistoryId: string;
  ShipmentId: string;
  Details: string;
  Date: Date;
  Location = new ShipmentHistoryLocation();
}

export class ShipmentIncidents {
  ShipmentIncidentId: string;
  ShipmentId: string;
  Description: string;
  IndicentDate: Date;
}

export class Notification {
  ShipmentNotificationHistoryId: string;
  Event: string;
  Channel: string;
  Value: string;
  Date: Date;
}

/* Dashboard Metrics */
export class RegionMetric {
  StateCode: string;
  Transit: number;
  Exception: number;
  Returned: number;
  Delivered: number;
  Total: number;
}

export class PeriodMetric {
  OperationDate: Date;
  Created: number;
  Delivered: number;
}

/* Account Statement */

export class AccountMovement {
  Reference: string;
  ShipmentId: string;
  TrackerNumber: string;
  MovementType: number;
  Date: Date;
  Type: string;
  TypeId: number;
  Amount: number;
  Balance: number;
  Comments: string;
  Status?: string;
  PaymentReference?: string;
  PaymentType?: string;
}

/* Account Statement */

export class AccountMovementFF {
  Reference: string;
  ShipmentId: string;
  TrackerNumber: string;
  CourierCode: string;
  MovementType: number;
  Date: Date;
  Type: string;
  TypeId: number;
  Amount: number;
  Balance: number;
  Comments: string;
  OrderId: string;
  Folio: string;
}

/* Balance */
export class Balance {
  Payments: number;
  Charges: number;
  PendingCharges: number;
  DateBalancePrevious: string;
  PaymentMode: string;
  CurrentBalance: number;
  PreviousBalance: number;
  SaldoDespues: number;
  MaxAmount?:number;
}
 /* Cargos Pendientes */
 export class CargosPendientes {
  Courier: string;
  TrackingNumber: string;
  Amount: number;
  TransactionTypeId: number;
  MovementType: number;
  Description: string;
  Date: Date;
  ShipmentId: string;
}
/* Orden */
export class Orden {
  ShortId?: string;
  OrderId?: string;
  ResellerId?: string;
  StoreId: string;
  OrderIdStore: string;
  Status: string;
  StatusStore: string;
  Items: any;
  AddressTo: string;
  Recipient: string;
  Sender: string;
  AdressFrom: string;
  CreatedAt?: Date;
  CreatedAtStore?: Date;
  Archived_At?: Date;
  Archived_AtStore?: Date;
  Updated_At?: Date;
  Updated_AtStore?: Date;
  CustomShipment?: any;
  isCustomShipment?: boolean;
  Folio: string;
  Currency: string;
  Subtotal: number;
  Taxes: number;
  Extras: number;
  Discounts: number;
  Total: number;
  orderShipment?: any;
  ResellerStoreId: string;
  resellerStore?: any;
  selected?: boolean;
  validShipment?: boolean;
  validMessage?: string;
  BatchId ?: string;
  NoBatch ?: string;
  HasError ?: boolean;
  ErrorDetail ?: string;
  Actions?: any[];
  Parcel?: string;
  HasShipment?: string;
  IsFull = false;
  OrderStatusFullfillment?: string = '';
  OrderStatusHistory?: string = '';
  RefundFulfillment = false;
  TotalExtraPayment?: number = 0;
  IsExtraPayment?: boolean = false;
  ItemDefect: string;
  ErrorElementList?: any;
  InMiddleware?: boolean;
  FolioNext?: number = 0;
  orderDetailShipment?: any;
  validations: any;
  pendingGuides?: number;
  slowestGuide?: string;
  haveShipments?: boolean;
}
export class OrdenHistory {
  OrderHistoryId: string;
  OrderId: string;
  ResellerId: string;
  EventType: string;
  OrderJson: string;
  CreatedAt: string;
}

export class OrdenMessage {
  Orders: Orden[];
  Messages: any;
}

 /* Orden */
 export class OrderShipment {
  OrderShipmentId: string;
  OrderId: string;
  ShipmentId: string;
  Date: Date;
}

export interface OrdenFolioCheck {
  folio: string;
  resellerStoreId: string;
  orderId?: string;
}

/* Payments */
export class Payment {
  ResellerPaymentId: string;
  ResellerId: string;
  ResellerTransactionId: string;
  PaymentDate: Date;
  Amount: number;
  BankAutorizationNumber: string;
  Reference: string;
  Status: string;
  PaymentType: string;
  ErrorMessage: string;
  PaymentReference: string;
  DueDate: Date;
  urlReference?: string;
}

export class PaymentOrder {
  tokenId: string;
  amount: number;
  reference: string;
  paymentType: string; // card bank_account store
  resellerCardId: string;
  cardBin: string;
  cardTermination: string;
  cardToken: string;
  promoCode?: string;
}

export class PaymentOrderEtomin {
  tokenId: string;
  amount: number;
  reference: string;
  paymentType: string;
  paymentMethod: string;
  resellerCardId: string;
  addressId: string;
  installment: string;
  document: string;
  identity: string;
  bank: string;
  identityNumber: string;
  contact: string;
  dniNumber: string;
  taxNumber: string;
  cardBin: string;
  cardTermination: string;
  cardToken: string;
  promoCode?: string;
}

export class PaymentOrderPayPal {
  amount: number;
  reference: string;
  paymentType: string;
  intent: string;
  createTime: string;
  statusTransaction: string;
  email?: string;
  name?: string;
  orderID : string;
  paymentID : string;
  resellerPaymentId : string;
  objResult: string;
  promoCode?: string;
}

export class PaymentOrderMercadoPago {
  broker: string;
  type: string;
  email: string;
  amount: number;
  paymentMethodId: string;
  promoCode?: string;
  reference?: string;
  cardProvider?: string;
  issuerName?: string;
  maxAmountAllowed?: string;
  minAmountAllowed? : string;
  cardToken?: string;
  resellerCardId?: string;
  saveCard: boolean;
}

export class SaveCardMercadoPago {
  email: string;
  paymentMethodId: string;
  cardProvider: string;
  cardToken: object;
}
export class ResellerCard {
  ResellerCardId: string;
  ResellerId: string;
  CustomerId: string;
  CardId: string;
  CardNumber: string;
  addressId: string;
  ClientId: string;
}

export class DomiciliationData {
  cif: string;
  name: string;
  noTelefono: number;
  razonSocial: string;
  address1: string;
  email: string;
  zipCode: string;
  bankName: string;
  iban: string;
  bic: string;
  city: string;
}

/* Tickets */
export class ShipmentTicket {
  ShipmentTicketId: string;
  ShipmentId: string;
  AssignedExecutiveId: string;
  Active: boolean;
  CreatedDate: Date;
}

export class ShipmentTicketEntry {
  ShipmentTicketEntryId: string;
  ShipmentTicketId: string;
  Comments: string;
  CreatedDate: Date;
  CreatedBy: string;
}

export interface ResellerSearchFilter {
  name?: string;
  email?: string;
  phone?: string;
  balanceState: ResellerBalanceFilterOptions;
  pageNumber: number;
  pageSize: number;
}

export enum ResellerBalanceFilterOptions {
  All = null,
  Negative = 1,
  Positive = 2
}

export enum TransactionType {
  Unknown = 0,
  ShipmentFee = 1,
  ExtraweightFee = 2,
  ReturnFee = 3,
  Payment = 4,
  Refund = 5
}

export enum MovementType {
  Unknown = 0,
  Charge = 1,
  Payment = 2
}

export enum ConnectionMessages {
  AlreadyConnected = 'La tienda ya está conectada',
  Success = 'La tienda se conectó correctamente',
}

export interface PagingInfo {
  pageNumber: number;
  pageSize: number;
  startRecordIndex: number;
  endRecordIndex: number;
}

export interface PagedResult<T> {
  records: T[];
  totalItems: number;
}

export interface ShipmentTransaction {
  id: string;
  Amount: number;
  Date: Date;
  MovementType: MovementType;
  Status: number;
  Type: string;
  TypeId: number;
  Refunded: boolean;
}

export interface ShipmentTransactionPending {
  id: string;
  Amount: number;
  Date: Date;
  MovementType: MovementType;
  Status: number;
  Type: string;
  TypeId: number;
  Refunded: boolean;
  applied: boolean;
}

 /* Stores */
export interface Store {
  StoreId: string;
  Name: string;
  Status: boolean;
  CreatedAt: Date;
  Updated_At: Date;
  image: string;
  Config?: string;
}

 /* Stores */
 export interface ResellerStore {
  ResellerStoreId?: string;
  ShortId: string;
  ResellerId: string;
  StoreId: string;
  Config?: any;
  Status: string;
  CreatedAt: Date;
  Updated_At: Date;
  Inactivity_Counter: number;
  Canceled_At: Date;
  TotalOrders?: number;
  OrdersSent?: number;
  OrdersNotSent?: number;
  RedirectURL: string;
  address: ResellerAddress;
  AddressId: string;
  Name?: string;
  isReconnect?: boolean;
  LocationId: string;
  UpdateStock: boolean;
  Marketplace?: boolean;
  StartDate?:Date;
  PreferencesSettings?: any;
  stores?: { MarketPlace: boolean };
}
export class OrderItem {
  ItemId: number;
  SKU: number;
  ItemName: string;
  ItemQuantity: number;
  ItemUnitPrice: number;
  ItemTotalPrice: number;
}
export class ItemRequest {
  ItemStoreId: number;
  SKU: string;
  UPC: string;
  Name: string;
  Width: number;
  Length: number;
  Height: number;
  Weight: number;
  Quantity: number;
  UnitPrice: number;
  TotalPrice: number;
  ItemId:string;
  TypeProceso:number; //1: Manual,2: Carga masiva -- Aplica para Full360
  FullfillmentFileId:string;
  File: string;
}

export class TransactionReport {
  year: string;
  week: string;
}

export class BulkOrderResponse {
  folio: string;
  position: string;
  detail: string;
  currentOrder: Orden;
}

export class LocationSKU {
  itemId: number;
  name: string;
  available: number;
  location_id: number;
  sku: string = '';
  locationName: string;
  productId: string;
  imageStore: string;
  resellerStoreId: string;
  storeId: string;
  skuPakke: string;
  updateSKU: boolean;
  status:number;
  width: number;
  height: number;
  depth: number;
  weight: number;
  diameter: number;
  length: number;
  description :  string;
  upc: string;
  variantId: string;
}


/**
 * Tracking interfaces
 */

export interface ITrackingInformation {
  orderId: string;
  trackingNumber: string;
  courierCode: string;
  storeId: string;
}

export class CustomIcon {
  path: string;
  name: string;
}

export interface RateShippingBase {
  token: string;
  score: number;
}

export interface FolioHistory {
  CourierServiceId: string;
  Folio: string;
  Estatus: string;
  Description: string;
}

/** FULLFILLMENT **/
export interface ItemsFullfillment {
  FullfillmentItemId: string;
  FullfillmentCategoryId: string;
  FullfillmentTagId: string;
  NameCategory: string;
  Category: string;
  ResellerId: string;
  SKU: string;
  Name: string;
  IsActive: boolean;
  CeateAt: Date;
  Description: string;
  Disposition: number;
  InStrore: number;
  InPurchaseOrder: number;
  UPC: string;
  PartNumber: string;
  Weigth: number;
  Witdth: number;
  Length: number;
  Hight: number;
  FullfillmentFileId: string;
  FileType: string;
  File: string;
  AsigOrder: number;
  UnitOfMeasure: string;
  InList:boolean;
}

export class ItemRequestFullfillment {
  FullfillmentItemId?: string = '';
  FullfillmentCategoryId: string;
  FullfillmentTagId: string;
  SKU: string;
  Name: string;
  Description: string;
  UPC = '';
  PartNumber = '';
  Weigth = 0;
  Witdth = 0;
  Length = 0;
  Hight = 0;
  UnitOfMeasure: string;
}
export class ItemFullfillment {
  FullfillmentCategoryId: string;
  FullfillmentTagId: string;
  SKU: string;
  Name: string;
  Description: string;
  UPC = '';
  PartNumber = '';
  Weigth = 0;
  Witdth = 0;
  Length = 0;
  Hight = 0;
  ProductId = '';
  FullfillmentOrderItemId = '';
  StoreId = '';
  VariantId = '';
  ResellerStoreId='';
  InventoryId='';
}
export class FullfillmentFile {
  file = '';
  fileType = '';
  name = '';
}

export interface LocationStore {
  LocationId: string;
  Name: string;
  Addres: string;
  City: string;
  Zip: string;
  Phone: string;
}

// TODO: Sin ninguna funcionalidad
export class FullfillmentShipping {

}

export class FullfillmentCategory {
  FullfillmentCategoryId: string;
  Name: string;
  IsActive: boolean;
  CreatedAt: string;
}

export class ItemByFilter {
  ResellerId: string;
  Name:  string;
  Description: string;
  SKU: string;
  StartDate: string;
  EndDate: string;
  Status: string;
}

/**Kardex */
export class ItemKardex {
  FullfillmentKardexId: string;
  CreatedAt: string;
  SKU: string;
  StatusIn: string;
  StatusOut: string;
  Quantity: string;
  TotalExistence: string;
  Commentary: string;
  Observations: string;
  OrderId: string;
  Selected = false;
  Name: string;
  Category: string;
  FolioNext: number;
}

// TODO: Es una clase y no debería llevar nombre de función
export class kardexByFilter {
  FullfillmentItemId: string;
  Movement: string;
  StartDate: string;
  EndDate: string;
  InStatus: string;
  OutStatus: string;
}

/**Merchandise */
export class Merchandise {
  FullfillmentCategoryId: string;
  SKU: string;
  FullfillmentItemId: string;
  Name: string;
  Quantity: number;
}

export class ListMerchandise {
  FullfillmentMerchandiseId: string;
  FullfillmentItemId: string;
  Category: string;
  SKU: string;
  Name: string;
  QuantityReseller: number;
  QuantityLogistic: number;
  Status: string;
  CreatedAt: string;
  Selected = false;
  IsActive = false;
}

export class MerchandiseByFilter {
  Name:  string;
  SKU: string;
  StartDate: string;
  EndDate: string;
  StatusByFilter: string;
  CategoryByFilter: string;
}

export class ItemMapping {
  constructor() {
    this.ItemStoreId = '';
    this.SKU = '';
    this.Name = '';
    this.UPC = null;
    this.Length = null;
    this.Height = null;
    this.Width = null;
    this.Weight = null;
    this.Quantity = 1;
    this.UnitPrice = 0;
    this.TotalPrice = 0;
  }

  ItemStoreId: string;
  SKU: string;
  Name: string;
  UPC?: string;
  Length?: number;
  Height?: number;
  Width?: number;
  Weight?: number;
  Quantity: number;
  UnitPrice: number;
  TotalPrice: number;
}

  export class ResellerItemMapping {
  constructor() {
    this.ItemStoreId = '';
    this.SKU = '';
    this.Name = '';
    this.UPC = '';
    this.Quantity = 1;
    this.UnitPrice = 0;
    this.TotalPrice = 0;
    this.Length = 0;
    this.Width = 0;
    this.Height = 0;
    this.Weight = 0;
  }

  ItemStoreId: string;
  SKU: string;
  Name: string;
  UPC: string;
  Quantity: number;
  UnitPrice: number;
  TotalPrice: number;
  Length: number;
  Width: number;
  Height: number;
  Weight: number;
}

export class OrderMapping {
  IsBulkCollect: boolean;
  ResellerStoreId: string;
  ShortId: string;
  StoreId: string;
  OrderIdStore: string;
  Folio: string;
  Status: string;
  StatusStore: string;
  Items: ItemMapping[];
  AddressTo: Address;
  Recipient: AddressContact;
  Sender: AddressContact;
  AddressFrom: Address;
  Subtotal: number;
  Taxes: number;
  Discounts: number;
  Extras: number;
  Total: number;
  Currency: string;
  Parcel: Parcel;
  ErrorMsg: object;
  rawErrorList?: string[];
  internalId?: number;
  IsFull = false;
}

export interface OnBoardingInfo {
  completePercentage: number;
  email: boolean;
  phone: boolean;
  profile: boolean;
  balance: boolean;
  business: boolean;
  status: string;
  isDuplicatePhone: boolean;
  verificationLimit?: string;
  isVIPVerification?:boolean;
  startVerification?:boolean;
  isPhoneVerified?: boolean;
  isEmailVerified?: boolean;

  // TODO: Deprecado?
 /* businessAddress: boolean;
  order: boolean; */
}

export interface OnBoardingFileType {
  ResellerFileTypeId: string;
  Name: string;
  Class: number;
}

export interface ResellerLoadedFile {
  ResellerFileId: string;
  ResellerFileType: string;
  Created: Date;
  Mime: string;
  Name: string;
  Validated: boolean;
  Invalid: boolean;
}

export interface ResellerFile {
  ResellerFileId: string;
  ResellerFileType: string;
  Mime: string;
  Name: string;
  File: string;
}

export interface ResellerFileToUpload {
  file: string;
  fileType: string;
  name: string;
}

export enum LayoutBulkcollect {
  FOLIO = 'FOLIO_ORDEN',
  NAME_TO = 'NOMBRE_DESTINATARIO',
  EMAIL_TO = 'CORREO_DESTINATARIO',
  PHONE_TO =	'TELEFONO_DESTINATARIO',
  COMPANY_TO = 'COMPANIA_DESTINATARIO',
  ZIP_CODE_TO = 'CP_DESTINATARIO',
  MUNICIPALITY_TO =	'MUNICIPIO_DESTINATARIO',
  COLONY_TO = 'COLONIA_DESTINATARIO',
  STATE_TO = 'ESTADO_DESTINATARIO',
  STREET_NUMBER_TO =	'CALLE_NUMERO_DESTINATARIO',
  REFERENCE_TO = 'REFERENCIA_DESTINATARIO',
  WEIGHT =	'PESO',
  WIDTH= 'ANCHO',
  HEIGHT= 'ALTO',
  LENGTH= 'LARGO',
  PRODUCT = 'PRODUCTO',
  SKU = 'SKU',
  QUANTITY = 'CANTIDAD',
  UNIT_PRICE = 'PRECIO_UNITARIO'
}

export enum ResellerLayoutBulkcollect {
  FOLIO = 'FOLIO_ORDEN',
  NAME_TO = 'NOMBRE_DESTINATARIO',
  EMAIL_TO = 'CORREO_DESTINATARIO',
  PHONE_TO =	'TELEFONO_DESTINATARIO',
  COMPANY_TO = 'COMPANIA_DESTINATARIO',
  ZIP_CODE_TO = 'CP_DESTINATARIO',
  MUNICIPALITY_TO =	'MUNICIPIO_DESTINATARIO',
  COLONY_TO = 'COLONIA_DESTINATARIO',
  STATE_TO = 'ESTADO_DESTINATARIO',
  STREET_NUMBER_TO =	'CALLE_NUMERO_DESTINATARIO',
  REFERENCE_TO = 'REFERENCIA_DESTINATARIO',
  WEIGHT =	'PESO',
  WIDTH= 'ANCHO',
  HEIGHT= 'ALTO',
  LENGTH= 'LARGO',
  UPC= 'UPC',
  PRODUCT = 'PRODUCTO',
  SKU = 'SKU',
  QUANTITY = 'CANTIDAD',
  UNIT_PRICE = 'PRECIO_UNITARIO'
}

export enum LayoutBulkcollectCO {
  FOLIO = 'FOLIO_ORDEN',
  NAME_TO = 'NOMBRE_DESTINATARIO',
  EMAIL_TO = 'CORREO_DESTINATARIO',
  PHONE_TO =	'TELEFONO_DESTINATARIO',
  COMPANY_TO = 'COMPANIA_DESTINATARIO',
  ZIP_CODE_TO = 'CP_DESTINATARIO',
  MUNICIPALITY_TO =	'CIUDAD_DESTINATARIO',
  COLONY_TO = 'INF_ADICIONAL_DESTINATARIO',
  STATE_TO = 'DEPARTAMENTO_DESTINATARIO',
  STREET_NUMBER_TO =	'CALLE_NUMERO_DESTINATARIO',
  REFERENCE_TO = 'REFERENCIA_DESTINATARIO',
  WEIGHT =	'PESO',
  WIDTH= 'ANCHO',
  HEIGHT= 'ALTO',
  LENGTH= 'LARGO',
  UPC= 'UPC',
  PRODUCT = 'PRODUCTO',
  SKU = 'SKU',
  QUANTITY = 'CANTIDAD',
  UNIT_PRICE = 'PRECIO_UNITARIO'
}

export enum itemBulkcollect {
  SKU = 'SKU',
  NAME = 'NOMBRE',
  BARCODE = 'UPC',
  PART_NUMBER =	'NUMERO_PARTE',
  DESCRIPTION = 'DESCRIPCION',
  WIDTH= 'ANCHO',
  HEIGHT= 'ALTO',
  LENGTH= 'LARGO'
}

export enum itemBulkPurchase {
  ID_PROVEEDOR = 'ID_PROVEEDOR',
  SKU = 'SKU',
  CANTIDAD = 'CANTIDAD',
  FECHA_ENTREGA =	'FECHA_ENTREGA(DD-MM-YYYY)',
}

export enum itemBulkcollectSeller {
  SKU = 'SKU',
  NAME = 'NOMBRE',
  BARCODE = 'UPC',
  WIDTH= 'ANCHO',
  HEIGHT= 'ALTO',
  LENGTH= 'LARGO'
}
export class PurchaseOrdens {
  FullfillmentPurchaseOrderId = '';
  Folio = 0;
  DeliveryDate: string;
  ProviderName = '';
  IsActive: boolean;
  QuantityReseller = 0;
  QuantityLogistic = 0;
  ItemsTotal = 0;
  Status = '';
  LogisticProvider = '';
  IsTemporaryFolio = 1;
}

export class PurchaseOrdensItem {
  FullfillmentItemId = '';
  Name = '';
  SKU = '';
  QuantityReseller = 0;
  QuantityLogistic = 0;
  QuantityUpdate = 0;
  Updated = 0;
  FullfillmentFileId = '';
  FileType = '';
  File = '';
  UPC = '';
}

export interface ProviderFullfillment {
  FullfillmentProviderId: string;
  ResellerId: string;
  Name: string;
}
export interface CategoryFullfillment {
  FullfillmentCategoryId: string;
  ResellerId: string;
  Name: string;
}

// TODO: Es una clase y no debería llevar nombre de función
export class newProdiverFullfillment {
  FullfillmentProviderId = '';
  ProviderId = 0;
  Name  = '';
  RFC  = '';
  ContactName  = '';
  Phone  = '';
  Emaill  = '';
  Address  = '';
  Suburb  = '';
  IsActive = false;
}

// TODO: Es una clase y no debería llevar nombre de función
export class newCategoryFullfillment {
  FullfillmentCategoryId = '';
  Name  = '';
  IsActive = false;
}

// TODO: Es una clase y no debería llevar nombre de función
export class newTagFullfillment {
  FullfillmentTagId = '';
  Name  = '';
  IsActive = false;
}

export class StoreFulfillment {
  ResellerStoreId = '';
  StoreId = '';
  Name  = '';
}

export class StoreBySKU {
  FullfillmentStoreItemId = '';
  ResellerStoreId = '';
  StoreId = '';
  Name = '';
  SKU  = '';
}

export class FullfillmentTag {
  FullfillmentTagId: string;
  Name: string;
  IsActive: boolean;
  CreatedAt: string;
}

export class DashboardFulfillment {
  Status: [];
  OrderGraphy: [OrderGraphys];
  OrderByCourier: [];
  OrderByStore: [];
  TotalAmount: [];
  Utility = 0;
  AvgPrepare = 0;
  TopFiveItem: [Top5Item];
  OrderStatus: [OrderStatus];
  AvgOrderOpen = 0;
  AvgOrderComplete = 0;
  errorCode = 0;
  message = '';
  OrderContry: [OrderContry];
  GrapycInventory: [];
  DashboardStock: [];
  DashboardTotalStock: [];
  DashboardTotalNotStock: [];
}

export class OrderGraphys {
  TypeOrder: string;
  TotalOrder: number;
  OrderDate: string;
}

export class Top5Item {
  FullfillmentItemId: string;
  SKU: string;
  Name: string;
  Quantity: number;
  TotalPrice: number;
  File: string;
  FileType: string;
}

export class OrderStatus {
  PendingStocked = 0;
  PendindShipment = 0;
}

export class OrderContry {
  StateName = '';
  id = '';
  value = 0;
  IdContry = '';
}

export class GrapycInventory {
  Quantity = 0;
  Total = 0;
  CreatedAt: string;
}

export class DashboardTotalStock {
  SKU: string;
  Name: string;
  Quantity = 0;
  Disposition = 0;
}

export class DashboardTotalNotStock {
  SKU: string;
  Name: string;
  QuantityPurchaseOrder = 0;
  DispositionOrder = 0;
}

export class Return {
  FullfillmentOrderReturnId: string;
  FolioReturn: string;
  FolioOrder: string;
  RMA: string;
  OrderId: string;
  Status: string;
  CreatedAt: string;
  QuantityRetun: string;
  QuantityRetunAcept: string;
  TrackingNumber: string;
  ShipmentId: string;
  TypeReturn: string;
}

export class ReturnDetail {
  Return: Return;
  ReturnDetail: any;
  ReturnTransaction: any;
  OriginShipmentDetail: any;
}

export class ItemReturn {
  FullfillmentItemId: string;
  ItemId: string;
  Name: string;
  SKU: string;
  QuantityDisposition: number;
  QuantityReturn: number;
}

// TODO: Es una clase y no debería llevar nombre de función
export class getEmail{
  statusCode: number;
  message: string;
  id: string;
}

export class GlobalConfigData {
  statusCode: number;
  message: string;
  ConfigData: ConfigData;
}

export class ConfigData {
  ConfigName: string;
  ConfigBody: ConfigBody;
}

export class ConfigBody {
  urlToDesktop: string;
  urlToMobile: string;
}

export class OrderStatusFulfillment {
  value: string;
  title: string;
  type: string;
}

export class PaymentTerms {
  key: string;
  description: string;
}

export class PaymentMethod {
  key: string;
  description: string;
}

export class CFDIuse {
  key: string;
  description: string;
}

export class ResellerItem {
  ResellerItemId: string;
  ResellerId: string;
  SKU: string;
  Name: string;
  UPC: string;
  Weight: number;
  Width: number;
  Length: number;
  Hight: number;
  IsActive: boolean;
  ParcelId: string;
  parcel: string;
}
export class AddressFormValidators {
  ContactName: boolean = true;
  Phone1: boolean = true;
  ZipCode: boolean = true;
  State: boolean = true;
  Country: boolean = true;
  Address1: boolean = true;
  Neighborhood: boolean = true;
}

export class ShipmentData {
    originAddress: ResellerAddress;
    destinationAddress: ResellerAddress;
    frequentParcel: ResellerParcel;
    isInternational: boolean;
    selectCountryFrom: boolean;
    selectCountryTo: boolean;
    zipCodeFrom: any;
    zipCodeTo: any;
    weight: any;
    height: any;
    width: any;
    length: any;
    addInsurance: any;
    InsuredAmount: any;
    countryFrom: string;
    countryTo: string;
    cityFrom: any;
    cityTo: any;
    existsFrecuentOriginAddress: boolean;
    existsFrecuentDestinationAddress: boolean;
    pieces?: {
      Weight: number;
      Height: number;
      Width: number;
      Length: number;
      Reference: string
    }[];
}
export class FromShipmentQuoteData {
  weight: string = null;
  height: string = null;
  width: string = null;
  length: string = null;
  addInsurance: boolean = false;
  insuredAmount: string = null;
  existsData: boolean = false;
  destroyData: boolean= false;
}

export interface ActionCondition {
  Id: string;
  Type: string;
  Condition: string;
  Value: string;
}

export interface ResellerStoreAction {
  ResellerStoreActionId: string;
  ResellerStoreId: string;
  Conditions: ActionCondition[];
  CourierCode?: string;
  CourierServiceId?: string;
  Order?: number;
  Type?: string;
  CreatedAt?: string;
  UpdatedAt?: string;
}

// ORDERS BULK MASSIVE FOR FULLFILLMENT GETDETAILS
export interface BulkUploadProgress {
  WebhookProcessId: string;
  ProcessName: string;
  StatusWebhook: BulkUploadProgressStatus;
  TotalToProcess: number;
  StartDate: string;
  EndDate: string;
  ResellerId: string;
  Result: string;
}

export interface BulkUploadProgressResult {
  WebhookProcessId: string;
  ProcessName: string;
  StatusWebhook: BulkUploadProgressStatus;
  TotalToProcess: number;
  StartDate: string;
  EndDate: string;
  ResellerId: string;
  Result: { orderList: string[], orderListWithError: { folio: string, statusError: string }[] };
}
export enum BulkUploadProgressStatus {
  IN_PROCESS = 'IN_PROCESS', CANCELED = 'CANCELED', ERROR = 'ERROR', FINISH = 'FINISH', SEEN = 'SEEN'
}

// BULK VALIDATION FULLFILLMENT
export interface MassiveOrderValidation {
  dataToMassive: OrderMapping[];
  withOutFolio:  number;
  onLoad:        number;
  isValid:       boolean;
}

// BULK SAVE FULLFILLMENT
export class OrderIntoBulkUploadRequest {
  resellerStoreId: string;
  folio: string;
  items: { sku: string, quantity: number, unitPrice?: number, totalPrice?: number }[];
  addressTo: { address1: string, address2?: string, city: string, neighborhood: string, state: string, zipCode: string };
  recipient: { name: string, companyName?: string, phone: string, email: string };
  subtotal?: number;
  extras?: number;
  discounts?: number;
  taxes?: number;
}
export class BulkUploadRequest {
  urlWebhook: string;
  listOrders: OrderIntoBulkUploadRequest[];
}

// ORDERS (NEW FULLFILLMENT)
export interface OrdersF360 {
  orders: OrdersF360info[];
  total: number;
  actualPage: number;
  limit: number;
}
export interface OrdersF360info {
  orderId: string;
  storeId: string;
  folio: string;
  createdAtStore: string;
  orderStatus: OrderStatusF360;
  paymentStatus: PaymentStatusF360;
  orderHierarchy: OrderHierarchyF360;
}

export interface OrderStatusF360 {
  number: number;
  description: string;
  warning: boolean;
  percentageShipment: number;
}

export interface PaymentStatusF360 {
  number: number;
  description: string;
}

export interface OrderHierarchyF360 {
  active: boolean;
  isParent: boolean;
}

export interface RegimesCatalogResponse {
  code: number;
  message: string;
  data: RegimesCatalog[]
}

export interface RegimesCatalog {
  clave: string;
  description: string;
}

// DYNAMIC CUSTOM COURIERS
export interface CustomCouriersFormResponse {
  code: number;
  message: string;
  data: CustomCouriersForm[];
}

export interface CustomCouriersForm {
  label: string;
  formControl: string;
  type: string;
  inputIcon: string;
  validators: CustomCouriersValidators;
}

export interface CustomCouriersValidators {
  maxLength?: number;
  minLength?: number;
  max?: number;
  min?: number;
}


export class ResellerRecurringCharge {
  amount : number;
  minAmount : number;
  status : string;
}

// PROMO CODE DATA
export class PromoCodeDataResponse {
  code: number;
  message: string;
  data: PromoCodeData;
}

export class PromoCodeData {
  type: string;
  percent?: number;
  dateAmounts?: number;
  minAmount?: number;
  maxAmount?: number;
  shipmentsPromo?: number;
}
