import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import * as JSZip from 'jszip';
import { parse as json2csv } from 'json2csv';
import { json2csv as json2csvNamespace } from 'json2csv';

@Injectable()
export class FileUtilService {
  saveBase64PngFile(fileName: string, base64Data: string): Promise<any> {
    return fetch('data:image/png;base64,' + base64Data)
      .then(res => {
        if (!res.ok) {
          throw new Error('Ocurrió un error al guardar el archivo');
        }

        return res.blob();
      })
      .then(blob => this.saveFile(blob, fileName));
  }

  SaveBase64PDFNewFile(fileName: string, base64Data: string): Promise<any> {
    if(typeof base64Data == "object")
      throw new Error(JSON.parse(JSON.stringify(base64Data)).message);
    else{
      return fetch('data:application/pdf;base64,' + base64Data)
        .then(res => {
          if (!res.ok) {
            throw new Error('Ocurrió un error al guardar el archivo');
          }

          return res.blob();
        })
        .then(blob => this.saveFile(blob, fileName));
    }
  }

  SaveByteArrayZPLNewFile(fileName: string, base64Data: string): Promise<any> {
    if (typeof base64Data === 'object') {
      throw new Error(JSON.parse(JSON.stringify(base64Data)).message);
    } else {
      return fetch('data:application/x-lbl;base64,' + base64Data)
        .then(res => {
          if (!res.ok) {
            throw new Error('Ocurrió un error al guardar el archivo');
          }

          return res.blob();
        })
        .then(blob => this.saveFile(blob, fileName));
    }
  }

  /**
   * Descargar la etiqueta en un archivo con formato diferente a PDF
   * @param filename
   * @param text
   * @see PAK3804
   */
  downloadLabelByEncode(filename, text) {

    try{
      let element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', filename);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }catch(error){
      console.error('Error general: '+JSON.stringify(error));
      return false;
    }
    return true;
  }

  saveZipOfBase64PdfFiles(
    pdfFiles: { fileName: string, base64Data: string }[],
    zipFileName: string
  ): Promise<any> {
    const zip = new JSZip();
    const pdfPromises = pdfFiles.map(file =>
      fetch('data:data:application/pdf;base64,' + file.base64Data)
        .then(data => zip.file(sanitizeFileName(file.fileName), data.blob()))
    );

    return Promise.all(pdfPromises)
      .then(() => zip.generateAsync({ type: 'blob' }))
      .then(zipBlob => this.saveFile(zipBlob, zipFileName));
  }

  saveCollectionAsCsvFile<T>(
    data: Readonly<T>,
    fileName: string,
    fields?: Array<string | json2csvNamespace.FieldInfo<T>>
  ) {
    const options = { fields: fields };
    const csv = json2csv(data, options);

    this.saveFile(new Blob(['\ufeff' + csv]), fileName);
  }


  saveFile(blob, fileName) {
    return saveAs(blob, sanitizeFileName(fileName));
  }

  saveZipOfBase64Files(files: { fileNameWithExtension: string, base64Data: string, mime: string }[], zipFileName: string): Promise<any> {
    const zip = new JSZip();
    const pdfPromises = files.map(file =>
      fetch(`data:data:${file.mime};base64,` + file.base64Data)
        .then(data => zip.file(sanitizeFileName(file.fileNameWithExtension), data.blob()))
    );
    return Promise.all(pdfPromises)
      .then(() => zip.generateAsync({ type: 'blob' }))
      .then(zipBlob => this.saveFile(zipBlob, zipFileName));
  }
}

function sanitizeFileName(fileName: string): string {
  return fileName.replace(/[\\/:*?"<>|]/g, '');
}
