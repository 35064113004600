/*
 Copyright 2015 Logentries.
 Please view license at https://raw.github.com/logentries/le_js/master/LICENSE
*/
'use strict';(function(b,e){"function"===typeof define&&define.amd?define(function(){return e(b)}):"object"===typeof exports?("object"===typeof global&&(b=global),module.exports=e(b)):b.LE=e(b)})(this,function(b){function e(a){var c=a.trace?(Math.random()+Math.PI).toString(36).substring(2,10):null,q=a.page_info,e=a.token,g=a.print,f=a.no_format,r;r="undefined"===typeof XDomainRequest?a.ssl:"https:"===b.location.protocol?!0:!1;var k;k=b.LEENDPOINT?b.LEENDPOINT:f?"webhook.logentries.com/noformat":"js.logentries.com/v1";
k=(r?"https://":"http://")+k+"/logs/"+e;var h=[],l=!1,s=!1;if(a.catchall){var t=b.onerror;b.onerror=function(a,b,d){m({error:a,line:d,location:b}).level("ERROR").send();return t?t(a,b,d):!1}}var p=function(){var a=b.navigator||{doNotTrack:void 0},c=b.screen||{};return{url:(b.location||{}).pathname,referrer:document.referrer,screen:{width:c.width,height:c.height},window:{width:b.innerWidth,height:b.innerHeight},browser:{name:a.appName,version:a.appVersion,cookie_enabled:a.cookieEnabled,do_not_track:a.doNotTrack},
platform:a.platform}},u=function(){var a=null,a=Array.prototype.slice.call(arguments);if(0===a.length)throw Error("No arguments!");return a=1===a.length?a[0]:a},m=function(a){var b=u.apply(this,arguments),d={event:b};"never"===q||s&&"per-entry"!==q||(s=!0,"undefined"===typeof b.screen&&"undefined"===typeof b.browser&&m(p()).level("PAGE").send());c&&(d.trace=c);return{level:function(a){if(g&&"undefined"!==typeof console&&"PAGE"!==a){var b=null;"undefined"!==typeof XDomainRequest&&(b=d.trace+" "+d.event);
try{console[a.toLowerCase()].call(console,b||d)}catch(c){console.log(b||d)}}d.level=a;return{send:function(){var a=[],b=JSON.stringify(d,function(b,d){if("undefined"===typeof d)return"undefined";if("object"===typeof d&&null!==d){var c;a:{for(c=0;c<a.length;c++)if(d===a[c])break a;c=-1}if(-1!==c)return"<?>";a.push(d)}return d});l?h.push(b):n(e,b)}}}}};this.log=m;var n=function(a,b){l=!0;var d;d="undefined"!==typeof XDomainRequest?new XDomainRequest:new XMLHttpRequest;d.constructor===XMLHttpRequest?
d.onreadystatechange=function(){4===d.readyState&&(400<=d.status?(console.error("Couldn't submit events."),410===d.status&&console.warn("This version of le_js is no longer supported!")):(301===d.status&&console.warn("This version of le_js is deprecated! Consider upgrading."),0<h.length?n(a,h.shift()):l=!1))}:d.onload=function(){0<h.length?n(a,h.shift()):l=!1};d.open("POST",k,!0);d.constructor===XMLHttpRequest&&(d.setRequestHeader("X-Requested-With","XMLHttpRequest"),d.setRequestHeader("Content-type",
"application/json"));d.overrideMimeType&&d.overrideMimeType("text");d.send(b)}}function p(a){var b,c={ssl:!0,catchall:!1,trace:!0,page_info:"never",print:!1,endpoint:null,token:null};if("object"===typeof a)for(var f in a)c[f]=a[f];else throw Error("Invalid parameters for createLogStream()");if(null===c.token)throw Error("Token not present.");b=new e(c);var g=function(a){if(b)return b.log.apply(this,arguments);throw Error("You must call LE.init(...) first.");};return{log:function(){g.apply(this,arguments).level("LOG").send()},
warn:function(){g.apply(this,arguments).level("WARN").send()},error:function(){g.apply(this,arguments).level("ERROR").send()},info:function(){g.apply(this,arguments).level("INFO").send()}}}var c={},f=function(a){if("string"!==typeof a.name)throw Error("Name not present.");if(c.hasOwnProperty(a.name))throw Error("A logger with that name already exists!");c[a.name]=new p(a);return!0};return{init:function(a){var b={name:"default"};if("object"===typeof a)for(var c in a)b[c]=a[c];else if("string"===typeof a)b.token=
a;else throw Error("Invalid parameters for init()");return f(b)},createLogStream:f,to:function(a){if(!c.hasOwnProperty(a))throw Error("Invalid name for logStream");return c[a]},destroy:function(a){"undefined"===typeof a&&(a="default");delete c[a]},log:function(){for(var a in c)c[a].log.apply(this,arguments)},warn:function(){for(var a in c)c[a].warn.apply(this,arguments)},error:function(){for(var a in c)c[a].error.apply(this,arguments)},info:function(){for(var a in c)c[a].info.apply(this,arguments)}}});