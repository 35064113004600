import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild} from '@angular/router';

import {Injectable, Injector} from '@angular/core';
import {concatMap, first, map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import { from, Observable, of } from 'rxjs';

@Injectable()
export class AsynchronousGuardProcessor implements CanActivate, CanActivateChild {
  CountryCode = environment.countryCode;

  constructor(
    public guardInjector: Injector,
  ) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Promise<boolean | UrlTree> | boolean | Observable <boolean | UrlTree> {
    return from(route.data.syncGuards).pipe(concatMap ((value) => {
      const GUARD = this.guardInjector.get(value);
      const RESULT = GUARD.canActivate(route, state);
      if (RESULT instanceof Observable) {
          return RESULT;
      } else if (RESULT instanceof Promise) {
          return from(RESULT);
      } else {
          return of(RESULT);
      }
  }), first((element) => element === false || element instanceof UrlTree, true));

  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }
}
