import { Component, ViewChild, Input } from '@angular/core';
import { ModalComponent } from '../../modal.component';
import { AuthenticationService } from 'src/app/core/services';

@Component({
  selector: 'app-sesion-modal',
  templateUrl: './sesion-modal.component.html'
})
export class SesionModalComponent {

  constructor(private authService: AuthenticationService) { }

  @Input() header? : string;
  @Input() message? : string;
  @ViewChild(ModalComponent, { static : true } ) private modal: ModalComponent;

  show() {
    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }

  logout() {
    this.hide();
    this.authService.logout().subscribe();
  }
}
