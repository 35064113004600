import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { Title } from '@angular/platform-browser';
import { CustomIcon } from "../../core/models/models";
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

/* Data for a menu item */
export class MenuItem {
    path: string;   /* The URL path to the page */
    title: string;  /* The Title of the page */
    icon?: string;  /* An optional icon for the page title */
  }

  enum CustomIconList {
    HOME = '../assets/img/icons/home.svg',
    SIDE_HOME = '../assets/img/icons/side_home.svg',
    SIDE_ORDER = '../assets/img/icons/side_order.svg',
    SIDE_SHIPMENT = '../assets/img/icons/side_shipment.svg',
    SIDE_STORE = '../assets/img/icons/side_store.svg',
    SIDE_PICKUP = '../assets/img/icons/side_pickup.svg',
    SIDE_ADDRESS = '../assets/img/icons/side_address.svg',
    SIDE_PARCEL = '../assets/img/icons/side_parcel.svg',
    SIDE_BALANCE_PAYMENTS = '../assets/img/icons/side_balance_payments.svg',
    SIDE_BATCH = '../assets/img/icons/side_batch.svg',
    SIDE_MOVEMENTS = '../assets/img/icons/side_movements.svg',
    SIDE_RESELLER = '../assets/img/icons/side_reseller.svg',
    SIDE_HD_REPORTS = '../assets/img/icons/side_hd_reports.svg',
    SIDE_HD_USERS = '../assets/img/icons/side_hd_users.svg',
    SIDE_HD_TRANSACTIONS = '../assets/img/icons/side_hd_transactions.svg',
    SIDE_HD_DOMICILIATION = '../assets/img/icons/Facturacion.svg',
    MENU = '../assets/img/icons/menu.svg',
    PERFIL = '../assets/img/icons/perfil.svg',
    HELP = '../assets/img/icons/help.svg',
    BILL = '../assets/img/icons/Facturacion.svg',
    BELL = '../assets/img/icons/bell.svg',
    TRUCK = '../assets/img/icons/delivery-truck.svg',
    CONFIG = '../assets/img/icons/config.svg',
    TARIFARIO = '../assets/img/icons/Vector.svg',
    QUIT = '../assets/img/icons/quit.svg',
    SIDE_RETURNL = '../assets/img/icons/side_returnl.svg',
    SIDE_RETURNH = '../assets/img/icons/side_returnh.svg',
    PACKING = '../assets/img/icons/Empaque.svg',
    LIFEGUARD = '../assets/img/icons/Ayuda.svg',
    SIDE_ITEMS_FULLFILLMENT = 'assets/img/icons/registro menrcancia.svg',
    SIDE_ITEMS_FULLFILLMENTB = 'assets/img/icons/registro menrcanciab.svg',
    SIDE_STOCKTAKING_FULLFILLMENT = 'assets/img/icons/Inventario.svg',
    SIDE_STOCKTAKING_FULLFILLMENTB = 'assets/img/icons/Inventario.svg',
    SIDE_LOADMASIVE_FULLFILLMENTB = 'assets/img/icons/load-masive.svg',
    STAR = 'assets/img/icons/star.svg',
    PRICE = 'assets/img/icons/price.svg',
    EFFECTIVE = 'assets/img/icons/effective.svg',
    SERVICE_BOX = 'assets/img/icons/service-box.svg',
    CALENDAR = 'assets/img/icons/calendar.svg',
    SIDE_CATEGORY_FULLFILLMENT = 'assets/img/icons/category_red.svg',
    SIDE_CATEGORY_FULLFILLMENTB = 'assets/img/icons/category_blue.svg',
    SIDE_TAG_FULLFILLMENT = 'assets/img/icons/tag_red.svg',
    SIDE_TAG_FULLFILLMENTB = 'assets/img/icons/tag_blue.svg',
    SIDE_STOCKTAKING_CARD = 'assets/img/icons/CrearArticulos.svg',
    SIDE_ORDER_CARD = 'assets/img/icons/GenerarSolicitudes.svg',
    PIE_GRAPH = 'assets/img/icons/pie-chart.svg',
    MENU_BASIC = 'assets/img/icons/menu-ui-basic.svg',
    RULES_CONFIGURATION = '/assets/img/icons/config_reglas_azul.svg'
}

@Injectable()
export class AppMenuService {

      // Make it possible to send an event about menu changed, so we can talk between components
      menuChanged = new Subject<any>();

        /* Keep track of which menu item is currently being active/selected */
      activeMenuItem$: Observable<MenuItem>;

      constructor(private router: Router,
        private titleService: Title,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
        ) {
this.activeMenuItem$ = null ;//this.router.events;
// .filter(e => e instanceof NavigationEnd)
// .map(_ => this.router.routerState.root)
// .map(route => {
//   const active = this.lastRouteWithMenuItem(route.root);

//   if (active && active.title) {
//     this.titleService.setTitle(active.title);
//   }

//   return active;
// });
}

      getMenuItems(): MenuItem[] {
        return this.router.config
          .filter(route =>
            route.data &&
            route.data.title &&
            !route.data.hidden )
          .map(route => {
            if (!route.data.title) {
              throw new Error('Missing title for toolbar menu route ' + route.path);
            }
            return {
              path: route.path,
              title: route.data.title,
              icon: route.data.icon
            };
          });
      }

      setCustomIcon() {

        let customIconList = [];
       Object.keys(CustomIconList).forEach(key =>
            {
               /*Registrar iconos pakke */
                this.matIconRegistry.addSvgIcon(
                    key,
                    this.domSanitizer.bypassSecurityTrustResourceUrl(CustomIconList[key])
                );
            }

            );





        return customIconList;

      }

      private lastRouteWithMenuItem(route: ActivatedRoute): MenuItem {
        let lastMenu;

        do {
          lastMenu = this.extractMenu(route) || lastMenu;
        }
        while ((route = route.firstChild));

        return lastMenu;
      }

      private extractMenu(route: ActivatedRoute): MenuItem {
        const cfg = route.routeConfig;

        return cfg && cfg.data && cfg.data.title
          ? {path: cfg.path, title: cfg.data.title, icon: cfg.data.icon}
          : undefined;
      }
}
