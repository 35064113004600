import { Pipe, PipeTransform } from '@angular/core';

enum VendorName {
    MGT = 'Magento' ,
    KOM = 'Kometia',
    SPF = 'Shopify',
    WOO = 'WooCommerce',
    PRS = 'PrestaShop',
    AWZ = 'Amazon',
    MER = 'MercadoLibre',
    TNG = 'TuNegocio',
    JUM = 'JumpSeller',
    VTX = 'VTEX'
}
@Pipe({
    name: 'VendorName'
})
export class VendorNamePipe implements PipeTransform {

    /**
     * Devuelve el nombre del vendedor solicitado
     * @param vendorCode Código del vendedor
     */
    transform(vendorCode: any): string {

        let result = VendorName[vendorCode];
        if(!result){
            result = '';
        }
        return result;
    }
}