import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../core/services';
import { environment } from '../../../environments/environment';
@Component({
  template: `
  <div style="height: 100vh" class="d-flex flex-column">
    <div class="text-center pt-5 mb-5">
      <img style="height: 80px" alt="logo" src="{{opts.branding.mainLogoUrl}}">
    </div>
    <div class="text-white text-center">
      <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
    </div>
  </div>
    `
})
export class HomeComponent implements OnInit {
    opts=environment;

    constructor(
    private router: Router,
    private authSvc: AuthenticationService,
    private activatedRoute:ActivatedRoute,
  ) { }

  ngOnInit(): void {
     this.authSvc.user$.subscribe( //? Verificar si es posible obtener el usarState desde getUserStateObservable
      userState => {
        if (userState.isAdmin) {
          this.router.navigate(['admin/configuracion']);

        } else if (userState.isReseller) {
          this.router.navigate(['dashboard']);

        } else if (userState.isFullfillment) {
          this.router.navigate(['fulfillment/dashboard']);

        } else if (userState.isHelpDesk) {
          this.router.navigate(['helpdesk']);

        } else {

          this.router.navigate(['login']);
        }
      }
    ).unsubscribe();
  }
}
