import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'feeType'
})
export class FeeTypePipe implements PipeTransform {
  constructor() { }

  transform(feeType: any): string {
    switch (feeType) {
      case 'OVERWEIGHT': return 'Sobrepeso';
      default: return feeType;
    }
  }
}
