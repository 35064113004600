import { Component, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { ModalComponent } from '../../shared/components/modal.component';
import { ShipmentItem, Shipment } from '../../core/models/models';
import { environment } from 'src/environments/environment';
import { FormatHelperService } from 'src/app/core/services/util/format-helper.service';

@Component({
  selector: 'app-shipment-quoted-amount-detail',
  templateUrl: './shipment-quoted-amount-detail.component.html'
})
export class ShipmentQuotedAmountDetailComponent {
  @ViewChild(ModalComponent , { static : true } ) private modal: ModalComponent;
  @Input() shipment: ShipmentItem;

  constructor(private formatHelp: FormatHelperService){}

  countryCode = environment.countryCode;
  currencyDecimal = (environment.countryCode === "MX" || environment.countryCode === "ES") ? "1.2-2" : "1.0-0";
  defaultTextDecimal = (environment.countryCode || environment.countryCode === "ES") === "MX" ? "0.00" : "0";
  currencySymbol = environment.countryCode === "MX" ? "MXN" : environment.countryCode === "ES" ? "EUR" : "COP";
  languageCode = environment.languageCode;

  formatCurrency(value) {
    return this.formatHelp.formatCurrency(this.languageCode, this.currencySymbol, value);
  }

  show() {
    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }
}
