import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { ItemsFullfillment } from 'src/app/core/models/models';

@Injectable()
export class NotificationObsService {

    private _InboxBannerObservable: Subject<any> = new Subject();

    get getInboxBannerObservable() {
        return this._InboxBannerObservable.asObservable();
    }

    set setInboxBannerObservable(data: any) {
        this._InboxBannerObservable.next(data);
    }


    notifications = [];
    private _notificaionObservable: BehaviorSubject<any> = new BehaviorSubject<any>(this.notifications);

    get getNotificaionObservable() {
        return this._notificaionObservable.asObservable();
    }

    set setNotificaionObservable(data: any) {
        this._notificaionObservable.next(data);
    }


    clear = false;
    private _clearIconInboxObservable: BehaviorSubject<any> = new BehaviorSubject<any>(this.clear);

    get getClearIconInboxObservable() {
        return this._clearIconInboxObservable.asObservable();
    }

    set setClearIconInboxObservable(data: any) {
        this._clearIconInboxObservable.next(data);
    }


    private _countNewNotifisObservable: Subject<any> = new Subject();

    get getcountNewNotifiObservable() {
        return this._countNewNotifisObservable.asObservable();
    }

    set setcountNewNotifiObservable(data: any) {
        this._countNewNotifisObservable.next(data);
    }

}

