import { Component } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: 'general-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./sndbx.loader.component.scss', './prod.loader.component.scss']
})
export class LoaderUI {
  runningSandbox: boolean;
  
  constructor(){
    const { environmentCode } = environment;
    this.runningSandbox = (environmentCode === 'LOCAL' || environmentCode === 'LOCAL_MX' || environmentCode === 'SBX_MX');
  }
}