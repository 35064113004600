import { Component, Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../default-layout/service/layout.service';
import { environment } from '../../../environments/environment';
import {ResellerService} from '../../core/services';
import {animate, style, transition, trigger} from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  templateUrl: './gradient-layout.component.html',
  styleUrls: ['./gradient-layout-component.scss'],
  animations: [
    trigger('opacityAnimation', [
      transition(':enter', [
        style({opacity: 0}),
        animate(100)
      ])
    ])
  ]
})
export class GradientLayoutComponent implements OnInit{
  dashboardImg = "";
  fullfillment = false;
  dashboardImgFullfillment="";
  fullfilmentBannerFlag = environment.fulfillmentBannerFlag;
  CountryCode = environment.countryCode;
  loginConfigGlobalImage;
  globalImage;
  loadGlobalImage = true;
  opts = environment;

  constructor(private translate: TranslateService, public layoutService: LayoutService, private resellerService: ResellerService,private route:ActivatedRoute, private router: Router) {
    this.translate.get(['background.login', 'background.loginfullfillment'
                      ])
    .subscribe(translations => {
      this.dashboardImg = translations['background.login'];
      this.dashboardImgFullfillment = translations['background.loginfullfillment'];
    });

   }

   ngOnInit(){
    const currentUrl = this.router.url;
    const urlWithoutQueryParam = currentUrl.split('?')[0];
    if (urlWithoutQueryParam === '/reset-password') {
      this.getLoginConfigGlobalImage();
    } else if(urlWithoutQueryParam === '/fulfillment/reset-password') {
      this.fullfillment = true;
      this.getLoginConfigGlobalImage();
    } else {
      this.layoutService.fullfillment.subscribe(flag => {
        this.fullfillment = flag;
        this.fullfilmentBannerFlag = this.fullfilmentBannerFlag ? !flag : false;
        this.getLoginConfigGlobalImage();
      } );
    }
  }
  getDefaultBackground() {
    // const backgroundUrl = this.fullfillment ? this.dashboardImgFullfillment : this.dashboardImg;
    // return this.formatUrl(backgroundUrl);
    return this.formatUrl(this.opts.branding.backgroundImgUrl);
  }

  getLoginConfigGlobalImage() {
    this.loadGlobalImage = true;
    this.resellerService.getLoginConfigActive(this.fullfillment).subscribe(
      (response) => {
        this.loadGlobalImage = false;
        if (response) {
          this.loginConfigGlobalImage = response;
          this.globalImage = JSON.parse(this.loginConfigGlobalImage.ConfigBody);
        }
      },
      (error) => {
        this.loadGlobalImage = false;
        console.error(error);
      }
    );
  }

  formatUrl(url: string): string {
    return `url(${url})`;
  }
}
