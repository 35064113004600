import { Component,Input,ViewChild } from '@angular/core';import { ModalComponent } from "../../shared/components/modal.component";
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { Pagination } from '../paginator/pagination';
import { Router } from '@angular/router';
import { ResellerStore } from 'src/app/core/models/models';
@Component({
  selector: "app-errors-orders-modal",
  templateUrl: "./errors-orders-modal.component.html",
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', visibility: 'hidden' })
      ),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ],
  styleUrls: ["./errors-orders-modal.component.scss"]
})
export class ErrorsOrdersModalComponent {
  @Input() header?: string;
  @Input() message?: string;
  @Input() okText = "Aceptar";
  @Input() isInfo: boolean = true;
  @Input() listOrder?: any[] = null;
  @Input() storesConfig: ResellerStore[] = [];
  @ViewChild(ModalComponent, { static: true }) private modal: ModalComponent;


  constructor(
    private router: Router
  ) {
    //this.paginator.scopedData = []; 
    this.paginator = new Pagination();
    //this.dataSource = new MatTableDataSource<any>([]);
    //this.dataSource.paginator = new Pagination();
    //this.dataSource.paginator = this.paginator;
  }

  totalOrders = 0;
  totalSuccess = 0;
  totalWarning = 0;
  totalError = 0;
  displayedColumns: string[] = ['Logo', 'Tienda', 'Folio', 'Estatus', 'Resultado'];  
  dataSource: MatTableDataSource<any>;
  
  showPlain:boolean = false;
  isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');
  expandedElement: any;

  //@ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
  paginator: Pagination;
  
  @ViewChild(MatSort,{static:true}) sort: MatSort;

  show(selOrden, isInfoDetails = true) {
    this.isInfo = isInfoDetails;
    this.totalOrders = 0;
    this.totalSuccess = 0;
    this.totalWarning = 0;
    this.totalError = 0;
    this.totalOrders = 0;
    this.totalSuccess = 0;
    this.totalWarning = 0;
    this.totalError = 0;
    this.listOrder = null;
    this.listOrder = selOrden;

    if (this.isInfo) {

      this.listOrder.forEach(orderRes => {
        orderRes.ErrorDetail = orderRes.ErrorDetail ? orderRes.ErrorDetail : [];
        if (orderRes.ErrorDetail && orderRes.ErrorDetail.length >= 0) {
          if (orderRes.HasError) {
            if (orderRes.HasSeverity) {
              this.totalError++;
            } else {
              this.totalWarning++;
            }
          } else if (orderRes.ErrorDetail.length === 0) {
            this.totalSuccess++;
          }
        }
        this.totalOrders++;
      });

      for (let order of this.listOrder) {
        if (order.HasError == 1 && typeof order.ErrorDetail == "string") {
          order.ErrorDetail = JSON.parse(order.ErrorDetail);
          order.HasSeverity = false;
          for (let oError of order.ErrorDetail) {
            if (oError.severity > 0) {
              order.HasSeverity = true;
              break;
            }
          }
        } else if (order.HasError != 1) {
          order.ErrorDetail = [{ message: "Se guardo correctamente." }];
          order.HasError = false;
        }
        if (order.resellerStore && typeof order.resellerStore.Config == "string") {
          order.resellerStore.Config = JSON.parse(order.resellerStore.Config);
        } else {
          this.storesConfig.forEach(element => {
            if (order.ResellerStoreId == element.ResellerStoreId) {
              order.resellerStore = element;
            }
          });
        }
      }

    }
    else{
      this.listOrder.forEach(orderRes => {
        if( orderRes.StatusResponse == 200){
          this.totalSuccess ++;
        }
        else if(orderRes.StatusResponse != 200){
          this.totalError ++;
        }
  
        this.storesConfig.forEach(element => {
          if (orderRes.ResellerStoreId == element.ResellerStoreId) {
            orderRes.resellerStore = element;
          }
        });
        this.totalOrders ++;  
      });
    }

    if(this.listOrder && Array.isArray(this.listOrder)){
      this.listOrder = this.listOrder.sort(function (a, b) {
        if(a && b){
          if (b.CreatedAt < a.CreatedAt) { return -1; }
          else if (b.CreatedAt > a.CreatedAt) { return 1; }
          else { return 0; }
        }
      });
    }
    
    let filterValue = (this.dataSource) ? this.dataSource.filter:"";
    this.dataSource = new MatTableDataSource<any>(this.listOrder);
    this.paginator.chageData(this.listOrder.sort());
    //this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.applyFilter(filterValue);

    if(this.listOrder.length > 0){
        this.modal.show();
    }
  }

  dataDetails: any = null;
  expandedIndex: string = null;
  isRowExpanded( data: any ) {
    return this.expandedIndex === data.OrderId;
  }
  collapseRow(data: any) {
    if ( data.OrderId === this.expandedIndex) {
      this.expandedIndex = null;
      this.dataDetails = null;
      return;
    }
    this.parseDataDetail( data );
    this.expandedIndex = data.OrderId;
  }
  private parseDataDetail(data: any) {
    this.dataDetails = {
      listErrorDetail: data.ErrorDetail,
      errorPosition: data.MsgPosition

    };
  }
  plain(selOrden, storesConfig) {

    this.listOrder = null;
    this.listOrder = selOrden; 

    
    for (let order of this.listOrder) {
      if (order.HasError == 1 && typeof order.ErrorDetail == "string") {
        order.ErrorDetail = JSON.parse(order.ErrorDetail);
        order.HasSeverity=false;
        for(let oError of order.ErrorDetail){
          if(oError.severity>0){
            order.HasSeverity=true;
            break;
          }
        }
      }else if (order.HasError!=1){
        order.ErrorDetail = [ { message: "Se guardo correctamente."} ];
        order.HasError = false;
      }
     


      if (order.resellerStore && typeof order.resellerStore.Config == "string") {
        order.resellerStore.Config = JSON.parse(order.resellerStore.Config);
      } else {
        storesConfig.forEach(element => {
          if (order.ResellerStoreId == element.ResellerStoreId) {
            order.resellerStore = element;
          }
        });
        if(!order.resellerStore){
          order.resellerStore = {
            Config:{
              StoreName:"N/A"
            }
          }
        }
      }
    }

    let filterValue = (this.dataSource) ? this.dataSource.filter:"";
    this.dataSource = new MatTableDataSource<any>(this.listOrder);
    //this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.applyFilter(filterValue);

    if(this.listOrder.length > 0){
        this.showPlain = true;
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  showDetailOrderHistory(item) {
    this.router.navigate([`/ordenes/detalle-historico/${item.UpdateOrderHistoryId}/${item.ResellerStoreId}`]);
  }

  hide() {
    this.modal.hide();
  }
}
