import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { ItemsFullfillment } from 'src/app/core/models/models';

@Injectable()
export class ProfileObsService {

    onboardingData = {};

    private _onboardingObservable: BehaviorSubject<any> = new BehaviorSubject<any>(this.onboardingData);

    get getOnboardingObservable() {
        return this._onboardingObservable.asObservable();
    }

    set setOnboardingObservable(data: any) {
        this._onboardingObservable.next(data);
    }

}

