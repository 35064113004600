import { Component, ViewChild, EventEmitter, Output, Input, AfterViewInit } from '@angular/core';
import { ModalComponent } from '../../shared/components/modal.component';
import { FormBuilder, Validators, FormGroup, NgForm, NgModel, FormsModule } from '@angular/forms';
import { CustomValidators } from '../../shared/validators/custom-validators';
import { ResellerService, GrowlSvc, CommonService } from '../../core/services';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { ResellerNotificationConfig } from 'src/app/core/models/models';
import { NotificationConfig } from '../../core/models/models';

@Component({
  selector: 'app-config-notification',
  templateUrl: './config-notification.component.html',
  styleUrls: ['./config-notification.component.scss']
})

export class ConfigNotificationComponent implements AfterViewInit {
  @Output() phoneNumberVerified = new EventEmitter();
  @Output() verificationCodeSent = new EventEmitter();
  @Output() onHide = new EventEmitter();
  @Output() onClose = new EventEmitter<any>();
  @Input() useModal = true;
  @ViewChild(ModalComponent, { static : true } ) public modal: ModalComponent;

  private notificationConfigs: ResellerNotificationConfig[] = [];
  private notificationConfigsToSave: ResellerNotificationConfig[] = [];

  configNotifForm: FormGroup;
  notifFormSubmitted = false;
  savingNotifInfo = false;
  validWebhook = false;

  flagSmsReq = false;
  flagEmailReq = false;
  flagWebhookReq = false;

  flagSave = false;

  msgConfig = "";

  flagWaiting = false;

  dataToSave : any;

  countryCode = environment.countryCode;

  constructor(
    private fb: FormBuilder,
    private resellerSvc: ResellerService,
    private growlSvc: GrowlSvc,
    public commonSvc: CommonService,
  ) {

    this.configNotifForm = this.fb.group({
      email: ['', Validators.email],
      smsPhoneNumber: false,
      webhook: false,
      smsState: false,
      emailState: false,
      webhookState: false,
      smsGenShip: false,
      emailGenShip: false,
      webhookGenShip: false
    });
  }


  private _smsPhoneConfig: any = null;
  get smsPhoneConfig(){
    return this._smsPhoneConfig;
  }
  set smsPhoneConfig(val){
    if(val && val.number) {
      this.flagSmsReq = false;
    }else{
      this.configNotifForm.controls['smsState'].setValue(false);
      this.configNotifForm.controls['smsGenShip'].setValue(false);
    }
    this._smsPhoneConfig = val;
  }

  private _webhookModel: any = null;
  get webhookModel(){
    return this._webhookModel;
  }
  set webhookModel(val){
    if(!val && val === '') {
      this.configNotifForm.controls['webhookState'].setValue(false);
      this.configNotifForm.controls['webhookGenShip'].setValue(false);
    }
    this._webhookModel = val;
  }

  private _emailModel: any = null;
  get emailModel(){
    return this._emailModel;
  }
  set emailModel(val){
    if(!val && val === '') {
      this.configNotifForm.controls['emailState'].setValue(false);
      this.configNotifForm.controls['emailGenShip'].setValue(false);
    }
    this._emailModel = val;
  }

  validReadyToSave(){
    if(this.dataToSave.email && this.dataToSave.email !== ''){
      return ( this.dataToSave.emailState || this.dataToSave.emailGenShip);
    }
    
    if(this.dataToSave.smsPhoneNumber && this.dataToSave.smsPhoneNumber.internationalNumber !== ''){
      return (this.dataToSave.smsState || this.dataToSave.smsGenShip);
    }
    
    if(this.countryCode === 'MX' || this.countryCode === 'ES'){
      if(this.dataToSave.webhook && this.dataToSave.webhook !== ''){
        return ( this.dataToSave.webhookState || this.dataToSave.webhookGenShip );
        
      }
    }

    return true;


  }



  validateSMSInput(){
    this.dataToSave = this.configNotifForm.value;
    if(this.dataToSave.smsState || this.dataToSave.smsGenShip){
      this.flagSmsReq = !this.dataToSave.smsPhoneNumber ;
    }else{
      this.flagSmsReq = false;
      if(!this.dataToSave.smsPhoneNumber){
        this.smsPhoneConfig = '';
      } 
    }    
  }

  validateEmailInput(){
    this.dataToSave = this.configNotifForm.value;
    if(this.dataToSave.emailState || this.dataToSave.emailGenShip){
      this.flagEmailReq = this.dataToSave.email === '';
    }else{
      this.flagEmailReq = false;
    }
  }

  validateWebhookInput(){
    this.dataToSave = this.configNotifForm.value;
    if(this.dataToSave.webhookState || this.dataToSave.webhookGenShip){
      this.flagWebhookReq = this.dataToSave.webhook === '';
    }else{
      this.flagWebhookReq = false;
    }
  }

 

  phoneNumberSms = '';
  

  verifyingPhoneNumber = false;
  sendingSms = false;
  verifyingEmail = false;

  emailInput = "example";

  errorMessage = '';

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Mexico, CountryISO.Colombia, CountryISO.Spain];

  viewInited = false;

  onSubmit(form: NgForm): void{
    this.notifFormSubmitted = true;
    if(form.valid){
      console.log("Valido");
    }else{
      console.log("Invalido");
    }

  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.viewInited = true;
    }, 300);
  }

  get smsPhoneNumber(): string {
    const telNumber = this.configNotifForm.get('smsPhoneNumber').value.internationalNumber;
    return telNumber;
  }

  show(dataConfig?: NotificationConfig) {
    this.modal.show();
    this.configNotifForm.reset({ smsPhoneNumber: dataConfig.PhoneNumber  == 'Sin asignar' ? '' : dataConfig.PhoneNumber, 
                                     email: dataConfig.Email  == 'Sin asignar' ? '' : dataConfig.Email, 
                                     webhook: dataConfig.Webhook  == 'Sin asignar' ? '' : dataConfig.Webhook,
                                     smsState: dataConfig.IsSmsState,
                                     emailState: dataConfig.IsEmailState,
                                     webhookState: dataConfig.IsWebhookState,
                                     smsGenShip: dataConfig.IsSmsShipGenerate,
                                     emailGenShip: dataConfig.IsEmailShipGenerate,
                                     webhookGenShip: dataConfig.IsWebhookShipGenerate});
  }

  hide() {
    this.modal.hide();
    setTimeout(() => this.onHide.emit(true), 100);
  }


  saveResellerNotificationConfigs() {
    this.savingNotifInfo = true;
    this.flagWaiting = true;
    this.dataToSave = this.configNotifForm.value;

    if(!this.validReadyToSave()){
      this.growlSvc.growlError("Favor de seleccionar la opción de notificación faltante.");
      this.savingNotifInfo = false;
      return false;
    }



    this.buildNotificacionConfigsToSave();

    this.resellerSvc.saveResellerNotificationConfigs(this.notificationConfigsToSave)
    .pipe(finalize(() => this.savingNotifInfo = false))
      .subscribe(
        res => {
          this.growlSvc.growlSuccess('La información fue actualizada');
          this.onClose.emit(this.dataToSave);
          this.hide();
          this.getResellerNotificationConfigs();
        },
        err => {this.growlSvc.growlError(err);this.savingNotifInfo = false;}
      );

      
      
  }

  private getResellerNotificationConfigs() {
    this.resellerSvc.getResellerNotificationConfigs()
      .subscribe(
        res => {
          this.notificationConfigs = res;
          //this.setNotificationView();
        },
        err => this.growlSvc.growlError(err));
  }

  private buildNotificacionConfigsToSave() {

    this.notificationConfigsToSave = [];

    if (this.dataToSave.smsState) {
      this.addNotificationConfigToSave('STATUS_CHANGE', 'SMS', this.commonSvc.sanitizeInternationalPhoneNumber(this.dataToSave.smsPhoneNumber.internationalNumber));
    }

    if (this.dataToSave.emailState) {
      this.addNotificationConfigToSave('STATUS_CHANGE', 'Email', this.dataToSave.email);
    }
    if (this.dataToSave.webhookState) {
      this.addNotificationConfigToSave('STATUS_CHANGE', 'Webhook', this.dataToSave.webhook);
    }

    if (this.dataToSave.smsGenShip) {
      this.addNotificationConfigToSave('SHIPMENT_GENERATED', 'SMS', this.commonSvc.sanitizeInternationalPhoneNumber(this.dataToSave.smsPhoneNumber.internationalNumber));
    }

    if (this.dataToSave.emailGenShip) {
      this.addNotificationConfigToSave('SHIPMENT_GENERATED', 'Email', this.dataToSave.email);
    }
    if (this.dataToSave.webhookGenShip) {
      this.addNotificationConfigToSave('SHIPMENT_GENERATED', 'Webhook', this.dataToSave.webhook);
    }
  }

  private addNotificationConfigToSave(type: string, channel: string, value: string) {
    // search for existing and extract id
    const exsNc = this.notificationConfigs.find(w => w.PakkeEventTypeId === type && w.Channel === channel);
    const exsNcId = exsNc ? exsNc.ResellerNotificationId : null;

    const newNc = new ResellerNotificationConfig();

    newNc.ResellerNotificationId = exsNcId;
    newNc.Channel = channel;
    newNc.PakkeEventTypeId = type;
    newNc.Value = value;

    this.notificationConfigsToSave.push(newNc);
  }

}
