import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { ItemsFullfillment } from 'src/app/core/models/models';

@Injectable()
export class ResellerObsService {

    resellerData = null;

    private _resellerObservable: BehaviorSubject<any> = new BehaviorSubject<any>(this.resellerData);

    get getResellerObservable() {
        return this._resellerObservable.asObservable();
    }

    set setResellerObservable(data: any) {
        this._resellerObservable.next(data);
    }


    userState = null;

    private _userStateObservable: BehaviorSubject<any> = new BehaviorSubject<any>(this.userState);

    get getUserStateObservable() {
        return this._userStateObservable.asObservable();
    }

    set setUserStateObservable(data: any) {
        this._userStateObservable.next(data);
    }


  

}

