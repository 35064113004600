import { Component, ViewChild, EventEmitter, Output, OnDestroy, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { GrowlSvc } from '../../core/services/growl.service';
import { ResellerService } from '../../core/services/domain/reseller.service';
import { CommonService } from '../../core/services/common.service';

import { ResellerAddress, ZipCodeDetail, ResellerParcel } from '../../core/models/models';
import { CustomValidators } from '../../shared/validators/custom-validators';

import { ModalComponent } from '../../shared/components/modal.component';
import { finalize, tap, filter, debounceTime, switchMap, map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { AddressType } from '../../core/constants';

@Component({
  selector: 'app-edit-frequent-parcel',
  templateUrl: './edit-frequent-parcel.component.html'
})

export class EditFrequentParcelComponent implements OnInit, OnDestroy {
  /*
  When the address type property is set, the form doesn't show
  the address usage controls (IsOriginAddress and IsDestinationAddress),
  and the address will be assigned to the type specfied under addressTypeMode
  */
  @Input() addressTypeMode?: AddressType;

  @Output() saved = new EventEmitter();

  @ViewChild(ModalComponent , { static : true } ) private modal: ModalComponent;

  editForm: FormGroup;
  saving = false;
  submitted = false;
  frequentAddressZipCodeDetail: ZipCodeDetail;

  get isAddressTypeModeSet(): boolean {
    return !!this.addressTypeMode;
  }

  private editingModel: ResellerParcel;

  constructor(
    private fb: FormBuilder,
    private growlSvc: GrowlSvc,
    private resellerSvc: ResellerService,
    public commonSvc: CommonService
  ) {
    this.createEditParcelForm();
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(): void {
    
  }

  show(parcel?: ResellerParcel) {
    this.submitted = false;
    this.editForm.reset();
    this.modal.show();

    this.editingModel = parcel || new ResellerParcel();

    this.editForm.patchValue(this.editingModel);
  }

  hide() {
    this.modal.hide();
  }

  createEditParcelForm() {
    this.editForm = this.fb.group({
      Width: ['', Validators.compose([Validators.required, Validators.min(1),CustomValidators.integer])],
      Name: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
      Length: ['', Validators.compose([Validators.required, Validators.min(1),CustomValidators.integer])],
      Height: ['', Validators.compose([Validators.required, Validators.min(1),CustomValidators.integer])],
      Default: true
    });
  }

  save() {
    this.submitted = true;

    if (!this.editForm.valid) {
      return;
    }

    const saveModel = this.prepareSaveModel();
    this.saving = true;

    this.resellerSvc.saveResellerParcel(saveModel)
      .pipe(
        tap((updatedModel) => {
          this.growlSvc.growlSuccess('La información se ha guardado');
          this.modal.hide();
          this.saved.emit(updatedModel);
        }),
        finalize(() => this.saving = false)
      )
      .subscribe(undefined, err => this.growlSvc.growlError(err));
  }

  private prepareSaveModel(): ResellerParcel {
    const saveParcel = { ...this.editForm.value };

    saveParcel.ParcelId = this.editingModel.ParcelId;

    return saveParcel;
  }

}
