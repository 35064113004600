import { DomSanitizer } from '@angular/platform-browser'
import { PipeTransform, Pipe } from "@angular/core";

enum RouteImage {
  generate = 'assets/img/icons/shipment-status/generate.svg',
  transit = 'assets/img/icons/shipment-status/transit.svg',
  ondelivery = 'assets/img/icons/shipment-status/on_delivery.svg',
  delivery = 'assets/img/icons/shipment-status/delivery.svg',
  generate_done= 'assets/img/icons/shipment-status/generate_done.svg',
  transit_done = 'assets/img/icons/shipment-status/transit_done.svg',
  ondelivery_done = 'assets/img/icons/shipment-status/on_delivery_done.svg',
  delivery_done = 'assets/img/icons/shipment-status/delivery_done.svg'
}

@Pipe({
  name: 'ShipmentStatusLogo'
})
export class ShipmentStatusLogoPipe implements PipeTransform {

  constructor() { }

  transform(status: any): string {
    let result = RouteImage[status];
    if (!result) {
      result = "";
    }
    return result;
  }
}