import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResellerInboxComponent } from './reseller-inbox.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../../pipes.module';
import { MatTooltipModule } from '@angular/material';
import { TooltipModule } from 'ngx-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ResellerInboxComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    PipesModule,
    MatTooltipModule,
    TooltipModule,
    TranslateModule
  ],
  exports: [
    ResellerInboxComponent,
  ],
})
export class ResellerInboxModule { }
