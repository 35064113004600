import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/components/common/messageservice';

interface AppErrorSeverity { severity?: ErrorSeverity; }
interface AppErrorDetail extends AppErrorSeverity { detail: string; }
interface AppErrorMessage extends AppErrorSeverity { message: string; }
type ErrorSeverity = 'warn' | 'error';

export type AppError = AppErrorDetail | AppErrorMessage;

@Injectable()
export class GrowlSvc {
  constructor(private messageService: MessageService) { }
  growlError(
    error: string | AppError,
    sticky = true
  ) {
    let messageText = '';
    let severity: ErrorSeverity = 'error';

    if (isString(error)) {
      messageText = error;
    } else {
      severity = (error && error.severity) ? error.severity : 'error';
      if (isAppErrorDetail(error)) {
        messageText = ( error && error.detail) ? error.detail : 'El servicio no está disponible, inténtalo de nuevo más tarde. (código 00)';
      } else {
        messageText = ( error && error.message ) ? error.message : 'El servicio no está disponible, inténtalo de nuevo más tarde. (código 01)';
      }
    }
    if(messageText !== ''){
      this.addMessage(severity || 'error', messageText, sticky);
    }
    
  }
  growlControlado(oError) {
    this.addMessage((oError.Severity==0 || oError.severity==0)?'warn':'error', oError.message, (oError.Severity==0 || oError.severity==0)?false:true);
  }

  growlWarn(message: string, sticky = false) {
    this.addMessage('warn', message, sticky);
  }

  growlSuccess(message: string, sticky = false) {
    this.addMessage('success', message, sticky);
  }

  growlMessages(Messages){  
    this.messageService.clear();  
    this.messageService.addAll(Messages);
  }

  getMessage4Multiple(severity: string, message: string) {
    let sticky = true;
    switch (severity){
      case "growlSuccess":
      severity = "success";
      
      break; 
      case "growlWarn":
      severity = "warn";
      
      break;
      case "growlError":
      severity = "error";
    
      break;
      default:
      severity = "success";
     
    }; 
    const messageContainer = sticky ? 'stickyMessages' : 'messages';
    let Message = { key: messageContainer, severity: severity, summary: message };
    return Message;
  }

  private addMessage(severity: string, message: string, sticky: boolean) {
    this.messageService.clear();
    const messageContainer = sticky ? 'stickyMessages' : 'messages';
    this.messageService.add({ key: messageContainer, severity: severity, summary: message });
  }
}

function isString(x: any): x is string {
  return typeof x === 'string';
}

function isAppErrorDetail(error: AppError): error is AppErrorDetail {
  return (<AppErrorDetail>error).detail !== undefined;
}

function isAppErrorMessage(error: AppError): error is AppErrorMessage {
  return (<AppErrorMessage>error).message !== undefined;
}
