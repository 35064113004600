import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "orderStatus",
})
export class OrderStatusPipe implements PipeTransform {
  transform(statusCode: string): string {
    switch (statusCode) {
      case "1":
        return "Pendiente de Pago";

      case "2":
        return "Pagada";

      case "3":
        return "Procesada";

      case "4":
        return "Cancelado";

      case "5":
        return "Guia Manual";

     case "6":
        return "Con devolución";

      default:
        return "";
    }
  }
}
