import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {Courier, CourierCustomAccount, CustomCouriersFormResponse} from 'src/app/core/models/models';
import {environment} from 'src/environments/environment';

import {delay, map} from 'rxjs/operators';
import {FormatHelperService} from '../util/format-helper.service';

@Injectable()
export class CourierSvc {
  DynamicsLogos = [];

  constructor(
    private http: HttpClient,
    private formatHelp: FormatHelperService,
  ) {
  }

  apiUrl = environment.apiUrl;

  // getCouriers(): Observable<Courier[]> {
  //     return this.http.get<Courier[]>(this.apiUrl + 'Couriers').pipe(
  //         map(model => model.filter(item => item.Status === 1))
  //     );
  // }

  getCouriers(): Observable<Courier[]> {
    return this.http.get<Courier[]>(this.apiUrl + 'Couriers/active').pipe(
      map(couriers => {
        couriers.forEach(s => {
          s.Name = s.CourierName;
        });

        return couriers;
      })
    );
  }

  getCouriersDynamics(): Observable<any> {
    return this.http.get<Courier[]>(this.apiUrl + `Couriers/dynamics`).pipe(
      map(couriers => {
        couriers.forEach(s => {
          s.Name = s.CourierName;
        });
        return couriers;
      })
    );
  }

  getCouriersFromCustom(): Observable<CourierCustomAccount[]> {
    return this.http.get<CourierCustomAccount[]>(this.apiUrl + 'Couriers/customAccount');
  }

  getActiveCouriersQuote(): Observable<Courier[]> {
    return this.http.get<Courier[]>(this.apiUrl + 'Couriers/activeQuote');
  }

  loadDynamicCourierLogos(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getCouriersDynamics().subscribe(couriers => {
        this.DynamicsLogos = [];
        couriers.map(item => {
          const configDynamic: any = this.formatHelp.getValidJSON(item.DynamicConfig);
          const urlValid = (configDynamic && configDynamic.body && configDynamic.body.urlLogo)
            ? configDynamic.body.urlLogo : '/assets/img/brand/noStoreLogo.png';
          this.DynamicsLogos.push({
            CourierCode: item.CourierCode,
            urlLogo: urlValid
          });
        });
        resolve(null);
      }, error => {
        reject(error);
      });
    });
  }

  /**
   * @param courierCode
   * @description Get the form of the custom courier using the courierCode
   */
  getCourierDynamicForm(courierCode:string) {
    const url = `${this.apiUrl}Couriers/getCourierDynamicForm/${courierCode}`
    return this.http.get<CustomCouriersFormResponse>(url)
  }

}
