import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat/Observable';
import { ApiService } from '.';
import { LogUtilService } from 'src/app/core/services/util/log.service';

@Injectable ()

export class StripeService {


  constructor(
    private apiService: ApiService,
    private logHelper: LogUtilService
    ) { }

  sendPaymentOrder(payment: any): Observable<any> {
    const url = 'Reseller/rechargeWalletPaymentsCard';
    this.logHelper.info('STRIPE payment', null, payment, 'Request recharge');
    return this.apiService.post(url, payment);
  }
}
