import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PakkeVerifyStore {
  isVerified$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  isPending$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null)
  constructor() {}

/**
 * @description Save verified status
 * @see PT-1589
 * @param isVerified
 */
public saveVerifiedStatus(verifiedStatus: string) {
    this.isVerified$.next(verifiedStatus);
  }

/**
 * @returns A Observable
 * @description Get verified Status
 * @see PT-1589
 */
  public getVerifiedStatus() {
    return this.isVerified$.asObservable();
  }

  /**
 * @description Save verified pending
 * @see PT-1589
 * @param isPending
 */
public savePending(isPending: boolean) {
  this.isPending$.next(isPending);
}

/**
* @returns A Observable
* @description Get verified pending
* @see PT-1589
*/
public getPending() {
  return this.isPending$.asObservable();
}




}