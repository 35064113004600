import { Component, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { ModalComponent } from '../../shared/components/modal.component';

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html'
})
export class ConfirmationModalComponent {
    @Input() header?: string;
    @Input() message?: string;
    @Input() okText = 'Aceptar';
    @Input() cancelText = 'Cancelar';
    @Input() varTmp = {};
    @Output() close = new EventEmitter();
    @ViewChild(ModalComponent, { static : true } ) private modal: ModalComponent;

    show() {
        this.modal.show();
    }

    hide() {
        this.modal.hide();
        if(!isEmpty(this.varTmp)){
            this.close.emit({ isOk:false,varTmp: { ant: this.varTmp["ant"],new: this.varTmp["new"]} });
        }else{
            this.close.emit(false);
        }
        
    }

    ok() {
        this.modal.hide();
        if(!isEmpty(this.varTmp)){
            this.close.emit({ isOk:true,varTmp:{ ant: this.varTmp["ant"],new: this.varTmp["new"]} });
        }else{
            this.close.emit(true);
        }
        
    }
    
}
function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}
