import { Injectable } from "@angular/core";

@Injectable()
export class FormatHelperService {
  getValidJSON(jsonData) {
    let result = { body: undefined, isValidJson: true };
    try {
      result.body = JSON.parse(jsonData);
    } catch (err) {
      console.error(
        "[ERROR] getValidJSON : Ocurrio un error al convertir la cadena recibida"
      );
      result.isValidJson = false;
    }
    return result;
  }

  formatCurrency(languageCode, currencySymbol, value){
    return new Intl.NumberFormat(languageCode, { style: 'currency', currency: currencySymbol}).format(value);
  }

  getJSONFromString(stringJson: string): any {
    let returnedJson = stringJson;

    if (typeof stringJson === 'string') {
      const jsonObject = this.getValidJSON(stringJson);

      if (jsonObject.isValidJson) {
        returnedJson = jsonObject.body;
      }
    }
    return returnedJson;
  }

}
