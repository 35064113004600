import { Component, ViewChild, EventEmitter, Output, OnDestroy, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { GrowlSvc } from '../../core/services/growl.service';
import { ResellerService } from '../../core/services/domain/reseller.service';
import { CommonService } from '../../core/services/common.service';

import { ResellerAddress, ZipCodeDetail, ItemReturn } from '../../core/models/models';

import { ModalComponent } from '../../shared/components/modal.component';
import { finalize, tap, filter, debounceTime, switchMap, map } from 'rxjs/operators';

import { FullfillmentService } from 'src/app/core/services';



@Component({
  selector: 'send-return-component',
  templateUrl: './send-return.component.html',
  styleUrls: ['./send-return.component.scss']
})

export class SendReturnComponent implements OnInit, OnDestroy {

 
  
  nextStep(){

  }
  @Output() saved = new EventEmitter();
  @Output() closed = new EventEmitter();
  @Input() title = 'Notificar al cliente de su devolución';
  @Input() orderId = '';
  
  @ViewChild(ModalComponent , { static : true } ) private modal: ModalComponent;
  saving = false;
  submitted = false;
  savedOk=false;
  configNotifForm: FormGroup;
  returnId= '';
  private _emailModel: any = null;
  get emailModel(){
    return this._emailModel;
  }
  set emailModel(val){
    if(!val && val === '') {
      this.configNotifForm.controls['email'].setValue('');
    }
    this._emailModel = val;
  }
  dataToSave : any;
  flagEmailReq = false;

  constructor(
    private fb: FormBuilder,
    private growlSvc: GrowlSvc,
    private _fullfillmentService: FullfillmentService,
    public commonSvc: CommonService
  ) {
    this.configNotifForm = this.fb.group({
      email: ['', Validators.email],
    });
  
  }

  ngOnInit(): void {
   
  }

  ngOnDestroy(): void {
  }

  async show(ReturnId?: string,OrderId?: string) {
    this.configNotifForm.controls['email'].setValue('');
    this.submitted = false;
   
   
    if(ReturnId){
      this.returnId=ReturnId;
      await this.getEmail(OrderId);
      this.modal.show();
    }
  }

  hide() {
    this.modal.hide();
    this.closed.emit(true);
  }
  finalice() {

    this.modal.hide();
    this.savedOk=false;
    this.closed.emit(true);
    this.saved.emit(true);

  }
  save() {
   this.saving=true;
   this.dataToSave = this.configNotifForm.value;
   if(this.dataToSave.email ==='' || this.dataToSave.email ===null)
   {
    this.saving=false;
    this.growlSvc.growlError('Es necesario ingresar correo');
    return 0;
   }
    this._fullfillmentService.sendEmailReturn(this.returnId,this.dataToSave.email)
      .pipe(
        tap((updatedModel) => {
          if(updatedModel.statusCode===200){
            
            this.savedOk=true;
            this.saving = false
          }
          else{
            this.savedOk=false;
            this.growlSvc.growlError(updatedModel.message);
          }
          
        }),
        finalize(() => this.saving = false)
      )
      .subscribe(undefined, err => {this.growlSvc.growlError(err);
        this.savedOk=false;});
  }
  
  validateEmailInput(){
    this.dataToSave = this.configNotifForm.value;
    if(this.dataToSave.email || this.dataToSave.email){
      this.flagEmailReq = this.dataToSave.email === '';
    }else{
      this.flagEmailReq = false;
    }
  }
  async getEmail(OrderId?: string) {
    const me = this;
   
    return new Promise((resolve) => {
    
      me._fullfillmentService.getEmail(OrderId).subscribe(
        (res) => {

          if(res.statusCode==201){
            this._emailModel = res.id;
            resolve();
          }
          else{
            this.savedOk=false;
            this.growlSvc.growlError('No se encontro correo del cliente, ingresar uno');
          }
          
          
        },
        (err) => {
          this.growlSvc.growlError(err);
        }
      );
    });
  }

}

