import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'paymentMode'
})
export class PaymentModePipe implements PipeTransform {
    constructor() { }

    transform(type: any): string {
        switch (type) {
            case 1: return 'Prepago';
            case 2: return 'Pospago';
            default: return type;
          }
    };
}
