import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnableModuleGuard implements CanActivate {

  environmentConfig = environment;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const data = route.data['module'];
    if (data && data !== '') {
     return this.getValue(this.environmentConfig, data);
    }

    return true;
  }

  private getValue(object, path): boolean {
    const properties = path.split('.');
    return (properties.length > 1) ?
      this.getValue(object[properties.shift()], properties.join('.')) : object[properties.shift()];
  }

}
