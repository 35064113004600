import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { ResellerAddress, ReportHistory } from 'src/app/core/models/models';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';
import { Bussiness } from '../bussiness.model';
import { LoadingSpinnerService } from 'src/app/shared/loadingspinner/loading-spinner.service';
import { OnBoardingService } from '../../service/on-boarding.service';
import { GrowlSvc, ResellerService } from 'src/app/core/services';
import { error } from 'protractor';

@Component({
  selector: 'app-bussiness',
  templateUrl: './bussiness.component.html',
  styleUrls: ['./bussiness.component.scss']
})
export class BussinessComponent implements OnInit {

  @Input() isComplete: boolean = false;
  @Output() completeEvent = new EventEmitter<void>();

  businessForm: FormGroup;
  selectedRecipientFrequentAddress: ResellerAddress;
  selectFirstAddress = true;
  notUseFrequentAddressFrom = false;
  bussiness: Bussiness;

  loadingReseller: boolean;

  incControl = new FormControl();
  filteredOptions: Observable<string[]>;
  options = [
    "Documentos",
    "Automotriz",
    "Electrónicos",
    "Muebles",
    "Ropa",
    "Artículos de cocina",
    "Hogar, decoración y jardín",
    "Ferretería",
    "Calzado",
    "Joyería, bisutería y accesorios",
    "Relojería",
    "Artículos de belleza",
    "Artículos de salud y bienestar",
    "Deportes",
    "Juguetería",
    "Artículos para mascotas",
    "Papelería y mercería",
    "Libros y revistas",
    "Instrumentos musicales",
    "Otros"
  ];

  isAddress: boolean = false;

  constructor(
    private fb: FormBuilder,
    private loading: LoadingSpinnerService,
    private onBngService: OnBoardingService,
    private resellerService: ResellerService,
    private growlSvc: GrowlSvc,
  ) { }

  ngOnInit() {
    this.loadingReseller = true;
    this.filteredOptions = this.incControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filter(value))
      );
    this.initBussinessForm();

    this.onBngService.getResellerBussiness().subscribe((response: any) => {
      if (response && response.statusCode != 404) {
        this.selectedRecipientFrequentAddress = response.Address;
        let values = JSON.parse(response.Config);
        this.incControl.setValue(values.Industry);
        this.businessForm.controls['BusinessName'].setValue(values.BusinessName);
        this.businessForm.controls['ECommerceURL'].setValue(values.URL);
        this.businessForm.controls['SocialURL'].setValue(values.SocialNetwork);
      }
      this.loadingReseller = false;
    }, (err) => {
      this.loadingReseller = false;
    });
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  saveBussinessData() {
    if (this.businessForm.valid) {
      this.isAddress = true;
    }
    else {
      this.businessForm.get('BusinessName').markAsDirty();
      this.businessForm.get('ECommerceURL').markAsDirty();
      this.businessForm.get('SocialURL').markAsDirty();
    }
  }

  async saveAddress(event) {
    try {
      this.loading.show();
      let config = {
        BusinessName: this.businessForm.get('BusinessName').value,
        URL: this.businessForm.get('ECommerceURL').value,
        SocialNetwork: this.businessForm.get('SocialURL').value,
        Industry: this.incControl.value
      };

      // if (event.AddressId == undefined) {
        let res: any = await new Promise((resolve, reject) => {
          this.resellerService.saveResellerAddress(event).subscribe((result) => {
            if (result) {
              resolve(result);
            }
          },
            err => reject(err));
        });

        this.bussiness = {
          ResellerBussinessId: '',
          ResellerId: '',
          AddressId: res.AddressId,
          Config: JSON.stringify(config)
        }

      this.onBngService.setResellerBussiness(this.bussiness).subscribe((response: any) => {
        if (response && response.statusCode != 400) {
          this.growlSvc.growlSuccess('Se completo el paso Tu Empresa, de manera exitosa.');
          this.isComplete = true;
          this.loading.hide();
          this.completeEvent.emit();
        }
        else {
          this.growlSvc.growlWarn(response.details);
          this.loading.hide();
        }
      },
        (error) => {
          this.growlSvc.growlWarn(error.detail);
          this.loading.hide();
        });

    } catch (error) {
      this.growlSvc.growlWarn(error.detail);
      this.loading.hide();
      return;
    }
  }

  private initBussinessForm() {
    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    const formModel = {
      BusinessName: ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
      ECommerceURL: ['', Validators.compose([Validators.pattern(reg), Validators.required])],
      SocialURL: ['', Validators.compose([Validators.pattern(reg)])],
      // SocialURL: ['', Validators.compose([Validators.pattern(reg), Validators.required])],
      // SocialURL: [''],
    };

    this.businessForm = this.fb.group(formModel);
  }

}
