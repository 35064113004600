import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResultMassiveGuidesComponent } from './result-massive-guides.component';
import { PipesModule } from 'src/app/shared/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { PopoverModule } from "ngx-popover";

@NgModule({
  declarations: [ResultMassiveGuidesComponent],
  imports: [
    CommonModule,
    PipesModule,
    TranslateModule,
    PopoverModule,
  ],
  exports: [
    ResultMassiveGuidesComponent,
  ], 
})
export class ResultMassiveGuidesModule { }
