import { Injectable } from '@angular/core';
import { Observable, throwError, of, pipe } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';

@Injectable()
export class NotificationService {
  constructor(
    private apiService: ApiService,
  ) { }

  getInboxHistory(): Observable<any> {
    return this.apiService.get('Reseller/inboxHistory');
  }

  updateInboxHistory(notifiData): Observable<any> {
    return this.apiService.put('Reseller/updateInboxHistory', notifiData);
  }

  updateAllInboxHistory(): Observable<any> {
    return this.apiService.put('Reseller/updateAllInboxHistory');
  }

}

    
