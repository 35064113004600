import { Pipe, PipeTransform } from '@angular/core';

enum RouteIcon {
  PANEL = 'assets/img/icons/ico1.png',
  BOX = 'assets/img/icons/ico2.png',
  LOCATION = 'assets/img/icons/ico3.png',
  CLOCK = 'assets/img/icons/ico4.png',
  ADDRESSEE = 'assets/img/icons/ico5.png',
  PAPER = 'assets/img/icons/ico6.png',
  KEY = 'assets/img/icons/ico7.png'
}
@Pipe({
  name: 'iconSource'
})
export class IconSourcePipe implements PipeTransform {
  transform(code: any): string {
    switch (code) {
      case 'ico-ico1' /* Dashboard */: // PANEL
        return 'assets/img/icons/ico1.png';

      case 'ico-ico2' /* Mapa */: // BOX
        return 'assets/img/icons/ico2.png';

      case 'ico-ico3' /* CounterClock */: // LOCATION
        return 'assets/img/icons/ico3.png';

      case 'ico-ico4' /* Caja Flecha */: // CLOCK
        return 'assets/img/icons/ico4.png';

      case 'ico-ico5' /* Hoja */: // ADDRESSEE
        return 'assets/img/icons/ico5.png';

      case 'ico-ico6' /* Llave */: // PAPER
        return 'assets/img/icons/ico6.png';

      case 'ico-ico7' /* Edificios */:
        return 'assets/img/icons/ico7.png';

      case 'ico-ico8' /* Flechas en circulo */:
        return 'assets/img/icons/ico8.png';

      case 'ico-ico9' /* Triangulo Nodos */:
        return 'assets/img/icons/ico9.png';

      case 'ico-ico10' /* Campana sin relleno */:
        return 'assets/img/icons/ico10.png';

      case 'ico-ico11':
        return 'assets/img/icons/ico11.png';

      case 'ico-envelope':
        return 'assets/img/icons/envelope.svg';

      case 'ico-adobe':
        return 'assets/img/icons/adobe.png';

      case 'ico-atencion':
        return 'assets/img/icons/atencion.png';

      case 'ico-atras':
        return 'assets/img/icons/atras.png';

      case 'ico-buscar':
        return 'assets/img/icons/buscar.png';

      case 'ico-descargar1':
        return 'assets/img/icons/descargar1.png';

      case 'ico-devolver':
        return 'assets/img/icons/devolver.png';

      case 'ico-descargar3':
        return 'assets/img/icons/descargar3.png';

      case 'ico-descargar4':
        return 'assets/img/icons/descargar4.png';

      case 'ico-download-red':
        return 'assets/img/icons/download-red.svg';

      case 'ico-download-white':
        return 'assets/img/icons/download-white.svg';

      case 'ico-editar':
        return 'assets/img/icons/editar.png';

      case 'ico-excel':
        return 'assets/img/icons/excel.png';

      case 'ico-print':
        return 'assets/img/icons/print.png';

      case 'ico-seguridad':
        return 'assets/img/icons/seguridad.png';

      case 'ico-order1':
        return 'assets/img/icons/order1.svg';

      case 'ico-phoneVerification':
        return 'assets/img/icons/phoneVerification.png';

      case 'ico-ver':
        return 'assets/img/icons/ver.png';

      case 'check-green':
        return 'assets/img/icons/check-green.png';

      case 'check-gray':
        return 'assets/img/icons/check-gray.png';

      case 'ico-codebar':
        return 'assets/img/icons/codebar.png';

      case 'alert-balance-red':
        return 'assets/img/icons/alert-balance-red.png';

      case 'alert-balance-yellow':
        return 'assets/img/icons/alert-balance-yellow.png';

        case 'shipment-generated':
            return 'assets/img/icons/shipment-generated.png';

        case 'shipment-generated-step-one':
            return 'assets/img/icons/shipment-generated-step-one.png';

        case 'shipment-generated-step-two':
            return 'assets/img/icons/shipment-generated-step-two.png';

        case 'shipment-generated-step-three':
                return 'assets/img/icons/shipment-generated-step-three.png';

        case 'alert-balance-green':
          return 'assets/img/icons/alert-balance-green.png';

        case 'onboarding-default-incomplete':
          return 'assets/img/icons/onboarding-default-incomplete.png';

        case 'onboarding-default-complete':
          return 'assets/img/icons/onboarding-default-complete.png';

        case 'onboarding-address-incomplete':
          return 'assets/img/icons/onboarding-icon1-0.png';

        case 'onboarding-address-complete':
          return 'assets/img/icons/onboarding-icon1-1.png';

        case 'onboarding-store-incomplete':
          return 'assets/img/icons/onboarding-icon2-0.png';

        case 'onboarding-store-complete':
          return 'assets/img/icons/onboarding-icon2-1.png';

        case 'onboarding-bill-incomplete':
          return 'assets/img/icons/onboarding-icon3-0.png';

        case 'onboarding-bill-complete':
          return 'assets/img/icons/onboarding-icon3-1.png';

        case 'onboarding-phone-incomplete':
          return 'assets/img/icons/onboarding-icon4-0.png';

        case 'onboarding-phone-complete':
          return 'assets/img/icons/onboarding-icon4-1.png';

        case 'onboarding-profile-incomplete':
          return 'assets/img/icons/onboarding-icon5-0.png';

        case 'onboarding-profile-complete':
          return 'assets/img/icons/onboarding-icon5-1.png';

        case 'onboarding-profile-unverified':
          return 'assets/img/icons/onboarding-icon5-2.png';

      case 'onboarding-balance-incomplete':
          return 'assets/img/icons/onboarding-icon6-0.png';

      case 'onboarding-balance-complete':
        return 'assets/img/icons/onboarding-icon6-1.png';

      case 'onboarding-email-incomplete':
          return 'assets/img/icons/onboarding-icon7-0.png';

      case 'onboarding-email-complete':
        return 'assets/img/icons/onboarding-icon7-1.png';

      case 'onboarding-profile':
        return 'assets/img/icons/onboarding-profile.png';

      case "alert-balance-green":
        return "assets/img/icons/alert-balance-green.png";

      case "ico-notimage" /* no imagen */:
        return "assets/img/icons/ico7.png";

      case 'sura' /* Seguro */:
        return 'assets/img/icons/sura.png';

      case 'alert-warning':
        return 'assets/img/icons/alert-warning.svg';

      case 'delivery-truck':
        return 'assets/img/icons/delivery-truck.svg';

      case 'ico-tarifario':
        return 'assets/img/icons/Vector.svg';

      case 'ico-exclamation-triangle':
        return 'assets/img/icons/exclamation-triangle.svg';

      case 'ico-phone':
        return 'assets/img/icons/phone-icon.png';
      
      case 'ico-email':
        return 'assets/img/icons/email-icon.png';
        
      default:
        return '';
    }
  }
}
