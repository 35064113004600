import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AuthUser, ConfirmCredentials, Password } from '../models/models';
import { ApiService } from './api.service';

@Injectable()
export class UserService {
  constructor(
    private apiService: ApiService
  ) {}

  registerUser(user: AuthUser): Observable<ConfirmCredentials> {
    return this.apiService.post<ConfirmCredentials>('Users/signUp', user);
  }

  confirmUser(creds: ConfirmCredentials): Observable<any> {
    return this.apiService.get('Users/confirm?uid=' + creds.uid + '&token=' + creds.token);
  }

  confirmSignUpLoginExternalSites(creds: ConfirmCredentials): Observable<any> {
    return this.apiService.post('Users/confirmSignUpLoginExternalSites', creds);
  }

  changePassword(passwordChange: Password): Observable<any> {
    return this.apiService.post('Users/changePassword', passwordChange);
  }

  resetPassword(email: string): Observable<any> {
    return this.apiService.post('Users/reset', { email: email });
  }

  restorePassword(newPassword: string, token: string): Observable<any> {
    const restorePasswordHttpOptions = {
      headers: new HttpHeaders({
        'Authorization': token
      })
    };

    return this.apiService.post('Users/resetPassword', { 'newPassword': newPassword }, restorePasswordHttpOptions);
  }
}
