import {
  Component,
  ViewChild,
  EventEmitter,
  Output,
  Input,
  ElementRef,
  SimpleChanges, OnDestroy, OnChanges
} from '@angular/core';
import { ModalComponent } from "./modal.component";
import { ShipmentItem, Shipment } from "../../core/models/models";
import { environment } from "src/environments/environment";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ShipmentService, GrowlSvc, ResellerService } from "src/app/core/services";

import {OnBoardingFileType, Reseller, ResellerFileToUpload, ResellerLoadedFile} from 'src/app/core/models/models';
import { OnBoardingFileService } from "src/app/on-boarding/service/on-boarding-file.service";
import { NgxSpinnerService } from "ngx-spinner";
import { AclarationType } from 'src/app/core/models/aclarations-type.enum';

@Component({
  selector: "app-shipment-aclaration",
  templateUrl: "./shipment-aclaration.component.html",
  styleUrls: ['./shipment-aclaration.component.scss']
})
export class ShipmentAclarationComponent implements OnDestroy{
  @ViewChild(ModalComponent, { static: true }) private modal: ModalComponent;
  @Input() shipment: ShipmentItem;

  @Output() close = new EventEmitter();
  constructor(private formBuilder: FormBuilder,
              private shipmentSvc: ShipmentService,
              private growlSvc: GrowlSvc,
              private onBoardingFileService: OnBoardingFileService,
              private resellerService: ResellerService,
              private spinner: NgxSpinnerService) { }
  countryCode = environment.countryCode;
  currencyDecimal = (environment.countryCode === "MX" || environment.countryCode === "ES") ? "1.2-2" : "1.0-0";
  defaultTextDecimal = (environment.countryCode === "MX" || environment.countryCode === "ES") ? "0.00" : "0";
  currencySymbol = environment.countryCode === "MX" ? "MXN" : environment.countryCode === "ES" ? "EUR" : "COP";
  @Input() okText = "Iniciar aclaración";
  @Input() cancelText = "Cancelar";
  @Input() header?: string;
  haveAclarations = false;
  newAclaration = false;
  startclaration = false;
  history = [];
  SelectedAclaration: any = null;
  uploadFileView: boolean = false;

  private reseller: Reseller;
  currentFileToUpload: OnBoardingFileType;
  @ViewChild('adjuntoUpload', {static: false}) fileInput: ElementRef;
  ResellerFileToUpload: ResellerFileToUpload[] = [];
  filesToUpload: OnBoardingFileType[] = [];
  loadedFiles: any = {};
  reason: string | undefined;
  disableAclaration: boolean = false;
  disableOverweight: boolean = false;

  isSelectedAclaration(item) {
    return this.SelectedAclaration == item;
  }

  selectAclaration(item) {
    this.newAclaration = true;
    this.reason = item.text;
    if(this.reason === "Sobrepeso" || this.reason === "Daño" || this.reason === "Sustracción") {
      this.uploadFileView = true;
      if (this.shipment.Content) {
        this.aclarationForm.patchValue({
          content: this.shipment.Content,
        });
      }
    }
    this.SelectedAclaration = item;
  }

  patchValueContent() {
    setTimeout(() => {
      this.aclarationForm.patchValue({
        content: this.shipment.Content,
      });
    }, 500);
  }

  show() {

    this.haveAclarations = this.shipment.Clarification ? true: false;
    if(this.haveAclarations){
      this.getAclarationHistory(this.shipment.ShipmentId);
    }else{
      this.newAclaration = true;
    }
    this.aclarationForm = this.formBuilder.group({
      aclarationType: [null, Validators.compose([Validators.required]) ],
      content: ['', Validators.compose([Validators.required, Validators.minLength(4)])]
    });
    this.modal.show();
  }

  aclarationForm: FormGroup;

  ngOnInit() {
    this.newAclaration = false;
    this.aclarationForm = this.formBuilder.group({
      aclarationType: [null, Validators.compose([Validators.required])],
      content: ['', Validators.compose([Validators.required, Validators.minLength(4)])]
    });
    this.getAclarationFileTypes(3);
  }

  aclarationChange(){
    this.uploadFileView = false;
    this.expandedIndex = null;
    this.newAclaration = !this.newAclaration;
  }

  expandedIndex: string = null;
  isRowExpanded( data: any ) {
    return this.expandedIndex === data.ShipmentClarificationId;
  }
  collapseRow(data: any) {
    if ( data.ShipmentClarificationId === this.expandedIndex) {
      this.expandedIndex = null;
      return;
    }
    this.expandedIndex = data.ShipmentClarificationId;
  }

  getAclarationHistory(shipmentId){
    this.shipmentSvc.getAclarationHistory(shipmentId)
      .subscribe(
        shipmentHistory => {
          this.history  = shipmentHistory;
        },
        err => {
          this.growlSvc.growlError(err);
        }
      );
  }


  aclarationReason = [
    {
      text:"Sobrepeso",
      info:"Peso que excede al cotizado, reportado por la paquetería.",
      val:0
    },{
      text:"Robo",
      info:"Tu paquete no llego a su destino, tampoco ha sido regresado y/o se desconoce su paradero.",
      val:1
    },{
      text:"Daño",
      info:"En caso de que tu paquete presente alguna anomalia ó esté dañado.",
      val:2
    },{
      text:"Extravío",
      info:"El paquete aún no se ha entregado y se desconoce el paradero del mismo.",
      val:3
    },{
      text:"Sustracción",
      info:"Se tienen indicios o sospechas de que el paquete fue abierto en el transcurso a ser entregado.",
      val:4
    }
  ];

  hide() {
    this.loadedFiles = {};
    this.uploadFileView = false;
    this.expandedIndex = null;
    this.SelectedAclaration = null;
    this.newAclaration = false;
    this.haveAclarations = false
    this.history = [];
    this.modal.hide();
    this.close.emit(false);
    this.cleanResellerFileToUpload();
  }

  ok() {
    console.log('Files to send', this.ResellerFileToUpload);
    const type = this.SelectedAclaration.val;
    this.SelectedAclaration = null;
    this.expandedIndex = null;
    this.newAclaration = false;
    this.haveAclarations = false;
    this.history = [];
    this.close.emit({
      confirm: true,
      aclarationType: type + '',
      content: this.aclarationForm.value.content,
      resellerFileToUpload: this.ResellerFileToUpload
    });
    this.cleanResellerFileToUpload();
  }

  getAclarationFileTypes(type:number) {
    this.onBoardingFileService.getAclarationFileTypes(type).subscribe(data => {
      this.filesToUpload = data;
    }, err => {
      this.growlSvc.growlError(err);
    });
  }

  promtUploadFile(fileToUpload: OnBoardingFileType) {
    this.currentFileToUpload = fileToUpload;
    this.fileInput.nativeElement.click();
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if( file && file.size > 20005671 ){
              this.growlSvc.growlWarn('El archivo supera los 20MB permitidos. Favor de ingresar un archivo de menor tamaño.');
                return;
      }
      reader.readAsDataURL(file);
      reader.onload = () => {
        const adjunto: ResellerFileToUpload = {
          fileType: this.currentFileToUpload.ResellerFileTypeId,
          name: file.name,
          file: typeof reader.result === 'string' ? reader.result.split(',')[1] : null
        };

        let exist = this.ResellerFileToUpload.filter(x => x.name == adjunto.name);

        if (exist.length === 0) {
          this.ResellerFileToUpload.push(adjunto);
          this.loadedFiles[adjunto.fileType] = adjunto;
        } else {
          this.growlSvc.growlWarn('Ya tienes una imagen seleccionada con el mismo nombre, intenta con otra diferente.');
        }

        if(this.ResellerFileToUpload !== [])
          this.startclaration = true;

      };
    }
  }

  validStartClaration(startclaration, content) {
    return !(startclaration && content);
  }

  downloadFile(adjunto: ResellerLoadedFile) {
    this.spinner.show('spClarification');
    this.onBoardingFileService.downLoadFile(adjunto.ResellerFileId).subscribe(data => {
      const blobData = this.convertBase64ToBlobData(data.File, data.Mime);
      const blob = new Blob([blobData], {type: data.Mime});
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = adjunto.Name || 'file';
      link.click();
      this.spinner.hide('spClarification');
    }, err => {
      console.log('Error al descargar archivo: ', err);
      this.growlSvc.growlError(err);
      this.spinner.hide('spClarification');
    });
  }

  private convertBase64ToBlobData(base64Data: string, contentType: string, sliceSize = 512) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  getDateItem(dateString: string){
    return JSON.parse(dateString).when
  }

  cleanResellerFileToUpload() {
    this.ResellerFileToUpload = [];
  }

  /**
   * @param item 
   * @description Options to disable aclarations tooltip
   * @see PT-1238 
   */
  tooltipDisable(item:number) {
    return (
      (this.disableAclaration && !this.disableOverweight && [AclarationType.SOBREPESO, AclarationType.ROBO, AclarationType.EXTRAVÍO].includes(item)) ||
      (!this.disableAclaration && !this.disableOverweight) ||
      (!this.disableAclaration && this.disableOverweight && [AclarationType.ROBO, AclarationType.DAÑADO, AclarationType.EXTRAVÍO, AclarationType.SUSTRACCIÓN].includes(item)) ||
      (this.disableAclaration && this.disableOverweight && [AclarationType.ROBO, AclarationType.EXTRAVÍO].includes(item))
    );
  }

  ngOnDestroy(): void {
    console.log('Aqui');
    this.cleanResellerFileToUpload();
  }

}
