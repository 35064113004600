import {Pipe, PipeTransform} from '@angular/core';
import {environment} from '../../../environments/environment';
const moment = require('moment-timezone');

@Pipe({
  name: 'CustomDate'
})
export class CustomDate implements PipeTransform {

  constructor() {
  }

  transform(value, format = ''): string {
    if (value) {
      return moment(value).tz(environment.timeZone).format(format);
    }
    return '';
  }
}
