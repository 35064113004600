import { Pipe, PipeTransform } from '@angular/core';

enum RouteImage {
    HOME = 'assets/img/icons/home.svg',
    SIDE_HOME = 'assets/img/icons/side_home.svg',
    SIDE_ORDER = 'assets/img/icons/side_order.svg',
    SIDE_SHIPMENT = 'assets/img/icons/side_shipment.svg',
    SIDE_STORE = 'assets/img/icons/side_store.svg',
    SIDE_PICKUP = 'assets/img/icons/side_pickup.svg',
    SIDE_ADDRESS = 'assets/img/icons/side_address.svg',
    SIDE_PARCEL = 'assets/img/icons/side_parcel.svg',
    SIDE_RETURNL = 'assets/img/icons/side_returnl.svg',
    SIDE_RETURNH = 'assets/img/icons/side_returnh.svg',
    SIDE_ITEMS_FULLFILLMENT = 'assets/img/icons/registro menrcancia.svg',
    SIDE_ITEMS_FULLFILLMENTB = 'assets/img/icons/registro menrcanciab.svg',
    SIDE_STOCKTAKING_FULLFILLMENT = 'assets/img/icons/Inventario.svg',
    SIDE_STOCKTAKING_FULLFILLMENTB = 'assets/img/icons/InventarioB.svg',
    SIDE_LOADMASIVE_FULLFILLMENTB = 'assets/img/icons/load-masive.svg', 
    SIDE_CATEGORY_FULLFILLMENT = 'assets/img/icons/category_red.svg',
    SIDE_CATEGORY_FULLFILLMENTB = 'assets/img/icons/category_blue.svg',

}

@Pipe({
    name: 'AppIcon'
})
export class AppIconPipe implements PipeTransform {

    /**
     * Devuelve la ruta donde se obtendrá el icono.
     * @param iconId Identificador del icono
     */
    transform( iconId: any ): string {
        let result = RouteImage[iconId];
        if(!result){
            result = '';
        }
        return result;
    }
}