import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'trackStatus'
})
export class TrackStatusPipe implements PipeTransform {
  shipmentTitle = environment.countryCode === 'ES' ? 'ENVÍO' : 'GUÍA';
  shipmentTranslate: any;

  constructor(private translateServ: TranslateService) {

    this.translateServ.get(['shipments']).subscribe(result => {
      this.shipmentTranslate = result.shipments;
    });

   }

  transform(status: any): string {
    switch (status) {
      case 'WAITING': return  `${this.shipmentTitle} ${this.shipmentTranslate.status.waitingMay}`;
      case 'IN_TRANSIT': return `${this.shipmentTitle} ${this.shipmentTranslate.status.inItransitMay}`;
      case 'ON_DELIVERY': return `${this.shipmentTitle} ${this.shipmentTranslate.status.onDeliveryMay}`;
      case 'DELIVERED': return `${this.shipmentTitle} ${this.shipmentTranslate.status.deliveredMay}`;
      case 'EXCEPTION': return `${this.shipmentTitle} ${this.shipmentTranslate.status.exceptionMay}`;
      case 'RETURNED': return `${this.shipmentTitle} ${this.shipmentTranslate.status.returnedMay}`;
      case 'CANCELLED': return `${this.shipmentTitle} ${this.shipmentTranslate.status.cancelledMay}`;
      default: return status;
    }
  }
}
