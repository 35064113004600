import { Component, OnInit, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';
import { environment } from 'src/environments/environment';
import { GrowlSvc, CommonService, ResellerService } from 'src/app/core/services';
import { ZipCodeDetail, Reseller, ResellerBillingInfo, ResellerAddress, Municipio, PaymentTerms, PaymentMethod, CFDIuse, RegimesCatalogResponse } from 'src/app/core/models/models';
import { NgSelectComponent } from '@ng-select/ng-select';
import { map, debounceTime, distinctUntilChanged, filter, switchMap, finalize, take, takeUntil } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';
import { LayoutService } from 'src/app/containers/default-layout/service/layout.service';
import { AddressTypes } from 'src/app/address-form/address-form/model/address-type';
import { AddressType } from 'src/app/core/constants';
import {LoadingSpinnerService} from '../../shared/loadingspinner/loading-spinner.service';
import {socialDenominationValidator} from '../../shared/validators/social-denomination.validator';

@Component({
  selector: 'app-bill-info',
  templateUrl: './bill-info.component.html',
  styleUrls: ['./bill-info.component.scss']
})
export class BillInfoComponent implements OnInit, OnDestroy {

  @ViewChild('manualSelectionDropdown', { static: false }) manualSelectionDropdown: NgSelectComponent;

  @Output() completeEvent = new EventEmitter<void>();

  type: AddressType = 0;
  useNeighborhoodCombo = false;
  reseller: Reseller;
  editingBillingInfo = false;
  countryCode = environment.countryCode;


  billingAddressForm: FormGroup;
  billingAddressSubmitted = false;

  frequentAddresses: ResellerAddress[] = [];

  resellerInfoForm: FormGroup;
  editingResellerInfo = false;

  apiKey: string;
  CountryCode = environment.countryCode;

  addressType: any;
  private subscriptions: Subscription[] = [];
  manualMunicipios = false;
  allmunicipios: Municipio[];
  municipiosLoading = false;
  addressZipCodeDetail: ZipCodeDetail;
  state: any;
  municipio: any;
  resellerInfo: any;
  ZipCodeLength: number;
  ZipcodesDetails: ZipCodeDetail[] = [];
  ZipcodesDetailsSuggestions: string[] = [];
  zipCodeaAutoComplete = false;
  neighborhoodSuggestions: string[] = [];
  neighborhoodAutoComplete = false;

  paymentTerms: PaymentTerms [] = [];
  paymentMethods: PaymentMethod[] = [];
  CFDIUse: { BillingCFDIUseCodeKey: string, Description: string }[] = [];

  mbindLabel = 'Municipio';
  mbindValue = 'stateCode';
  mgroupBy = 'StateName';
  stateAndCityLabel = 'Ciudad y Estado';
  neighborhoodbLabel = 'Colonia';

  regimen = 'Regimen';
  documentType = 'Tipo de documento';
  personType = 'Tipo de persona';
  taxRegimenCatalog: any[] = [];
  regimenValues: any[] = [];
  documentTypeValues = [
    { clave: '11', descripcion: 'Registro civil' },
    { clave: '12', descripcion: 'Tarjeta de identidad' },
    { clave: '13', descripcion: 'Cédula de ciudadanía' },
    { clave: '21', descripcion: 'Tarjeta de extranjería' },
    { clave: '22', descripcion: 'Cédula de extranjería' },
    { clave: '31', descripcion: 'NIT' },
    { clave: '41', descripcion: 'Pasaporte' },
    { clave: '42', descripcion: 'Documento de identificación' },
  ];
  personTypeValuesMX = [
    { clave: 'F', descripcion: 'Persona Física' },
    { clave: 'M', descripcion: 'Persona Moral' },
  ];

  personTypeValuesCO = [
    { clave: '1', descripcion: 'Persona Jurídica' },
    { clave: '2', descripcion: 'Persona Natural' },
  ];

  rbindLabel = 'descripcion';
  rbindValue = 'clave';

  allMunReady = false;
  selectMun = false;
  laddaBillingInfo = false;

  $onDestroy: Subject<void> = new Subject<void>();

  constructor(
    private _fb: FormBuilder,
    private _growlSvc: GrowlSvc,
    private _resellerSvc: ResellerService,
    public commonSvc: CommonService,
    public layoutService: LayoutService,
    private growlSvc: GrowlSvc,
    private loadingService: LoadingSpinnerService,
  ) {
    this.loadRegimenList();
  }

  private loadRegimenList(): void {
    if (this.CountryCode === 'MX') {
      this.commonSvc.getTaxRegimenByPersonType().pipe(take(1)).subscribe((taxRegimes) => {
        this.taxRegimenCatalog = taxRegimes;
      });
    }
  }

  ngOnInit() {
    this.createResellerInfoForm();
    this.createBillingAddressForm();

    this.getReseller();

    this.getPaymentTerms();
    this.getPaymentMethods();


    this.addressType = AddressTypes[this.type];

    this.setZipcodeSub();
    if (this.CountryCode === 'CO') {
      this.mbindLabel = 'ciudad';
      this.mbindValue = 'dane';
      this.mgroupBy = 'departamento';
      this.stateAndCityLabel = 'Ciudad y Departamento';
      this.neighborhoodbLabel = 'Barrio';
      this.neighborhoodAutoComplete = true;
      this.zipCodeaAutoComplete = true;
    }
    this.ZipCodeLength = (this.CountryCode === 'MX' || this.CountryCode === 'ES') ? 5 : 6;
    this.manualMunicipios = this.CountryCode === 'CO';
    if (this.manualMunicipios) {
      this.getAllMunicipios();
    }
  }

  filterZipCode(event) {
    const query = event.query;
    const filtered = this.ZipcodesDetails.filter(zip => zip.ZipCode.indexOf(query) >= 0);
    this.ZipcodesDetailsSuggestions = filtered.map(item => item.ZipCode);
  }

  filterNeighborhoods(event) {
    const query = event.query;
    if (!this.addressZipCodeDetail || !this.addressZipCodeDetail.Neighborhoods || !this.addressZipCodeDetail.Neighborhoods.length) {
      this.neighborhoodSuggestions = [];
      return;
    }
    this.neighborhoodSuggestions = this.addressZipCodeDetail.Neighborhoods.filter(item => item.indexOf(query) >= 0);
  }


  public setZipcodeSub() {
    this.subscriptions.push(
      this.pipeZipCodeValueChanges(this.billingAddressForm.get('ZipCode').valueChanges)
        .pipe(takeUntil(this.$onDestroy), finalize(() => this.setZipcodeSub()), switchMap((value: string) =>
          this.refreshAddressZipCodeDetails(value))

        )
        .subscribe(undefined, err => {

          this._growlSvc.growlError(err);
        })

    );
  }

  private pipeZipCodeValueChanges(stream: Observable<any>) {

    return stream.pipe(
      filter(
        (value: string) => {
          const val = (value != null ? value.length : 0);
          if (val === 0 && this.CountryCode === 'CO') { this.getAllMunicipios(); }
          return val >= 5;
        }
      ),
      debounceTime(300),
      distinctUntilChanged()
      , map(res => {
        // this.CostReady = false;
        return res;
      })
    );
  }

  refreshAddressZipCodeDetails(zipCode: string): Observable<void> {
    this.manualMunicipios = this.CountryCode === 'CO';
    return this.commonSvc.getZipCodeDetail(zipCode).pipe(
      map(zipCodeDetail => {
        if (zipCodeDetail.ZipCode === 'NoZipCode') {
          this.getAllMunicipios();
          if (this.CountryCode === 'CO') {
            this.addressZipCodeDetail = null;
          } else {
            this.manualMunicipios = true;
            this.billingAddressForm.patchValue({
              City: '',
              State: '',
              CityAndState: '',
              StateName: '',
            });
            if (this.useNeighborhoodCombo) {
              this.billingAddressForm.patchValue(
                { Neighborhood: '' }
              );
            }
          }

          this.addressZipCodeDetail = null;

        } else {
          this.addressZipCodeDetail = zipCodeDetail;
          const actualNeighborhood = this.billingAddressForm.get('Neighborhood').value;
          const State = '[{ "code": "' + zipCodeDetail.StateCode + '" , "name": "' + zipCodeDetail.StateName + '"}]';
          this.state = JSON.parse(State);
          const Mun = '[{ "Municipio": "' + zipCodeDetail.City + '" }]';
          this.municipio = JSON.parse(Mun);

          if (this.CountryCode === 'MX' || this.CountryCode === 'ES') {
            this.billingAddressForm.patchValue({
              City: zipCodeDetail.City,
              State: zipCodeDetail.StateCode,
              CityAndState: zipCodeDetail.City + ', ' + zipCodeDetail.StateCode,
            });
            if (this.useNeighborhoodCombo) {
              this.billingAddressForm.patchValue({
                Neighborhood: zipCodeDetail.Neighborhoods.includes(actualNeighborhood) ? actualNeighborhood : null
              });
            }
            this.manualMunicipios = false;
          } else if (this.CountryCode === 'CO') {
            this.getAllMunicipios();
            const currentDane = this.billingAddressForm.get('DaneShort').value;
            if (!currentDane || !currentDane.length || this.addressZipCodeDetail.Dane !== currentDane) {
              this.billingAddressForm.patchValue({
                City: '',
                State: '',
                CityAndState: '',
                StateName: '',
                Dane: '',
                DaneShort: '',
              });
              this.manualMunicipios = false;
              setTimeout(() => {
                this.manualMunicipios = true;
              }, 100);
            }
            this.filterCities(this.addressZipCodeDetail.Dane);
            if ( zipCodeDetail.Neighborhoods &&  zipCodeDetail.Neighborhoods.length) {
              if (zipCodeDetail.Neighborhoods.includes(actualNeighborhood) && (this.countryCode === 'MX' || this.countryCode === 'ES')) {
                this.billingAddressForm.patchValue({
                  Neighborhood: actualNeighborhood
                });
              }
            }
          }
        }

      })
    );
  }

  private filterCities(dane) {
    const filtered = this.allmunicipios.filter(mun => mun.daneShort === dane);
    if (filtered && filtered.length) {
      this.allmunicipios = filtered;
    }
  }

  changeMun(data = null) {
    if (!data) {
      this.billingAddressForm.patchValue({
        City: '',
        State: '',
        StateName: '',
        CityAndState: ''
      });
      this.getZipsByDane('');
      return;
    }
    if (this.CountryCode === 'CO') {
      this.billingAddressForm.patchValue({
        City: data.ciudad,
        State: data.StateCode,
        StateName: data.departamento,
        CityAndState: data.ciudad + ', ' + data.StateCode,
        Dane: data.dane,
        DaneShort: data.daneShort
      });

      if (this.addressZipCodeDetail && this.addressZipCodeDetail.Dane && this.addressZipCodeDetail.Dane !== data.daneShort) {
        this.billingAddressForm.patchValue({ ZipCode: '' });
      }

      if (this.billingAddressForm.get('Address1').value !== '') {
        this.getZipsByDane(data.daneShort, this.billingAddressForm.get('Address1').value, data.Municipio, data.departamento, true);
      } else {
        this.getZipsByDane(data.daneShort, data.ciudad, data.Municipio, data.departamento, true);
      }

    } else {
      this.billingAddressForm.patchValue({
        City: data.Municipio,
        State: data.StateCode,
        StateName: data.StateName,
        CityAndState: data.Municipio + ', ' + data.StateCode
      });
    }
  }

  changeReg(data = null) {
    if (!data) {
      this.billingAddressForm.get('CFDIUseCode').reset();
      this.billingAddressForm.patchValue({
        Regimen: '',
        ClaveRegimen: ''
      });
    } else {
      const { BillingTaxRegimeId, Description } = data;
      this.billingAddressForm.patchValue({
        Regimen: Description,
        ClaveRegimen: BillingTaxRegimeId,
      });
      this.billingAddressForm.get('CFDIUseCode').reset();
      this.getCFDiUseCatalog(BillingTaxRegimeId);
    }
  }

  private getCFDiUseCatalog(billingTaxRegimeId: number, currentCFDiSelected?: string): void {
    this.loadingService.show();
    this.commonSvc.getCFDiCatalogByTaxRegimen(billingTaxRegimeId).subscribe(response => {
      this.loadingService.hide();
      this.CFDIUse = response;
      if (currentCFDiSelected && !response.some(({BillingCFDIUseCodeKey}) => BillingCFDIUseCodeKey === currentCFDiSelected)) {
        this.reseller.billingInfo.CFDIUseCode = null;
        this.billingAddressForm.get('CFDIUseCode').reset();
      }
    }, error => {
      this.growlSvc.growlError('Hubo un problema al cargar el cátalogo de Usos de CFDi.');
    });
  }

  changeDT(data = null) {
    if (!data) {
      this.billingAddressForm.patchValue({
        DocumentType: '',
        ClaveDocumentType: ''
      });
      return;
    }
    if (this.CountryCode === 'CO') {
      this.billingAddressForm.patchValue({
        DocumentType: data.descripcion,
        ClaveDocumentType: data.clave,
      });
    }
  }

  changePT(data = null) {
    if (!data) {
      this.regimenValues = [];
      this.CFDIUse = [];
      this.billingAddressForm.patchValue({
        PersonType: '',
        ClavePersonType: '',
      });
      this.billingAddressForm.get('Regimen').reset();
      this.billingAddressForm.get('CFDIUseCode').reset();
    } else if (this.CountryCode === 'CO') {
      this.billingAddressForm.patchValue({
        PersonType: data.descripcion,
        ClavePersonType: data.clave
      });
    } else if (this.countryCode === 'MX') {
      const {clave} = data;
      this.regimenValues = this.taxRegimenCatalog
        .filter(taxRegimen => (clave === 'M' && taxRegimen.JuridicalPerson) || (clave === 'F' && taxRegimen.IndividualPerson));

      const currentSRegimenSelected = this.billingAddressForm.get('Regimen').value;
      if (currentSRegimenSelected && !this.regimenValues.some(taxRegime => taxRegime.Description === currentSRegimenSelected)) {
        this.billingAddressForm.get('Regimen').reset();
        this.billingAddressForm.get('CFDIUseCode').reset();
        this.CFDIUse = [];
      }

      this.billingAddressForm.patchValue({
        PersonType: data.descripcion,
        ClavePersonType: data.clave
      });
    }
  }

  private getZipsByDane(dane: string, address = '', town = '', department = '', autoComplete = false) {
    if (!dane || !dane.length) {
      this.ZipcodesDetails = [];
      return;
    }
    this.commonSvc.getZipCodeDetailsByDane(dane, address, town, department).subscribe(data => {
      this.ZipcodesDetails = data;
      if (autoComplete) {
        this.billingAddressForm.patchValue({ZipCode: this.ZipcodesDetails[0].ZipCode});
      }
    });
  }

  municipiosSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    const state = (environment.countryCode === 'MX' || environment.countryCode === 'ES') ? 'StateName' : 'departamento';
    const city = (environment.countryCode === 'MX' || environment.countryCode === 'ES') ? 'Municipio' : 'ciudad';
    return item[city].normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLocaleLowerCase().indexOf(term) > -1 || item[state].normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLocaleLowerCase().indexOf(term) > -1;
  }

  private getAllMunicipios() {
    this.allmunicipios = [];
    this.municipiosLoading = true;
    this.commonSvc.getAllMunicipios(this.CountryCode).subscribe(data => {
      this.municipiosLoading = false;
      this.allmunicipios = data;
      this.allMunReady = true;
      if (this.selectMun) {
        this.selectMun = false;
        setTimeout(() => { this.selectOneMun(); }, 100);
      }
    }, err => { this.municipiosLoading = false; });
  }

  private selectOneMun() {

    if (this.allMunReady && this.CountryCode === 'CO' && this.manualSelectionDropdown) {
      this.selectMun = false;
      const dane = this.billingAddressForm.get('Dane').value;
      if (dane && dane.length) {
        const found = this.manualSelectionDropdown.itemsList.findItem(dane);
        if (found) {
          this.manualSelectionDropdown.select(found);
        }
      }
    }
  }

  editBillingInfo() {
    this.billingAddressSubmitted = false;
    this.billingAddressForm.controls['ZipCode'].setValue(this.reseller.billingInfo.ZipCode);
    this.setZipcodeSub();
    if (this.manualMunicipios) {
      this.getAllMunicipios();
    }
    this.billingAddressForm.reset(this.reseller.billingInfo);
    const personType = this.personTypeValuesMX
      .find( ({clave, descripcion}) => clave === this.reseller.billingInfo.PersonType || descripcion === this.reseller.billingInfo.PersonType);
    this.changePT(personType);
    this.editingBillingInfo = true;
  }

  saveResellerBillingInfo() {
    const billingInfoModel = { ...this.billingAddressForm.value };
    billingInfoModel.BillingInfoId = this.reseller.billingInfo.BillingInfoId;
    this.laddaBillingInfo = true;
    this._resellerSvc.saveResellerBillingInfo(billingInfoModel)
      .subscribe(
        res => {
          this._growlSvc.growlSuccess('La información fue guardada con exito');
          this.completeEvent.emit();
          this.reseller.billingInfo = res;
          if (this.reseller.billingInfo.PersonType.length === 1) {
            const personType = this.personTypeValuesMX.find(p => p.clave === this.reseller.billingInfo.PersonType);
            this.reseller.billingInfo.PersonType = personType ? personType.descripcion : this.reseller.billingInfo.PersonType;
          }
          this.editingBillingInfo = false;
          this.laddaBillingInfo = false;
        },
        err => {
          this._growlSvc.growlError(err);
          this.laddaBillingInfo = false;
        }
      );
  }


  private getReseller() {
    this._resellerSvc.getAuthReseller()
      .subscribe(
        res => {
          this.reseller = res;

          this.resellerInfoForm.patchValue(this.reseller);

          this.frequentAddresses = this.reseller.addresses ? this.reseller.addresses : [];

          this.reseller.applications = this.reseller.applications ? this.reseller.applications : [];
          this.reseller.billingInfo = this.reseller.billingInfo ? this.reseller.billingInfo : this.notBillingInfo();
          if (this.reseller.billingInfo.TaxId.trim() === '') {
            this.editBillingInfo();
            this.editingBillingInfo = true;
          }

          if (this.reseller.billingInfo.PersonType && this.reseller.billingInfo.PersonType.length === 1) {
            const personType = this.personTypeValuesMX.find(p => p.clave === this.reseller.billingInfo.PersonType);
            this.reseller.billingInfo.PersonType = personType ? personType.descripcion : this.reseller.billingInfo.PersonType;
            this.changePT(personType);
          }
          if (!isNaN(parseFloat(this.reseller.billingInfo.ClaveRegimen))) {
            this.getCFDiUseCatalog(Number(this.reseller.billingInfo.ClaveRegimen), this.reseller.billingInfo.CFDIUseCode);
          }
          // Transferencia electronica como valor default
          if (!this.reseller.billingInfo.PaymentMethodCode) {
            this.billingAddressForm.patchValue({ PaymentMethodCode: '03' });
          }
          this.reseller.serviceConfigs = this.reseller.serviceConfigs ? this.reseller.serviceConfigs : [];

          this.apiKey = this.reseller.applications[0] ? this.reseller.applications[0].restApiKey : '';
        },
        err => this._growlSvc.growlError(err)
      );
  }

  private notBillingInfo() {
    this.editBillingInfo();
    this.editingBillingInfo = true;
    return new ResellerBillingInfo();
  }

  private createResellerInfoForm() {
    this.resellerInfoForm = this._fb.group({
      Name: ['', Validators.required],
      SendRecipientNotifications: [{ value: false, disabled: !this.editingResellerInfo }]
    });
  }

  private createBillingAddressForm() {
    const formModel = {
      TaxName: this.CountryCode === 'MX' ? ['', [Validators.required, socialDenominationValidator]] : ['', [Validators.required]],
      TaxId: ['', Validators.required],
      Email: ['', Validators.email],
      ZipCode: [null, Validators.compose([Validators.required, CustomValidators.zipCode])],
      State: ['', Validators.required],
      City: ['', Validators.required],
      Neighborhood: ['', Validators.required],
      Address1: ['', Validators.required],
      Address2: ['', Validators.maxLength(30)],
      CityAndState: [{value: null, disabled: true}],
      Dane: [''],
      DaneShort: [''],
      StateName: (this.CountryCode === 'CO') ? ['', Validators.required] : [''],
      Regimen: (this.CountryCode === 'MX') ? ['', Validators.required] : [''],
      ClaveRegimen: (this.CountryCode === 'MX') ? ['', Validators.required] : [''],
      DocumentType: (this.CountryCode === 'CO') ? ['', Validators.required] : [''],
      ClaveDocumentType: (this.CountryCode === 'CO') ? ['', Validators.required] : [''],
      PersonType: (this.CountryCode === 'CO') ? ['', Validators.required] : [''],
      ClavePersonType: (this.CountryCode === 'CO') ? ['', Validators.required] : [''],

      PaymentTermsCode: [''], // Forma de pago
      PaymentMethodCode: ['', [Validators.required]], // Método de pago
      CFDIUseCode: (this.CountryCode === 'MX') ? ['', Validators.required] : [{value: 'G03'}]
    };
    if (this.CountryCode === 'CO') {
      formModel.ZipCode = [null, Validators.compose([Validators.required, CustomValidators.zipCodeCo])];
    }
    this.billingAddressForm = this._fb.group(formModel);
  }

  private getPaymentTerms() {
    this.commonSvc.getPaymentTermsCatalog()
      .subscribe(
        res => {
          this.paymentTerms = res;
        },
        err => this._growlSvc.growlError(err)
      );
  }

  private getPaymentMethods() {
    this.commonSvc.getPaymentMethodsCatalog()
      .subscribe(
        res => {
          this.paymentMethods = res;
        },
        err => this._growlSvc.growlError(err)
      );
  }

  private getCFDIUse() {
    let personType = this.billingAddressForm.getRawValue().PersonType;
    if (!personType || personType.length < 1) {
      personType = 'F';
    }
    this.commonSvc.getCFDIUseCatalog(personType)
      .subscribe(
        res => {
          this.CFDIUse = res;
        },
        err => this._growlSvc.growlError(err)
      );
  }

  getPaymentTermsDescription (key: string) {
    if (key) {
      const paymentTerms = this.paymentTerms.find(x => x.key === key);
      return paymentTerms ? paymentTerms.description : '';
    }
    return '';
  }

  getPaymentMethodsDescription (key: string) {
    if (key) {
      const paymentMethod = this.paymentMethods.find(x => x.key === key);
      return paymentMethod ? paymentMethod.description : '';
    }
    return '';
  }

  getCFDIDescription (key: string) {
    if (key) {
      const CFDIUse = this.CFDIUse.find(({BillingCFDIUseCodeKey}) => BillingCFDIUseCodeKey === key);
      return CFDIUse ? CFDIUse.Description : '';
    }
    return '';
  }

  changePaymentTerms(value = null) {
    if (value) {
      this.billingAddressForm.controls['PaymentMethodCode'].setValidators([Validators.required]);
      this.billingAddressForm.controls['PaymentTermsCode'].setValidators([Validators.required]);
      this.billingAddressForm.controls['CFDIUseCode'].setValidators([Validators.required]);
    } else {
      this.billingAddressForm.controls['PaymentMethodCode'].clearValidators();
      this.billingAddressForm.controls['PaymentTermsCode'].clearValidators();
      this.billingAddressForm.controls['CFDIUseCode'].clearValidators();
    }
    this.billingAddressForm.controls['PaymentMethodCode'].updateValueAndValidity();
    this.billingAddressForm.controls['PaymentTermsCode'].updateValueAndValidity();
    this.billingAddressForm.controls['CFDIUseCode'].updateValueAndValidity();
  }

  ngOnDestroy() {
    this.$onDestroy.next();
    this.$onDestroy.complete();
  }
}
