import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { LayoutService } from '../default-layout/service/layout.service';

@Component({
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss']
})
export class PublicLayoutComponent implements OnInit {
  CountryCode = environment.countryCode;
  fullfillment=false;
  noticePrivacy: string;
  termsAndConditions: string;
  opts = environment;

  constructor(
    public layoutService: LayoutService,
    private translateServ: TranslateService
  ) {
    // this.translateServ.get(['publicLayout']).subscribe(result => {
    //   this.noticePrivacy = result.noticePrivacy;
    //   this.termsAndConditions = result.termsAndConditions;
    // });
  }

  ngOnInit(){
    this.layoutService.fullfillment.subscribe(flag =>{
      this.fullfillment = flag;
    });
  }
}
