import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneVerificationComponent } from '../views-reseller/reseller-common/phone-verification.component';
import { ModalModule } from './modal.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LaddaModule } from 'angular2-ladda';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
@NgModule({
  imports: [MatProgressSpinnerModule,CommonModule, ModalModule, FormsModule, ReactiveFormsModule, LaddaModule, NgxIntlTelInputModule],
  declarations: [
    PhoneVerificationComponent
  ],
  exports: [
    PhoneVerificationComponent
  ]
})
export class PhoneVerificationModule { }
