import { Pipe, PipeTransform } from '@angular/core';

enum RouteImage {
    // PAKKE_BRAND = 'assets/img/brand/pakke.svg',
    CREATE_ORDER = 'assets/img/custom/create_order.svg',
    CREATE_SHIPMENT = 'assets/img/custom/create_shipment.svg',
    PICKUP = 'assets/img/custom/pickup.svg',
    SEARCH = 'assets/img/custom/seach.svg',
    NOTINFORMATION ='assets/img/custom/not_information.svg',
    BULK_TEMPLATE = 'assets/img/custom/bulk_template.svg',
    BULK_STORE = 'assets/img/custom/bulk_store.svg',
    BULK_UPLOAD = 'assets/img/custom/bulk_upload.svg',
    SIDE_STOCKTAKING_CARD = 'assets/img/icons/CrearArticulos.svg',
    SIDE_ORDER_CARD = 'assets/img/icons/GenerarSolicitudes.svg',
}

@Pipe({
    name: 'AppImage'
})
export class AppImagePipe implements PipeTransform {

    /**
     * Devuelve la ruta donde se obtendrá la imagen.
     * @param imgId Identificador de la imagen
     */
    transform( imgId: any ): string {
        let result = RouteImage[imgId];
        if(!result){
            result = '';
        }
        return result;
    }
}
