import { Injectable } from '@angular/core';
import * as LE from "../../../../assets/js/le.min.js";
import { environment } from '../../../../environments/environment';
LE.init(environment.logEntryKey);

@Injectable()
export class LogUtilService {
    
    error(sProceso: string,sId:string,oParametros:any,sDescripcion:string) {
        let NewMsg = {};
        NewMsg = {
            Proceso:sProceso
            ,Proyecto:"UI"
            ,Estatus:"[ERROR]"
            ,Descripcion: sDescripcion
            ,Id:sId
            ,Parametros:oParametros
        }
        LE.error(JSON.stringify(NewMsg));
    }

    info(sProceso: string,sId:string,oParametros:any,sDescripcion:string) {
        let NewMsg = {};
        NewMsg = {
            Proceso:sProceso
            ,Proyecto:"UI"
            ,Estatus:"[OK]"
            ,Descripcion: sDescripcion
            ,Id:sId
            ,Parametros:oParametros
        }
        LE.log(NewMsg);
    }

}