import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label, BaseChartDirective } from 'ng2-charts';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {
    @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

    @Input() chartType = 'line';

    @Input() lineChartData: ChartDataSets[] = [
        { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Órdenes' },
    ];
    @Input() lineChartLabels: Label[] = [];

    @Input() lineChartColors: Color[] = [];

    @Input() lineChartOptions: (ChartOptions) = {
        responsive: true,
        animation: {
            animateRotate: true,
        },
        scales: {},
        plugins: {
            datalabels: {
                color: '#ffffff',
                font: {
                    weight: 'bold'
                }
            }
        }
    };

  public lineChartPlugins = [ChartDataLabels];


  constructor() { }

  ngOnInit() {
  }

}
