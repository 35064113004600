import {Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap';
import {Observable} from 'rxjs';
import {ModalConfirmGenericStepsEnum} from './modal-confirm-generic-steps.enum';

@Component({
  selector: 'app-modal-confirm-generic',
  templateUrl: './modal-confirm-generic.component.html',
  styleUrls: ['./modal-confirm-generic.component.scss']
})
export class ModalConfirmGenericComponent {
  @Input() headerTemplate: TemplateRef<any>;
  @Input() messageTemplate: TemplateRef<any>;
  @Input() messageSuccessTemplate: TemplateRef<any>;
  @Input() messageErrorTemplate: TemplateRef<any>;
  @Input() action: Observable<any>;

  @Output() closeOn = new EventEmitter<ModalConfirmGenericStepsEnum>();
  @Output() responseAction = new EventEmitter<any>();
  @Output() errorAction = new EventEmitter<any>();

  currentStep: ModalConfirmGenericStepsEnum = ModalConfirmGenericStepsEnum.INITIAL;

  constructor(public bsModalRef: BsModalRef) {
  }

  public get stepModal(): typeof ModalConfirmGenericStepsEnum {
    return ModalConfirmGenericStepsEnum;
  }

  closeModal() {
    this.bsModalRef.hide();
    this.closeOn.emit(this.currentStep);
  }

  executeAction() {
    this.currentStep = ModalConfirmGenericStepsEnum.LOADING;
    this.action.subscribe(
      response => {
        this.responseAction.emit(response);
        this.currentStep = ModalConfirmGenericStepsEnum.SUCCESS;
      },
      error => {
        console.error(error);
        this.errorAction.emit(error);
        this.currentStep = ModalConfirmGenericStepsEnum.ERROR;
      }
    );
  }
}
