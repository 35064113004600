import { Component,ViewChild,OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CargosPendientes } from 'src/app/core/models/models';
import { ResellerService } from '../../core/services/domain/reseller.service';
import { GrowlSvc } from '../../core/services/growl.service';
import { environment } from 'src/environments/environment';
import { FormatHelperService } from 'src/app/core/services/util/format-helper.service';

@Component({
  
  templateUrl: 'pending-payments.component.html',
  styleUrls: ['pending-payments.component.scss']
})

export class PendingPaymentsComponent implements OnInit {

  displayedColumns: string[] = ['Courier', 'TrackingNumber', 'Amount', 'Description','Date'];
  isLoading = true;
  dataSource = null;

  countryCode = environment.countryCode;
  currencyDecimal = (environment.countryCode === "MX" || environment.countryCode === "ES") ? "1.2-2" : "1.0-0";
  defaultTextDecimal = (environment.countryCode === "MX" || environment.countryCode === "ES") ? "0.00" : "0";
  currencySymbol = environment.countryCode === "MX" ? "MXN" : environment.countryCode === "ES" ? "EUR" : "COP";
  languageCode = environment.languageCode;

  @ViewChild(MatPaginator , { static : true } ) paginator: MatPaginator;

  goBack(){ }

  constructor(
    public dialogRef: MatDialogRef<PendingPaymentsComponent>,
    private resellerSvc: ResellerService,
    private growlSvc: GrowlSvc,
    private formatHelp: FormatHelperService) { }
   
  
  ngOnInit() {
    
    this.getPendingCharges();
  }

  formatCurrency(value) { 
    return this.formatHelp.formatCurrency(this.languageCode, this.currencySymbol, value);
  }

  getPendingCharges(){
  
    this.resellerSvc.getPendingCharges()
    .subscribe(
      res => {
        this.dataSource = new MatTableDataSource<CargosPendientes>(res);
        this.dataSource.paginator = this.paginator;

        this.isLoading = false;
       
      },
      err => {
        this.growlSvc.growlError(err);
      }
    );
  
  
  }
  
}
