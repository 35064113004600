import { Component, ViewChild, EventEmitter, Output, OnDestroy, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { GrowlSvc } from '../../core/services/growl.service';
import { ResellerService } from '../../core/services/domain/reseller.service';
import { CommonService } from '../../core/services/common.service';

import { ResellerAddress, ZipCodeDetail } from '../../core/models/models';

import { ModalComponent } from '../../shared/components/modal.component';
import { finalize, tap, filter, debounceTime, switchMap, map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { AddressType } from '../../core/constants';
import { AddressFormComponent } from 'src/app/address-form/address-form/address-form.component';


@Component({
  selector: 'app-edit-frequent-address',
  templateUrl: './edit-frequent-address.component.html'
})

export class EditFrequentAddressComponent implements OnInit, OnDestroy {
  /*
  When the address type property is set, the form doesn't show
  the address usage controls (IsOriginAddress and IsDestinationAddress),
  and the address will be assigned to the type specfied under addressTypeMode
  */
  @Input() addressTypeMode?: AddressType;
  @ViewChild("addresFormDetination", { static: false })
  addresFormDetination!: AddressFormComponent;
  selectedRecipientFrequentAddress: ResellerAddress;
  nextStep(){

  }
  @Output() saved = new EventEmitter();
  @Output() closed = new EventEmitter();
  @Input() title = 'Editar dirección';

  @ViewChild(ModalComponent , { static : true } ) private modal: ModalComponent;
  saving = false;
  submitted = false;

  get isAddressTypeModeSet(): boolean {
    return !!this.addressTypeMode;
  }

  private editingModel: ResellerAddress;

  deleteCount: number | undefined;
  maxAddresses: boolean | undefined;
  origenFirst: boolean | undefined;
  constructor(
    private fb: FormBuilder,
    private growlSvc: GrowlSvc,
    private resellerSvc: ResellerService,
    public commonSvc: CommonService
  ) {
  }

  ngOnInit(): void {
   
  }

  ngOnDestroy(): void {
  }

  show(address?: ResellerAddress,deleteCount?: number, maxAddresses?: boolean) {
    this.submitted = false;
    this.modal.show();
    this.deleteCount = deleteCount;
    this.maxAddresses = maxAddresses;
    this.editingModel = address || new ResellerAddress();
    if(address){
      this.origenFirst = address.IsOriginAddress;
      this.addresFormDetination.address=address;
    }
  }

  hide() {
    this.addresFormDetination.reset();
    this.modal.hide();
    this.closed.emit(true);
  }

  save() {
    this.submitted = this.addresFormDetination.addressFormSubmitted = true;
    this.selectedRecipientFrequentAddress = this.addresFormDetination.getAddres();

    if (!this.selectedRecipientFrequentAddress) {
      return;
    }

    if (this.addresFormDetination.addressForm.invalid) {
      return;
    }
    const twoSelected = this.selectedRecipientFrequentAddress.IsDestinationAddress && this.selectedRecipientFrequentAddress.IsOriginAddress;
    if ((!this.selectedRecipientFrequentAddress.IsDestinationAddress && !this.selectedRecipientFrequentAddress.IsOriginAddress) || twoSelected){
      this.growlSvc.growlWarn("Selecciona si es una dirección de origen o destino");
      return;
    }
    if ((this.selectedRecipientFrequentAddress.IsOriginAddress) && this.maxAddresses && !this.origenFirst){
      this.growlSvc.growlWarn("Limite de direcciones de origen alcanzado");
      return;
    }

    if(this.editingModel && this.editingModel.AddressId){
      this.selectedRecipientFrequentAddress.AddressId=this.editingModel.AddressId;
    }
    this.saving = true;

    this.resellerSvc.saveResellerAddress(this.selectedRecipientFrequentAddress)
      .pipe(
        tap((updatedModel) => {
          this.growlSvc.growlSuccess('La información se ha guardado');
          this.addresFormDetination.reset();
          this.modal.hide();
          this.saved.emit(updatedModel);
        }),
        finalize(() => this.saving = false)
      )
      .subscribe(undefined, err => this.growlSvc.growlError(err));
  }
}
