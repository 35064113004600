import { Component, ViewChild, EventEmitter, Output, OnDestroy, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { GrowlSvc } from '../../core/services/growl.service';
import { ResellerService } from '../../core/services/domain/reseller.service';
import { CommonService } from '../../core/services/common.service';

import { ResellerAddress, ZipCodeDetail } from '../../core/models/models';

import { ModalComponent } from '../../shared/components/modal.component';
import { finalize, tap, filter, debounceTime, switchMap, map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { AddressType } from '../../core/constants';
import { AddressFormComponent } from 'src/app/address-form/address-form/address-form.component';

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();


@Component({
  selector: 'app-edit-detail-address',
  templateUrl: './edit-detail-address.component.html'
})

export class EditDetailAddressComponent implements OnInit, OnDestroy {

  @Input() addressTypeMode?: AddressType;
  @ViewChild("addresFormDetination", { static: false })
  addresFormDetination!: AddressFormComponent;
  selectedRecipientFrequentAddress: ResellerAddress;
  @Output() saved = new EventEmitter<ResellerAddress>();
  @Output() closed = new EventEmitter();
  @Input() title = 'Editar dirección';
  @Input() orderId = '';

  @ViewChild(ModalComponent , { static : true } ) private modal: ModalComponent;
  saving = false;
  submitted = false;
  formValid = false;


  get isAddressTypeModeSet(): boolean {
    return !!this.addressTypeMode;
  }

  private editingModel: ResellerAddress;

  constructor(
    private fb: FormBuilder,
    private growlSvc: GrowlSvc,
    private resellerSvc: ResellerService,
    public commonSvc: CommonService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  show(address?: ResellerAddress, orderId?: any) {
    this.submitted = false;
    this.orderId = orderId;
    this.editingModel = address || new ResellerAddress();

    if (address) {
      this.addresFormDetination.address = address;
    }

    this.modal.show();
  }

  hide() {
    this.addresFormDetination.reset();
    this.modal.hide();
    this.closed.emit(true);
  }

  save() {
    this.submitted = this.addresFormDetination.addressFormSubmitted = true;
    this.selectedRecipientFrequentAddress = this.addresFormDetination.getAddres();

    if (!this.selectedRecipientFrequentAddress) {
      return;
    }
    if(this.editingModel && this.editingModel.AddressId){
      this.selectedRecipientFrequentAddress.AddressId=this.editingModel.AddressId;
    }
    this.saving = true;
    this.selectedRecipientFrequentAddress.OrderId=this.orderId;

    const phoneNumber = phoneUtil.parseAndKeepRawInput(this.selectedRecipientFrequentAddress.Phone1);

    if (!phoneUtil.isValidNumber(phoneNumber)) {
        this.growlSvc.growlError('El formato del teléfono es invalido');
        this.saving = false;
        return;
    }

    this.resellerSvc.updateAddress(this.selectedRecipientFrequentAddress)
      .pipe(
        tap((updatedModel) => {
          if(updatedModel.errorCode===201){
            this.growlSvc.growlSuccess('La información se ha modificado correctamente');
            this.modal.hide();
            this.saved.emit(this.selectedRecipientFrequentAddress);
            this.addresFormDetination.reset();
          }
          else{
            this.growlSvc.growlError(updatedModel.message);
          }

        }),
        finalize(() => {
            this.saving = false;
            this.formValid = false;
        })
      )
      .subscribe(undefined, err => this.growlSvc.growlError(err));
  }

  formChange(formValid) {
    this.formValid = formValid;
  }
}
