import { NgModule } from "@angular/core";
import { SearchComponent } from "./search.component";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../../shared/shared.module';
import {MatInputModule} from '@angular/material';
import {MatRadioModule } from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';
@NgModule({
  imports: [
    SharedModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    ReactiveFormsModule
  ],
  declarations: [
    SearchComponent
  ],
  exports: [
    SearchComponent,
  ],
  //providers: [DatePipe],
  entryComponents:[ ]
})
export class SearchModule {}
