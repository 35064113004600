import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService, CommonService} from '../services';
import {concatMap, map} from 'rxjs/operators';
import {of, from} from 'rxjs';
import { StoreService } from 'src/app/views-reseller/store/service/store.service';

@Injectable({
  providedIn: 'root'
})
export class ResellerGlobalConfigGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private storeService: StoreService,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.authenticationService.user$.pipe(
      concatMap((userState) => {

        if(this.storeService.marketplaceIcons.length == 0) {
          this.storeService.fillMarketplaceStores().then(() => {
            console.log("[INFO] Finish fill Marketplace Store Info");
          });
        }

          if (!userState.resellerGlobalConfig) {
            return from(this.commonService.getMyConfig()).pipe(
              map((response) => {
                  if (response) {
                    const local = JSON.parse(localStorage.getItem('accessToken'));
                    local.ResellerGlobalConfig = response;
                    localStorage.setItem('accessToken', JSON.stringify(local));
                    userState.resellerGlobalConfig = response;
                    this.authenticationService.updateUserState(userState);
                    return true;
                  } else {
                    this.authenticationService.logout();
                    return false;
                  }
                }
              ),
            );
          } else {
            return of(true);
          }
        }
      )
    );
  }

}
