import { Component, Input, OnInit } from '@angular/core';
import { LayoutService } from 'src/app/containers/default-layout/service/layout.service';
import { AuthenticationService, GrowlSvc, ResellerService } from 'src/app/core/services';

@Component({
  selector: 'app-modal-onboarding',
  templateUrl: './modal-onboarding.component.html',
  styleUrls: ['./modal-onboarding.component.scss']
})
export class ModalOnboardingComponent implements OnInit {

  showModalOnboarding = false;
  public visible = false;
  public visibleAnimate = false;

  @Input() dilogClass = '';
  @Input() useModal = true;

  constructor(
    public growlSvc: GrowlSvc,
    public layoutService: LayoutService,
    private auth: AuthenticationService,
    private _resellerSvc: ResellerService
  ) { }

  ngOnInit(){
    const token = this.auth.getTokenInfo();
    if(token && token.ResellerGlobalConfig && !token.ResellerGlobalConfig.DontShowAgainOnboarding){
      this.showModalOnboarding = true;
    }
  }

  public show(toggle = true): void {
    if(this.showModalOnboarding){
      if (this.useModal && toggle) {
        this.layoutService.toggleSide(false);
      }
      setTimeout(() => { this.visibleAnimate = true; this.visible = true; }, 100);
    }
  }

  public hide(toggle = true): void {
    if (window.innerWidth > 960 && toggle) { this.layoutService.toggleSide(true); }
    this.visibleAnimate = false;
    setTimeout(() => this.visible = false, 300);
  }

  public preferentOnboarding(dontShowAgain = false){

    this.hide();

    localStorage.setItem("notifyDay",this.getDateLocalStorage());

    if(dontShowAgain){
      const config = {
        DontShowAgainOnboarding: true,
        Orden_Compra: 0,
        ResellerId: '',
        ResellerGlobalConfigId: '',
        Batches: 0,
        ShipmentGeneration: 0
      }
      this._resellerSvc.saveResellerGlobalConfig(config).subscribe((res) =>{
        this.auth.updateTokenInfoOnboarding(res['DontShowAgainOnboarding']);
      })
    }
  }

  getDateLocalStorage(){
    let dFecha = new Date();
    return dFecha.getFullYear().toString()+"-"+dFecha.getMonth().toString()+"-"+dFecha.getDay().toString();
  }

}
