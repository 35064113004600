import { Injectable, EventEmitter, Output } from '@angular/core';
import { Layout, LayoutModels } from '../model/layout';
import { Router } from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {ItemRequest} from '../../../core/models/models';

@Injectable()
export class LayoutService {

  @Output() layoutSet: EventEmitter<Layout> = new EventEmitter<Layout>();
  @Output() sideToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() action: EventEmitter<LayoutModels.Action> = new EventEmitter<LayoutModels.Action>();
  @Output() fullfillment: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onboardingUpdate: EventEmitter<void> = new EventEmitter<void>();
  private isFullfillment = new BehaviorSubject(localStorage.getItem('fullfillment') === '1');
  isFullfillmentObservable = this.isFullfillment.asObservable();
  sideToggleVar = true;
  editable = true;
  owner = true;
  lstItems: Array<ItemRequest> = [];

  constructor(
      private router: Router,

  ) { }

  setLayout(message: Layout) {
    this.layoutSet.emit(message);
    if (window.innerWidth > 960) { this.toggleSide(true); }
  }

  setFullfillment(flag:boolean)
  {
    this.fullfillment.emit(flag);
    this.isFullfillment.next(flag);
  }

  toggleSide(val = !this.sideToggleVar) {
    this.sideToggleVar = val;
    this.sideToggle.emit(val);
  }

  exeAction(action: LayoutModels.Action) {
      if (action.url) {
          this.router.navigate([action.url]);
          return ;
      }
      this.action.emit(action);
  }

  upOboarding() {
    this.onboardingUpdate.emit();
  }
}
