import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "orderStatusFF",
})
export class OrderStatusFFPipe implements PipeTransform {
  transform(status: string): string {
    switch (status) {
      case "Surtido":
        return "Procesando";

      default:
        return status;
    }
  }
}
