import { Injectable } from '@angular/core';
import { Observable, throwError, of, pipe, from } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';

import {
  Shipment,
  ShipmentItem,
  ShipmentQuoteRq,
  ShipmentRateOptions,
  ShipmentHistory,
  Notification,
  RegionMetric,
  GuiaBase64,
  OrderShipment, ITrackingInformation, RateShippingBase, CartaPorte64
} from 'src/app/core/models/models';
import { map, finalize, mergeMap, delay, switchMap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { FileUtilService } from 'src/app/core/services/util/file-util.service';
import { environment } from 'src/environments/environment';
import { Loader } from '@googlemaps/js-api-loader';

@Injectable()
export class ShipmentService {
  constructor(
    private apiService: ApiService,
    private authService: AuthenticationService,
    private fileUtil: FileUtilService
  ) { }

  getShipmentRates(shipmentQuoteRq: ShipmentQuoteRq): Observable<ShipmentRateOptions> {
    return this.apiService.post<ShipmentRateOptions>('Shipments/rates', shipmentQuoteRq);
  }
  getShipmentCount(): Observable<number> {
    return this.apiService.get<number>('Shipments/countByReseller');
  }

  postRateShipping(data: RateShippingBase): Observable<any> {
    return this.apiService.post<any>(`Shipments/${data.token}/rate?score=${data.score}`);
  }

  postShipment(shipment: Shipment): Observable<Shipment> {
    return this.apiService.post<Shipment>('Shipments', shipment)
      .pipe(
        finalize(() => this.authService.refreshResellerBalance(5000, true))
      );
  }

  quoteAndGenerateMassiveShipments(shipment: any): Observable<Shipment> {
    return this.apiService.post<Shipment>('Shipments/quoteAndGenerateMassiveShipments', shipment);
  }

  postUpdateTrackingNumber(data: ITrackingInformation): Observable<any> {
    return this.apiService.post(`Orders/tracking/update`, data);
  }

  getShipmentById(id: string): Observable<ShipmentItem> {
    return this.apiService.get<ShipmentItem>('Shipments/getDetail/' + id)
      .pipe(
        map(data => {
          data.CreatedAt = new Date(data.CreatedAt);
          data.ExpiresAt = new Date(data.ExpiresAt);

          return data ? Object.assign(new ShipmentItem(), data) : null;
        })
      );
  }

  createAclaration(aclaration: object): Observable<any> {
    return this.apiService.post<any>('Shipments/createAclaration',aclaration);

  }

  // getAclarationHistory(shipmentId: string): Observable<any[]> {
  //   return this.apiService.get<any>('Shipments/getAclarationHistory/'+shipmentId);
  // }

  getAclarationHistory(shipmentId: string): Observable<any[]> {
    return this.apiService.get<any>('Shipments/clarificationLastDetails/'+shipmentId);
  }

  getShipmentHistory(id: string): Observable<ShipmentHistory[]> {
    return this.apiService.get<ShipmentHistory[]>('Shipments/' + id + '/history');
  }

  getShipmentNotifications(id: string): Observable<Notification[]> {
    return this.apiService.get<Notification[]>('Shipments/' + id + '/notifications/history')
      .pipe(map(res => res ? res.sort((a, b) => a.Date > b.Date ? -1 : 1) : []));
  }

  getCountryShipmentMetrics(year: Number, month: Number): Observable<RegionMetric[]> {
    return this.apiService.get<RegionMetric[]>('Shipments/metrics/states/' + year + '/' + month);
  }

  getPeriodShipmentMetrics(year: Number, month: Number): Observable<any[]> {
    return this.apiService.get<any[]>('Shipments/metrics/period/' + year + '/' + month);
  }

  downloadShipmentLabel(id: string, labelType: string, multi?: boolean) {
    const multiPackages = multi? multi : false;
    if (labelType === 'URL') {
      return this.apiService.get<GuiaBase64>(`Shipments/${id}/label?labelType=${labelType}`);
    } else {
    const extension = labelType === 'PDF' ? '.pdf' : '.lbl';
    const url = multi? `Shipments/packages/${id}?labelType=${labelType}` : `Shipments/${id}/label?labelType=${labelType}`
    if (!multi) {
      return this.apiService.get<GuiaBase64>(url)
      .pipe(
        mergeMap((shipmentLabel) => {
            if (shipmentLabel.LabelType === 'ZPL') {
              return this.fileUtil.SaveByteArrayZPLNewFile(shipmentLabel.TrackingNumber + extension, shipmentLabel.data);
            }
            return this.fileUtil.SaveBase64PDFNewFile(shipmentLabel.TrackingNumber + extension, shipmentLabel.data);
          }
        )
      );
    } else {
      return this.apiService.get<any>(url)
      .pipe(
        mergeMap(filesMultiShipment => {
          const files = filesMultiShipment.filter(f => f.data)
            .map(f => {
              return {
                fileNameWithExtension: f.TrackingNumber+extension,
                base64Data: f.data,
                mime: labelType === 'PDF' ? 'application/pdf' : 'application/x-lbl'
              };
            });

          if (files.length === 0) {
            return throwError({ message: 'No se encontraron los archivos.', severity: 'warn' });
          }

          return this.fileUtil.saveZipOfBase64Files(files,'shipments.zip')
            .then(() => files.map(f => f.fileNameWithExtension));
        })
      );
    }

    }
  }

  downloadShipmentLabelZPL(id: string, labelType: string) {
    return this.apiService.get<GuiaBase64>(`Shipments/${id}/label?labelType=${labelType}`);
  }

  downloadConsigmentNote(shipmentId: string) {
    return this.apiService.get<CartaPorte64>('Shipments/' + shipmentId + '/consigmentNote')
      .pipe(
        mergeMap(shipmentConsigmentNote  =>
          this.fileUtil.SaveBase64PDFNewFile( "CP-" + shipmentConsigmentNote.TrackingNumber + '.pdf', shipmentConsigmentNote.data)
        )
      );
  }

  /**
  * Downloads the shipment labels, as a zip file, for the supplied shipment IDs
  *
  * @param shipmentIds The shipment ids whose pdf label will be downloaded.
  * @returns An array of strings with the downloaded filenames.
  */
  downloadShipmentLabels(shipmentIds: string[]): Observable<string[]> {
    return this.apiService.post<GuiaBase64[]>('Shipments/labels', shipmentIds)
      .pipe(
        mergeMap(shipments => {
          // Ignore shipments whose label file doesn't exist
          const labelFiles = shipments.filter(s => s.data)
            .map(s => {
              return {
                fileName: s.TrackingNumber + '.pdf',
                base64Data: s.data
              };
            });

          if (labelFiles.length === 0) {
            return throwError({ message: `No se encontraron etiquetas para ${environment.countryCode === 'ES' ? 'los envíos' : 'las guías'} indicadas.`, severity: 'warn' });
          }

          return this.fileUtil.saveZipOfBase64PdfFiles(labelFiles, /*zipFileName*/ 'guias.zip')
            .then(() => labelFiles.map(f => f.fileName));
        })
      );
  }

  getShipmentsByTrackingNumber(trackingNumber: string): Observable<ShipmentItem[]> {
    return this.apiService.get<ShipmentItem[]>('Shipments/tracking?trackingNumber=' + trackingNumber.toLowerCase())
      .pipe(
        map(shipments => {
          shipments.forEach(s => {
            s.History = s.History.sort((a, b) => a.Date > b.Date ? -1 : 1);
          });

          return shipments;
        })
      );
  }

  requestRefund(shipmentId) {
    return this.apiService.post(`Shipments/${shipmentId}/requestRefund`);
  }

  saveShipmentToOrder(orderShipment: OrderShipment): Observable<OrderShipment> {
    return this.apiService.post<OrderShipment>('OrderShipments', orderShipment)
      .pipe(
        finalize(() => console.log('Relacion Order - Shipment Generada'))
      );
  }

  countShipmentByReseller() {
    return this.apiService.get<any>('Shipments/count')
      .pipe(
        finalize(() => console.log('Conteo de guias realizado'))
      );
  }

  getLastTrackingWebHookInfo(shipmentId): Observable<any> {
    return this.apiService.get<any>(`Shipments/LastTrackingWebHookInfo?shipmentId=${shipmentId}`);
  }

  downloadMassivePackList(data): Observable<any> {
    return this.apiService.post<any>('Shipments/createPackListMassiveShipmentsFromOrders', data)
      .pipe(
        mergeMap(detailsPDF =>
            this.fileUtil.SaveBase64PDFNewFile('OrderShipment' + '-packlist.pdf', detailsPDF)
        )
      );
  }

  async initMap(center: google.maps.LatLngLiteral, map: any, courierName: string, loader: Loader) {
    const { Map } = await loader.importLibrary('maps') as google.maps.MapsLibrary;
    map = new Map(document.getElementById('map') as HTMLElement, {
      center,
      zoom: 12,
      mapId: 'Curiers-services'
    });

    this.nearBySearch(center, map, courierName, loader);
  }

  async nearBySearch(center: google.maps.LatLngLiteral, map: any, courierName: string, loader: Loader) {
    const { Place } = await loader.importLibrary('places') as google.maps.PlacesLibrary;
    const { AdvancedMarkerElement } = await loader.importLibrary('marker') as google.maps.MarkerLibrary;

    const request = {
      textQuery: courierName,
      fields: ['displayName', 'location', 'businessStatus'],
      locationBias: center,
      isOpenNow: false,
      maxResultCount: 8,
      language: 'es-MX',
      region: 'es',
      useStrictTypeFiltering: false,
    };

    const { places } = await Place.searchByText(request);

    if (places.length) {
      const { LatLngBounds } = await loader.importLibrary('core') as google.maps.CoreLibrary;
      const bounds = new LatLngBounds();

      places.forEach((place) => {
        const markerView = new AdvancedMarkerElement({
          map,
          position: place.location,
          title: place.displayName,
        });

        bounds.extend(place.location as google.maps.LatLng);
      });

      map.fitBounds(bounds);
    } else {
      console.log("No couriers places found");
    }
  }

  /**
   * @param shipmentId
   * @description Get the evidence images to shipment details
   * @see PT-1146
   */
  getEvidencesImg(shipmentId: string) {
    //TODO: IMPLEMENTAR CON SERVICIO Y BORRAR EL OF
    return of({}).pipe(delay(1000))
  }

}
