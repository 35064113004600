import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from 'src/environments/environment';
import { RouteParamsUtilService } from 'src/app/core/services/util/route-params-util.service';

@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient,
    private paramsUtil: RouteParamsUtilService
  ) { }

  get<T>(path: string, params?: { [param: string]: any }): Observable<T> {
    const normalizedParms = this.paramsUtil.normalizeQueryParams(params);
    return this.http.get<T>(`${environment.apiUrl}${path}`, { params: normalizedParms });
  }

  put<T>(path: string, body: Object = {},
    options?: {
      headers?: HttpHeaders | {
        [header: string]: string | string[];
      };
    }): Observable<T> {
    return this.http.put<T>(`${environment.apiUrl}${path}`, body, options);
  }

  patch<T>(path: string, body: Object = {},
    options?: {
      headers?: HttpHeaders | {
        [header: string]: string | string[];
      };
    }): Observable<T> {
    return this.http.patch<T>(`${environment.apiUrl}${path}`, body, options);
  }

  post<T>(path: string, body: Object = {},
    options?: {
      headers?: HttpHeaders | {
        [header: string]: string | string[];
      };
    }): Observable<T> {
    return this.http.post<T>(`${environment.apiUrl}${path}`, body, options);
  }

  postFull360<T>(path: string, body: Object = {},
          options?: {
            headers?: HttpHeaders | {
              [header: string]: string | string[];
            };
          }): Observable<T> {
    return this.http.post<T>(`${environment.f360Url}${path}`, body, options);
  }

  delete<T>(path,
    options?: {
      headers?: HttpHeaders | {
        [header: string]: string | string[];
      };
    }): Observable<T> {
    return this.http.delete<T>(`${environment.apiUrl}${path}`, options);
  }

  getF360<T>(path: string, options?: { [param: string]: any }): Observable<T> {
    const normalizedOptions = this.paramsUtil.normalizeQueryParams(options);
    return this.http.get<T>(`${environment.f360Url}${path}`, normalizedOptions);
  }
  
}
