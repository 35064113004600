import { Injectable } from "@angular/core";
import { Subject, BehaviorSubject } from "rxjs";
import { ItemsFullfillment } from 'src/app/core/models/models';

@Injectable()
export class ShipmentObsService {

    // Shipment result
    private _shipmentsObservable: Subject<any> = new Subject();

    get getShipmentsObservable() {
        return this._shipmentsObservable.asObservable();
    }

    set setShipmentsObservable(data:any) {
        this._shipmentsObservable.next(data);
    }


    // Massive shipments
    data: any = null;

    private _massiveShipmentObservable: BehaviorSubject<any> = new BehaviorSubject<any>(this.data);

    get getMassiveShipmentObservable() {
        return this._massiveShipmentObservable.asObservable();
    }

    set setMassiveShipmentObservable(data:any) {
        this._massiveShipmentObservable.next(data);
    }


    // Processing massive shipments
    processing: any = 'stop';

    private _massiveProcessObservable: BehaviorSubject<any> = new BehaviorSubject<any>(this.processing);

    get getMassiveProcessObservable() {
        return this._massiveProcessObservable.asObservable();
    }

    set setMassiveProcessObservable(data:any) {
        this._massiveProcessObservable.next(data);
    }


}

