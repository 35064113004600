import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'suffix'
})
export class SuffixPipe implements PipeTransform {

    transform(value: string, suffix: string): string {
 
        if (value == undefined || value == null) { 
            return '';
        }

        return value + ' ' + suffix;

    }
}
