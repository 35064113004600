import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class DefaultHeadersInterceptor implements HttpInterceptor {

  constructor(private auth: AuthenticationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const headersConfig = {};
    const authToken = this.auth.getAccessToken();

    // Authorization header.
    if (authToken && !req.headers.has('Authorization')) {
      this.auth.isAPIKeyValid('header-interceptor');
      headersConfig['Authorization'] = authToken;
    }

    // Default Content-Type header.
    if ((req.method === 'POST' || req.method === 'PUT' || req.method === 'PATCH')
      && !req.headers.has('Content-Type')) {
      headersConfig['Content-Type'] = 'application/json';
    }

    // If there are headers to be added, clone the request and add the additional headers.
    if (Object.keys(headersConfig).length > 0) {
      const alteredReq = req.clone({ setHeaders: headersConfig });

      return next.handle(alteredReq);
    }

    // Return original request without any modification
    return next.handle(req);
  }
}
