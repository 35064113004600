import {Component, EventEmitter, Output, ViewChild} from '@angular/core';
import {GrowlSvc, ResellerService} from 'src/app/core/services';
import {ModalComponent} from '../../modal.component';

@Component({
  selector: 'app-modal-validate-data',
  templateUrl: './modal-validate-data.component.html',
  styleUrls: ['./modal-validate-data.component.scss']
})
export class ModalValidateDataComponent {
  @ViewChild(ModalComponent, { static: true }) private modal: ModalComponent;

  @Output() saved = new EventEmitter<any>();
  isLoadingRequest = false;
  order;
  itemData = [];

  // option = 'pakke';

  constructor(private resellerService: ResellerService,
              private growlSvc: GrowlSvc) { }

  show(order) {
    this.order = order;
    this.itemData = (order.Items && Array.isArray(order.Items)) ? order.Items
        .filter(itemInOrder => itemInOrder.ValidateInformation)
        .map(itemInOrder => {
          if (itemInOrder.Parcel) {
            itemInOrder.Width = itemInOrder.Parcel.Width;
            itemInOrder.Length = itemInOrder.Parcel.Length;
            itemInOrder.Hight = itemInOrder.Parcel.Height;
          }
          itemInOrder.option = 'pakke';
          itemInOrder.applyAll = false;
          return itemInOrder;
        })
      : [];
    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }

  modalConfirm() {
    this.isLoadingRequest = true;
    const dataRequest = [];
    for (const itemInOrder of this.itemData) {
      dataRequest.push(this.buildDimensionsFromOptionSelected(itemInOrder));
    }
    this.resellerService.updateItemDimensions(dataRequest).subscribe((response) => {
      this.isLoadingRequest = false;
      if (response.hasOwnProperty('errorCode')) {
        this.growlSvc.growlError(response.message);
      } else {
        this.growlSvc.growlSuccess('La información se ha guardado.');
        this.saved.emit(true);
        this.modal.hide();
      }
    }, (error) => {
      this.isLoadingRequest = false;
      this.growlSvc.growlError(error.message);
    });
  }

  private buildDimensionsFromOptionSelected(itemInOrder) {
    let dimensions = null;
    if (itemInOrder.option === 'pakke') {
      dimensions = {
        Weight: itemInOrder.Weight,
        Width: itemInOrder.Width,
        Length: itemInOrder.Length,
        Hight: itemInOrder.Hight
      };
    }
    if (itemInOrder.option === 'ecommerce') {
      dimensions = {
        Weight: itemInOrder.WeightEcommerce,
        Width: itemInOrder.WidthEcommerce,
        Length: itemInOrder.LengthEcommerce,
        Hight: itemInOrder.HightEcommerce
      };
    }
    dimensions.ResellerItemId = itemInOrder.ResellerItemId;
    dimensions.OrderId = itemInOrder.OrderId;
    dimensions.applyAll = itemInOrder.applyAll;
    return dimensions;
  }

  selectOption(option, indexItem) {
    this.itemData[indexItem].option = option;
  }

}
