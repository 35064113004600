import { Component, OnInit, ViewChild, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from '../../core/services/authentication.service';
import { LoggedUserState, ResellerAddress } from '../../core/models/models';
import { from, Observable, of, Subscription } from 'rxjs';
import { ResellerService } from '../../core/services/domain/reseller.service';
import { CargosPendientes } from 'src/app/core/models/models';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../core/services/common.service';
import {AddressType} from '../../core/constants';
import { ResellerObsService } from 'src/app/core/services/sharing/reseller-obs.service';

@Component({
    templateUrl: './frequent-address.component.html',
    styleUrls: ['./frequent-address.component.scss'],
    selector: 'frequent-address'
  })
export class FrequentAddressComponent implements OnInit, OnDestroy {

    @Input() type: AddressType = 0;
    @Input() frequentAddresses: ResellerAddress[];
    @Input() tight = false;
    @Input() frequentAddressToForm = false;
    private _selectedAddresses: ResellerAddress;
    @Input() get selectedAddresses(): ResellerAddress {
        return this._selectedAddresses;
    };
    @Input() maxAddresses: boolean | undefined;
    set selectedAddresses(value: ResellerAddress) {
        this._selectedAddresses = value;
        this.selectedAddressesChange.emit(value);
    }
    @Output() selectedAddressesChange = new EventEmitter<ResellerAddress>();
    @Output() onClose = new EventEmitter<boolean>();
    @Output() selectionChange = new EventEmitter<ResellerAddress>();

    filteredFrequentAddresses: ResellerAddress[];
    filteredSwitch = false;
    countryCode = environment.countryCode;

    get frequentAddressList(): ResellerAddress[] {
      if (this.countryCode === 'ES' && this.type === 0) {
        this.filteredFrequentAddresses = this.filteredFrequentAddresses ? this.filteredFrequentAddresses.filter(value => value.Country === this.countryCode) : this.filteredFrequentAddresses;
        this.frequentAddresses = this.frequentAddresses ? this.frequentAddresses.filter(value => value.Country === this.countryCode) : this.frequentAddresses;
      }
      if (this.filteredSwitch) {
          return this.filteredFrequentAddresses;
      } else {
          return this.frequentAddresses;
      }
    }

    user: LoggedUserState;
    private userStateSubscription: Subscription;

    constructor(
        private authSvc: AuthenticationService,
        private resellerSvc: ResellerService,
        private router: Router,
        private commonService: CommonService,
        private resellerObsService: ResellerObsService
    ) {
        // this.userStateSubscription = this.authSvc.user$.subscribe(userState => this.user = userState);
        this.userStateSubscription = this.resellerObsService.getUserStateObservable.subscribe(userState => this.user = userState);
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.userStateSubscription.unsubscribe();
    }

    isSelected(address: ResellerAddress): boolean {
        return !!this._selectedAddresses && !!this._selectedAddresses.AddressId && this._selectedAddresses.AddressId === address.AddressId;
    }

    select(address: ResellerAddress) {
        const addr = JSON.parse(JSON.stringify(address));
        this.selectedAddresses = addr;
        this.selectionChange.emit(this.selectedAddresses);
    }

    close() {
        this.onClose.emit(false);
    }

    search(terms: string) {
        terms = terms.toLowerCase();
        if (!terms || !terms.length) { this.filteredSwitch = false; this.filteredFrequentAddresses = []; return ; }
        this.filteredSwitch = true;
        this.filteredFrequentAddresses = this.frequentAddresses.filter(item => {
            if (!!item.AddressAlias && item.AddressAlias.toLowerCase().indexOf(terms) >= 0) { return item; }
            if (!!item.City && item.City.toLowerCase().indexOf(terms) >= 0) { return item; }
            if (!!item.ZipCode && item.ZipCode.toLowerCase().indexOf(terms) >= 0) { return item; }
            if (!!item.Address1 && item.Address1.toLowerCase().indexOf(terms) >= 0) { return item; }
            if (!!item.Address2 && item.Address2.toLowerCase().indexOf(terms) >= 0) { return item; }
            if (!!item.CompanyName && item.CompanyName.toLowerCase().indexOf(terms) >= 0) { return item; }
            if (!!item.Email && item.Email.toLowerCase().indexOf(terms) >= 0) { return item; }
            if (!!item.ContactName && item.ContactName.toLowerCase().indexOf(terms) >= 0) { return item; }
            if (!!item.Phone1 && item.Phone1.toLowerCase().indexOf(terms) >= 0) { return item; }
            if (!!item.Neighborhood && item.Neighborhood.toLowerCase().indexOf(terms) >= 0) { return item; }
        });

        // this.paginator.chageData(this.filteredOrders);

    }
}
