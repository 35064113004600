// import { Injectable } from '@angular/core';

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs-compat/Observable";
import { ApiService } from ".";
import { LogUtilService } from 'src/app/core/services/util/log.service';

@Injectable ()

export class PaycometBizumService {


  constructor(
    private apiService: ApiService,
    private logHelper: LogUtilService
    ) { }

  sendPaymentOrder(payment: any): Observable<any> {
    const url = 'Reseller/rechargeWalletPaymentsBizum';

    this.logHelper.info("Payment",null,payment,"Request recharge");
    console.log("Requesting API BIZUM order payment.");

    return this.apiService.post(url, payment);

  }
}
