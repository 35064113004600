import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-shadowed-card',
  template: `
      <div class="card p-3 card-shadow">
          <ng-content></ng-content>
      </div>
  `,
  styles: [`.card-shadow {
      border: none;
      box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.1);
      transition: 0.3s;
  }`, `.card-shadow:hover {
      box-shadow: 8px 8px 8px 0 rgba(0, 0, 0, 0.1);
  }`]
})
export class ShadowedCardComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }

}
