import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { map } from "rxjs/operators";
import { AuthenticationService, CommonService, GrowlSvc, UserService } from "../services";
import { AuthService, SocialUser } from "angularx-social-login";
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthUser } from "../models/models";
import { LoadingSpinnerService } from "src/app/shared/loadingspinner/loading-spinner.service";
import { FormBuilder } from "@angular/forms";
import { MatSnackBar } from "@angular/material";
import { LoginComponent } from 'src/app/views/login/login.component';
import { TranslateService } from "@ngx-translate/core";
import { LayoutService } from "src/app/containers/default-layout/service/layout.service";

export class PreAuthGuard implements CanActivate {

  socialSubscription: Subscription;
  socialUser: SocialUser;
  flagShowSocialNetworksLogin = false;
  userLog = new AuthUser();
  loginComponent: LoginComponent = new LoginComponent(
    this._authSvc,
    this._userSvc,
    this.loading,
    this._growlSvc,
    this._fb,
    this.commonSvc,
    this.snackBar,
    this.route,
    this.translate,
    this.activatedRoute,
    this.layoutService,
    this.socialAuthService,
    this._router,
  );

  constructor(
    private _authSvc: AuthenticationService ,
    private _router: Router,
    private _growlSvc: GrowlSvc,
    private _userSvc: UserService,
    private socialAuthService: AuthService,
    private loading: LoadingSpinnerService,
    private _fb: FormBuilder,
    public commonSvc: CommonService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private translate: TranslateService ,
    private activatedRoute:ActivatedRoute,
    public layoutService: LayoutService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.flagShowSocialNetworksLogin = environment.socialNetworks.showLoginOptions;

    if (route && route.params && route.params.token && route.params.uid &&
      route.queryParams && route.queryParams.path)  {
        const TOKEN = route.params.token;
        const UID = route.params.uid;
        const PATH = route.queryParams.path;

        return this._userSvc.confirmSignUpLoginExternalSites({token: TOKEN, uid: UID})
          .pipe(
            map(userState => {
              this._authSvc.setTokenLogin(userState, PATH);
              // this._router.navigate(['/'+PATH], { replaceUrl: true });
              return true;
            })
          )
          .toPromise()
          .then(() => {
            return true
          })
          .catch(error => {
            this._growlSvc.growlError(error, true);
            this._router.navigate(['/login']);
            return false;
          } )
    }

    else if (route && route.params && route.params.token && route.queryParams && route.queryParams.path)  {
      const TOKEN = route.params.token;
      const PATH = route.queryParams.path;

      if (this.flagShowSocialNetworksLogin) {
        if (TOKEN === 'facebook') {
          this.loginComponent.signInWithFB(`/${PATH}`);
            return true;
        }
        else if (TOKEN === 'google') {
          this.loginComponent.signInWithGoogle(`/${PATH}`);
          return true;
        }
      }

      return this._authSvc.loginByTokenExternalSites(TOKEN)
        .pipe(
          map(userState => {
            this._authSvc.setTokenLogin(userState, PATH);
            // this._router.navigate(['/'+PATH], { replaceUrl: true });
            return true;
          })
        )
        .toPromise()
        .then(() => {
          return true
        })
        .catch(error => {
          this._growlSvc.growlError(error, true);
          this._router.navigate(['/login']);
          return false;
        } )
    }
    this._router.navigate(['/login']);
    return false;
  }

}
