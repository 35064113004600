import { Component,OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from '../../core/services/common.service';

import { GrowlSvc } from '../../core/services/growl.service';
import { AuthenticationService } from '../../core/services/authentication.service';
import { UserService } from '../../core/services/user.service';
import { ResellerService } from '../../core/services/domain/reseller.service';

import { AuthUser, ConfirmCredentials, AccessTokenInfo, LoggedUserState } from '../../core/models/models';
import { finalize, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { GradientLayoutComponent } from '../../containers/gradient-layout/gradient-layout.component'
import { LayoutService } from 'src/app/containers/default-layout/service/layout.service';
import { CookieService } from 'ngx-cookie-service';
import { LoadingSpinnerService } from 'src/app/shared/loadingspinner/loading-spinner.service';
// Social login
import { AuthService, SocialUser } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { Subscription } from 'rxjs';

declare var gtag: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit{
  CountryCode = environment.countryCode;
  fullfillment = false;
  fulfillmentLogin = !!environment.fulfillmentLogin;
  fulfillmentProduct = environment.fulfillmentProduct;
  userLog = new AuthUser();
  userReg = new AuthUser();
  acceptedTerms = true;
  laddaLogin = false;
  laddaRegister = false;
  resetPwdEmail: string;
  bCodigoRef : boolean;
  referralCode: string;
  // loginCountry: string;
  termsAndConditions: string;
  routes="";
  authToken: string = "";
  logoUrl= environment.branding.mainLogoUrl;
  private cachedAccessToken: AccessTokenInfo = null;
  opts=environment;

  public currentDate = new Date();

  socialSubscription: Subscription;
  socialUser: SocialUser;
  flagShowSocialNetworksLogin = false;
  environmentCode: string = environment.environmentCode;

  constructor(
    private _authSvc: AuthenticationService,
    private _userSvc: UserService,
    private loading: LoadingSpinnerService,
    private _growlSvc: GrowlSvc,
    private _fb: FormBuilder,
    public commonSvc: CommonService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private activatedRoute:ActivatedRoute,
    public layoutService: LayoutService,
    private socialAuthService: AuthService,
    private _router: Router,
  ) {
    this.bCodigoRef = environment.codigoRef;
    this.referralCode = this.route.snapshot.queryParamMap.get('referralCode');
    this.createRegisterForm();
    const MostrarMensaje = false;
    if(MostrarMensaje)
      this.openSnackBar();
  }
  ngOnInit(){
    const url = this.activatedRoute.snapshot.routeConfig.path;
    this.flagShowSocialNetworksLogin = environment.socialNetworks.showLoginOptions;
    if( this.fulfillmentLogin || (url == "fulfillment/login" && environment.countryCode=='MX') )
    {
      this.fullfillment=true;
      this.layoutService.setFullfillment(true);
    } else if( url == "saldos-y-pagos") {
      this.fullfillment=false;
      this.layoutService.setFullfillment(false);
      this.route.queryParams.subscribe (params => {
        this.authToken = params.auth;
        if(this.authToken != undefined) {
          this.loading.show();
          this._authSvc.loginByToken(this.authToken, false).subscribe((result:any) => {
            result = Object.assign(result, { path: 'saldo-y-pagos' });
            this._authSvc.setTokenLogin(result);
            this.loading.hide();
          }, (err) => {
             this.loading.hide();
            this._growlSvc.growlError("Al parecer tu token de autentificación a caducado o es inválido.", false);
          });
        }
      });
    }
    else
    {
      this.fullfillment=false;
      this.layoutService.setFullfillment(false);
      this.route.queryParams.subscribe (params => {
        this.authToken = params.auth;
        if(this.authToken != undefined) {
          this.loading.show();
          this._authSvc.loginByToken(this.authToken, true).subscribe((result:any) => {
            this._authSvc.setTokenLogin(result);
            this.loading.hide();
          }, (err) => {
             this.loading.hide();
            this._growlSvc.growlError("El token enviado ya no es valido, pero puedes iniciar sesión manualmente.", false);
          });
        }
      });
    }
  }
  openSnackBar() {
    const message =  'Estaremos en mantenimiento del 07 de febrero del 2019 a las 23:00 hrs hasta las 05:00 hrs del 08 de febrero del 2019';
    const action = 'Enterado';
    this.snackBar.open(message, action, {
      duration: 45000,
    });
  }

  login() {
    this.laddaLogin = true;
    this.userLog.ttl = environment.apittl;
    this.userLog.fullfillment=this.fullfillment;
    const url=this.activatedRoute.snapshot.routeConfig.path;
    this._authSvc.login(this.userLog,url)
      .pipe(finalize(() => this.laddaLogin = false))
      .subscribe(undefined, err => this._growlSvc.growlError(err, /*sticky*/ false));
  }

  register() {
    if (!this.acceptedTerms === true) {
      this._growlSvc.growlWarn('Para continuar, es necesario que aceptes los términos y condiciones');
      return;
    }

    this.laddaRegister = true;
    this.userReg.name = this.registerForm.value.Name;
    this.userReg.email = this.registerForm.value.Email;
    this.userReg.password = this.registerForm.value.Password;
    this.userReg.confirm = this.registerForm.value.Password;
    this.userReg.ReferralCode = this.registerForm.value.Referido ? this.registerForm.value.Referido : '';
    this.userReg.acceptedTerms = this.acceptedTerms;

    this._userSvc.registerUser(this.userReg)
      .subscribe(
        res => {
          // Event snippet for Post-impresion conversion page
          gtag('event', 'conversion', {'send_to': 'AW-780221802/u9ARCOrWipMBEOr6hPQC'});

          this.confirm(res);
        },
        err => {
          this._growlSvc.growlError(err);
          this.laddaRegister = false;
        });
  }

  registerForm: FormGroup;
  registerFormSubmitted = false;

  private passwordComplexityValidator(control: FormControl): { [key: string]: boolean } | null {
    const regex = /^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W])|(?=.*\W)(?=.*\d))|(?=.*\W)(?=.*[A-Z])(?=.*\d)).{8,}$/gm;
    const result = regex.test(control.value);
    if (!result) {
      return {
        passwordComplexity: !result // false = theres no error
      };
    } else {
      return null;
    }
  }

  private createRegisterForm() {
    this.registerForm = this._fb.group({
      Name: [null, Validators.required],
      Email: [null, Validators.compose([Validators.required, Validators.email])],
      Password: [null, Validators.compose([Validators.required, this.passwordComplexityValidator])],
      Referido: [this.referralCode, null]
    });
  }

  confirm(cred: ConfirmCredentials) {
    this._userSvc.confirmUser(cred)
      .subscribe(
        res => {
          this.userLog.email = this.userReg.email;
          this.userLog.password = this.userReg.password;
          this.login();
        },
        err => {
          this.userLog.email = null;
          this.userLog.password = null;
          this._growlSvc.growlError(err);
          this.laddaRegister = false;
        });
  }

  clearRegisterForm() {
    this.registerForm.reset();
    this.registerFormSubmitted = false;
    this.registerForm.markAsPristine();
    this.registerForm.markAsUntouched();
    this.populateForm();
  }

  populateForm() {
     this.registerForm.controls['Referido'].setValue(this.referralCode);
  }

  resetPwd() {
    this._userSvc.resetPassword(this.resetPwdEmail)
      .subscribe(
        res => this._growlSvc.growlSuccess('¡Casi terminas! Ahora revisa tu cuenta de correo electrónico, te hemos enviado un mensaje con una liga para que puedas restablecer tu contraseña. Por favor toma en cuenta que la liga expira en 15 min.',
          /*sticky*/ true),
        err => this._growlSvc.growlError(err)
      );
  }

  signInWithGoogle(path=''): void {
    this.socialLoginSubscribe('Google', path);
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(path=''): void {
    this.socialLoginSubscribe('Facebook', path);
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  socialLoginSubscribe(socialNetwork: string, path='') {
    this.socialSubscription = this.socialAuthService.authState.subscribe(async (user) => {
      if( this.socialUser ) { return; }
      this.socialUser = user;
      if (user && user.email) {
        this.loading.show();
        try {
          localStorage.setItem('socialLogin', socialNetwork);
          const loginResponse: any = await this._authSvc.signInSocialNetwork(user.email, user.name, socialNetwork, this.fullfillment).toPromise();
          if (loginResponse.isLogin) {
            this._authSvc.setTokenLogin(loginResponse, path);
            return;
          }

          await this._userSvc.confirmUser(loginResponse).toPromise();
          this.userLog.email = user.email;
          this.userLog.password = loginResponse.password;
          this.login();
        } catch (error) {
          this._growlSvc.growlError(error);
        } finally {
          this.socialUser = null;
          this.socialSubscription.unsubscribe();
          this.loading.hide();
        }
      }
    });
  }

  confirmSocialRegister(credential: ConfirmCredentials) {
    this._userSvc.confirmUser(credential)
      .subscribe(
        () => {
          this.userLog.email = this.userReg.email;
          this.userLog.password = this.userReg.password;
          this.login();
        },
        err => {
          this.userLog.email = null;
          this.userLog.password = null;
          this._growlSvc.growlError(err);
        });
  }

  redirectToLanding() {
    window.location.href = 'https://www.pakke.com/mx-es/search_superposicion/';
  }

}
