import { Component, EventEmitter, Output, Input, OnChanges } from '@angular/core';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { PagingInfo } from 'src/app/core/models/models';

const DEFAULT_PAGE_SIZE = 30;

@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.scss']
})
export class PagerComponent implements OnChanges {
  @Input() legendInPager: string;
  @Input() title: string;
  /** The total number of items that are being paginated. Defaulted to 0. */
  @Input() totalItems = 0;

  /** The page number of the displayed list of items. Defaulted to 1. */
  @Input()
  get pageNumber(): number { return this._pageNumber; }
  set pageNumber(value: number) {
    this._pageNumber = Math.max(coerceNumberProperty(value), 1);
  }
  private _pageNumber = 1;

  /** Number of items to display on a page. Defaulted to DEFAULT_PAGE_SIZE. */
  @Input()
  get pageSize(): number { return this._pageSize; }
  set pageSize(value: number) {
    this._pageSize = coerceNumberProperty(value);
    this._pageSize = value > 0 ? this._pageSize : DEFAULT_PAGE_SIZE;
    this.pageSizeText = String(this._pageSize);
  }
  pageSizeText = String(DEFAULT_PAGE_SIZE);
  private _pageSize: number = DEFAULT_PAGE_SIZE;

  @Output() pageInfoChanged = new EventEmitter();

  pagesState: {
    numberOfPages: number;
    startPage: number,
    endPage: number,
    pages: number[]
  };

  constructor() {
    this.calculatePagesState();
  }

  numberMask = createNumberMask({
    prefix: '',
    includeThousandsSeparator: false,
    thousandsSeparatorSymbol: '',
    allowLeadingZeroes: true
  });

  ngOnChanges() {
    this.calculatePagesState();
  }

  goToPage(pageNumber: number) {
    let pageSizeLocal: number;
    if(coerceNumberProperty(this.pageSizeText, DEFAULT_PAGE_SIZE) >= 100){
        pageSizeLocal = 100
        this.pageSizeText = '100'
    }else{
      pageSizeLocal = coerceNumberProperty(this.pageSizeText,DEFAULT_PAGE_SIZE)
    }
    
    this.pageNumber = pageNumber;
    this.pageSize = coerceNumberProperty(pageSizeLocal, DEFAULT_PAGE_SIZE);
    this.calculatePagesState();

    const startRecordIndex = (this.pageNumber - 1) * this.pageSize;
    const endRecordIndex = startRecordIndex + this.pageSize - 1;

    this.pageInfoChanged.emit(<PagingInfo>{
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      startRecordIndex: startRecordIndex,
      endRecordIndex: endRecordIndex
    });
  }

  onPageSizeKeyUpEnter() {
    this.goToPage(1);
  }

  private calculatePagesState() {
    const numberOfPages = Math.max(Math.ceil(this.totalItems / this.pageSize), 1);

    let startPage: number, endPage: number;
    if (numberOfPages <= 10) {
      // Less than 10 total pages, show them all
      startPage = 1;
      endPage = numberOfPages;
    } else {
      // More than 10 total pages. Calculate start and end pages
      if (this.pageNumber <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (this.pageNumber + 4 >= numberOfPages) {
        startPage = numberOfPages - 9;
        endPage = numberOfPages;
      } else {
        startPage = this.pageNumber - 5;
        endPage = this.pageNumber + 4;
      }
    }

    // if requested page is greater than existing, set to last one.
    // Don't alter the pageNumber if totalItems is zero.
    if (this.totalItems && this.pageNumber > numberOfPages) { this.pageNumber = endPage; }

    // create an array of pages
    const pages = Array.from(Array(endPage + 1 - startPage), (_, i) => startPage + i);

    this.pagesState = {
      numberOfPages: numberOfPages,
      startPage: startPage,
      endPage: endPage,
      pages: pages
    };
  }
}

function coerceNumberProperty(value: any, fallbackValue = 0): number {
  const numberValue = Number(value);
  return value && !Number.isNaN(numberValue) ? numberValue : fallbackValue;
}

