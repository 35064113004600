export const AddressTypes = [
    {
        name: 'origen',
        notifications: false
    },
    {
        name: 'destino',
        notifications: true
    }
];
