import { Component, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { ModalComponent } from '../../shared/components/modal.component';

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html'
})
export class MessageModalComponent {
  @Input() header?: string;
  @ViewChild(ModalComponent , { static : true } ) private modal: ModalComponent;

  show() {
    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }
}
