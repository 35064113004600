import { DefaultUrlSerializer, UrlTree } from '@angular/router';

export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    const queryParamsStart = url.indexOf('?');

    if (queryParamsStart < 0) {
      return super.parse(url.toLowerCase());
    }

    const path = url.substring(0, queryParamsStart);
    const queryParms = url.substr(queryParamsStart, url.length - path.length);

    return super.parse(path.toLowerCase() + queryParms);
  }
}
