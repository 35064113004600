import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Inject, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';

// import Pipes
import { IconSourcePipe } from './shared/pipes/icon-source.pipe';

// Import containers
import { DefaultLayoutComponent, GradientLayoutComponent, PublicLayoutComponent, GradientRegisterLayoutComponent } from './containers';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { AmazingTimePickerModule } from 'amazing-time-picker';
// Import routing module
import { AppRoutingModule } from './app.routing';
// Import 3rd party components
import { LaddaModule } from 'angular2-ladda';
import { LoadingSpinnerComponent } from './shared/loadingspinner/loading-spinner.component';
import { MatNativeDateModule } from '@angular/material/core';
import {PakkeMaterialModule} from './material-module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RateComponent } from './views/general/rate.component';
import { RateDialogComponent } from './views/general/rate-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LayoutService } from './containers/default-layout/service/layout.service';
import {MatRadioModule} from '@angular/material/';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
//Translation
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SearchModule } from 'src/app/shared/search/search.module';
import { LoginModule } from './views/login/login.module';
import { OnBoardingComponent } from './on-boarding/on-boarding.component';
import { OnBoardingService } from './on-boarding/service/on-boarding.service';
import { OnBoardingModule } from './on-boarding/on-boarding.module';
import { TooltipModule } from 'primeng/tooltip';
import { ChartsModule } from 'ng2-charts';
import { FusionChartsModule } from "angular-fusioncharts";
// Import FusionCharts library and chart modules
import * as FusionCharts from "fusioncharts";
import * as charts from "fusioncharts/fusioncharts.charts";
import * as FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
// Snipper
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxMaskModule } from 'ngx-mask';
//Social login
import { environment } from 'src/environments/environment';
import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import {NgxFaviconModule} from 'ngx-favicon';
import {AvailableCustomFavicons, customFavicons} from './favicon.config';
import { StoreService } from './views-reseller/store/service/store.service';
import {HideComponentModule} from './directives/hide-component/hide-component.module';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ResellerInboxModule } from './shared/components/reseller-inbox/reseller-inbox.module';
import { ResultMassiveGuidesModule } from './views-reseller/shipments/result-massive-guides/result-massive-guides.module';

const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(environment.GOOGLE_CLIENT_ID)
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(environment.FB_APP_ID)
  }
]);

export function socialProvideConfig() {
  return config;
}

// Pass the fusioncharts library and chart modules
FusionChartsModule.fcRoot(FusionCharts, charts, FusionTheme);

const APP_CONTAINERS = [
  DefaultLayoutComponent,
  GradientLayoutComponent,
  PublicLayoutComponent,
  GradientRegisterLayoutComponent
];

@NgModule({
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoadingSpinnerComponent,
    RateComponent,
    RateDialogComponent,
  ],
  entryComponents: [
    LoadingSpinnerComponent,
    RateDialogComponent
  ],
  imports: [
    ResellerInboxModule,
    SearchModule,
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    TooltipModule,
    HttpClientModule,
    CoreModule.forRoot(),
    AppRoutingModule,
    AmazingTimePickerModule,
    FusionChartsModule,
    NgxSpinnerModule,
    LaddaModule.forRoot({
      style: 'expand-right',
      spinnerColor: 'white'
    }),
    OnBoardingModule,
    SharedModule,
    SocialLoginModule,
    MatNativeDateModule,
    PakkeMaterialModule,
    FormsModule,
    ReactiveFormsModule
    , ChartsModule
    , RouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [ HttpClient ]
      }
    }),
    NgxMaskModule.forRoot(),
    PopoverModule.forRoot(),
    NgxFaviconModule.forRoot<AvailableCustomFavicons>({
      faviconElementId: 'favicon',
      defaultUrl: 'favicon.ico',
      custom: customFavicons,
    }),
    HideComponentModule,
    ResultMassiveGuidesModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    LayoutService,
    StoreService,
    {
      provide: AuthServiceConfig,
      useFactory: socialProvideConfig
    },
    IconSourcePipe
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  //constructor(@Inject(CURRENCY_CODE) private _currencyCode: string){}
 }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
