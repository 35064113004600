import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';

import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';

@Injectable()
export class LoggedGuard implements CanActivate {
  constructor(
    private _authSvc: AuthenticationService,
    private _router: Router
  ) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._authSvc.user$.pipe(
      map(userState => {
        if (this._authSvc.getAccessToken()) {
          if (userState.isFullfillment) {
            this._router.navigate(['/fulfillment/dashboard']);
          } else if (userState.role === 'HELP_DESK'){
            this._router.navigate(['/helpdesk']);
          } else {
            this._router.navigate(['/dashboard']);
          }
          return false;
        }
        return true;
      })
    );
  }
}
