import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'trackingStatusColorFF'
})
export class TrackingStatusColorFFPipe implements PipeTransform {
    constructor() { }

    transform(status: any): string {
        switch (status) {
            case 'WAITING': return '#60b361'; // Verde      
            case 'IN_TRANSIT': return '#60b361';          
            case 'ON_DELIVERY': return '#60b361';
            case 'DELIVERED': return '#60b361';         
            case 'EXCEPTION': return 'rgb(143, 95, 73)'; // Cafe 
            case 'RETURNED': return 'rgb(143, 95, 73)';
            case 'CANCELLED': return 'rgb(143, 95, 73)';
            case 'UNKNOWN': return 'rgb(143, 95, 73)';
            case 'Surtido': return '#60b361';
            case 'Preparación': return '#60b361';
            default: return 'grey';
        }
    };
} 