import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { GrowlModule } from 'primeng/growl';
import { ModalComponent } from './components/modal.component';
import { ConfirmationModalComponent } from './components/confirmation-modal.component';
import { ConfirmationCommentModalComponent } from './components/confirmation-comment-modal.component';
import { ErrorsOrdersModalComponent } from './components/errors-orders-modal.component';
import { HistoryOrdersModalComponent } from './components/history-orders-modal.component';
import { LaddaModule } from 'angular2-ladda';
import { MyDatePickerModule } from 'mydatepicker';
import { MessageModalComponent } from './components/message-modal.component';
import { HomeComponent } from './components/home.component';
import { ShipmentQuotedAmountDetailComponent } from './components/shipment-quoted-amount-detail.component';
import { ShipmentAclarationComponent } from './components/shipment-aclaration.component';
import { PagerComponent } from './components/pager.component';
import { GoBackButttonComponent } from 'src/app/shared/components/go-back-button.component';
import { PagedSearchViewComponent } from 'src/app/shared/forms/paged-search-view.component';
import { PagedSearchViewWithUrlStateComponent } from 'src/app/shared/forms/paged-search-view-with-url-state.component';
import { CourierNamePipe } from './pipes/courier-name.pipe';

import { PendingPaymentsComponent } from '../views-reseller/account-deposits/pending-payments.component';

import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';

import { RouterModule } from '@angular/router';
import { SesionModalComponent } from './components/modals/sesion/sesion-modal.component';
import { PipesModule } from './pipes.module';
import { ModalModule } from './modal.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TooltipModule } from 'primeng/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ShadowedCardComponent } from './components/shadowed-card.component';
import { DropdownMenuComponent } from '../views-reseller/orders/dropdown-menu/dropdown-menu.component';
import { MatMenuModule, MatButtonModule } from '@angular/material';
import { MatAutocompleteModule, MatTabsModule } from '@angular/material';
import {NgxSpinnerModule} from 'ngx-spinner';
import { ChartComponent } from './chart/chart.component';
import { ChartsModule } from 'ng2-charts';
import { ModalConfirmGenericComponent } from './components/modals/modal-confirm-generic/modal-confirm-generic.component';
import { AlertBannerComponent } from './components/alert-banner/alert-banner.component';
import { ConsigmentNoteComponent } from './components/modals/consigment-note/consigment-note.component';
import { CustomTooltipDirective } from '../directives/custom-tooltip.directive';
import { ModalValidateDataComponent } from './components/modals/validate-item-data/modal-validate-data.component';
import { MetamapBannerComponent } from './components/metamap-banner/metamap-banner.component';
import { InboxDirective } from '../directives/inbox/inbox.directive';
import { InboxDirectiveModule } from '../directives/inbox/inbox-directive.module';
import { PakkeVerifyComponent } from './components/pakke-verify/pakke-verify.component';
import { CodeInputModule } from 'angular-code-input';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { LoaderUI } from './loader/loader.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TextMaskModule,
    GrowlModule,
    MatTableModule,
    MatDialogModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatMenuModule,
    RouterModule,
    PipesModule,
    ModalModule,
    MatButtonModule,
    MatTooltipModule,
    TooltipModule,
    TranslateModule,
    MatAutocompleteModule,
    MatTabsModule,
    LaddaModule,
    NgxSpinnerModule,
    ChartsModule,
    InboxDirectiveModule,
    CodeInputModule,
    NgxIntlTelInputModule
  ],
  declarations: [
    PagerComponent,
    DropdownMenuComponent,
    PendingPaymentsComponent,
    ConfirmationModalComponent,
    ConfirmationCommentModalComponent,
    ErrorsOrdersModalComponent,
    HistoryOrdersModalComponent,
    MessageModalComponent,
    SesionModalComponent,
    ShipmentQuotedAmountDetailComponent,
    ShipmentAclarationComponent,
    GoBackButttonComponent,
    HomeComponent,
    ShadowedCardComponent,
    ChartComponent,
    ModalConfirmGenericComponent,
    AlertBannerComponent,
    ConsigmentNoteComponent,
    CustomTooltipDirective,
    ModalValidateDataComponent,
    MetamapBannerComponent,
    PakkeVerifyComponent,
    LoaderUI,
  ],
  exports: [
    PagerComponent,
    DropdownMenuComponent,
    PendingPaymentsComponent,
    ConfirmationModalComponent,
    ConfirmationCommentModalComponent,
    ErrorsOrdersModalComponent,
    HistoryOrdersModalComponent,
    MessageModalComponent,
    SesionModalComponent,
    ShipmentQuotedAmountDetailComponent,
    ShipmentAclarationComponent,
    GoBackButttonComponent,
    HomeComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
    GrowlModule,
    LaddaModule,
    MyDatePickerModule,
    PipesModule,
    ModalModule,
    TranslateModule,
    ShadowedCardComponent,
    ChartComponent,
    ModalConfirmGenericComponent,
    AlertBannerComponent,
    ConsigmentNoteComponent,
    CustomTooltipDirective,
    InboxDirective,
    ModalValidateDataComponent,
    MetamapBannerComponent,
    PakkeVerifyComponent,
    LoaderUI,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
