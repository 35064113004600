import { Pagination } from "src/app/shared/paginator/pagination";

export class ResellerInboxPagination extends Pagination {
  private selectedNotification: any[] = [];
  selectAllCheckbox = false;
  maxPages = 3;

  chageData(data: any[] = []) {
    if (data.length && this.selectedNotification.length) {
      data = data.map(notification => {
        this.selectedNotification.forEach(element => {
          if (notification.InboxHistoryId === element.InboxHistoryId) {
            return notification;
          }
        });
        return notification;
      });
    }
    super.chageData(data);
  }

  getPages(): number[] {
    if (this.pages.length > this.maxPages) {
        const pages: number[] = [];
        const currentPage = this.getCurrentPage();
        if (currentPage === 1) {
            for (let i = 1; i <= this.maxPages; i++) {
                pages.push(i);
            }
        } else if (currentPage === this.pages.length) {
            for (let i = (this.pages.length - this.maxPages + 1 ); i <= this.pages.length; i++) {
                pages.push(i);
            }
        } else {
            let sidepages = this.maxPages / 2;
            let lefpage = currentPage - sidepages + 1;
            let rightpage = currentPage + sidepages;
            if (this.maxPages % 2) {
                sidepages = (this.maxPages - 1) / 2;
                lefpage = currentPage - sidepages;
                rightpage = currentPage + sidepages;
            }
            if (lefpage < 1) {
                lefpage = 1;
                rightpage = this.maxPages;
            } else if (rightpage > this.pages.length ) {
                rightpage = this.pages.length ;
                lefpage = rightpage - this.maxPages + 1;
            }
            for (let i = lefpage; i <= rightpage; i++) {
                pages.push(i);
            }
        }
        return pages;
    }
    return this.pages;
}


}
   